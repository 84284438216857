import { useState } from 'react';
import { Button, Col, Form, Input, message, Modal, Radio, Row, Typography } from 'antd';
import { Customer, saveCustomer } from "Models/Bus/Customer"
import { PlusOutlined } from '@ant-design/icons';
import { InvoiceInterval, invoiceIntervalLabel } from 'Models/Enum';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { antOibValidator } from "Components/Validation"
import { useDataSourceRef } from 'net-common-ui';
import { dsoSettlement } from 'Models/Codebook/Settlement';

interface Props {
    onClose: (id?: number) => void;
}

const verticalRadioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

export const NewButton = ({ onClose }: Props) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });

    const showModal = () => {
        setVisible(true);
    };

    const close = () => {
        setVisible(false);
        onClose();
    }

    const handleSave = () => {
        form.validateFields().then((model: Customer) => {
            setSaving(true);
            saveCustomer(model).execute().then(result => {
                if (result.success) {
                    message.success("Korisnik uspješno spremljen.");
                    onClose(result.item.id);
                    setVisible(false);
                }
                setSaving(false);
            });
        });
    };
    return (
        <>
            <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>Novi korisnik</Button>
            <Modal
                title="Novi korisnik"
                visible={visible}
                onOk={handleSave}
                okText="Spremi"
                confirmLoading={saving}
                destroyOnClose
                width={700}
                onCancel={close}
            >
                <Form form={form} layout="vertical" name="form" initialValues={{ invoiceIntervalId: InvoiceInterval.Montly }} onFinish={handleSave} preserve={false}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Typography.Title level={3}>Osnovno</Typography.Title>
                            <Form.Item name="name" label="Naziv" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="oib" label="OIB" rules={[{ required: true }, { validator: antOibValidator }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="iban" label="IBAN" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="invoiceIntervalId" label="Interval fakturiranja" rules={[{ required: true }]}>
                                <Radio.Group>
                                    <Radio style={verticalRadioStyle} value={InvoiceInterval.Montly}>{invoiceIntervalLabel(InvoiceInterval.Montly)}</Radio>
                                    <Radio style={verticalRadioStyle} value={InvoiceInterval.BiMonthly}>{invoiceIntervalLabel(InvoiceInterval.BiMonthly)}</Radio>
                                    <Radio style={verticalRadioStyle} value={InvoiceInterval.Weekly}>{invoiceIntervalLabel(InvoiceInterval.Weekly)}</Radio>
                                    <Radio style={verticalRadioStyle} value={InvoiceInterval.Daily}>{invoiceIntervalLabel(InvoiceInterval.Daily)}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Typography.Title level={3}>Sjedište</Typography.Title>
                            <Form.Item label="Adresa">
                                <Form.Item name="street" noStyle rules={[{ required: true, message: "Ulica je obavezan unos." }]}>
                                    <Input placeholder="Ulica" maxLength={200} style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
                                </Form.Item>
                                <Form.Item name="streetNo" noStyle rules={[{ required: true, message: "Kućni broj je obavezan unos." }]}>
                                    <Input placeholder="K.br." maxLength={20} style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item name="settlement" label="Mjesto" rules={[{ required: true }]}>
                                <SettlementComboBox dataSource={settlementDs} />
                            </Form.Item>

                            <Typography.Title level={3}>Kontakt</Typography.Title>

                            <Form.Item name="phone" label="Telefon" rules={[{ required: true }]}>
                                <Input maxLength={50} />
                            </Form.Item>
                            <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
                                <Input maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* This makes enter submit the form - button is not visible! */}
                    <Button htmlType="submit" hidden />
                </Form>
            </Modal>
        </>
    );
}

import { useEffect, useState } from 'react';
import { getShipmentLogById, ShipmentLog } from 'Models/Logi/ShipmentLog/ShipmentLog';
import { Bux } from 'Components/Bux';
import { Collapse, Skeleton, Typography } from 'antd';
import { ShipmentEventLog } from 'Models/Logi/ShipmentLog/ShipmentEventLog';
import { DateDisplay } from "net-common-ui";
import { Shipment } from 'Models/Logi/Shipment';
import { EventPanel } from "./EventPanel"
import { transform } from "./transform";
import { ShipmentSaveType } from 'Models/Enum';
import { UserOutlined, ClockCircleOutlined } from '@ant-design/icons';

export const HistoryTab = ({ shipment }: { shipment: Shipment }) => {
    const [log, setLog] = useState<ShipmentLog>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const shipmentId = shipment?.id;

    useEffect(() => {
        if (shipmentId) {
            setLoading(true);
            getShipmentLogById(shipmentId).execute().then(log => {
                setLog(transform(log.item));
                setLoading(false);
            });
        }
    }, [shipmentId]);

    return <>
        {/* <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button key="edit" hidden={isEditing} disabled={!editable} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader> */}
        <Bux.Content>
            {loading && <Skeleton active />}
            {shipment && !loading && !log && <p>Greška pri učitavanju povijesti pošiljke.</p>}
            {log && <Collapse>
                {log.events.map((sel, i) =>
                    <Collapse.Panel
                        key={sel.id}
                        header={<PanelHeader sel={sel} index={log.events.length - i} first={i + 1 === log.events.length} />}
                        extra={<PanelHeaderExtra sel={sel} />}
                    >
                        <EventPanel shipment={shipment} log={log} eventLogId={sel.id} />
                    </Collapse.Panel>
                )}
            </Collapse>}
        </Bux.Content>

    </>;
}

const PanelHeader = ({ sel, index, first }: { sel: ShipmentEventLog, index: number, first: boolean }) => {
    let type = "";
    if (first) {
        type = "Pošiljka kreirana";
    }
    else {
        switch (sel.saveTypeId) {
            case ShipmentSaveType.ClientUi: type = "Korisnik ažurirao"; break;
            case ShipmentSaveType.ClientSendUi: type = "Korisnik poslao zahtjev"; break;
            case ShipmentSaveType.AdminUi: type = "Admin ažurirao"; break;
            case ShipmentSaveType.AdminCodUi: type = "Admin spremio otk. podatke"; break;
            case ShipmentSaveType.AdminWeightImport: type = "Admin uvoz mase"; break;
            case ShipmentSaveType.InvoiceGenerator: type = "Kreirana faktura"; break;
            case ShipmentSaveType.Sync: type = "Sinkronizacija sa kurirom"; break;
            case ShipmentSaveType.Delete: type = "Brisanje"; break;
            case ShipmentSaveType.Storno: type = "Storno"; break;
            case ShipmentSaveType.Gratis: type = "Gratis"; break;
        }
    }

    return <>
        <Typography.Text strong>{index}</Typography.Text>. {type}
    </>;
}

const PanelHeaderExtra = ({ sel }: { sel: ShipmentEventLog }) => {
    return <>
        <UserOutlined />&nbsp;{sel.createdBy.name}
        &nbsp;&nbsp;
        <ClockCircleOutlined />&nbsp;<DateDisplay showTime value={sel.createdOn} />
    </>;
}

import { Shipment } from 'Models/Logi/Shipment';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import {
    SaveOutlined
} from '@ant-design/icons';
import { ShipmentCod } from 'Models/Logi/ShipmentCod';
import { InputMoney } from 'Components/InputMoney';
import dayjs from 'dayjs';
import { DatePickerEx } from "Components/DatePickerEx";

interface Props {
    saving?: boolean;
    shipment: Shipment;
    shipmentCod: ShipmentCod;
    onSave: (shipmentCod: ShipmentCod) => void;
    onCancel: () => void;
}

export const CodForm = ({ saving, shipment, shipmentCod, onSave, onCancel }: Props) => {
    const [form] = Form.useForm();

    const handleSave = () => {
        form.validateFields().then((model: ShipmentCod) => {
            const full: ShipmentCod = { ...shipmentCod, ...model };

            onSave(full);

        });
    };

    const handleCancel = () => {
        onCancel();
        form.resetFields();
    }

    const rules = [{ required: true }];

    return (
        <Form
            //labelCol={{ span: 0 }}
            //wrapperCol={{ span: 24 }}
            name="shipment-cod-form"
            layout="vertical"
            form={form}
            onFinish={handleSave}
            initialValues={shipmentCod}
        //onValuesChange={handleValuesChange}
        >
            {shipment.status.code !== "DPR" && <Row gutter={[8, 40]} justify="center">
                <Col span={12}>
                    <Alert type="warning" closable showIcon message='Status pošiljke će biti ažuriran na "Uručeno primatelju" ukoliko spremite ove podatke!' />
                </Col>
            </Row>}
            <Row gutter={[8, 40]} justify="center">
                <Col span={12}>
                    <Form.Item name="customerAmount" label="Traženi iznos" rules={rules}>
                        <InputMoney />
                    </Form.Item>

                    <Form.Item name="carrierAmount" label="Uplaćeni iznos" rules={rules}>
                        <InputMoney />
                    </Form.Item>

                    <Form.Item name="carrierPaidOn" label="Vrijeme uplate (primamatelj - kurir)" rules={rules}>
                        <DatePickerEx format="L" disabledDate={value => value.isAfter(dayjs())} />
                    </Form.Item>

                    <Form.Item name="carrierTransferCode" label="Šifra prijenosa (kurir - Logex)" rules={rules}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="carrierTransferOn" label="Vrijeme prijenosa (kurir - Logex)" rules={rules}>
                        <DatePickerEx format="L" disabledDate={value => value.isAfter(dayjs())} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8} justify="center">
                <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
                <Col span={4}><Button disabled={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
            </Row>
        </Form>);
}

import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { ApiCall } from "net-common-ui";

export interface DashCustomerInfobar {
    shipmentCount?: number;
    packageCount?: number;
    shipmentWithCodCount?: number;
    packageAverageWeight?: number;
    codAmountTransferred?: number;
    codAmountPending?: number;
    shipmentTotalWithDiscountAverage?: number;
    invoicePendingTotal?: number;
    invoiceLastTotal?: number;
}

export function getDashCustomerInfobar(customerId: number, range: [Dayjs, Dayjs]): ApiCall<DashCustomerInfobar> {
    return ApiCall.get(kApi, "dash/customer-infobar", {
        customerId,
        from: range[0].format("YYYY-MM-DD"),
        to: range[1].format("YYYY-MM-DD")
    })
}

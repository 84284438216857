export enum DeliveryDeadline {
    Unknown = 0,
    D1 = 1,
    D2 = 2,
    D3 = 3
}

export enum DeliveryType {
    Unknown = 0,
    Address = 1,
    PostOffice = 2
}

export enum InvoiceInterval {
    Never = 0,
    Montly = 1,
    BiMonthly = 2,
    Weekly = 3,
    Daily = 4
}

export enum PaymentBy {
    Sender = 1
}

export enum PaymentType {
    Contract = 1
}

export enum ShipmentSaveType {
    Unknown = 0,
    // client (1-99)
    ClientUi = 1,
    ClientSendUi = 2,
    // admin (101-199)
    AdminUi = 101,
    AdminCodUi = 102,
    AdminWeightImport = 103,
    AdminCodImport = 104,
    // system (201-255)
    InvoiceGenerator = 201,
    Sync = 202,
    Delete = 203,
    Storno = 204,
    Gratis = 205
}

export enum LabelFormat {
    Unknown = 0,
    A42PerPage = 1,
    A44PerPage = 2
}

export enum NotifTarget {
    Unknown = 0,
    Sender = 1,
    Recipient = 2
}
export enum NotifChannel {
    Unknown = 0,
    Email = 1,
    Sms = 2
}

// flags
export enum CodType {
    None = 0,
    BezNaloga = 1,
    PostanskaUputnica = 2
}

export function invoiceIntervalLabel(value: InvoiceInterval): string {
    switch (value) {
        case InvoiceInterval.Never: return "Nikada";
        case InvoiceInterval.Montly: return "Mjesečno";
        case InvoiceInterval.BiMonthly: return "Polumjesečno";
        case InvoiceInterval.Weekly: return "Tjedno";
        case InvoiceInterval.Daily: return "Dnevno";
    }
}
export function deliveryTypeLabel(value: DeliveryType): string {
    switch (value) {
        case DeliveryType.PostOffice: return "Poštanski ured";
        case DeliveryType.Address: return "Adresa";
        case DeliveryType.Unknown: return "Nepoznato";
    }
}
export function deliveryDeadlineLabel(value: DeliveryDeadline): string {
    switch (value) {
        case DeliveryDeadline.D1: return "D+1";
        case DeliveryDeadline.D2: return "D+2";
        case DeliveryDeadline.D3: return "D+3";
    }
}
export function labelFormatLabel(value: LabelFormat): string {
    switch (value) {
        case LabelFormat.Unknown: return "Nepoznato";
        case LabelFormat.A42PerPage: return "A4, 2 po stranici";
        case LabelFormat.A44PerPage: return "A4, 4 po stranici";
    }
}
export function labelNotifTarget(value: NotifTarget): string {
    switch (value) {
        case NotifTarget.Unknown: return "Nepoznato";
        case NotifTarget.Sender: return "Pošiljatelj";
        case NotifTarget.Recipient: return "Primatelj";
    }
}
export function labelNotifChannel(value: NotifChannel): string {
    switch (value) {
        case NotifChannel.Unknown: return "Nepoznato";
        case NotifChannel.Email: return "Email";
        case NotifChannel.Sms: return "SMS";
    }
}

export function labelCodType(value: CodType): string {
    switch (value) {
        case CodType.None: return "Bez otkupnine";
        case CodType.BezNaloga: return "Bez naloga";
        case CodType.PostanskaUputnica: return "Poštanska uputnica";
    }
}

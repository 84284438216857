import { Pagination, Table, PageHeader } from 'antd';
import { FilterForm, CodTransferGroupFilter } from "./FilterForm"
import { applyGridState, DateDisplay, useDataSource, useStateManager } from "net-common-ui";
import Bux from "Components/Bux";
import { CodTransferGroup, dsoCodTransferGroup } from 'Models/Bus/CodTransferGroup';
import { CodTransfer, dsoCodTransferByCodTransferGroup } from 'Models/Bus/CodTransfer';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CurrencyText } from 'Components/Text';
import withFilterDrawer from 'Components/withFilterDrawer';
import { Customer } from 'Models/Bus/Customer';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const [view, ds] = useDataSource(dsoCodTransferGroup());
    const [state, sm] = useStateManager<GridState<CodTransferGroupFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("id", "eq", parseInt(filter.id) ? filter.id : null)
        );
    });

    const handleFilterChange = (filter: CodTransferGroupFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };


    return <Bux.Page>
        <Bux.Header>
            <PageHeader
                title={"Grupe isplata otkupnih iznosa"}
                extra={
                    <Link key="build" to="/admin/cod/transfer-group/build">
                        Obrada <ArrowRightOutlined />
                    </Link>}
            />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<CodTransferGroup>
                dataSource={view.data}
                pagination={false}
                expandable={{
                    expandedRowRender: record => <DetailRow group={record} />
                }}
                rowKey="id">
                <Table.Column title="Šifra" dataIndex="code" width={90}
                    render={(code, record: CodTransferGroup) => <Link to={"/admin/cod/transfer-group/" + record.id}>{code}</Link>} />
                <Table.Column title="Vrijeme isplate" dataIndex="createdOn" ellipsis render={value => <DateDisplay value={value} showTime />} />
                <Table.Column title="Naloga" dataIndex="codTransferCount" />
                <Table.Column title="Kreirao" dataIndex={["createdBy", "name"]} />
                <Table.Column title="Iznos" dataIndex="amount" width={120} render={value => <CurrencyText value={value} showSymbol />} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

const DetailRow = ({ group }: { group: CodTransferGroup }) => {
    const [view] = useDataSource(dsoCodTransferByCodTransferGroup(group.id));
    return (<Table<CodTransfer>
        size="small"
        dataSource={view.data}
        pagination={false}
        //showHeader={false}
        rowKey="id">
        <Table.Column title="Šifra transfera" dataIndex="code" width={120}
            render={(code, record: CodTransfer) => <Link to={"/admin/cod/transfer/" + record.id}>{code}</Link>} />
        <Table.Column dataIndex="customer" title="Korisnik" ellipsis render={(customer: Customer) => (
            <span><Link to={"/admin/customer/" + customer.id}>{customer.code}</Link> {customer.name}</span>
        )} />
        <Table.Column title="Poziv na broj" dataIndex="clearanceNumber" ellipsis />
        <Table.Column title="Iznos" dataIndex="amount" width={120} render={value => <CurrencyText value={value} showSymbol />} />
    </Table>);
}

import { useEffect, useState } from 'react';
import { Descriptions, Drawer } from 'antd';
import { CustomerNotifConfigTest, testCustomerNotifConfig } from "Models/Bus/CustomerNotifConfigTest"
import { CustomerNotifConfig } from "Models/Bus/CustomerNotifConfig"
import { NotifChannel } from "Models/Enum"

interface Props {
    model: CustomerNotifConfig;
}

export const MessageTest = ({ model }: Props) => {
    const [testResult, setTestResult] = useState<CustomerNotifConfigTest>(null);

    useEffect(() => {
        const showDrawer = async () => {
            const result = await testCustomerNotifConfig(model).execute();
            setTestResult(result.item);
        };

        model && showDrawer();

    }, [model]);


    const onClose = () => {
        setTestResult(null);
    };

    return <Drawer
        title="Test sadržaja obavijesti"
        placement="right"
        closable
        onClose={onClose}
        width={500}
        visible={!!testResult}
        bodyStyle={{ padding: 6 }}
    >
        {testResult && !testResult.isSuccess && <div>
        </div>}
        {testResult && testResult.isSuccess && model.notifChannelId === NotifChannel.Email && <div>
            <Descriptions bordered size="small" column={1} title={null}>
                <Descriptions.Item label="Naslov">{testResult.emailSubject}</Descriptions.Item>
            </Descriptions>
            <div dangerouslySetInnerHTML={{ __html: testResult.emailBodyHtml }}></div>
        </div>}
        {testResult && testResult.isSuccess && model.notifChannelId === NotifChannel.Sms && <div>
            <Descriptions bordered size="small" column={1} title={null}>
                <Descriptions.Item label="Sadržaj">{testResult.smsBody}</Descriptions.Item>
                <Descriptions.Item label="Znakova">{testResult.smsBody.length} (nakon umetanja varijabli, moguća je drugačija vrijednost)</Descriptions.Item>
            </Descriptions>
        </div>}
    </Drawer>;
}

import React, { useMemo } from "react";
import { DashDailyShipmentStats } from "Models/Dash/DashDailyShipmentStats"
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


export const ShipmentDailyCountChart = ({ value }: { value: DashDailyShipmentStats[] }) => {

    const chartRef = React.useRef<{ chart: Highcharts.Chart, container: any }>();

    // if (chartRef.current?.chart) {
    //     chartRef.current.chart.showLoading("blka")
    // }
    const options: Highcharts.Options = useMemo(() => ({
        title: {
            text: null
        },
        chart: {
            type: 'column',
            height: 240
        },
        credits: {
            enabled: false
        },


        xAxis: {
            categories: value.map(v => v.date.format("DD")),
            min: 0
            //minRange: 30,
            // labels: {
            //     formatter: function (x) {
            //         return this.value + "";
            //         /*

            //         formatter: function() { 
            //         // return (new Date(@Model.ForMonth.Year, @(Model.ForMonth.Month-1), this.value).getDay() == 0) 
            //         //     ? '<span style="fill:red">' + this.value + '</span>' 
            //         //     : this.value; 
            //     } 

            //         */
            //     }
            // },
        },
        yAxis: {
            min: 0,
            title: {
                text: null
            }
        },

        plotOptions: {
            column: {
                grouping: true,
                groupPadding: 0.05,
                pointPadding: 0,
                borderWidth: 0
            }
        },

        tooltip: {
            shared: true
        },
        legend: {
            floating: true,
            verticalAlign: "top",
            align: "right"
        },

        series: [{
            name: 'Kreirano',
            data: value.map(v => v.createdCount || 0),
            color: "#a5c4f0"
        }, {
            name: 'Zaprimljeno',
            data: value.map(v => v.pickedCount || 0),
            color: "#a4edb7"
        }]
        // }, {
        //     name: 'Uručeno',
        //     data: value.map(v => v.deliveredCount || 0)
        // }]
    } as Highcharts.Options), [value]);

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
    />;
}

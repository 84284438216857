import { useMemo, useState } from 'react';
import { Button, Drawer, Form, Input, message, Modal, Typography } from 'antd';
import { CustomerNotifConfig, saveCustomerNotifConfig } from 'Models/Bus/CustomerNotifConfig';
import { getTitle, parseMessage, serializeMessage } from './util';
import { NotifChannel } from 'Models/Enum';
import { QuestionCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { MessageTest } from "./MessageTest";

const { Text } = Typography;

interface Props {
    value: CustomerNotifConfig;
    onUpdate: (value?: CustomerNotifConfig) => void;
}


export const MessageEdit = ({ value, onUpdate }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [help, setHelp] = useState<boolean>(false);
    const [testModel, setTestModel] = useState<CustomerNotifConfig>(null);

    const defaults = useMemo(() => parseMessage(value.defaultMessage), [value]);
    const model = useMemo(() => ({
        ...defaults,
        ...parseMessage(value.message)
    }), [value, defaults]);

    const handleShow = () => {
        setVisible(true);
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const handleSave = async () => {
        form.validateFields().then((obj) => {
            setLoading(true);
            const serializedMessage = serializeMessage(obj, defaults);
            const newValue: CustomerNotifConfig = { ...value, message: serializedMessage };
            saveCustomerNotifConfig(newValue).execute().then(result => {
                setLoading(false);
                if (result.success) {
                    message.success("Sadržaj obavijesti uspješno spremljen.");
                    newValue.id = result.item.id;
                    onUpdate(newValue);
                    setVisible(false);
                }
            });
        });
    }

    const handleShowHelp = () => {
        setHelp(true);
    }
    const handleHideHelp = () => {
        setHelp(false);
    }

    const handleTest = () => {
        form.validateFields().then((obj) => {
            const serializedMessage = serializeMessage(obj, defaults);
            const newValue: CustomerNotifConfig = { ...value, message: serializedMessage };
            setTestModel(newValue);
        });
    }

    return <>
        <Button loading={loading} type="primary" ghost onClick={handleShow} icon={<EditOutlined />}>Uredi</Button>
        <MessageTest model={testModel} />
        <Drawer
            title="Pomoć"
            placement="right"
            width={640}
            onClose={handleHideHelp}
            visible={help}
        >
            <p>
                U svim poljima dozvoljen je upis proizvoljnog teksta. Umetanje varijabli se radi
                koristeći <Text code>{"{{varijabla}}"}</Text>. Dostupne su sljedeće varijable:
            </p>
            <ul>
                <li><Text code>ActionUrl</Text> - Link za akciju (koristi ze za button pri dnu poruke)</li>
                <li><Text code>Barcode</Text> - Vodeći barkod pošiljke</li>
                <li><Text code>CustomerCode</Text> - Šifra korisnika</li>
                <li><Text code>CustomerName</Text> - Naziv korisnika</li>
                <li><Text code>SenderName</Text> - Naziv pošiljatelja</li>
                <li><Text code>SenderPersonName</Text> - Kontakt osoba pošiljatelja</li>
                <li><Text code>SenderAddress</Text> - Adresa pošiljatelja u obliku "ulica kbr, PU mjesto"</li>
                <li><Text code>SenderStreet</Text> - Ulica pošiljatelja</li>
                <li><Text code>SenderStreetNo</Text> - Kućni broj pošiljatelja</li>
                <li><Text code>SenderPostOfficeCode</Text> - Šifra poštanskog ureda pošiljatelja</li>
                <li><Text code>SenderPostOfficeName</Text> - Naziv poštanskog ureda pošiljatelja</li>
                <li><Text code>SenderSettlementCode</Text> - Šifra mjesta pošiljatelja</li>
                <li><Text code>SenderSettlementName</Text> - Naziv mjestapošiljatelja</li>
                <li><Text code>RecipientName</Text> - Naziv primatelja</li>
                <li><Text code>RecipientPersonName</Text> - Kontakt osoba primatelja</li>
                <li><Text code>RecipientAddress</Text> - Adresa primatelja u obliku "ulica kbr, PU mjesto"</li>
                <li><Text code>RecipientStreet</Text> - Ulica primatelja</li>
                <li><Text code>RecipientStreetNo</Text> - Kućni broj primatelja</li>
                <li><Text code>RecipientPostOfficeCode</Text> - Šifra poštanskog ureda primatelja</li>
                <li><Text code>RecipientPostOfficeName</Text> - Naziv poštanskog ureda primatelja</li>
                <li><Text code>RecipientSettlementCode</Text> - Šifra mjesta primatelja</li>
                <li><Text code>RecipientSettlementName</Text> - Naziv mjesta primatelja</li>
                <li><Text code>IspPostOffice</Text> - Isporučni PU u obliku "PU Naziv". Dostupno samo za neke statuse.</li>
                <li><Text code>CarrierName</Text> - Naziv kurira</li>
                <li><Text code>CodAmount</Text> - Iznos otkupnine u obliku "123,45 EUR"</li>
            </ul>
        </Drawer>
        <Modal
            width="80%"
            title={getTitle(value)}
            visible={visible}
            onCancel={handleCancel}
            destroyOnClose
            closable={!loading}
            footer={[
                <Button key="1" style={{ float: "left" }} icon={<QuestionCircleOutlined />} onClick={handleShowHelp}>Pomoć</Button>,
                <Button key="2" style={{ float: "left" }} icon={<PlayCircleOutlined />} onClick={handleTest}>Test</Button>,
                <Button key="3" disabled={loading} onClick={handleCancel}>Odustani</Button>,
                <Button key="4" type="primary" loading={loading} onClick={handleSave}>Ok</Button>
            ]}
        >
            <Form form={form} layout="vertical" name="form" initialValues={model} onFinish={handleSave} preserve={false}>
                {value.notifChannelId === NotifChannel.Sms
                    ? <Form.Item name="body" label="Poruka" rules={[{ required: true, len: 160 }]}>
                        <Input.TextArea style={{ fontFamily: "monospace" }} rows={3} />
                    </Form.Item>
                    : <>
                        <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
                            <Input style={{ fontFamily: "monospace" }} />
                        </Form.Item>
                        <Form.Item name="title" label="Naslov" rules={[{ required: true }]}>
                            <Input style={{ fontFamily: "monospace" }} />
                        </Form.Item>
                        <Form.Item name="subtitle" label="Podnaslov" rules={[{ required: true }]}>
                            <Input.TextArea style={{ fontFamily: "monospace" }} rows={2} />
                        </Form.Item>
                        <Form.Item name="body" label="Poruka" rules={[{ required: true }]}>
                            <Input.TextArea style={{ fontFamily: "monospace" }} rows={8} />
                        </Form.Item>
                    </>}

                {/* This makes enter submit the form - button is not visible! */}
                <Button htmlType="submit" hidden />
            </Form>
        </Modal>
    </>;

    // return <Row>
    //     <Col span={24}>
    //         <Checkbox checked={value.isEnabled} onChange={e => handleChange("isEnabled", e.target.checked)}>
    //             {value.status.name} - {labelNotifTarget(value.notifTargetId)} - {labelNotifChannel(value.notifChannelId)}
    //         </Checkbox>
    //         {value.isEnabled && <MessageEditor value={value.message} onChange={e => handleChange("message", e)} />}
    //     </Col>
    // </Row>;
}

import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

interface DownloadStreamOptions {
    url: string;
    params?: any;
    sniffContentDisposition?: boolean;
    fileName?: string;
    method?: "get" | "post";
    data?: any;
}

interface DownloadStreamResult {
    success: boolean;
    blob: Blob;
    contentDisposition?: string;
    contentType?: string;
    download?: () => void;
}

export async function downloadStream(api: AxiosInstance, options: DownloadStreamOptions): Promise<DownloadStreamResult> {
    let response: AxiosResponse<any>;

    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/octet-stream'
        },
        params: options.params
    };

    try {
        response = await (options.method === "post"
            ? api.post(options.url, options.data, config)
            : api.get(options.url, config));
    }
    catch {
        return { success: false, blob: null };
    }

    let filename = options.fileName;
    const contentDisposition = response.headers["content-disposition"];
    if (options.sniffContentDisposition && contentDisposition) {
        const cdFilename = /filename="?([^"]*)"?[;$]/.exec(contentDisposition)[1];
        if (cdFilename) filename = cdFilename;
    }

    const blob = new Blob([response.data]);
    const download = () => {
        const localUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = localUrl;
        link.setAttribute('download', filename || "file");
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            link.remove();
        }, 0);
    };

    return {
        success: true,
        blob,
        contentDisposition,
        contentType: response.headers["content-type"],
        download
    };
}

export default downloadStream;

import React, { useState } from 'react';
import { Edit } from './Edit';
import { View } from './View';
import { Row, Col, Button, Modal, message } from 'antd';
import { WarningOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { AppAccount, deleteAppAccount } from 'Models/Bus/AppAccount';
import { Bux } from 'Components/Bux';
import { ProfileContext } from '../Context';

export const AppAccountTab = () => {
    const { state, dispatch } = React.useContext(ProfileContext);
    const { model } = state;

    const [editing, setEditing] = useState(false);

    const handleEdit = () => {
        setEditing(true);
    }
    const handleUpdate = (updated: boolean, appAccount: AppAccount) => {
        if (updated) {
            dispatch({ type: "APPACCOUNT_SAVED", payload: appAccount });
        }
        setEditing(false);
    }
    const handleDelete = async () => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Obrisati korisnički račun {model.appAccount.email}?</p>,
            okType: "primary",
            okText: "Obriši",
            okButtonProps: { danger: true },
            onOk: async () => {
                const result = await deleteAppAccount(model.appAccount.id).execute();
                if (result.success) {
                    message.success("Korisnički račun uspješno obrisan.");
                    dispatch({ type: "APPACCOUNT_DELETED" });
                    setEditing(false);
                }
            }
        });
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                {model.appAccount && <Col span={4}>
                    <Button danger disabled={editing} onClick={handleDelete} block icon={<DeleteOutlined />}>Obriši</Button>
                </Col>}
                <Col span={4}>
                    {model.appAccount
                        ? <Button type="primary" disabled={editing} onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                        : <Button type="primary" disabled={editing} onClick={handleEdit} block icon={<PlusOutlined />}>Dodaj</Button>}
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {editing
                ? <Edit appAccount={model.appAccount} customerId={model.id} onUpdate={handleUpdate} />
                : <View appAccount={model.appAccount} />
            }
        </Bux.Content>
    </>);
}

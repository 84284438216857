import React, { useState } from 'react';
import { Customer } from "Models/Bus/Customer"
import { Edit } from './Edit';
import { View } from './View';
import { Row, Col, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Bux } from 'Components/Bux';
import { ProfileContext } from '../Context';

export const MainTab = () => {
    const { state, dispatch } = React.useContext(ProfileContext);
    const { model } = state;

    const [editing, setEditing] = useState(false);


    const handleEdit = () => {
        setEditing(true);
    }
    const handleUpdate = (updated: boolean, customer: Customer) => {
        if (updated) {
            dispatch({ type: "CUST_SAVED", payload: customer });
        }
        setEditing(false);
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button disabled={editing} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {editing
                ? <Edit customer={model} onUpdate={handleUpdate} />
                : <View customer={model} />
            }
        </Bux.Content>
    </>);
}

import { UserManagerSettings } from 'oidc-client';


export interface Configuration {
    userManager: UserManagerSettings;
    api: {
        k: string
    },
    envLabel: "Development" | "Test" | "Production"
}

const CFG = window["__CFG__"] as Configuration;
if (!CFG) throw new Error("Could not find global configuration. Make sure __CFG__ is loaded.");
export default CFG;

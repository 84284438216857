import { AppAccount } from "Models/Bus/AppAccount"
import { Form, Row, Col, Checkbox, Input, Alert, Typography } from 'antd';

interface Props {
    appAccount: AppAccount;
}

export const View = ({ appAccount }: Props) => {
    return appAccount
        ? <Row gutter={[8, 40]} justify="center">
            <Col span={8}>
                <Form layout="vertical" name="form" initialValues={appAccount}>
                    <Typography.Title level={3}>Korisnički račun</Typography.Title>
                    <Form.Item name="identifier" label="Identifikator">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="fullName" label="Ime i prezime">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="isAccountEnabled" valuePropName="checked">
                        <Checkbox disabled>Račun omogućen (prijava je moguća)</Checkbox>
                    </Form.Item>
                </Form>


            </Col>
        </Row>
        : <Alert
            message="Upozorenje"
            description={<>
                <p>Korisnički račun nije definiran.</p>
                <p>Niti jedna osoba se neće moći prijaviti u aplikaciju prije nego se definira korisnički račun za prijavu.</p>
            </>}
            type="warning"
            showIcon
        />
}

import { Shipment } from 'Models/Logi/Shipment';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { StatusComboBox } from "Components/StatusComboBox";
import { InputWeight } from 'Components/InputWeight';
import { dsoStatus, Status } from 'Models/Logi/Status';
import { SaveOutlined } from '@ant-design/icons';
import { StatusHistoryPopover } from 'Components/StatusHistoryPopover';
import { useDataSourceRef } from 'net-common-ui';
import { DatePickerEx } from "Components/DatePickerEx";
const { Title } = Typography;

// copy: from antd
interface FieldData {
    name: (string | number)[];
    touched: boolean;
    validating: boolean;
    value: any;
    errors: any[];
}

interface Props {
    disabled?: boolean;
    saving?: boolean;
    shipment: Shipment;
    onSave: (shipment: Shipment) => void;
    onCancel: () => void;
}

// Admin ne moze vratit posilju i "neposlano", vec ima Grupu slanja, smrdat ce se state.
const DisabledStatusCodes = ['N/A', 'CRE'];
// Kad je paket uručen, moramo imati masu...
const MandatoryWeightStatusCodes = ['DPO', 'DPR'];

export const PackageForm = ({ disabled, saving, shipment, onSave, onCancel }: Props) => {
    const [form] = Form.useForm();
    const [statusDs] = useDataSourceRef(dsoStatus(), {
        configure: b => b.setQuery(q => {
            q.filter.set("id", "gt", 0);
            q.sort.set("logicalOrder", "asc");
        })
    });

    const handleSave = () => {
        form.validateFields().then((model: Shipment) => {
            const full: Shipment = { ...shipment, ...model };
            onSave(full);
        });
    };

    const handleCancel = () => {
        onCancel();
        form.resetFields();
    }

    const handleFieldsChange = (diff: FieldData[], model: FieldData[]) => {

        // Ovo je logika; kad netko izmjeni status - zelimo resetirati vrijeme statusa da forsiramo rucni unos. Ovo radimo da se izbjegne
        // slucajni update gdje se izmjeni status, vrijeme ostane od starog statusa i spremi se pogresno.
        // Kao bonus gledamo touched polje od statusOn polja, i resetiramo ga samo ako user nije na ruke vec odabrao!

        // hvatamo: ["packages", x, "status"] - gdje je x index (number)
        // resetiramo: ["packages", x, "statusOn"], gdje je x isti index

        for (let i = 0; i < diff.length; ++i) {
            const item = diff[i];
            // we're aiming for path name: ["packages", 0, "status"], which means status of package at index 0 is updates.
            // provjera "item.touched" jer na submit ce bit ovdje iako nije diran
            if (item.touched && item.name.length === 3 && item.name[0] === "packages" && item.name[2] === "status") {
                const packageIndex = item.name[1] as number;
                const statusOnName = ["packages", packageIndex, "statusOn"];
                const isStatusOnTouched = model.find(x => x.name.join() === statusOnName.join()).touched;
                if (!isStatusOnTouched) {
                    form.setFields([{
                        name: statusOnName,
                        value: null,
                        touched: true
                    }]);
                }

                // note uvijek resetiramo
                const statusNoteName = ["packages", packageIndex, "statusNote"];
                const isStatusNoteTouched = model.find(x => x.name.join() === statusNoteName.join()).touched;
                if (!isStatusNoteTouched) {
                    form.setFields([{
                        name: statusNoteName,
                        value: null,
                        touched: true
                    }]);
                }
            }
        }
    }

    return (
        <Form
            // labelCol={{ span: 0 }}
            // wrapperCol={{ span: 24 }}
            layout="vertical"
            name="shipment-form"
            form={form}
            onFinish={handleSave}
            initialValues={shipment}
            onFieldsChange={handleFieldsChange}
        //onValuesChange={handleValuesChange}
        >
            <Row gutter={[8, 8]}>
                <Form.List name="packages">
                    {fields => fields.map(field => {
                        const pack = shipment.packages[field.name];
                        return (
                            <Col key={field.key} xs={24} sm={12} md={8} lg={6}>
                                <Title level={4}>{pack.ordinal}. {pack.barcode}</Title>

                                <Form.Item
                                    name={[field.name, 'status']}
                                    rules={[{ required: true }]}
                                    label={<>
                                        Status <StatusHistoryPopover shipmentId={shipment.id} packageId={pack.id} />
                                    </>}
                                >
                                    <StatusComboBox
                                        disabled={disabled}
                                        dataSource={statusDs}
                                        optionDisabled={value => DisabledStatusCodes.includes(value.code)} />
                                </Form.Item>
                                <Form.Item name={[field.name, 'statusNote']} label="Napomena statusa">
                                    <Input disabled={disabled} />
                                </Form.Item>
                                <Form.Item name={[field.name, 'statusOn']} label="Vrijeme statusa" rules={[{ required: true }]}>
                                    <DatePickerEx disabled={disabled} showTime format="L HH:mm:ss" style={{ display: "block" }} />
                                </Form.Item>

                                <Form.Item name={[field.name, 'weight']} label="Najavljena masa">
                                    <InputWeight disabled />
                                </Form.Item>

                                <Form.Item noStyle dependencies={[["packages", field.name, "status"]]}>
                                    {form => {
                                        const status: Status = form.getFieldValue(["packages", field.name, "status"]);
                                        return <Form.Item
                                            name={[field.name, 'weightVerified']}
                                            label="Potvrđena masa"
                                            rules={[{ required: MandatoryWeightStatusCodes.includes(status.code) }]}
                                        >
                                            <InputWeight disabled={disabled} />
                                        </Form.Item>
                                    }}
                                </Form.Item>
                                <Form.Item
                                    name={[field.name, 'weightVerifiedSetOn']}
                                    label="Vrijeme postavljanja potvrđene mase"
                                >
                                    <DatePickerEx disabled={disabled} showTime format="L LTS" />
                                </Form.Item>
                                <Typography.Paragraph type='secondary'>
                                    Ova vrijednost je kriterij prema kojemu pošiljka ulazi u određeno obračunsko razdoblje, odnosno fakturu.
                                </Typography.Paragraph>
                            </Col>
                        )
                    })}
                </Form.List>
            </Row>

            {!disabled && <Row gutter={8} justify="center">
                <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
                <Col span={4}><Button loading={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
            </Row>}
        </Form>);
}

import { Shipment } from 'Models/Logi/Shipment';
import { Bux } from 'Components/Bux';
import { useDataSource } from "net-common-ui";
import { dsoShipmentNotifLogByShipmentId } from 'Models/Logi/ShipmentLog/ShipmentNotifLog';
import { ShipmentNotifTable } from 'Components/Shipment/NotifTable';

export function NotifTab({ shipment }: { shipment: Shipment }) {
    const [view] = useDataSource(dsoShipmentNotifLogByShipmentId(shipment.id), {
        execute: true,
        configure: b => b.setQuery(q => q.sort.set("id", "desc"))
    });

    return <Bux.Content>
        <ShipmentNotifTable dataView={view} />
    </Bux.Content>;
}

import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { ApiCall } from "net-common-ui";

export interface DashDailyShipmentStats {
    date?: Dayjs;
    createdCount?: number;
    pickedCount?: number;
    firstDeliveryCount?: number;
    deliveredCount?: number;
    returnedCount?: number;
    deliveredInTimeCount?: number;
    deliveryQualityCoef?: number;
    firstDeliveryInTimeCount?: number;
    firstDeliveryQualityCoef?: number;
    firstDeliveryInDay1?: number;
    firstDeliveryInDay2?: number;
    firstDeliveryInDay3?: number;
    firstDeliveryInDay4Plus?: number;
    averageDeliveryDays?: number;
    averageDeliveryWorkDays?: number;
    averageFirstDeliveryDays?: number;
    averageFirstDeliveryWorkDays?: number;
}

export function getDashDailyShipmentStats(range: [Dayjs, Dayjs]): ApiCall<DashDailyShipmentStats> {
    return ApiCall.get(kApi, "dash/daily-shipment-stats", {
        from: range[0].format("YYYY-MM-DD"),
        to: range[1].format("YYYY-MM-DD")
    })
}
import React from 'react';
import { Pagination, Button, Table, PageHeader } from 'antd';
import { FilterForm, ShipmentGroupFilter } from "./FilterForm"
import { applyGridState, DateDisplay, useDataSource, useStateManager } from "net-common-ui";
import Bux from "Components/Bux";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { dsoShipmentGroup, ShipmentGroup } from 'Models/Logi/ShipmentGroup';
import withFilterDrawer from 'Components/withFilterDrawer';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const match = useRouteMatch();
    const history = useHistory();

    const [view, ds] = useDataSource(dsoShipmentGroup());
    const [state, sm] = useStateManager<GridState<ShipmentGroupFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("id", "eq", filter.id)
        );
    });

    const handleFilterChange = (filter: ShipmentGroupFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };


    const newButtons = <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(match.url + "/new")}>Novi zahtjev</Button>;

    return <Bux.Page>
        <Bux.Header>
            <PageHeader
                title={"Predani zahtjevi"}
                extra={newButtons}
            />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<ShipmentGroup>
                dataSource={ds.view.data}
                pagination={false}
                rowKey="id">
                <Table.Column width={100} title="Broj" dataIndex="code" />
                <Table.Column width={80} title="Pošiljaka" dataIndex="shipmentCount" />
                <Table.Column width={100} title="Prikup" dataIndex="pickupDate" render={value => <DateDisplay value={value} />} />
                <Table.Column width={100} title="Kreiran" dataIndex="createdOn" render={value => <DateDisplay value={value} />} />
                <Table.Column ellipsis title="Napomena" dataIndex="note" />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

import { useEffect, useState } from 'react';
import { Link, Switch, Route, useParams, Redirect } from 'react-router-dom';
import { Menu, PageHeader, Skeleton } from 'antd';
import { Bux } from 'Components/Bux';
import { getShipmentInfoById, Shipment } from 'Models/Logi/Shipment';
import { MainTab } from "./MainTab";
import { PackageTab } from "./PackageTab";
import { CodTab } from "./CodTab";
import { HistoryTab } from "./HistoryTab";
import { NotifTab } from "./NotifTab";

export const Profile = ({ match, location }) => {
    const params = useParams<{ id: string }>();
    const [shipment, setShipment] = useState<Shipment>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getShipmentInfoById(params.id).execute().then(result => {
            //console.log("Shipment loaded", result.item);
            setShipment(result.item);
            setLoading(false);
        });
    }, [params.id]);

    if (!loading && !shipment) {
        return <Redirect to="/admin/shipment" />
    }

    const menu = <Menu mode="horizontal" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]}>
        <Menu.Item key={match.url}>
            <span>Pošiljka</span>
            <Link to={match.url} />
        </Menu.Item>
        <Menu.Item key={match.url + "/package"}>
            <span>Paketi</span>
            <Link to={match.url + "/package"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/cod"}>
            <span>Otkupnina</span>
            <Link to={match.url + "/cod"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/notif"}>
            <span>Obavijesti</span>
            <Link to={match.url + "/notif"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/log"}>
            <span>Povijest</span>
            <Link to={match.url + "/log"} />
        </Menu.Item>
    </Menu>;

    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title={"Pošiljka " + (shipment ? shipment.code : "")} footer={menu} />
            </Bux.Header>
            {loading
                ? <Bux.Content>
                    <Skeleton active />
                </Bux.Content>
                : <Switch>
                    <Route path={match.url} exact>
                        <MainTab shipment={shipment} onChange={shipment => setShipment(shipment)} />
                    </Route>
                    <Route path={match.url + "/package"} exact>
                        <PackageTab shipment={shipment} onChange={shipment => setShipment(shipment)} />
                    </Route>
                    <Route path={match.url + "/cod"} exact>
                        <CodTab shipment={shipment} onChange={shipment => setShipment(shipment)} />
                    </Route>
                    <Route path={match.url + "/notif"} exact>
                        <NotifTab shipment={shipment} />
                    </Route>
                    <Route path={match.url + "/log"} exact>
                        <HistoryTab shipment={shipment} />
                    </Route>
                </Switch>}

        </Bux.Page>


    );
}


import React from 'react';
import { Invoice } from 'Models/Bus/Invoice';
import produce from 'immer';

export interface ProfileState {
    model: Invoice;
    loading: boolean;
}

type Action =
    { type: "INVOICE_LOADING" } |
    { type: "INVOICE_LOADED", payload: Invoice } |
    { type: "INVOICE_VERIFIED", payload: Invoice } |
    { type: "INVOICE_PAID", payload: Invoice }
    ;


export const InitialState: ProfileState = {
    model: null,
    loading: true
};

interface ProfileContextValue {
    dispatch: React.Dispatch<Action>;
    state: ProfileState;
}

export const ProfileContext = React.createContext<ProfileContextValue>(null);

export const reducer = produce((draft: ProfileState, action: Action) => {
    //console.log("Reducer type " + action.type)
    switch (action.type) {
        case "INVOICE_LOADING":
            draft.loading = true;
            break;
        case "INVOICE_LOADED":
            draft.model = action.payload;
            draft.loading = false;
            break;
        case "INVOICE_VERIFIED":
            // assign instead of '=' since verify action returns invoice, not info (missing records)
            action.payload.invoiceRecords = draft.model.invoiceRecords;
            draft.model = action.payload;
            draft.loading = false;
            break;
        case "INVOICE_PAID":
            // assign instead of '=' since pay action returns invoice, not info (missing records)
            action.payload.invoiceRecords = draft.model.invoiceRecords;
            draft.model = action.payload;
            draft.loading = false;
            break;
    }
}, InitialState as any) as ((state: ProfileState, action: Action) => ProfileState);

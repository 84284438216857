import { Form, FormInstance, Input, Radio } from 'antd';
import { AddressAutoComplete } from 'Components/AddressAutoComplete';
import { RecipientSettlementComboBox } from 'Components/SettlementComboBox';
import { AddressBookEntry } from 'Models/Bus/AddressBookEntry';
import { dsoSettlementByServiceType } from 'Models/Codebook/Settlement';
import { DeliveryType } from 'Models/Enum'
import { useDataSourceRef } from 'net-common-ui';

interface Props {
    disabled?: boolean;
    form: FormInstance;
    serviceTypeId: number;
}

export function RecipientEditor({ serviceTypeId, disabled, form }: Props) {
    const [settlementDs] = useDataSourceRef(dsoSettlementByServiceType(serviceTypeId), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });
    const rules = disabled ? [] : [{ required: true }];

    const rest = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    };

    const handleAddressBookChange = (entry: AddressBookEntry) => {
        if (entry) {
            form.setFieldsValue({
                recipient: {
                    personName: entry.personName,
                    street: entry.street,
                    streetNo: entry.streetNo,
                    settlement: entry.settlement,
                    phone: entry.phone,
                    email: entry.email
                }
            });
        }
        else {
            form.setFieldsValue({
                recipient: {
                    personName: "",
                    street: "",
                    streetNo: "",
                    settlement: null,
                    phone: "",
                    email: ""
                }
            });
        }
    }

    const handleAddToAddressBookChange = (value: boolean) => {
        form.setFieldsValue({
            recipient: {
                saveAddressBookEntry: value
            }
        });
    }

    return <>
        <Form.Item name={["recipient", "saveAddressBookEntry"]} hidden>
            <Input />
        </Form.Item>
        <Form.Item name={["recipient", "name"]} label="Naziv" rules={rules} {...rest}>
            {/* <Input disabled={disabled} maxLength={200} /> */}
            <AddressAutoComplete
                mode="recipient"
                disabled={disabled}
                maxLength={200}
                allowAddToAddressBook={!form.getFieldValue("id")} // allow only for new shipment
                onEntryChange={handleAddressBookChange}
                onAddToAddressBookChange={handleAddToAddressBookChange}
            />

        </Form.Item>
        <Form.Item name={["recipient", "personName"]} label="Ime i prezime" {...rest}>
            <Input disabled={disabled} maxLength={200} />
        </Form.Item>
        <Form.Item label="Adresa" {...rest} required={!disabled}>
            <Form.Item name={["recipient", "street"]} noStyle rules={[{ required: true, message: "Ulica je obavezan unos." }]}>
                <Input disabled={disabled} placeholder="Ulica" maxLength={200} style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
            </Form.Item>
            <Form.Item name={["recipient", "streetNo"]} noStyle rules={[{ required: true, message: "Kućni broj je obavezan unos." }]}>
                <Input disabled={disabled} placeholder="K.br." maxLength={20} style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
            </Form.Item>
        </Form.Item>
        <Form.Item name={["recipient", "settlement"]} label="Mjesto" rules={rules} {...rest}>
            <RecipientSettlementComboBox disabled={disabled} dataSource={settlementDs} />
        </Form.Item>

        <Form.Item name="deliveryTypeId" label="Dostava" rules={rules} {...rest}>
            <Radio.Group disabled={disabled}>
                <Radio value={DeliveryType.Address}>Adresa</Radio>
                <Radio value={DeliveryType.PostOffice}>Poštanski ured</Radio>
            </Radio.Group>
        </Form.Item>

        <Form.Item noStyle dependencies={["deliveryTypeId", ["recipient", "settlement"]]}>
            {form => {
                const dt = form.getFieldValue("deliveryTypeId");
                if (dt === DeliveryType.PostOffice) {
                    const isp = form.getFieldValue(["recipient", "settlement", "ispPostOffice"]);
                    const ispText = isp ? isp.code + " - " + isp.name : "";
                    return <Form.Item label="Isporučni PU" {...rest}>
                        <Input value={ispText} disabled />
                    </Form.Item>;
                }
            }}
        </Form.Item>

        <Form.Item name={["recipient", "phone"]} label="Telefon" rules={rules} {...rest}>
            <Input disabled={disabled} maxLength={50} />
        </Form.Item>
        <Form.Item name={["recipient", "email"]} label="Email" rules={disabled ? [] : [{ type: "email" }]} {...rest}>
            <Input disabled={disabled} maxLength={200} />
        </Form.Item>
    </>;
}


import { useState } from 'react';
import { Form, Button, Row, Col, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { deletePriceAdditionalService, PriceAdditionalService, savePriceAdditionalService } from 'Models/Bus/PriceList/PriceAdditionalService';
import { PriceListSourceRadioGroup } from './PriceListSourceRadioGroup';
import { AdditionalServiceEditForm } from './AdditionalServiceEditForm';
import { PriceList } from 'Models/Bus/PriceList/PriceList';
import clone from 'just-clone';

interface Props {
    priceList: PriceList;
    basePriceList?: PriceList;
    onFinish?: (saved?: boolean, value?: PriceAdditionalService) => void;
}

export const AdditionalServiceEdit = ({ priceList, basePriceList, onFinish }: Props) => {
    const priceListId = priceList.id;
    const value = priceList.priceAdditionalService;
    const base = basePriceList?.priceAdditionalService;
    const actual = clone(value || base);

    const [useStandard, setUseStandard] = useState(!value && !!base);
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();

    const handleCancel = () => {
        onFinish();
    }
    const handleSubmit = async () => {
        const apiCall = useStandard
            ? deletePriceAdditionalService(priceListId)
            : savePriceAdditionalService(priceListId, form.getFieldsValue());

        setSaving(true);
        const result = await apiCall.execute();

        setSaving(false);
        if (result.success) {
            message.success("Postavke dodatnih usluga uspješno spremljene.");
            onFinish(true, result.item);
        }
    }

    return (<>
        {base && <PriceListSourceRadioGroup useStandard={useStandard} onChange={setUseStandard} />}

        {!useStandard && <AdditionalServiceEditForm form={form} value={actual} />}

        <Row gutter={8} justify="center">
            <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
            <Col span={4}><Button disabled={saving} type="primary" onClick={handleSubmit} block icon={<SaveOutlined />}>Spremi</Button></Col>
        </Row>
    </>);
}

import ReactDOM from 'react-dom';
import { OidcAuthManager as AuthManager, AuthContextProvider, NetConfigProvider } from "net-common-ui"
import Config from 'Config';
import App from './Views/App';
import { ErrorPage } from "./ErrorPage";
import { notification } from 'antd';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.less';
import './index.less';
import './antd.override.less';
import "dayjs/locale/hr";
import "antd/es/locale-provider/hr_HR";
import Highcharts from 'highcharts';

/* eslint-disable */
const validateMessages = {
    required: "Obavezan unos.",
    types: {
        email: "Vrijednost nije ispravna email adresa."
    }
};
/* eslint-enable */

notification.config({
    placement: "bottomRight"
});

const cfg = {
    oidcConfig: Config.userManager,
    initializeEndpoint: Config.api.k + "/system/initialize"
};

AuthManager.configure(cfg).init().then(result => {
    if (result.type === "user" && result.user) {
        ReactDOM.render(<AuthContextProvider>
            <NetConfigProvider form={{ validateMessages }} defaultCultureCode="hr-HR" defaultUiCultureCode="hr-HR">
                <App />
            </NetConfigProvider>
        </AuthContextProvider>, document.getElementById('root'));
    }
    else if (result.type === "error") {
        ReactDOM.render(<ErrorPage error={result.error} user={result.user} />, document.getElementById('root'));
    }
});

Highcharts.setOptions({
    lang: {
        months: ['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj', 'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac']
    },
    //colors: ['#FDD089', '#FF7F79', '#A0446E', '#251535'],
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window["reset"] = function () {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = ''
}
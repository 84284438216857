import React from "react";
import { Switch, Route } from "react-router";

import { Routes as AppAccount } from "./AppAccount";
import { Grid as CountryGrid } from './Country';
import { Grid as RegionGrid } from './Region';
import { Grid as PostOfficeGrid } from './PostOffice';
import { Grid as SettlementGrid } from './Settlement';
import { Management as CodebookManagement } from './Management';
//import Profile from "./Profile";

export const Cfg = ({ match }) => (
    <Switch>
        <Route path={match.url + "/account"} component={AppAccount} />
        <Route path={match.url + "/country"} exact component={CountryGrid} />
        <Route path={match.url + "/region"} exact component={RegionGrid} />
        <Route path={match.url + "/post-office"} exact component={PostOfficeGrid} />
        <Route path={match.url + "/settlement"} exact component={SettlementGrid} />
        <Route path={match.url + "/management"} exact component={CodebookManagement} />
    </Switch>);


import React from 'react';
import { Pagination, PageHeader, Table, Button } from 'antd';
import { FilterForm, InvoiceFilter } from "./FilterForm"
import { dsoInvoice, getInvoiceNumberDisplayLabel, Invoice } from "Models/Bus/Invoice"
import { applyGridState, DateDisplay, useActiveScreenSize, useDataSource, useStateManager } from "net-common-ui";
import { Bux } from 'Components/Bux';
import { Link, useHistory } from 'react-router-dom';
import { CurrencyText } from "Components/Text";
import { ArrowRightOutlined } from '@ant-design/icons';
import withFilterDrawer from 'Components/withFilterDrawer';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const [screenSize] = useActiveScreenSize();
    const history = useHistory();

    const [view, ds] = useDataSource(dsoInvoice());
    const [state, sm] = useStateManager<GridState<InvoiceFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("customer.id", "eq", filter.customer && filter.customer.id)
                .set("isPaid", "eq", filter.isPaid)
        );
    });

    const handleFilterChange = (filter: InvoiceFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    // const handleVerify = () =>{
    //     Modal.confirm({
    //         title: 'Potvrda',
    //         icon: <ExclamationCircleOutlined />,
    //         content: `Verificirati odabranih ${selectedVerifiable.length} faktura? Ova akcija je nepovratna.`,
    //         okText: 'Verificiraj',
    //         cancelText: 'Odustani',
    //         onOk() {
    //             console.log("bulk verify", selectedVerifiable);
    //         }
    //       });
    // }

    return <Bux.Page>
        <Bux.Header>
            <PageHeader
                title={"Fakture"}
                extra={[
                    <Button type="primary" key="process" onClick={() => history.push("/admin/invoice/build")}>
                        Obrada <ArrowRightOutlined />
                    </Button>
                ]} />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<Invoice>
                dataSource={ds.view.data}
                rowKey="id"
                pagination={false}
            // rowSelection={{ 
            //     type: "checkbox", 
            //     selectedRowKeys: selected, 
            //     onChange: (sel: number[]) => setSelected(sel) 
            // }}
            >
                <Table.Column dataIndex="number" title="Broj" render={(_, record: Invoice) => (
                    <Link to={"/admin/invoice/" + record.id}>{getInvoiceNumberDisplayLabel(record)}</Link>
                )} />
                <Table.Column dataIndex={["customer", "name"]} title="Korisnik" />
                {screenSize !== "mobile" && <Table.Column dataIndex={["invoiceShipment", "from"]} title="Pošiljke od" render={value => <DateDisplay value={value} />} />}
                {screenSize !== "mobile" && <Table.Column dataIndex={["invoiceShipment", "to"]} title="Pošiljke do" render={value => <DateDisplay value={value} />} />}
                <Table.Column dataIndex="dueOn" title="Rok" render={value => <DateDisplay value={value} />} />
                <Table.Column dataIndex="paidDate" title="Plaćeno" render={value => value && <DateDisplay value={value} />} />
                {screenSize === "desktop" && <>
                    <Table.Column dataIndex="price" title="Cijena" render={value => <CurrencyText value={value} />} />
                    <Table.Column dataIndex="discount" title="Rabat" render={value => <CurrencyText value={value} />} />
                    <Table.Column dataIndex="tax" title="Porez" render={value => <CurrencyText value={value} />} />
                </>}
                <Table.Column dataIndex="totalWithTax" title="Ukupno (EUR)" render={value => <CurrencyText value={value} />} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

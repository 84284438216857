import { AutoComplete, Button, Checkbox, Input, Tooltip, Typography } from "antd";
import { AddressBookEntry, dsoAddressBookEntryByCustomer } from "Models/Bus/AddressBookEntry";
import { useDataSource, useDebounce } from "net-common-ui";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { useCustomer } from "Views/MeContext";
import { AddressCell } from "./Address";
import { SearchOutlined, DatabaseOutlined } from "@ant-design/icons";
import { AddressEditDialog } from "./AddressEditDialog";
import React from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const DEFAULT_ADD_ADDRESS_BOOK = true;

type OptionType = {
    value: string;
    label: React.ReactNode;
    model?: AddressBookEntry;
};

interface Props {
    mode: "sender" | "recipient";
    value?: string;
    onChange?: (value: string) => void;
    onEntryChange?: (item: AddressBookEntry) => void;
    maxLength?: number;
    disabled?: boolean;
    allowAddToAddressBook?: boolean;
    onAddToAddressBookChange?: (value: boolean) => void;
}

export const AddressAutoComplete: React.VFC<Props> = ({
    mode,
    value, onChange, onEntryChange, maxLength, disabled,
    allowAddToAddressBook, onAddToAddressBookChange
}) => {
    const customer = useCustomer();
    const [view, ds] = useDataSource(dsoAddressBookEntryByCustomer(customer.id));
    const [entry, setEntry] = useState<AddressBookEntry>(null);
    const [editEntry, setEditEntry] = useState<boolean>(false);
    const inputRef = useRef<Input>();
    const [addToAddressBookValue, setAddToAddressBookValue] = useState(allowAddToAddressBook && DEFAULT_ADD_ADDRESS_BOOK);
    const initialized = useRef(false);

    if (!initialized.current) {
        initialized.current = true;
        // broadcast initial state
        onAddToAddressBookChange && onAddToAddressBookChange(addToAddressBookValue);
    }

    const options = useMemo<OptionType[]>(() => {
        return view.data.map(item => ({
            key: item.id || "hq",
            // Kemija jer value mora biti distinct, inace uzima prvi (od 2+ koji imaju isti value)
            // Value ionako ignoriramo kod selecta uzmemo iz model.name vrijednost
            value: item ? String(item.id) : "hq",
            model: item,
            label: (
                <Typography.Text ellipsis>
                    <AddressCell address={item} />
                </Typography.Text>
            )
        }));
    }, [view]);

    const search = useCallback(async (term: string) => {
        ds.setSize(10);
        ds.filter.clear();
        ds.filter.set({
            "isSender": mode === "sender",
            "isRecipient": mode === "recipient"
        })
        if (term) {
            ds.filter.nested(nb => nb
                .set("name", "contains", term)
                .set("personName", "contains", term)
                .set("street", "contains", term)
                .set("settlement.name", "contains", term)
                .set("settlement.postOffice.code", "contains", term)
                .set("phone", "contains", term)
                .set("email", "contains", term), "or")
        }
        await ds.execute();
    }, [ds, mode]);

    const [handleSearch] = useDebounce(search, 500);

    const update = (value: string, entry: AddressBookEntry) => {
        if (entry) {
            //console.log('onSelect', value, option);
            value = entry.name;
            if (maxLength && maxLength > 0 && value) {
                value = value.substring(0, maxLength);
            }
        }

        setEntry(entry);

        onChange && onChange(value);
        onEntryChange && onEntryChange(entry);
        onAddToAddressBookChange && onAddToAddressBookChange(addToAddressBookValue && !entry);
    };

    const handleSelect = (value: string, option: OptionType) => {
        update(value, option.model);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e.target.value);
    }

    const handleEditClose = (record: AddressBookEntry) => {
        setEditEntry(false);
        if (record) {
            update(record.name, record);
        }
    }

    const handleClear = () => {
        update("", null);
        inputRef.current.focus();
    }

    const handleAddToAddressBookChange = (e: CheckboxChangeEvent) => {
        setAddToAddressBookValue(e.target.checked);
        onAddToAddressBookChange && onAddToAddressBookChange(e.target.checked && !entry);
    }

    return (
        <div>
            <AutoComplete value={value} options={options} onSelect={handleSelect} onSearch={handleSearch} disabled={disabled}>
                <Input
                    ref={inputRef}
                    onChange={handleChange}
                    //addonAfter={<Tooltip title="Pretraži adresar"><SearchOutlined onClick={() => search(value)} /></Tooltip>}
                    addonAfter={!disabled && <Tooltip title="Pretraži adresar">
                        <Button
                            tabIndex={-1}
                            type="primary"
                            icon={<SearchOutlined />}
                            onClick={() => search(value)}
                            style={{ margin: "-5px -11px" }}
                        />
                    </Tooltip>}
                    maxLength={maxLength}
                    disabled={disabled}
                />
            </AutoComplete>
            {entry && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ cursor: "help" }}>
                    <Tooltip mouseEnterDelay={0.5} title="Vrijednosti su kopirane iz adresara. Dodatne izmjene na podacima ovdje se neće reflektirati na zapis u adresaru.">
                        <DatabaseOutlined /> Zapis iz adresara
                    </Tooltip>
                </span>
                <span>
                    <Tooltip mouseEnterDelay={0.5} title="Vrijednosti će biti očišćene. Akcija nema utjecaja na zapise u adresaru.">
                        <Button danger size="small" type="link" onClick={handleClear} tabIndex={-1}>Ukloni</Button>
                    </Tooltip>
                    {!entry.isReadOnly &&
                        <Tooltip mouseEnterDelay={0.5} title="Ažuriranje vrijednosti kroz adresar. Izmjena će se reflektirati u adresaru i ovdje.">
                            <Button size="small" type="link" onClick={() => setEditEntry(true)} tabIndex={-1}>Uredi adresu</Button>
                        </Tooltip>}
                </span>
            </div>}
            {!entry && allowAddToAddressBook && !disabled &&
                < Tooltip mouseEnterDelay={0.5} title="Podaci će se automatski dodati kao novi zapis u adresaru prilikom spremanja pošiljke, te će biti dostupni u budućnosti kod kreiranja novih pošiljaka.">
                    <Checkbox checked={addToAddressBookValue} onChange={handleAddToAddressBookChange} tabIndex={-1}>
                        Dodaj u adresar prilikom spremanja pošiljke
                    </Checkbox>
                </Tooltip>}
            <AddressEditDialog value={entry} visible={editEntry} onClose={handleEditClose} />
        </div >
    );
}

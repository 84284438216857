import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CustomerComboBox } from 'Components/CustomerComboBox';
import { Customer, dsoCustomer } from 'Models/Bus/Customer';
import { useDataSourceRef } from 'net-common-ui';

export interface CosTransferFilter {
    id?: string;
    customer?: Customer[];
}

interface FilterFormProps {
    initialFilter?: CosTransferFilter;
    onChange?: (filter: CosTransferFilter) => void;
    form?: FormInstance;
}

export const FilterForm = ({ onChange, initialFilter = {}, form }: FilterFormProps) => {
    const [customerDs] = useDataSourceRef(dsoCustomer());

    return (
        <Form
            form={form}
            layout="vertical"
            name="filter"
            initialValues={initialFilter}
            onFinish={onChange}>

            <Form.Item name="id" label="Šifra">
                <Input addonBefore="CT" />
            </Form.Item>

            <Form.Item name="customer" label="Korisnik">
                <CustomerComboBox dataSource={customerDs} multiple />
            </Form.Item>

            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden />
        </Form>
    )
}

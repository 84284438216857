import { useState } from 'react';
import { Button, Modal, Checkbox, Divider, Alert, message, Spin } from 'antd';
import {
    CheckOutlined
} from '@ant-design/icons';
import { getCustomerById } from 'Models/Bus/Customer';
import { Link } from 'react-router-dom';
import { Invoice, verifyInvoice } from 'Models/Bus/Invoice';

interface Props {
    invoiceId: number;
    customerId: number;
    isVerified: boolean;
    onSave: (invoice: Invoice) => void;
}

export const UpdateVerifyButton = ({ invoiceId, isVerified, customerId, onSave }: Props) => {
    const [notify, setNotify] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const handleShow = () => {
        if (email === null && !loading && !isVerified) {
            setLoading(true);
            getCustomerById(customerId).execute().then(result => {
                const email = (result.success ? result.item.emailInvoice : null) || "";
                setEmail(email);
                setNotify(!!email)
                setLoading(false);
                setVisible(true);
            });
        }
        else {
            setVisible(true);
        }
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const handleOk = async () => {
        setSaving(true);
        const result = await verifyInvoice(invoiceId, notify ? email : null).execute();
        setSaving(false);
        if (result.success) {
            message.success("Faktura uspješno verificirana.");
            setVisible(false);
            onSave(result.item); // return updated (saved) model - warning, not ;info variant
        }
    }

    return <>
        <Button disabled={isVerified || loading} type="primary" block onClick={handleShow} icon={<CheckOutlined />}>Verificiraj</Button>
        <Modal
            title="Verifikacija fakture"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose
            okText={saving ? <span><Spin size="small" /> Verificiraj</span> : "Verificiraj"}
            closable={!saving}
            okButtonProps={{ disabled: loading, loading: saving }}
            cancelButtonProps={{ disabled: saving }}
        >
            <p>Ova akcija će potvrditi fakturu te joj dodijeliti konačni broj i generirati popratnu dokumentaciju. <strong>Akcija je nepovratna!</strong></p>
            <Divider />
            <p>
                <Checkbox disabled={!email || saving} checked={notify} onChange={e => setNotify(e.target.checked)}>
                    Pošalji obavijest korisniku
                </Checkbox>
            </p>
            {email
                ? (notify
                    ? <Alert type="info" showIcon message={<span>
                        Obavijest će biti poslana na adresu: <strong>{email}</strong>.
                        <br />
                        Ukoliko želite poslati obavijest na drugu adresu, ažurirajte podatke na <Link to={`/admin/customer/${customerId}/main`}>profilu korisnika</Link>.</span>} />
                    : <Alert type="warning" showIcon message={<span>
                        Obavijest neće biti poslana.<br />Naknadno slanje obavijesti nije moguće.
                    </span>} />)
                : <Alert type="error" showIcon message={<span>
                    Obavijest nije moguća jer korisniku nije definirana mail adresa za obavijest.
                    <br />
                    Ukoliko želite poslati obavijest, prvo podesite adresu na <Link to={`/admin/customer/${customerId}/main`}>profilu korisnika</Link>.</span>} />}
        </Modal>
    </>;
}

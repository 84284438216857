import { Alert, Table } from 'antd';
import { PriceWeightRange } from "Models/Bus/PriceList/PriceWeightRange";
import { ServiceType } from 'Models/Codebook/ServiceType';
import { CurrencyText, WeightText } from "Components/Text";
import { WarningOutlined } from "@ant-design/icons";
import { PriceList } from 'Models/Bus/PriceList/PriceList';

interface Props {
    serviceType: ServiceType;
    priceList: PriceList;
    basePriceList?: PriceList;
}


export const WeightView = ({ priceList, basePriceList, serviceType }: Props) => {
    const serviceTypeId = serviceType.id;

    const valueRanges = priceList.priceWeightRanges;
    const baseRanges = basePriceList?.priceWeightRanges;
    const actualRanges = (valueRanges.length || !baseRanges ? valueRanges : baseRanges).filter(r => r.serviceTypeId === serviceTypeId);

    const valueExtra = priceList.priceWeightExtras;
    const baseExtra = basePriceList?.priceWeightExtras;
    const actualExtra = (valueExtra.length || !baseExtra ? valueExtra : baseExtra).find(r => r.serviceTypeId === serviceTypeId);

    return (<>
        {actualRanges.length
            ? <div>
                {!valueRanges.length && baseRanges && <Alert
                    message="Standardni cjenik"
                    description={<p>Koriste se parametri standardnog cjenika.</p>}
                    type="info"
                    showIcon
                    style={{ marginBottom: 6 }}
                />}
                <Table<PriceWeightRange> rowKey="id" pagination={false} dataSource={actualRanges} >
                    <Table.Column dataIndex="weightFrom" title="Masa od" render={v => <WeightText value={v} kg showSymbol />} />
                    <Table.Column dataIndex="weightTo" title="Masa do" render={v => <WeightText value={v} kg showSymbol />} />
                    <Table.Column dataIndex="priceZone1" title="Zona 1" render={v => <span>
                        <CurrencyText value={v} showSymbol />
                        {!v && <WarningOutlined className="text-warn" style={{ marginLeft: 4 }} />}
                    </span>} />
                    <Table.Column dataIndex="priceZone2" title="Zona 2" render={v => <span>
                        <CurrencyText value={v} showSymbol />
                        {!v && <WarningOutlined className="text-warn" style={{ marginLeft: 4 }} />}
                    </span>} />
                </Table>
            </div>

            : <Alert
                message="Upozorenje"
                description={<>
                    <p>Za uslugu <strong>{serviceType.name}</strong> nije definiran cjenik.</p>
                    <p>Ako se korisniku omogući unos pošiljaka ove vrste, pošiljke se neće moći fakturirati sve dok se ne unesu cjenovni razredi.</p>
                </>}
                type="warning"
                showIcon
            />
        }
        {actualRanges.length > 0 && actualExtra && <p>
            Za svakih {(actualExtra.weightIncrement / 1000)} kg iznad definiranih razreda, cijena se uvećava za <CurrencyText value={actualExtra.price} showSymbol />.
        </p>}
    </>);
}

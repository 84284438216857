import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { Customer } from "./Customer";
import { InvoiceRecord } from "./InvoiceRecord";
import { ConsumerActor } from "net-common-ui/dist/Auth/ConsumerActor";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { Dayjs } from "dayjs";

export interface Invoice {
    id?: number;
    invoiceTypeId?: number;
    customer?: Customer;
    customerPriceListId?: number;
    year?: number;
    number?: number;
    deviceNumber?: number;
    locationNumber?: number;
    fullNumberText?: number;
    clearanceNumber?: string;
    dueOn?: Dayjs;
    dueOnOffset?: number;
    price?: number;
    discount?: number;
    total?: number;
    taxCoef?: number;
    tax?: number;
    totalWithTax?: number;
    fileId?: number;

    isVerified?: boolean;
    verifiedBy?: ConsumerActor;
    verifiedOn?: Dayjs;
    verifyMailId?: number;
    paidDate?: Dayjs;
    isPaid?: boolean;
    createdBy?: ConsumerActor;
    createdOn?: Dayjs;

    invoiceRecords?: InvoiceRecord[];
    invoiceShipment?: InvoiceShipment;
}


export interface InvoiceShipment {
    invoiceId?: number;
    from?: Dayjs;
    to?: Dayjs;
}

export function getInvoiceNumberDisplayLabel(model: Invoice): string {
    return model.number ? String(model.number) : "TBD";
}


export function getInvoiceById(id: UrlParam): ApiCall<Invoice> {
    return ApiCall.get(kApi, `invoice/${id}`);
}

export function getInvoiceInfoById(id: UrlParam): ApiCall<Invoice> {
    return ApiCall.get(kApi, `invoice/${id};info`);
}

export function verifyInvoice(id: UrlParam, notify: string = null): ApiCall<Invoice> {
    return ApiCall.patch(kApi, `invoice/${id}/isVerified`, true, { notify }); // body is "true"
}

export function payInvoice(id: UrlParam, paidDate: Dayjs): ApiCall<Invoice> {
    return ApiCall.patch(kApi, `invoice/${id}/isPaid`, !!paidDate, { paidDate: paidDate?.format("YYYY-MM-DD") });
}

export function deleteInvoice(id: UrlParam): ApiCall<Invoice> {
    return ApiCall.delete(kApi, `invoice/${id}`);
}


export function dsoInvoice(): DataSourceOptions<Invoice> {
    return {
        api: kApi,
        url: "invoice"
    };
}

export function dsoInvoiceByCustomerId(customerId: UrlParam): DataSourceOptions<Invoice> {
    return {
        api: kApi,
        url: `customer/${customerId}/invoice`
    };
}
import { useState } from 'react';
import { Form, Button, Row, Col, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { deletePriceWeightRange, PriceWeightRange, savePriceWeightRange } from "Models/Bus/PriceList/PriceWeightRange";
import { deletePriceWeightExtra, PriceWeightExtra, savePriceWeightExtra } from 'Models/Bus/PriceList/PriceWeightExtra';
import { WeightEditForm, ModelWrapper } from "./WeightEditForm";
import { PriceList } from 'Models/Bus/PriceList/PriceList';
import { PriceListSourceRadioGroup } from './PriceListSourceRadioGroup';
import clone from 'just-clone';

// interface ModelWrapper {
//     ranges: PriceWeightRange[];
//     extra: PriceWeightExtra
// }

interface Props {
    serviceTypeId: number;
    priceList: PriceList;
    basePriceList?: PriceList;
    onFinish?: (saved?: boolean, ranges?: PriceWeightRange[], extra?: PriceWeightExtra) => void;
}

export const WeightEdit = ({ serviceTypeId, priceList, basePriceList, onFinish }: Props) => {
    const valueRanges = priceList.priceWeightRanges;
    const baseRanges = basePriceList?.priceWeightRanges;
    const actualRanges = clone(valueRanges.length || !baseRanges ? valueRanges : baseRanges).filter(r => r.serviceTypeId === serviceTypeId);

    const valueExtra = priceList.priceWeightExtras;
    const baseExtra = basePriceList?.priceWeightExtras;
    const actualExtra = clone(valueExtra.length || !baseExtra ? valueExtra : baseExtra).find(r => r.serviceTypeId === serviceTypeId);


    const [useStandard, setUseStandard] = useState(!valueRanges.length && !!baseRanges); // temeljimo samo na rasponima, gledamo na Extra kao dio toga...
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();


    const handleCancel = () => {
        onFinish();
    }
    const handleSubmit = async () => {

        const formModel = (form.getFieldsValue() as ModelWrapper);

        const apiCallRanges = useStandard
            ? deletePriceWeightRange(priceList.id, serviceTypeId)
            : savePriceWeightRange(priceList.id, serviceTypeId, formModel.ranges);

        const apiCallExtra = useStandard
            ? deletePriceWeightExtra(priceList.id, serviceTypeId)
            : savePriceWeightExtra(priceList.id, serviceTypeId, formModel.extra);

        setSaving(true);
        const resultRanges = await apiCallRanges.execute();
        const resultExtra = await apiCallExtra.execute();

        setSaving(false);

        if (resultRanges.success && resultExtra.success) {
            message.success("Cijene težinskih razreda uspješno spremljene.");
            onFinish(true, resultRanges.data, resultExtra.item);
        }
    }

    return (<>
        {baseRanges && <PriceListSourceRadioGroup useStandard={useStandard} onChange={setUseStandard} />}

        {!useStandard && <WeightEditForm form={form} value={{ ranges: actualRanges, extra: actualExtra }} />}

        <Row gutter={8} justify="center">
            <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
            <Col span={4}><Button disabled={saving} type="primary" onClick={handleSubmit} block icon={<SaveOutlined />}>Spremi</Button></Col>
        </Row>
    </>);









    /*
        const { state, dispatch } = React.useContext(ProfileContext);
        const { model } = state;
    
        const [form] = Form.useForm();
        const [saving, setSaving] = useState(false);
    
        const wrapper: ModelWrapper = {
            ranges: model.priceList.priceWeightRanges.filter(m => m.serviceTypeId === serviceTypeId),
            extra: model.priceList.priceWeightExtras.find(m => m.serviceTypeId === serviceTypeId) || PriceWeightExtra.fromJSON({
                priceListId: model.id,
                serviceTypeId
            })
        };
    
        const handleCancel = () => {
            onFinish();
        }
        const handleSave = () => {
            form.validateFields().then((wrapper: ModelWrapper) => {
                setSaving(true);
                PriceWeightRange.save(model.id, serviceTypeId, PriceWeightRange.fromJSON(wrapper.ranges)).execute().then(result => {
                    PriceWeightExtra.save(model.id, serviceTypeId, PriceWeightExtra.fromJSON(wrapper.extra)).execute().then(resultExtra => {
                        setSaving(false);
                        if (result.success) {
                            message.success("Cijene uspješno spremljene.");
                            onFinish();
                            dispatch({ type: "WEIGHTRANGES_SAVED", payload: { serviceTypeId, ranges: result.data } });
                            dispatch({ type: "WEIGHTEXTRAS_SAVED", payload: { serviceTypeId, extra: resultExtra.item } });
                        }
                    });
                });
            });
        }
    
        return (
            <Form form={form} layout="vertical" name="form" onFinish={handleSave} initialValues={wrapper}>
                <Row gutter={[8, 40]} justify="center">
                    <Col span={16}>
                        <Typography.Title level={3}>Razredi cijena pošiljaka</Typography.Title>
                        <Form.Item name="ranges">
                            <EditForm />
                        </Form.Item>
    
                        <Typography.Title level={3}>Cijena po masi iznad definiranih razreda</Typography.Title>
                        <Form.Item name={["extra", "weightIncrement"]} label="Za svakih (g)">
                            <InputWeight />
                        </Form.Item>
                        <Form.Item name={["extra", "price"]} label="Uvećaj za (EUR)">
                            <InputMoney />
                        </Form.Item>
                    </Col>
                </Row>
    
                <Row gutter={8} justify="center">
                    <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
                    <Col span={4}><Button disabled={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
                </Row>
            </Form>
        )
        */
}

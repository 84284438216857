import { Switch, Route } from "react-router";

import { Grid } from "./Grid";
import { Profile } from "./Profile";
import { Build } from "./Build";

export const CodTransferGroup = ({ match }) => (
    <Switch>
        <Route path={match.url} exact component={Grid} />
        <Route path={match.url + "/:id(\\d+)"} component={Profile} />
        <Route path={match.url + "/build"} exact component={Build} />
    </Switch>);

import { CustomerSender } from "Models/Bus/CustomerSender"
import { Form, Row, Col, Checkbox, Typography, Input } from 'antd';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { useDataSourceRef } from "net-common-ui";
import { dsoSettlement } from "Models/Codebook/Settlement";

interface Props {
    customerSender: CustomerSender;
}

export const View = ({ customerSender }: Props) => {
    const pointVisible = customerSender.isSenderLocked && !customerSender.useHqPoint;
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });

    return (
        <Form layout="vertical" name="form" initialValues={customerSender}>
            <Row gutter={[8, 40]} justify="center">
                <Col span={8}>
                    <Typography.Title level={3}>Postavke pošiljatelja</Typography.Title>
                    <Form.Item name="isSenderLocked" valuePropName="checked">
                        <Checkbox disabled>Pošiljatelj zaključan</Checkbox>
                    </Form.Item>
                    <Form.Item name="useHqPoint" valuePropName="checked">
                        <Checkbox disabled>Koristi adresu sjedišta korisnika</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                    {pointVisible && <>
                        <Typography.Title level={3}>Zaključani pošiljatelj</Typography.Title>

                        <Form.Item name="personName" label="Ime i prezime">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item name="name" label="Naziv">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Adresa">
                            <Form.Item name="street" noStyle rules={[{ required: true, message: "Ulica je obavezan unos." }]}>
                                <Input disabled placeholder="Ulica" style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
                            </Form.Item>
                            <Form.Item name="streetNo" noStyle rules={[{ required: true, message: "Kućni broj je obavezan unos." }]}>
                                <Input disabled placeholder="K.br." style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item name="settlement" label="Mjesto">
                            <SettlementComboBox disabled dataSource={settlementDs} />
                        </Form.Item>
                        <Form.Item name="phone" label="Telefon">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item name="email" label="Email">
                            <Input disabled />
                        </Form.Item>
                    </>
                    }
                </Col>
            </Row>
        </Form>
    )
}

import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ProfileContext } from '../Context';
import { ServiceType } from 'Models/Codebook/ServiceType';
import { WeightEdit } from "Views/Admin/PriceList/Common/WeightEdit"
import { WeightView } from 'Views/Admin/PriceList/Common/WeightView';
import { Bux } from 'Components/Bux';
import { PriceWeightRange } from 'Models/Bus/PriceList/PriceWeightRange';
import { PriceWeightExtra } from 'Models/Bus/PriceList/PriceWeightExtra';

interface Props {
    serviceType: ServiceType;
}

export const WeightTab = ({ serviceType }: Props) => {
    const serviceTypeId = serviceType.id;
    const { state, dispatch } = React.useContext(ProfileContext);
    const { editable } = state;
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    }
    const handleEditFinish = (saved?: boolean, ranges?: PriceWeightRange[], extra?: PriceWeightExtra) => {
        setIsEditing(false);
        if (saved) {
            dispatch({ type: "WEIGHTRANGES_SAVED", payload: { serviceTypeId, ranges } });
            dispatch({ type: "WEIGHTEXTRAS_SAVED", payload: { serviceTypeId, extra } });
        }
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button disabled={!editable || isEditing} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {isEditing
                ? <WeightEdit
                    serviceTypeId={serviceType.id}
                    priceList={state.model.priceList}
                    basePriceList={state.model.standardPriceList.priceList}
                    onFinish={handleEditFinish}
                />
                : <WeightView
                    serviceType={serviceType}
                    priceList={state.model.priceList}
                    basePriceList={state.model.standardPriceList.priceList}
                />}
        </Bux.Content>
    </>);
}

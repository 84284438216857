import { ShipmentLog } from 'Models/Logi/ShipmentLog/ShipmentLog';
import { PackageLog } from 'Models/Logi/ShipmentLog/PackageLog';

export function transform(log: ShipmentLog): ShipmentLog {
    try {
        // Sve naopako - najnovoji prvi.. 
        // Ovo nije samo radi ui sorta, vec i .find (kad se trazi previous) bi bio kompliciraniji da je drugacije.
        // Ne dirati ovo, ako se ne updejta i .find na EventPanelu
        log.cods.reverse();
        log.events.reverse();
        log.mains.reverse();
        log.packageStatuses.reverse();
        log.packages.reverse();
        log.recipients.reverse();
        log.senders.reverse();

        flattenPackages(log);
        return log;
    }
    catch (e) {
        console.error("Failed to transform log data.", e)
        return null;
    }
}

function flattenPackages(log: ShipmentLog) {
    const { packages, packageStatuses } = log;
    const shellPackages: PackageLog[] = [];

    // svaki status dodjelimo paketu
    for (let i = 0; i < packageStatuses.length; i++) {
        const status = packageStatuses[i];
        const pack = packages.find(p => p.packageId === status.packageId && p.shipmentEventLogId <= status.shipmentEventLogId);

        if (pack.shipmentEventLogId === status.shipmentEventLogId) {
            // ako je paket updejtan u ovom event logu, status samo assignamo na taj log
            pack.status = status.status;
            pack.statusOn = status.statusOn;
            pack.statusNote = status.statusNote;
            pack.statusLogId = status.id;
        }
        else {
            // ako paket nije sejvan u ovom logu, radimo "dummy" paket sa zadnjim podacima u tom trenutku, i statusom radi kojeg je nastao ovaj record
            shellPackages.push({
                ...pack,
                ...{
                    shipmentEventLogId: status.shipmentEventLogId,
                    status: status.status,
                    statusOn: status.statusOn,
                    statusNote: status.statusNote,
                    statusLogId: status.id
                }
            } as PackageLog);
        }
    }

    // za svaki slucaj - svaki paket koji nema status, assignamo u tom trenutku zadnji poznati status
    for (let i = 0; i < packages.length; i++) {
        const pack = packages[i];
        if (!pack.status) {
            const lastStatusLog = packageStatuses.find(ps => ps.packageId === pack.packageId && ps.shipmentEventLogId <= pack.shipmentEventLogId);
            pack.status = lastStatusLog.status;
            pack.statusOn = lastStatusLog.statusOn;
            pack.statusNote = lastStatusLog.statusNote;
            pack.statusLogId = lastStatusLog.id;
        }
    }

    log.packages = [...log.packages, ...shellPackages];
    // sort by shipmentEventLogId, then by statusLogId
    log.packages.sort((a, b) => b.shipmentEventLogId - a.shipmentEventLogId || b.statusLogId - a.statusLogId);

    //console.log(log.packages);
}

import { Alert, Descriptions } from 'antd';
import { DateDisplay } from "net-common-ui";
//import { SwapRightOutlined } from '@ant-design/icons';
import { StatusDisplay } from "Components/StatusDisplay";
import { StatusHistoryPopover } from '../StatusHistoryPopover';
import { WeightText, CurrencyText, PercentText } from '../Text';
import { Shipment } from 'Models/Logi/Shipment';
import { deliveryDeadlineLabel, deliveryTypeLabel, labelCodType } from 'Models/Enum';
import { ShipmentGroup } from 'Models/Logi/ShipmentGroup';
import { Package } from 'Models/Logi/Package';
import { ShipmentInvoice } from 'Models/Logi/ShipmentInvoice/ShipmentInvoice';
import { ShipmentReturnInvoice } from 'Models/Logi/ShipmentInvoice/ShipmentReturnInvoice';
import { ShipmentSender } from 'Models/Logi/ShipmentSender';
import { ShipmentRecipient } from 'Models/Logi/ShipmentRecipient';

const BoolText = ({ value }: { value: any }) => <span>{!value ? "Ne" : "Da"}</span>;

export const BasicDesc = ({ shipment }: { shipment: Shipment }) => (
    <Descriptions bordered size="small" column={1} title="Osnovno">
        <Descriptions.Item label="Šifra">{shipment.code}</Descriptions.Item>
        <Descriptions.Item label="Usluga">{shipment.serviceType.name}</Descriptions.Item>
        <Descriptions.Item label="Rok uručenja">{deliveryDeadlineLabel(shipment.deliveryDeadlineId)}</Descriptions.Item>
        <Descriptions.Item label="Rok uručenja (datum)">
            <DateDisplay value={shipment.expectedDeliveryDate} />
            {shipment.expectedDeliveryDate && " (" + shipment.expectedDeliveryDate.format("dddd") + ")"}
        </Descriptions.Item>
        <Descriptions.Item label="Vrsta prikupa">Adresa</Descriptions.Item>
        <Descriptions.Item label="Vrsta dostave">{deliveryTypeLabel(shipment.deliveryTypeId)}</Descriptions.Item>
        <Descriptions.Item label="Vrijednost"><CurrencyText value={shipment.value} showSymbol /></Descriptions.Item>
        <Descriptions.Item label="Vodeći barkod">{shipment.barcode}</Descriptions.Item>
        <Descriptions.Item label="Ukupno paketa">{shipment.packageCount}</Descriptions.Item>
        <Descriptions.Item label="Skupni status">{shipment.status.name}</Descriptions.Item>
    </Descriptions>);

export const EventsDesc = ({ shipment }: { shipment: Shipment }) => (
    <Descriptions bordered size="small" column={1} title="Događaji">
        <Descriptions.Item label="Kreirana"><DateDisplay value={shipment.createdOn} showTime /></Descriptions.Item>
        <Descriptions.Item label="Poslana"><DateDisplay value={shipment.shipmentGroup?.createdOn} showTime /></Descriptions.Item>
        <Descriptions.Item label="Zaprimljena"><DateDisplay value={shipment.pickedOn} showTime /></Descriptions.Item>
        <Descriptions.Item label="Vagana"><BoolText value={shipment.weightVerifiedSetOn} /></Descriptions.Item>
        <Descriptions.Item label="Vraćena"><DateDisplay value={shipment.returnDeliveredOn} showTime /></Descriptions.Item>
        <Descriptions.Item label="Uručena"><DateDisplay value={shipment.deliveredOn} showTime /></Descriptions.Item>

        {/* <Descriptions.Item label="Zaključena"><BoolText value={shipment.isFinished} /></Descriptions.Item>
        <Descriptions.Item label="Skupni status">{shipment.status.name}</Descriptions.Item> */}
    </Descriptions>);

export const SenderDesc = ({ sender }: { sender: ShipmentSender }) => (
    <Descriptions bordered size="small" column={1} title="Pošiljatelj">
        <Descriptions.Item label="Naziv">{sender.name}</Descriptions.Item>
        <Descriptions.Item label="Ime i prezime">{sender.personName}</Descriptions.Item>
        <Descriptions.Item label="Adresa">{sender.street} {sender.streetNo}</Descriptions.Item>
        <Descriptions.Item label="Mjesto">{sender.settlement.postOffice.code} {sender.settlement.name}</Descriptions.Item>
        <Descriptions.Item label="Telefon">{sender.phone}</Descriptions.Item>
        <Descriptions.Item label="Email">{sender.email}</Descriptions.Item>
    </Descriptions>);

export const RecipientDesc = ({ recipient }: { recipient: ShipmentRecipient }) => (
    <Descriptions bordered size="small" column={1} title="Primatelj">
        <Descriptions.Item label="Naziv">{recipient.name}</Descriptions.Item>
        <Descriptions.Item label="Ime i prezime">{recipient.personName}</Descriptions.Item>
        <Descriptions.Item label="Adresa">{recipient.street} {recipient.streetNo}</Descriptions.Item>
        <Descriptions.Item label="Mjesto">{recipient.settlement.postOffice.code} {recipient.settlement.name}</Descriptions.Item>
        <Descriptions.Item label="Telefon">{recipient.phone}</Descriptions.Item>
        <Descriptions.Item label="Email">{recipient.email}</Descriptions.Item>
    </Descriptions>);

export const ServicesDesc = ({ shipment }: { shipment: Shipment }) => (
    <Descriptions bordered size="small" column={1} title="Usluge">
        <Descriptions.Item label="Uručenje do 9h"><BoolText value={shipment.useDeliveryBy9} /></Descriptions.Item>
        <Descriptions.Item label="Uručenje do 11h"><BoolText value={shipment.useDeliveryBy11} /></Descriptions.Item>
        <Descriptions.Item label="Otkupna pošiljka">
            <BoolText value={shipment.useCod} />
            {shipment.useCod && <span>
                :&nbsp;<CurrencyText value={shipment.codAmount} showSymbol />
                ,&nbsp;{labelCodType(shipment.codTypeId)}</span>}
        </Descriptions.Item>
        <Descriptions.Item label="Povrat ovjerenog dokumenta"><BoolText value={shipment.useReturnSignedDoc} /></Descriptions.Item>
        <Descriptions.Item label="Uručiti osobno"><BoolText value={shipment.usePersonalDelivery} /></Descriptions.Item>
        <Descriptions.Item label="Uručenje subotom"><BoolText value={shipment.useSaturdayDelivery} /></Descriptions.Item>
        <Descriptions.Item label="Prikup subotom"><BoolText value={shipment.useSaturdayPickup} /></Descriptions.Item>
        <Descriptions.Item label="Povratnica"><BoolText value={shipment.usePovratnica} /></Descriptions.Item>
        <Descriptions.Item label="Nestandardni format"><BoolText value={shipment.useNonstandardFormat} /></Descriptions.Item>
        <Descriptions.Item label="Obavijest - SMS"><BoolText value={shipment.useNotifySenderSms || shipment.useNotifyRecipientSms} /></Descriptions.Item>
        <Descriptions.Item label="Obavijest - Email"><BoolText value={shipment.useNotifySenderEmail || shipment.useNotifyRecipientEmail} /></Descriptions.Item>
    </Descriptions>);

export const GroupDesc = ({ shipmentGroup }: { shipmentGroup: ShipmentGroup }) => {
    if (!shipmentGroup) {
        return <Descriptions bordered size="small" column={1} title="Slanje">
            <Descriptions.Item label="Šifra">
                <Alert type="info" message="Pošiljka nije poslana" showIcon />
            </Descriptions.Item>
        </Descriptions>;
    }
    return <Descriptions bordered size="small" column={1} title="Slanje">
        <Descriptions.Item label="Šifra">{shipmentGroup.code}</Descriptions.Item>
        <Descriptions.Item label="Zahtjev kreiran"><DateDisplay value={shipmentGroup.createdOn} showTime /></Descriptions.Item>
        <Descriptions.Item label="Kreirao">{shipmentGroup.createdBy?.name}</Descriptions.Item>
        <Descriptions.Item label="Željeno vrijeme prikupa"><DateDisplay value={shipmentGroup.pickupDate} /></Descriptions.Item>
    </Descriptions>;
}

export const PackageDesc = ({ pack, packageCount }: { pack: Package, packageCount: number }) => (
    <Descriptions bordered size="small" column={1} title={`Paket ${pack.ordinal}/${packageCount}`}>
        <Descriptions.Item label="Barkod">{pack.barcode}</Descriptions.Item>
        <Descriptions.Item label="Najavljena masa"><WeightText value={pack.weight} showSymbol /></Descriptions.Item>
        <Descriptions.Item label="Masa"><WeightText value={pack.weightVerified} showSymbol /></Descriptions.Item>
        <Descriptions.Item label="Volumen">
            {pack.volumeX || pack.volumeY || pack.volumeZ
                ? `${pack.volumeX}x${pack.volumeY}x${pack.volumeZ}`
                : null}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
            <StatusHistoryPopover shipmentId={pack.shipmentId} packageId={pack.id}><StatusDisplay value={pack.status} /></StatusHistoryPopover>
        </Descriptions.Item>
        <Descriptions.Item label="Napomena statusa">{pack.statusNote}</Descriptions.Item>
        <Descriptions.Item label="Vrijeme statusa"><DateDisplay value={pack.statusOn} showTime /></Descriptions.Item>
    </Descriptions>);

export const InvoiceDesc = ({ shipmentInvoice, shipmentReturnInvoice }: { shipmentInvoice: ShipmentInvoice, shipmentReturnInvoice: ShipmentReturnInvoice }) => (
    <Descriptions bordered size="small" column={1} title="Faktura">
        {shipmentInvoice
            ? <>
                <Descriptions.Item label="Faktura">{shipmentInvoice.invoice.fullNumberText}</Descriptions.Item>
                <Descriptions.Item label="Cijena prijevoza"><CurrencyText value={shipmentInvoice.price} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Rabat">
                    <CurrencyText value={shipmentInvoice.discount} showSymbol />
                    &nbsp;
                    (<PercentText value={shipmentInvoice.discountCoef} showSymbol />)
                </Descriptions.Item>
                <Descriptions.Item label="Dodatne usluge"><CurrencyText value={shipmentInvoice.additionalServiceTotal} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Ukupno (bez PDV)">
                    <CurrencyText value={shipmentInvoice.totalWithDiscount} showSymbol style={{ fontWeight: "bold" }} />
                </Descriptions.Item>
            </>
            : <Descriptions.Item label="Faktura">Nije izrađena</Descriptions.Item>}

        {shipmentReturnInvoice && <>
            <Descriptions.Item label="Faktura (povrat pošiljke)">{shipmentReturnInvoice.invoice.fullNumberText}</Descriptions.Item>
            <Descriptions.Item label="Ukupno (bez PDV)">
                <CurrencyText value={shipmentReturnInvoice.price} showSymbol style={{ fontWeight: "bold" }} />
            </Descriptions.Item>
        </>}
    </Descriptions>);

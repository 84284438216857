import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { Dayjs } from "dayjs";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { ConsumerActor } from "net-common-ui/dist/Auth/ConsumerActor";

export interface ShipmentGroup {
    id?: number;
    code?: string;
    customerId?: number;
    shipmentCount?: number;
    note?: string;
    pickupDate?: Dayjs;
    createdBy?: ConsumerActor;
    createdOn?: Dayjs;
}

export function saveShipmentGroup(model: ShipmentGroup, shipmentIds: number[]): ApiCall<ShipmentGroup> {
    return ApiCall.put(kApi, "shipment-group", { ...model, shipmentIds });
}

export function dsoShipmentGroup(): DataSourceOptions<ShipmentGroup> {
    return {
        api: kApi,
        url: "shipment-group"
    };
}

export function dsoShipmentGroupByCustomerId(customerId: UrlParam): DataSourceOptions<ShipmentGroup> {
    return {
        api: kApi,
        url: `customer/${customerId}/shipment-group`
    };
}
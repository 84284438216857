import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { Region } from "./Region";

export interface PostOffice {
    id?: number;
    code?: string;
    name?: string;
    region?: Region;
    isActive?: boolean;

    // used only by few endpoints
    isPickupAllowed?: boolean;
    isDeliveryAllowed?: boolean;
}


export function getPostOfficefullName(model: PostOffice): string {
    return model ? `${model.code} ${model.name}`.trim() : "";
}

export function getPostOfficeById(id: UrlParam): ApiCall<PostOffice> {
    return ApiCall.get(kApi, `post-office/${id}`);
}
export function dsoPostOffice(): DataSourceOptions<PostOffice> {
    return {
        api: kApi,
        url: "post-office"
    };
}
export function dsoPostOfficeByServiceType(serviceTypeId: UrlParam): DataSourceOptions<PostOffice> {
    return {
        api: kApi,
        url: `service-type/${serviceTypeId}/post-office`
    };
}
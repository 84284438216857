import React from 'react';
import { ComboBox, FilterBuilder } from "net-common-ui";
import { Settlement } from 'Models/Codebook/Settlement';
import { ComboBoxProps } from 'net-common-ui/dist/Components/ComboBox/ComboBox';

// Functions are in this scope (not inside component) because they are stateless, and all instances
// of SettlementComboBox may reuse same function. 


// Fixed way of displaying dropdown items
const itemTemplate = (model: Settlement) => {
    if (model.postOffice.code.indexOf("000") > 0) {
        return <><strong>{model.postOffice.code}</strong> - {model.name}</>;
    }
    return <>{model.postOffice.code} - {model.name}</>
}
// Fixed way of displaying selected items
const selectionTemplate = (model: Settlement) => {
    return model.postOffice.code + " - " + model.name;
}

// Fixed way of filtering data for all SettlementComboBoxes
const filter = (filter: FilterBuilder<Settlement>, term: string) => {
    filter.setLogic("or")
        .set("name", "contains", term)
        .set("postOffice.code", "contains", term);
}

// Props we expose on this control are the same as ComboBox, but we remove a few with Omit<...>
type Props = Omit<ComboBoxProps<Settlement>, "children" | "itemTemplate" | "selectionTemplate" | "filter" | "filterField">;

// Simply wrap ComboBox, set some fixed fields, and leave rest as params as usual
export const SettlementComboBox = React.forwardRef((props: Props, ref: React.Ref<any>) =>
    <ComboBox<Settlement>
        ref={ref}
        selectionTemplate={selectionTemplate}
        itemTemplate={itemTemplate}
        filter={filter}
        {...props}
    />);

export const SenderSettlementComboBox = React.forwardRef((props: Props, ref: React.Ref<any>) =>
    <ComboBox<Settlement>
        ref={ref}
        selectionTemplate={selectionTemplate}
        itemTemplate={itemTemplate}
        filter={filter}
        optionDisabled={model => !model.isPickupAllowed}
        {...props}
    />);

export const RecipientSettlementComboBox = React.forwardRef((props: Props, ref: React.Ref<any>) =>
    <ComboBox<Settlement>
        ref={ref}
        selectionTemplate={selectionTemplate}
        itemTemplate={itemTemplate}
        filter={filter}
        optionDisabled={model => !model.isDeliveryAllowed}
        {...props}
    />);

import { useEffect, useState } from 'react';
import { getShipmentInfoById, Shipment } from 'Models/Logi/Shipment';
import { CodForm } from './CodForm';
import { CodView } from './CodView';
import { Bux } from 'Components/Bux';
import { Alert, Button, Col, message, Row, Skeleton } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { getShipmentCodById, saveShipmentCod, ShipmentCod } from 'Models/Logi/ShipmentCod';
import { CodType, labelCodType } from 'Models/Enum';

export const CodTab = ({ shipment, onChange }: { shipment: Shipment, onChange: (shipment: Shipment) => void }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [shipmentCod, setShipmentCod] = useState<ShipmentCod>(null);

    useEffect(() => {
        if (shipment?.id) {
            // dont handle errors globally, 404 is fine
            getShipmentCodById(shipment.id).execute({ errorHandler: false }).then(result => {
                if (result.item) {
                    setShipmentCod(result.item);
                }
                else {
                    // default value
                    setShipmentCod({
                        shipmentId: shipment.id,
                        customerAmount: shipment.codAmount
                    });
                }
            });
        }
    }, [shipment]);


    // admin can edit if transfer has not been done (logex-customer)
    const editable = shipment
        && shipmentCod
        && shipment.shipmentGroup
        && shipment.useCod
        && shipment.codTypeId === CodType.BezNaloga
        && !shipment.isDeleted
        && !shipment.isCancelled
        && !shipmentCod.codTransfer;

    const handleEdit = () => {
        setIsEditing(true);
    }

    const handleCancel = () => {
        setIsEditing(false);
    }

    const handleSave = (shipmentCod: ShipmentCod) => {
        setIsSaving(true);
        saveShipmentCod(shipmentCod).execute().then(result => {
            setIsSaving(false);
            if (result.success) {
                message.success("Podaci o otkupnini spremljeni.");
                // reload jer su mozda computed polja izmjenjena
                getShipmentInfoById(shipment.id).execute().then(result => {
                    onChange(result.item);
                    setIsEditing(false);
                });
            }
        });
    }

    return <>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button key="edit" disabled={!editable || isEditing} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {shipment && shipment.useCod && shipment.codTypeId !== CodType.BezNaloga &&
                <Alert type="warning" showIcon message={"Uređivanje podataka nije moguće za vrstu otkupnine '" + labelCodType(shipment.codTypeId) + "'."} />}
            {!shipment || !shipmentCod
                ? <Skeleton active />
                : isEditing
                    ? <CodForm
                        saving={isSaving}
                        shipment={shipment}
                        shipmentCod={shipmentCod}
                        onSave={handleSave}
                        onCancel={handleCancel} />
                    : <CodView
                        useCod={shipment.useCod}
                        codTypeId={shipment.codTypeId}
                        shipmentCod={shipmentCod} />}
        </Bux.Content>

    </>;
}

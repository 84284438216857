import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { Country } from "./Country";

export interface Region {
    id?: number;
    code?: string;
    name?: string;
    country?: Country;
}

export function getRegionById(id: UrlParam): ApiCall<Region> {
    return ApiCall.get(kApi, `region/${id}`);
}
export function dsoRegion(): DataSourceOptions<Region> {
    return {
        api: kApi,
        url: "region"
    };
}
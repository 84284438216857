import { Col, Empty, Row } from 'antd';
import { CodTransferGroup } from 'Models/Bus/CodTransferGroup';
import { CurrencyText } from 'Components/Text';
import {
    CheckCircleOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';


interface Props {
    group: CodTransferGroup;
}

export const Summary = ({ group }: Props) => {
    return <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
            <Empty
                image={<CheckCircleOutlined className="text-success" style={{ fontSize: '60px' }} />}
                imageStyle={{ height: 60 }}
                description={
                    <span>
                        Grupa naloga uspješno kreirana. Ukupno {group.codTransferCount} naloga za isplatu <CurrencyText value={group.amount} showSymbol />.
                    </span>
                }
            >
                <Link to={"/admin/cod/transfer-group/" + group.id}>Prikaži naloge <ArrowRightOutlined /></Link>
            </Empty>
        </Col>
    </Row>;
}

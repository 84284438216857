import { useState } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { saveStandardPriceList } from 'Models/Bus/PriceList/StandardPriceList';

interface Props {
    onClose: (created: boolean) => void;
}

export const NewButton = ({ onClose }: Props) => {
    const [saving, setSaving] = useState(false);

    const handleSave = () => {
        setSaving(true);
        saveStandardPriceList().execute().then(result => {
            setSaving(false);
            if (result.success) {
                message.success("Standardni cjenik uspješno kreiran.")
                onClose(true);
            }
        });
    };
    return (
        <Button block type="primary" onClick={handleSave} disabled={saving} icon={<PlusOutlined />}>Novi cjenik</Button>
    );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Drawer, Dropdown, Form, Menu, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { SearchOutlined, DownOutlined, CloseOutlined, ReloadOutlined } from "@ant-design/icons"

type ChangeCallback<TFilter> = (filter: TFilter) => void;
interface FilterFormProps<TFilter> {
    disabled?: boolean;
    form?: FormInstance;
    filter?: TFilter;
    initialFilter?: TFilter;
    onChange?: ChangeCallback<TFilter>;
}



function getActiveCount<TFilter>(filter: TFilter): number {
    return filter
        ? Object.keys(filter).filter(key => filter[key] !== undefined).length
        : 0;
}

export const withFilterDrawer = <TFilter extends Object>(
    FilterForm: React.ComponentType<FilterFormProps<TFilter>>
) => ({ onChange, disabled, initialFilter, filter, ...rest }: FilterFormProps<TFilter>) => {
    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);
    const lastFilter = useRef<TFilter>({} as TFilter);
    const [activeCount, setActiveCount] = useState<number>(getActiveCount(lastFilter.current));
    const change = useRef<ChangeCallback<TFilter>>(onChange);
    const hasFilter = activeCount > 0;

    // keep track of latest change callback
    change.current = onChange;

    const updateFilter = useCallback((filter: TFilter, updateForm: boolean) => {
        if (!filter) {
            filter = initialFilter || {} as TFilter;
        }

        if (updateForm) {
            form.resetFields();
            form.setFieldsValue(filter);
        }

        setActiveCount(getActiveCount(filter));
        setVisible(false);
        lastFilter.current = filter;
        change.current(filter);
    }, [initialFilter, form]);

    const handleFilterChange = (filter: TFilter) => {
        // dont update form, since this is form change handler
        updateFilter(filter, false);
    }

    const handleRefresh = () => {
        updateFilter(lastFilter.current, false);
    }

    // update filter
    useEffect(() => {
        updateFilter(filter, true);
    }, [filter, updateFilter]);

    const menu = <Menu>
        <Menu.Item key="clear" icon={<CloseOutlined />} onClick={() => updateFilter(null, true)} disabled={!hasFilter}>
            Očisti filtere
        </Menu.Item>
        <Menu.Item key="refresh" icon={<ReloadOutlined />} onClick={handleRefresh}>
            Osvježi
        </Menu.Item>
    </Menu>;

    return <>
        <Dropdown.Button
            disabled={disabled}
            type="primary"
            overlay={menu}
            placement="bottomCenter"
            icon={<DownOutlined />}
            onClick={() => setVisible(true)}
            trigger={["click"]}
        >
            <SearchOutlined />
            Filteri
            {hasFilter && <span>&nbsp;(aktivno: {activeCount})</span>}
        </Dropdown.Button>
        <Drawer
            title="Filteri"
            placement="right"
            width={500}
            onClose={() => setVisible(false)}
            visible={visible}
            // Za sada zakomentirano da izbjegnemo render prije nego se drawer otvori.. mana je samo warning cini se... Ako bude
            // problema, otkomentirati ovo i fuckit
            //forceRender // otherwise form isnt rendered, and form var throws warnings
            footer={<Row gutter={16}>
                <Col span={12}><Button block onClick={() => form.resetFields()}>Očisti</Button></Col>
                <Col span={12}><Button block type="primary" onClick={form.submit}>Ok</Button></Col>
            </Row>}
        >
            <FilterForm form={form} onChange={handleFilterChange} {...rest} />
        </Drawer>
    </>
}

export default withFilterDrawer;

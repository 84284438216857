import { Alert, Row, Col, Tooltip } from 'antd';
import { PercentText, CurrencyText } from "Components/Text";
import { WarningOutlined, CheckOutlined } from "@ant-design/icons";
import { PriceList } from 'Models/Bus/PriceList/PriceList';

const fields = [
    { label: "Dostava do 9", name: "deliveryBy9Price", hasCoef: false, hasPrice: true },
    { label: "Dostava do 11", name: "deliveryBy11Price", hasCoef: false, hasPrice: true },
    { label: "Nestandardni format", name: "nonstandardFormatPrice", hasCoef: false, hasPrice: true },
    { label: "Povratnica", name: "povratnicaPrice", hasCoef: false, hasPrice: true },
    { label: "Povrat potpisanog dokumenta", name: "returnSignedDocPrice", hasCoef: false, hasPrice: true },
    { label: "Otkupnina", name: "codPrice", hasCoef: false, hasPrice: true },
    { label: "Dostava/Uručenje subotom", name: "saturdayHandlingPrice", hasCoef: true, hasPrice: false },
    { label: "Uručenje osobno", name: "personalDeliveryPrice", hasCoef: false, hasPrice: true },
    { label: "Povratno rukovanje", name: "returnHandlingPrice", hasCoef: true, hasPrice: false },
    { label: "SMS", name: "smsPrice", hasCoef: false, hasPrice: true },
    { label: "Email", name: "emailPrice", hasCoef: false, hasPrice: true }
];

interface Props {
    priceList: PriceList;
    basePriceList?: PriceList;
}

export const AdditionalServiceView = ({ priceList, basePriceList }: Props) => {
    const value = priceList.priceAdditionalService;
    const base = basePriceList?.priceAdditionalService;
    const actual = value || base;

    return actual ?
        <div>
            {!value && base && <Alert
                message="Standardni cjenik"
                description={<p>Koriste se parametri standardnog cjenika.</p>}
                type="info"
                showIcon
                style={{ marginBottom: 6 }}
            />}
            <Row gutter={[16, 16]} style={{ fontWeight: "bold" }}>
                <Col span={8}>Usluga</Col>
                <Col span={8}>Iznos</Col>
                <Col span={8}>Uvećanje</Col>
            </Row>
            {fields.map(field => (
                <Row gutter={[16, 16]} key={field.name}>
                    <Col span={8}>
                        {actual[field.name] || actual[field.name + "Coef"]
                            ? <CheckOutlined className="text-success" />
                            : <Tooltip title="Poželjno je upisati iznos ili uvećanje"><WarningOutlined className="text-warn" /></Tooltip>}
                        <span style={{ marginLeft: 4 }}>{field.label}</span>
                    </Col>
                    <Col span={8}>
                        {field.hasPrice && <CurrencyText value={actual[field.name]} showSymbol />}
                    </Col>
                    <Col span={8}>
                        {field.hasCoef && <PercentText value={actual[field.name + "Coef"]} showSymbol />}
                    </Col>
                </Row>))}
        </div>
        : <Alert
            message="Upozorenje"
            description={<>
                <p>Cjenik nema definirane cijene dodatnih usluga.</p>
                <p>Korisnik i dalje može koristiti dodatne usluge, samo one neće biti naplaćene dok se ovdje ne definiraju cijene.</p>
            </>}
            type="warning"
            showIcon
        />;
}

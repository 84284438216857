import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { ApiCall } from "net-common-ui";

export interface DashDailyCustomerStats {
    customerId?: number;
    customerCode?: string;
    customerName?: string;
    shipmentCount?: number;
}

export function getDashDailyCustomerStats(range: [Dayjs, Dayjs]): ApiCall<DashDailyCustomerStats> {
    return ApiCall.get(kApi, "dash/daily-customer-stats", {
        from: range[0].format("YYYY-MM-DD"),
        to: range[1].format("YYYY-MM-DD")
    })
}

import React, { useContext, useCallback } from 'react';
import { Customer } from 'Models/Bus/Customer';
import produce from 'immer';
import { AppAccount } from 'Models/Bus/AppAccount';
import { getMeInfo, Me } from 'Models/Bus/Me';
import { ApiCallView } from 'net-common-ui/dist/Data/ApiCall';

export interface MeState {
    customer: Customer;
    appAccount: AppAccount;
    loading: boolean;
    invalid: boolean;
}

type Action =
    { type: "ME_LOADING" } |
    { type: "ME_LOADED", payload: Me } |
    { type: "ME_ERROR" }
    ;


export const InitialState: MeState = {
    customer: null,
    appAccount: null,
    loading: true,
    invalid: false
};

interface MeContextValue {
    dispatch: React.Dispatch<Action>;
    state: MeState;
}

export const useCustomer = () => {
    return useContext(MeContext).state.customer;
}

export const useAppAccount = () => {
    return useContext(MeContext).state.appAccount;
}

export const useIsAdmin = () => {
    return useContext(MeContext).state.appAccount.isAdmin || false;
}

// Creates a function that may be invoked to reload context.
// Resulting function may receive external dispatch (used in root), but
// you can call it without params - dispatch will be taken from context
export const useMeLoader = (): (dispatch?: React.Dispatch<Action>) => Promise<ApiCallView<Me>> => {
    const contextDispatch = useContext(MeContext)?.dispatch;

    return useCallback((dispatch?: React.Dispatch<Action>) => {
        const d = dispatch || contextDispatch;
        d({ type: "ME_LOADING" });
        return getMeInfo().execute().then(result => {
            if (result.success) {
                d({ type: "ME_LOADED", payload: result.item });
            }
            else {
                d({ type: "ME_ERROR" });
            }
            return result;
        });
    }, [contextDispatch]);
}

export const MeContext = React.createContext<MeContextValue>(null);

export const reducer = produce((draft: MeState, action: Action) => {
    //console.log("Reducer type " + action.type)
    switch (action.type) {
        case "ME_LOADING":
            draft.loading = true;
            break;
        case "ME_LOADED":
            draft.customer = action.payload.customer;
            draft.appAccount = action.payload.appAccount;
            draft.loading = false;
            break;
        case "ME_ERROR":
            draft.loading = false;
            draft.invalid = true;
            break;
    }
}, InitialState as any) as ((state: MeState, action: Action) => MeState);

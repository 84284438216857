import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface Country {
    id?: number;
    iso2?: string;
    iso3?: string;
    name?: string;
    isActive?: boolean;
}

export function getCountryById(id: UrlParam): ApiCall<Country> {
    return ApiCall.get(kApi, `country/${id}`);
}
export function dsoCountry(): DataSourceOptions<Country> {
    return {
        api: kApi,
        url: "country"
    };
}
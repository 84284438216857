import { Popover, Table, Tag } from 'antd';
import { DateDisplay } from "net-common-ui";
import { ShipmentNotifLog } from 'Models/Logi/ShipmentLog/ShipmentNotifLog';
import {
    MailOutlined,
    PhoneOutlined
} from '@ant-design/icons';
import { labelNotifChannel, labelNotifTarget, NotifChannel, NotifTarget } from 'Models/Enum';
import { Status } from 'Models/Logi/Status';
import { StatusDisplay } from 'Components/StatusDisplay';
import { Link } from 'react-router-dom';
import { DataView } from "net-common-ui/dist/Data/DataSource";
import { Customer } from 'Models/Bus/Customer';

interface Props {
    dataView: DataView<ShipmentNotifLog>;
    admin?: boolean;
}

export const ShipmentNotifTable = ({ dataView, admin }: Props) => {
    const routePrefix = admin ? "/admin" : "/pub";
    return <Table<ShipmentNotifLog>
        dataSource={dataView.data}
        pagination={false}
        rowKey="id"
    >

        {admin && <Table.Column ellipsis dataIndex="customer" title="Korisnik" render={(customer: Customer) =>
            <><Link to={"/admin/customer/" + customer.id}>{customer.code}</Link> {customer.name}</>
        } />}

        <Table.Column width={120} title="Pošiljka" dataIndex="barcode" render={(barcode: string, record: ShipmentNotifLog) =>
            <Link to={routePrefix + "/shipment/" + record.shipmentId}>{barcode}</Link>} />
        <Table.Column width={140} ellipsis title="Status" dataIndex={["customerNotifConfig", "status"]} render={(value: Status) => <StatusDisplay value={value} />} />
        <Table.Column width={60} title="Kanal" dataIndex={["customerNotifConfig", "notifChannelId"]} render={(value: NotifChannel) => labelNotifChannel(value)} />
        <Table.Column width={80} title="Odredište" dataIndex={["customerNotifConfig", "notifTargetId"]} render={(value: NotifTarget) => labelNotifTarget(value)} />


        <Table.Column title="Primatelj" ellipsis key="recipient" render={(_, record: ShipmentNotifLog) => <>
            {record.mail && <span><MailOutlined /> {record.mail.toAddressGdpr}</span>}
            {record.sms && <span><PhoneOutlined /> {record.sms.toAddressGdpr}</span>}
        </>} />
        <Table.Column width={140} title="Kreirano" dataIndex="createdOn" render={value => <DateDisplay value={value} showTime />} />
        <Table.Column width={110} ellipsis title="Status" key="status" render={(_, record: ShipmentNotifLog) => record.isPending
            ? <Tag color="blue">U obradi</Tag>
            : (record.errorMessage
                ? <Popover content={record.errorMessage || "Nepoznato"} placement="left"><Tag color="volcano">Preskočeno</Tag></Popover>
                : <Tag color="green">Poslano</Tag>)} />
    </Table>
}
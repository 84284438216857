import { useState } from 'react';
import { Button, Modal, Checkbox, Divider, Alert } from 'antd';
import {
    CheckOutlined
} from '@ant-design/icons';

interface Props {
    loading: boolean;
    onSave: (notifyCustomer: boolean) => void;
}

export const BuildButton = ({ loading, onSave }: Props) => {
    const [notify, setNotify] = useState<boolean>(true);
    const [visible, setVisible] = useState<boolean>(false);

    const handleShow = () => {
        setVisible(true);
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const handleOk = async () => {
        onSave(notify);
    }

    return <>
        <Button type="primary" block loading={loading} onClick={handleShow} icon={<CheckOutlined />}>Kreiraj naloge</Button>
        <Modal
            title="Izrada otkupnih naloga"
            visible={visible}
            onOk={handleOk}
            okButtonProps={{ loading }}
            onCancel={handleCancel}
            cancelButtonProps={{ disabled: loading }}
            destroyOnClose
            closable={!loading}
            okText="Kreiraj naloge"
        >
            <p>Ova akcija će kreirati naloge za transfer otkupnih iznosa svim navedenim korisnicima. <strong>Akcija je nepovratna!</strong></p>
            <Divider />
            <p>
                <Checkbox checked={notify} disabled={loading} onChange={e => setNotify(e.target.checked)}>
                    Pošalji obavijest korisnicima
                </Checkbox>
            </p>
            <Alert type="info" showIcon message={<span>
                Obavijest će biti poslana svim korisnicima na adresu upisanu u polju <em>Email za transfere otkupnina</em>.
                <br />
                Ukoliko želite poslati obavijest na drugu adresu, ažurirajte podatke na profilima korisnika.</span>} />
        </Modal>
    </>;
}

import React, { useState } from 'react';
import { Button, Descriptions, Drawer } from 'antd';
import { getMailById, Mail } from "Models/Bus/Mail"
import { DateDisplay } from "net-common-ui";
import { Ok, Error } from "./StatusIcon";

interface Props {
    mailId: number;
}

export const MailDisplay = ({ mailId, children }: React.PropsWithChildren<Props>) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mail, setMail] = useState<Mail>(null);

    const showDrawer = async () => {
        setLoading(true);
        const result = await getMailById(mailId, true).execute();
        setMail(result.item);
        setLoading(false);
        setVisible(true);

    };
    const onClose = () => {
        setVisible(false);
    };

    return <>
        <Button disabled={loading} type="link" onClick={showDrawer} style={{ padding: 0, height: "auto" }}>{children || "Prikaži detalje"}</Button>
        <Drawer
            title="Detalji mail poruke"
            placement="right"
            closable
            onClose={onClose}
            width={500}
            visible={visible}
            bodyStyle={{ padding: 6 }}
        >
            {!mail && <p>Greška prilikom učitavanja podataka o mail poruci</p>}
            {mail && <div>
                <Descriptions bordered size="small" column={1} title={null}>
                    <Descriptions.Item label="Primatelj">{mail.toAddressGdpr}</Descriptions.Item>
                    <Descriptions.Item label="Kreirano"><DateDisplay value={mail.createdOn} showTime /></Descriptions.Item>
                    <Descriptions.Item label="Obrađeno"><DateDisplay value={mail.processedOn} showTime /></Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {mail.errorMessage ? <Error extra={mail.errorMessage} /> : <Ok extra="Uspješno poslano" />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Naslov">{mail.subject}</Descriptions.Item>
                </Descriptions>
                <div dangerouslySetInnerHTML={{ __html: mail.bodyHtml }}></div>
            </div>}
        </Drawer>
    </>;
}

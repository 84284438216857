import { useHistory } from 'react-router-dom';
import { Shipment } from 'Models/Logi/Shipment';
import { ShipmentView } from './ShipmentView';
import { Bux } from 'Components/Bux';
import { Col, Row, Skeleton } from 'antd';
import { DeleteButton, StornoButton, GratisButton } from "Components/Shipment/ActionButton";

export const MainTab = ({ shipment, onChange }: { shipment: Shipment, onChange: (shipment: Shipment) => void }) => {
    const history = useHistory();

    const handleDelete = async () => {
        history.push("/admin/shipment");
    }

    const handleStorno = async () => {
        const updated: Shipment = { ...shipment, isCancelled: true };
        onChange(updated);
    }

    const handleGratis = async () => {
        const updated: Shipment = { ...shipment, isGratis: true };
        onChange(updated);
    }

    return <>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <GratisButton shipment={shipment} key="gratis" onExecuted={handleGratis} block />
                </Col>
                <Col span={4}>
                    <DeleteButton shipment={shipment} key="delete" onExecuted={handleDelete} block />
                </Col>
                <Col span={4}>
                    <StornoButton shipment={shipment} key="storno" onExecuted={handleStorno} block />
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {!shipment
                ? <Skeleton active />
                : <ShipmentView shipment={shipment} />
            }
        </Bux.Content>
    </>;
}

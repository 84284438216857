import { UrlParam } from "net-common-ui";
import { Status } from "Models/Logi/Status";
import { kApi } from "Api";
import { ShipmentEventLog } from "./ShipmentEventLog";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { Dayjs } from "dayjs";

export interface PackageStatusLog {
    id?: number;
    packageId?: number;
    shipmentEventLogId?: number;
    shipmentEventLog?: ShipmentEventLog;
    statusOn?: Dayjs;
    status?: Status;
    statusMeta?: string;
    statusNote?: string;
}

export function dsoPackageStatusLogByPackageId(shipmentId: UrlParam, packageId: UrlParam): DataSourceOptions<PackageStatusLog> {
    return {
        api: kApi,
        url: `shipment/${shipmentId}/package/${packageId}/package-status-log`
    };
}
import React from 'react';
import { Pagination, PageHeader, Table } from 'antd';
import { FilterForm, RegionFilter } from "./FilterForm"
import { dsoRegion, Region } from "Models/Codebook/Region"
import { applyGridState, useDataSource, useStateManager } from "net-common-ui";
import { Bux } from 'Components/Bux';
import withFilterDrawer from 'Components/withFilterDrawer';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const [view, ds] = useDataSource(dsoRegion());
    const [state, sm] = useStateManager<GridState<RegionFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("code", "contains", filter.code)
                .set("name", "contains", filter.name)
        );
    });

    const handleFilterChange = (filter: RegionFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };


    return <Bux.Page>
        <Bux.Header><PageHeader title={"Regije"} /></Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<Region> rowKey="id" pagination={false} dataSource={view.data}>
                <Table.Column dataIndex="id" title="Id" />
                <Table.Column dataIndex="code" title="Šifra" />
                <Table.Column dataIndex="name" title="Naziv" />
                <Table.Column dataIndex={["country", "name"]} title="Država" />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

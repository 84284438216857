import React from 'react';
import { Table, Tag, Tooltip } from 'antd';
import { DateDisplay, useDataSource } from "net-common-ui";
import { Shipment } from 'Models/Logi/Shipment';
import { dsoPackageByShipmentId, Package } from 'Models/Logi/Package';
import { Link } from 'react-router-dom';
import { CloseCircleFilled, CheckCircleOutlined, SwapRightOutlined, StopOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { StatusHistoryPopover } from '../StatusHistoryPopover';
import { WeightText, CurrencyText } from '../Text';
import { DataView } from "net-common-ui/dist/Data/DataSource";
import { Customer } from 'Models/Bus/Customer';
import { CodType, deliveryDeadlineLabel } from 'Models/Enum';
import { StatusDisplay } from 'Components/StatusDisplay';
import { ShipmentCod } from 'Models/Logi/ShipmentCod';
import { useIsAdmin } from 'Views/MeContext';
import { ShipmentSender } from 'Models/Logi/ShipmentSender';
import { ShipmentRecipient } from 'Models/Logi/ShipmentRecipient';
import { AddressCell } from "Components/Address";

/*
const AllColumns: ColumnsType<Shipment> = [
    {
        title: "Broj",
        dataIndex: "code",
        width: 80,
        render: (code, record: Shipment) => <>
            <Link to={"shipment/" + record.id}>{code}</Link>
            {record.sendErrorMessage && <Tooltip title={record.sendErrorMessage}>
                <CloseCircleFilled style={{ color: "#d62d2d", marginLeft: 4 }} />
            </Tooltip>}
        </>
    },
    {
        title: "Korisnik",
        dataIndex: "customer",
        ellipsis: true,
        render: (customer: Customer) => <span>
            <Link to={"/admin/customer/" + customer.id}>{customer.code}</Link> {customer.name}
        </span>
    },
    {
        title: "Barkod",
        dataIndex: "barcode",
        width: 120
    },
    {
        title: "Status",
        dataIndex: "status",
        ellipsis: true,
        render: status => status.name
    },
    {
        title: "Vrijeme statusa",
        dataIndex: "statusOn",
        width: 160,
        render: value => <DateDisplay value={value} showTime />
    }
];

*/

interface Props {
    dataView: DataView<Shipment>;
    onSelectionChange?: (selectedKeys: number[]) => void;
}

export const ShipmentTable = ({ dataView, onSelectionChange }: Props) => {
    return <Table<Shipment>
        dataSource={dataView.data}
        rowSelection={{
            type: "checkbox",
            onChange: (selectedRowKeys: number[]) => {
                onSelectionChange(selectedRowKeys);
            }
        }}
        pagination={false}
        expandable={{
            expandedRowRender: record => <DetailRow shipment={record} />
        }}
        rowKey="id"
    >
        <Table.Column dataIndex="code" title="Broj" width={90} render={(code, record: Shipment) => <>
            <Link to={"/pub/shipment/" + record.id}>{code}</Link>
            {record.sendErrorMessage && <Tooltip title={record.sendErrorMessage}>
                <CloseCircleFilled style={{ color: "#d62d2d", marginLeft: 4 }} />
            </Tooltip>}
            <br />
            {record.isCancelled
                ? <em className="text-warn">Storno</em>
                : record.isDeleted
                    ? <em className="text-danger">Obrisano</em>
                    : record.shipmentGroup
                        ? <em>Poslano</em>
                        : null}
        </>} />
        <Table.Column title="Pošiljatelj" dataIndex="sender" ellipsis render={(pt: ShipmentSender) => <AddressCell address={pt} />} />
        <Table.Column title="Primatelj" dataIndex="recipient" ellipsis render={(pt: ShipmentRecipient) => <AddressCell address={pt} />} />
        <Table.Column title="Status" dataIndex="status" ellipsis render={(status, record: Shipment) => <>
            <StatusDisplay value={status} />
            <br />
            <DateDisplay value={record.statusOn} showTime />
        </>} />
        <Table.Column title="Usluge" key="services" ellipsis render={(_, record: Shipment) => <ServicesCell shipment={record} />} />
    </Table>;
}

export const AdminShipmentTable = ({ dataView }: Props) => {
    return <Table<Shipment>
        dataSource={dataView.data}
        pagination={false}
        expandable={{
            expandedRowRender: record => <DetailRow shipment={record} />
        }}
        rowKey="id"
    >
        <Table.Column dataIndex="code" title="Broj" width={90} render={(code, record: Shipment) => <>
            <Link to={"shipment/" + record.id}>{code}</Link>
            {record.sendErrorMessage && <Tooltip title={record.sendErrorMessage}>
                <CloseCircleFilled style={{ color: "#d62d2d", marginLeft: 4 }} />
            </Tooltip>}
            <br />
            {record.isCancelled
                ? <em className="text-warn">Storno</em>
                : record.isDeleted
                    ? <em className="text-danger">Obrisano</em>
                    : record.shipmentGroup
                        ? <em>Poslano</em>
                        : null}
        </>} />

        <Table.Column dataIndex="customer" title="Korisnik / PU" ellipsis render={(customer: Customer, record: Shipment) => <>
            <Link to={"/admin/customer/" + customer.id}>{customer.code}</Link> {customer.name}
            <br />
            {record.sender.settlement.postOffice.code} <SwapRightOutlined /> {record.recipient.settlement.postOffice.code}
        </>} />
        <Table.Column title="Barkod" dataIndex="barcode" width={120} render={(barcode, record: Shipment) => <>
            {barcode}
        </>} />
        <Table.Column title="Status" dataIndex="status" ellipsis render={(status, record: Shipment) => <>
            <StatusDisplay value={status} />
            <br />
            <DateDisplay value={record.statusOn} showTime />
        </>} />
        <Table.Column title="Usluge" key="services" ellipsis render={(_, record: Shipment) => <ServicesCell shipment={record} />} />
    </Table>;
}

const ServicesCell = ({ shipment }: { shipment: Shipment }) => {
    const {
        deliveryDeadlineId,

        useCod,
        codTypeId,
        codAmount,
        shipmentCod,

        useDeliveryBy9,
        useDeliveryBy11,
        // useSaturdayDelivery,
        // useSaturdayPickup,
        useReturnSignedDoc,
        usePersonalDelivery,
        usePovratnica,
        useNonstandardFormat,

        isGratis } = shipment;

    return <div className="spec-shipment-service-cell">
        <Tag color="processing">
            {deliveryDeadlineLabel(deliveryDeadlineId)}
            {useDeliveryBy9 && <span> do 9h</span>}
            {useDeliveryBy11 && <span> do 11h</span>}
        </Tag>

        {isGratis && <Tooltip title={<span>Opcija <em>Gratis</em> je aktivna! Pošiljka neće biti fakturirana.</span>}><Tag color="processing">Gratis</Tag></Tooltip>}
        {useCod && <CodTag codTypeId={codTypeId} codAmount={codAmount} shipmentCod={shipmentCod} />}
        {useReturnSignedDoc && <Tag>Dokument</Tag>}
        {usePersonalDelivery && <Tag>Osobno</Tag>}
        {usePovratnica && <Tag>Povratnica</Tag>}
        {useNonstandardFormat && <Tag>Nestandardno</Tag>}
    </div>;
}

const CodTag = ({ codTypeId, codAmount, shipmentCod }: { codTypeId: CodType, codAmount: number, shipmentCod: ShipmentCod }) => {
    const admin = useIsAdmin();
    let color: string;
    let icon: React.ReactNode = null;
    let tooltip: string = null;

    if (codTypeId === CodType.BezNaloga) {
        // BEZ NALOGA

        if (shipmentCod?.codTransfer) {
            color = "success";
            icon = <CheckCircleOutlined />;
            tooltip = "Isplaćeno korisniku";
        }
        else if (admin && shipmentCod && !shipmentCod.codTransfer) {
            if (shipmentCod.carrierAmount === shipmentCod.customerAmount) {
                color = "warning";
                icon = <ClockCircleOutlined />;
                tooltip = "Kurir uplatio, isplata moguća";
            }
            else {
                color = "error";
                icon = <StopOutlined />;
                tooltip = "Kurir uplatio, ali isplata nije moguća";
            }
        }
        else {
            color = "default";
            tooltip = "Nije isplaćeno";
        }
    }
    else if (codTypeId === CodType.PostanskaUputnica) {
        // POŠTANSKA UPUTNICA

        color = "default";
        tooltip = "Poštanska uputnica";
    }

    return <Tooltip title={tooltip}><Tag color={color}>Otk: <CurrencyText value={codAmount} showSymbol />{icon}</Tag></Tooltip>;
}

const DetailRow = ({ shipment }: { shipment: Shipment }) => {
    const [view] = useDataSource(dsoPackageByShipmentId(shipment.id), { execute: true });
    return <Table<Package>
        size="small"
        dataSource={view.data}
        pagination={false}
        //showHeader={false}
        rowKey="id">
        <Table.Column title="R.Br." dataIndex="ordinal" width={50} />
        <Table.Column title="Barkod" dataIndex="barcode" width={120} />
        <Table.Column title="Najava" dataIndex="weight" width={90} render={value => <WeightText value={value} showSymbol />} />
        <Table.Column title="Masa" dataIndex="weightVerified" width={90} render={value => <WeightText value={value} showSymbol />} />
        <Table.Column title="Status" dataIndex="status" ellipsis render={(status, pack: Package) => (
            <StatusHistoryPopover shipmentId={shipment.id} packageId={pack.id}><StatusDisplay value={pack.status} /></StatusHistoryPopover>
        )} />
        <Table.Column title="Vrijeme statusa" dataIndex="statusOn" ellipsis render={value => <DateDisplay value={value} showTime />} />
    </Table>;
}

import { useMemo } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import downloadStream from 'downloadStream';
import { kApi } from 'Api';
import { DownloadOutlined } from "@ant-design/icons";

const downloadFile = async (url: string, defaultFileName: string) => {
    // filename is igonred if content disposition is successfully sniffed
    const result = await downloadStream(kApi, { url, sniffContentDisposition: true, fileName: defaultFileName });
    result.success && result.download();
}

const downloadReport = async (id: number, name: "invoice-shipment" | "invoice-notif", format: "xls" | "pdf") => {
    // filename is igonred if content disposition is successfully sniffed
    const result = await downloadStream(kApi, {
        url: "rpt/" + name + ";" + format,
        method: "post",
        data: { invoiceId: id },
        sniffContentDisposition: true,
        fileName: "invoice_shipments." + (format === "xls" ? "xlsx" : "pdf")
    });
    result.success && result.download();
}

const getFileMenu = (invoiceId: number, showInvoice: boolean) => (
    <Menu>
        {showInvoice && <Menu.Item key="pdf" onClick={() => downloadFile(`invoice/${invoiceId};pdf`, "invoice.pdf")}>
            <DownloadOutlined /> Faktura (Pdf)
        </Menu.Item>}
        <Menu.Item key="rpt-shipment-pdf" onClick={() => downloadReport(invoiceId, "invoice-shipment", "pdf")}>
            <DownloadOutlined /> Prilog - Pošiljke (Pdf)
        </Menu.Item>
        <Menu.Item key="rpt-shipment-xls" onClick={() => downloadReport(invoiceId, "invoice-shipment", "xls")}>
            <DownloadOutlined /> Prilog - Pošiljke (Excel)
        </Menu.Item>
        <Menu.Item key="rpt-notif-pdf" onClick={() => downloadReport(invoiceId, "invoice-notif", "pdf")}>
            <DownloadOutlined /> Prilog - Obavijesti (Pdf)
        </Menu.Item>
        <Menu.Item key="rpt-notif-xls" onClick={() => downloadReport(invoiceId, "invoice-notif", "xls")}>
            <DownloadOutlined /> Prilog - Obavijesti (Excel)
        </Menu.Item>
    </Menu>
);

interface Props {
    invoiceId: number;
    showAsLink?: boolean;
}

export function DownloadInvoiceButton({ invoiceId, showAsLink }: Props) {
    const menu = useMemo(() => getFileMenu(invoiceId, showAsLink), [invoiceId, showAsLink]);
    if (showAsLink) {
        return <Dropdown overlay={menu} trigger={['click']}>
            <Button type="link" onClick={e => e.preventDefault()} icon={<DownOutlined />}>Preuzmi</Button>
        </Dropdown>;
    }
    else {
        return <Dropdown.Button type="primary"
            onClick={() => downloadFile(`invoice/${invoiceId};pdf`, "invoice.pdf")}
            overlay={menu}
            //trigger={["click"]}
            icon={<DownOutlined />}
        >
            <DownloadOutlined /> Faktura
        </Dropdown.Button>
    }
};


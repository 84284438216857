import { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { useDataSourceRef } from 'net-common-ui';
import { dsoSettlement } from 'Models/Codebook/Settlement';
import { AddressBookEntry, saveAddressBookEntry } from 'Models/Bus/AddressBookEntry';

interface Props {
    visible: boolean;
    value: AddressBookEntry;
    onClose: (record?: AddressBookEntry) => void;
    allowOptionsEdit?: boolean;
}

export const AddressEditDialog = ({ visible, value, onClose, allowOptionsEdit }: Props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });

    const handleSave = () => {
        form.validateFields().then((model: AddressBookEntry) => {
            setSaving(true);
            saveAddressBookEntry({ ...value, ...model }).execute().then(result => {
                setSaving(false);
                if (result.success) {
                    message.success("Adresa uspješno spremljena.");
                    onClose(result.item);
                }
            });
        });
    };

    return (
        <Modal
            title="Zapis u adresaru"
            visible={visible}
            onOk={handleSave}
            okText="Spremi"
            confirmLoading={saving}
            destroyOnClose
            width={700}
            onCancel={() => onClose(null)}
        >
            <Form form={form} layout="vertical" name="form" initialValues={value} onFinish={handleSave} preserve={false}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Typography.Title level={3}>Osnovno</Typography.Title>
                        <Form.Item name="name" label="Naziv" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="personName" label="Ime i prezime">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Adresa">
                            <Form.Item name="street" noStyle rules={[{ required: true, message: "Ulica je obavezan unos." }]}>
                                <Input placeholder="Ulica" maxLength={200} style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
                            </Form.Item>
                            <Form.Item name="streetNo" noStyle rules={[{ required: true, message: "Kućni broj je obavezan unos." }]}>
                                <Input placeholder="K.br." maxLength={20} style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item name="settlement" label="Mjesto" rules={[{ required: true }]}>
                            <SettlementComboBox dataSource={settlementDs} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Typography.Title level={3}>Kontakt</Typography.Title>
                        <Form.Item name="phone" label="Telefon" rules={[{ required: true }]}>
                            <Input maxLength={50} />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
                            <Input maxLength={200} />
                        </Form.Item>

                        <Typography.Title level={3}>Postavke</Typography.Title>
                        <Form.Item name="isSender" valuePropName='checked'>
                            <Checkbox disabled={!allowOptionsEdit}>Ponudi za pošiljatelja</Checkbox>
                        </Form.Item>
                        <Form.Item name="isRecipient" valuePropName='checked'>
                            <Checkbox disabled={!allowOptionsEdit}>Ponudi za primatelja</Checkbox>
                        </Form.Item>

                    </Col>
                </Row>
                {/* This makes enter submit the form - button is not visible! */}
                <Button htmlType="submit" hidden />
            </Form>
        </Modal>

    );
}

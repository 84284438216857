import { kApi } from "Api";
import { ApiCall, UrlParam } from "net-common-ui";
import { CodTransfer } from "Models/Bus/CodTransfer";
import { Shipment } from "./Shipment";
import { Dayjs } from "dayjs";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { CodType } from "Models/Enum";

export interface ShipmentCod {
    shipmentId?: number; // pk
    shipment?: Shipment;
    customerAmount?: number;
    amountMatchedOn?: Dayjs;

    // Isplata carrier->logex (i podaci o recipient->carrier)
    carrierAmount?: number;
    carrierPaidOn?: Dayjs;
    carrierTransferCode?: string;
    carrierTransferOn?: Dayjs;

    // Isplata pošiljatelju(logex->sender)
    codTransfer?: CodTransfer;

    // import-only
    barcode?: string;
    deliveredOn?: Dayjs;
    existingShipmentCod?: ShipmentCod;
    shouldUpdate?: boolean; // client-only
}

export function getShipmentCodAllowUpdate(model: ShipmentCod): boolean {
    return (
        // its a valid shipment
        model.shipmentId &&
        // must have valid cod option
        model.shipment.useCod &&
        // must be "bez naloga"
        model.shipment.codTypeId === CodType.BezNaloga &&
        // not already transferred
        !model.existingShipmentCod?.codTransfer &&
        // ako je u pripremi (zahtjev nije poslan)
        model.shipment.status.code !== "CRE" &&
        // obrisana
        !model.shipment.isDeleted &&
        // stornirana
        !model.shipment.isCancelled);
}

export function getShipmentCodById(shipmentId: UrlParam): ApiCall<ShipmentCod> {
    return ApiCall.get(kApi, `shipment/${shipmentId}/shipment-cod`);
}

export function saveShipmentCod(model: ShipmentCod): ApiCall<ShipmentCod> {
    return ApiCall.put(kApi, `shipment/${model.shipmentId}/shipment-cod`, model);
}

export function saveShipmentCodMultiple(model: ShipmentCod[]): ApiCall<ShipmentCod> {
    return ApiCall.put(kApi, "shipment-cod;multi", model);
}

export function dsoShipmentCod(): DataSourceOptions<ShipmentCod> {
    return {
        api: kApi,
        url: "shipment-cod"
    };
}
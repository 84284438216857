import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CodeInput, useDataSourceRef } from "net-common-ui";
import { PostOfficeComboBox } from 'Components/PostOfficeComboBox';
import { dsoPostOffice, PostOffice } from 'Models/Codebook/PostOffice';

export interface SettlementFilter {
    code?: string;
    name?: string;
    postOffice?: PostOffice[];
    ispPostOffice?: PostOffice[];
}

interface FilterFormProps {
    initialFilter?: SettlementFilter;
    onChange?: (filter: SettlementFilter) => void;
    form?: FormInstance;
}

export const FilterForm = ({ onChange, initialFilter = {}, form }: FilterFormProps) => {
    const [postOfficeDs] = useDataSourceRef(dsoPostOffice(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("code", "asc"))
    });

    return (
        <Form
            form={form}
            layout="vertical"
            name="filter"
            initialValues={initialFilter}
            onFinish={onChange}>

            <Form.Item name="code" label="Šifra">
                <CodeInput />
            </Form.Item>

            <Form.Item name="name" label="Naziv">
                <Input />
            </Form.Item>

            <Form.Item name="postOffice" label="Poštanski ured">
                <PostOfficeComboBox multiple dataSource={postOfficeDs} />
            </Form.Item>

            <Form.Item name="ispPostOffice" label="Isporučni ured">
                <PostOfficeComboBox multiple dataSource={postOfficeDs} />
            </Form.Item>

            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden />
        </Form>
    )
}

import React, { useMemo } from "react";
import { Select, SelectProps } from "antd";
import { CodType, labelCodType } from 'Models/Enum';
import { OptionsType, OptionData, OptionGroupData } from "rc-select/lib/interface";

interface Props extends SelectProps<CodType> {
    limit?: CodType;
}

const LIST = [
    CodType.BezNaloga,
    CodType.PostanskaUputnica
];


function toArray(value: CodType): CodType[] {
    const MAX = 32; // any number higher than total number of cod types
    const result: CodType[] = [];
    for (let i = 1; i <= MAX; i *= 2) {
        if (value & i) {
            result.push(i);
        }
    }
    return result;
}

function fromArray(values: CodType[]): CodType {
    let result: CodType = 0;
    for (let i = 0; i < values.length; ++i) {
        result |= values[i];
    }
    return result;
}

export const CodTypeSelect: React.VFC<Props> = ({ mode, limit, value, onChange, ...rest }) => {
    const items: { label: string, value: CodType }[] = useMemo(() => {
        return LIST
            .filter(x => !limit || (limit & x) !== 0)
            .map(x => ({
                value: x,
                label: labelCodType(x)
            }));
    }, [limit]);

    const multi = mode === "multiple";
    const internalValue = multi ? toArray(value) : value;
    const internalChange = (value: (CodType | CodType[]), second: OptionsType | OptionData | OptionGroupData) => {
        const mapped = Array.isArray(value) ? fromArray(value) : value;
        onChange && onChange(mapped, second);
    }

    return (
        <Select
            mode={mode}
            options={items}
            value={internalValue}
            onChange={internalChange}
            {...rest}
        />
    );
}
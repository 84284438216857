import { Button, Col, Row, Table, Typography } from 'antd';
import { CodTransferGroup } from 'Models/Bus/CodTransferGroup';
import { CodTransfer } from 'Models/Bus/CodTransfer';
import { CurrencyText } from 'Components/Text';
import { DateDisplay } from "net-common-ui";
import { Customer } from 'Models/Bus/Customer';
import { Link } from 'react-router-dom';
import { Shipment } from 'Models/Logi/Shipment';
import { BuildButton } from "./BuildButton";
const { Text, Paragraph } = Typography;

interface Props {
    loading: boolean;
    group: CodTransferGroup;
    onBuild: (notifyCustomer: boolean) => void;
    onCancel: () => void;
}

export const Preview = ({ loading, group, onBuild, onCancel }: Props) => {
    if (group.codTransfers.length === 0) {
        return <Row justify="center">
            <Col span={24}>
                Niti jedna pošiljka nije spremna za isplatu. Pošiljke moraju zadovoljiti sljedeće kriterije:
                <ul>
                    <li>Otkupna pošiljka</li>
                    <li>Otkupnina sravnjena u razdoblju: <DateDisplay value={group.rangeFrom} /> - <DateDisplay value={group.rangeTo} /></li>
                    <li>Status: Uručena primatelju</li>
                    <li>Iznos tražene otkupnine je jednak iznosu uplaćene otkupnine (od strane kurira)</li>
                    <li>Pošiljka nije već uključena u prethodni nalog za isplatu</li>
                </ul>
                <Button onClick={onCancel}>Povratak</Button>
            </Col>
        </Row>;
    }

    return <>
        <Row gutter={[16, 16]}>
            <Col span={16}>
                <Paragraph>
                    Biti će kreirano ukupno <Text style={{ fontWeight: "bold" }}>{group.codTransferCount}</Text> naloga,
                    sa ukupnim iznosom od <CurrencyText style={{ fontWeight: "bold" }} value={group.amount} showSymbol />.
                </Paragraph>
                <Paragraph>Ova akcija je nepovratna! Provjerite iznose i podatke prike generiranja naloga.</Paragraph>
            </Col>
            <Col span={4}>
                <Button disabled={loading} block onClick={onCancel}>Odustani</Button>
            </Col>
            <Col span={4}>
                <BuildButton loading={loading} onSave={notify => onBuild(notify)} />
            </Col>
        </Row>

        <Table<CodTransfer>
            dataSource={group.codTransfers}
            pagination={false}
            rowKey={record => record.customer.id}
            expandable={{ expandedRowRender: record => <DetailRow codTransfer={record} /> }}
        >
            <Table.Column dataIndex="customer" title="Korisnik" ellipsis render={(customer: Customer) => (
                <span><Link to={"/admin/customer/" + customer.id}>{customer.code}</Link> {customer.name}</span>
            )} />
            <Table.Column dataIndex={["shipments", "length"]} title="Pošiljaka" width={70} />
            <Table.Column dataIndex="amount" title="Iznos" width={120}
                render={value => <CurrencyText style={{ fontWeight: "bold" }} value={value} showSymbol />}
            />
        </Table>
    </>;
};


const DetailRow = ({ codTransfer }: { codTransfer: CodTransfer }) => {
    return <Table<Shipment>
        size="small"
        dataSource={codTransfer.shipments}
        pagination={false}
        //showHeader={false}
        rowKey="id">
        <Table.Column title="Pošiljka" dataIndex="code" width={80} />
        <Table.Column title="Barkod" dataIndex="barcode" width={120} />
        <Table.Column title="Usluga" dataIndex={["serviceType", "name"]} ellipsis />
        <Table.Column title="Paketa" dataIndex="packageCount" ellipsis />
        <Table.Column title="Prim. platio" dataIndex={["shipmentCod", "carrierPaidOn"]} ellipsis render={value => <DateDisplay value={value} />} />
        <Table.Column title="Kurir platio" dataIndex={["shipmentCod", "carrierTransferOn"]} ellipsis render={value => <DateDisplay value={value} />} />
        <Table.Column title="Sravnjeno" dataIndex={["shipmentCod", "carrierTransferOn"]} ellipsis render={value => <DateDisplay value={value} />} />
        <Table.Column title="Iznos" dataIndex={["shipmentCod", "customerAmount"]} width={90} render={value => <CurrencyText value={value} showSymbol />} />
    </Table>;
}

import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { PriceWeightRange } from "Models/Bus/PriceList/PriceWeightRange"
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { InputMoney } from "Components/InputMoney";
import { InputWeight } from 'Components/InputWeight';

interface Props {
    value?: PriceWeightRange[];
    onChange?: (value: PriceWeightRange[]) => void;
}

export const WeightEditFormItem = React.forwardRef(({ value, onChange }: Props, ref: any) => {

    // executing fromJSON(...) to get a copy, since react goes batshit crazy if we touch anything inside props
    const [ranges, setRanges] = useState(value || []);

    const setCalcRanges = (ranges: PriceWeightRange[]): void => {
        for (let i = 0; i < ranges.length; ++i) {
            const prev = i < 1 ? null : ranges[i - 1];
            //const next = i < ranges.length - 1 ? ranges[i + 1] : null;
            ranges[i].weightFrom = prev
                ? prev.weightTo !== undefined
                    ? prev.weightTo + 1 // add 1 gram to the next range, so no overlap
                    : undefined
                : 0;
        }
        setRanges(ranges);
        onChange && onChange(ranges);
    }

    const handleAdd = () => {
        const newRange: PriceWeightRange = {};
        newRange.id = ranges.length ? Math.max(...ranges.map(r => r.id)) + 1 : 1;
        setCalcRanges([...ranges, newRange]);
    }
    const handleRemove = (id: number) => {
        const index = ranges.findIndex(r => r.id === id);
        ranges.splice(index, 1);
        setCalcRanges([...ranges]);
    }
    const handleChange = (value: PriceWeightRange) => {
        const index = ranges.findIndex(r => r.id === value.id);
        ranges[index] = value;
        setCalcRanges([...ranges]);
    }

    // pre-create first row...
    if (ranges.length < 1) handleAdd();

    return (
        <div ref={ref}>
            <Row gutter={[8, 8]}>
                <Col span={5}>Od (g)</Col>
                <Col span={5}>Do (g)</Col>
                <Col span={5}>Zona 1 (EUR)</Col>
                <Col span={5}>Zona 2 (EUR)</Col>
                <Col span={4}></Col>
            </Row>

            {ranges.map((range, iRange) => {
                return <RangeElement
                    key={range.id}
                    value={range}
                    isLast={iRange === ranges.length - 1}
                    isFirst={iRange === 0}
                    onRemove={handleRemove} onChange={handleChange} />
            })}

            <Row gutter={[8, 8]}>
                <Col span={8}>
                    <Button type="dashed" onClick={handleAdd} block icon={<PlusOutlined />}>Dodaj</Button>
                </Col>
            </Row>
        </div>
    )
});

interface RangeElementProps {
    value: PriceWeightRange;
    isFirst: boolean;
    isLast: boolean;
    onChange: (value: PriceWeightRange) => void;
    onRemove: (id: number) => void;
}

const RangeElement = ({ value, onChange, onRemove, isFirst, isLast }: RangeElementProps) => {
    const handleChange = (diff: Partial<PriceWeightRange>) => {
        onChange({ ...value, ...diff });
    }
    return <Row gutter={[8, 8]}>
        <Col span={5}>
            <InputWeight
                disabled
                style={{ width: "100%" }}
                value={value.weightFrom} />
        </Col>
        <Col span={5}>
            <InputWeight
                value={value.weightTo}
                style={{ width: "100%" }}
                onChange={(fieldValue: number) => handleChange({ weightTo: fieldValue })} />
        </Col>
        <Col span={5}>
            <InputMoney
                value={value.priceZone1}
                style={{ width: "100%" }}
                onChange={(fieldValue: number) => handleChange({ priceZone1: fieldValue })} />
        </Col>
        <Col span={5}>
            <InputMoney
                value={value.priceZone2}
                style={{ width: "100%" }}
                onChange={(fieldValue: number) => handleChange({ priceZone2: fieldValue })} />
        </Col >
        <Col span={4}>
            {!isFirst && <Button block onClick={() => onRemove(value.id)} icon={<MinusCircleOutlined />} />}
        </Col>
    </Row>;
}

import { kApi } from "Api";
import { ApiCall } from "net-common-ui";

export interface PriceWeightRange {
    id?: number;
    priceListId?: number;
    serviceTypeId?: number;
    weightFrom?: number;
    weightTo?: number;
    priceZone1?: number;
    priceZone2?: number;
}

export function savePriceWeightRange(priceListId: number, serviceTypeId: number, model: PriceWeightRange[]): ApiCall<PriceWeightRange> {
    return ApiCall.put(kApi, `price-list/${priceListId}/service-type/${serviceTypeId}/price-weight-range;multi`, model);
}

export function deletePriceWeightRange(priceListId: number, serviceTypeId: number): ApiCall<PriceWeightRange> {
    return ApiCall.delete(kApi, `price-list/${priceListId}/service-type/${serviceTypeId}/price-weight-range`);
}
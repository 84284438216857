import React from "react";
import { Collapse, PageHeader } from 'antd';
import Bux from "Components/Bux";

const { Panel } = Collapse;

interface Item {
    title: string,
    text: string | React.ReactNode;
}

const items: Item[] = [
    {
        title: "Koliko je potrebno za dostavu paketa?",
        text: <p>Paketi u domaćem transportu na svoje odredište obično stižu unutar standardnog vremena
        isporuke koje je u okviru od 3 dana (ne uključujući otoke). Logex omogućava korištenje logističkih
            usluga koje se izvršavaju putem ovlaštenih pod ugovorenih kurira koji pakete isporučuju od ponedjeljka do petka, tijekom radnog vremena.</p>
    }, {
        title: "Mogu li preko Logex-a slati pakete kao pravni subjekt bez opcije sklapanja ponude o suradnji",
        text: <p>Pravni subjekti ne mogu slati pakete preko Logex-a bez opcije sklapanja ponude o suradnji.</p>
    }, {

        title: "Mogu li preko Logexa-a slati pakete kao fizička osoba?",
        text: <p>Fizičke osobe ne mogu slati pakete putem platforme Logexa-a.</p>
    }, {
        title: "Da li Logex nudi rješenja za izradu adresnica?",
        text: <p>Logex osigurava tvrtkama platformu za kreiranje i slanje pošiljaka koji odgovaraju njihovim zahtjevima i količini paketa koje šalju.</p>
    }, {
        title: "Da li Logex-om mogu poslati paket za inozemstvo?",
        text: <p>Logex u ovom trenutku ne pruža dostavu paketa u inozemstvo.</p>
    }, {
        title: "Koja je maksimalna dimenzija i masa paketa?",
        text: <p>Logex putem ovlaštenih pod ugovorenih kurira pruža uslugu slanja paketa maksimalno do 30 kg.
        Najmanje dimenzije su 9 cm x 14 cm. Najveća dopuštena dužina jedne stranice iznosi 200 cm, a u
            zbroju dužine, širine i visine ne smije prelaziti 350 cm.</p>
    }, {
        title: "Koliki je trošak dostave Logex-om?",
        text: <p>Točna cijena slanja paketa ovisi o vašim željama i zahtjevima. Ukoliko pakete šaljete svakodnevno,
            Logex će vam dati individualnu ponudu za proizvode i usluge koje najbolje odgovaraju vašim potrebama.</p>
    }, {
        title: "Želim slati nestandardne pakete. Što moram uzeti u obzir?",
        text: <>
            <p>Neki se paketi zbog dimenzija ili ambalaže ne mogu proslijediti putem sustava pokretnih traka u automatiziranim
            sortirnim centrima naših podugovorenih kurira. Takve pošiljke kurir ručno skenira i sortira u područnim i sortirnim centrima.
            Naknada za manipulaciju takvim paketima naplaćuje se po paketu prema važećem cjeniku.</p>
            <p>Takve pošiljke između ostalih uključuju:</p>
            <ul>
                <li>Vreće, gume, zarolane predmete, bale tkanine, kanistre, plastične kante, drvene kutije</li>
                <li>Pošiljke iz kojih vire dijelovi bilo koje vrste</li>
            </ul>
            <p>Na web stranici <a href="https://www.logex.hr" target="_blank" rel="noopener noreferrer">logex.hr</a> možete pronaći savjete za pakiranje.</p>
        </>
    }, {
        title: "Koja roba se ne može prevoziti?",
        text: <>
            <p>Neprimjereno i / ili nekonvencionalno-pakirana roba je uglavnom isključena iz otpreme putem naših partnera.
            Računala (npr. monitor računala ili prijenosna računala) prevoze se samo u izvornom pakiranju.
            Isporuka također nije moguća i u slijedećim slučajevima:</p>
            <ul>
                <li>Neprimjereno pakirana roba i/ili nije unutar standarda oblika pakovanja uobičajenog u trgovini.</li>
                <li>Pošiljke koje su očito preteške ili prevelike.</li>
                <li>svezani ili spojeni paketi, pošiljke otpravljene u drvenim sanducima.</li>
                <li>pokvarljiva, zarazna ili odbojna roba</li>
                <li>ljudski ostatci</li>
                <li>životinje ili biljke</li>
                <li>pošiljke u vrećama, torbama ili labavo pakirane pošiljke.</li>
                <li>visoko-vrijedna roba, npr. gotovina, plemeniti metali, dokumenti s novčanom vrijednosti,
                    izvučene srećke i slično, pravi biseri, drago kamenje, nakit.</li>
                <li>umjetnička djela, predmeti sentimentalne vrijednosti</li>
                <li>proizvodi pod temperaturnim režimom</li>
                <li>streljivo, vatreno oružje, eksplozivi i slično</li>
            </ul>
        </>
    }, {
        title: "Je li mogu pratiti pakete u realnom vremenu?",
        text: <p>Da, i pošiljatelj i primatelj mogu pomoću Track and Trace-a bilo koji
            paket u pratiti u realnom vremenu. Jednostavno upišite broj paketa.</p>
    }, {
        title: "Koji je iznos standardnog osiguranja za pakete i da li je moguće uzeti dodatno osiguranje?",
        text: <>
            <p>Korisnik usluga iz portfelja tvrtke Logex usluge d.o.o. ostvaruje sva prava na osnovu Općih
            uvjeta za korištenje usluga pod ugovorenog ovlaštenog kurira. Opći uvjeti za korištenje usluga – link na opće uvjete pod korisnika.</p>
            <p>Odgovornost međutim ne uključuje posljedične štete kao što je na primjer izostanak zarade.
            Vrijednost robe se definira kao nabavna vrijednost, za rabljene stvari kao trenutna cijena, a za aukcijske artikle,
            aukcijska cijena, ovisno o tome koja je u datom slučaju najniža.
            U slučaju potrebe moguće je ugovoriti veći iznos pokrića.</p>
        </>
    }, {
        title: "Kada će stići moj paket?",
        text: <p>Paketi se unutar Hrvatske obično isporučuju u roku od 3 radna dana. Dostava se izvršava putem
            ovlaštenih pod ugovorenih kurira koji pakete isporučuju od ponedjeljka do petka, tijekom radnog vremena.</p>
    }, {
        title: "Kako mogu saznati gdje se točno nalazi moj paket trenutno?",
        text: <p>U svakom trenutku možete pratiti Vaš paket online.Imajte na umu da naši podugovoreni kuriri
        koriste distribucijske centre, stoga rute dostave možda Vam neće uvijek izgledati kao izravne.
            Kako biste vidjeli gdje se Vaš paket trenutno nalazi, jednostavno unesite broj paketa.</p>
    }, {
        title: "Što se događa ukoliko nisam na adresi u vrijeme dostave?",
        text: <p>Kurir će ostaviti obavijest, a paket će vratiti u depo (područno skladište) ili poštanski
        ured. Na obavijesti ćete naći podatke o adresi gdje možete podići vaš paket.
            Paket se nakon isteka perioda skladištenja, od pet radnih dana nakon pokušaja dostave, vraća pošiljatelju.</p>
    }, {
        title: "Moj je paket oštećen, što mi je činiti?",
        text: <p>Ukoliko Vaš paket ima vanjsko oštećenje, imate pravo odbiti preuzimanje ili zahtijevati otvaranje paketa,
        provjeru sadržaja te sastavljanje zapisnika o eventualnom oštećenju.</p>
    }, {
        title: "Moj paket nije stigao, što mi je činiti?",
        text: <>
            <p>Prije svega provjerite status paketa pomoću Logex-ove online aplikacije za praćenje paketa.
            Kako bi to učinili, unesite broj paketa koji Vam je dao pošiljatelj, ili ako ga niste dobili, zatražite
            pošiljatelja da vam ga pošalje. Nadalje, provjerite da li Vam je kurir eventualno ostavio obavijest u slučaju
            da niste bili doma u vrijeme prvog pokušaja dostave. Na ceduljici obavijesti je navedeno gdje se Vaš paket
            nalazi – uskladišteno u depo-u (područnom skladištu) ili u poštanskom uredu. Na obavijesti je također
            naveden broj paketa, pomoću kojeg možete pratiti paket.</p>
            <p>U slučaju da Vam funkcija praćenja paketa ne bude od pomoći, stupite u kontakt
                putem e-pošte: <a href="mailto:info@logex.hr">info@logex.hr</a>.
                Prilikom ispune zahtjeva obavezno navesti broj paketa.</p>
        </>
    }, {

        title: "Moje pitanje ovdje nije navedeno. Gdje mogu saznati više informacija?",
        text: <p>Vaše pitanje pošaljite putem e-pošte: <a href="mailto:info@logex.hr">info@logex.hr</a></p>
    }
];


export const Faq = () => (
    <Bux.Page>
        <Bux.Header>
            <PageHeader title="Česta pitanja" />
        </Bux.Header>
        <Bux.Content>
            <Collapse accordion>
                {items.map(({ title, text }, i) => (
                    <Panel header={title} key={i}>
                        <p>{text}</p>
                    </Panel>
                ))}
            </Collapse>
        </Bux.Content>
    </Bux.Page>);

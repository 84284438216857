import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";

export interface Sms {
    id?: number;
    sourceKey?: string;
    toAddress?: string;
    toAddressGdpr?: string;
    body?: string;
    createdOn?: Dayjs;
    processedOn?: Dayjs;
    attemptCount?: number;
    errorMessage?: string;
    isPending?: boolean;
    isSuccess?: boolean;
}

export function getSmsById(id: UrlParam, includeBody: boolean = false): ApiCall<Sms> {
    return ApiCall.get(kApi, `sms/${id}`, { includeBody });
}

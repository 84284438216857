import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";
import { Invoice } from "./Invoice";

export interface InvoiceBuild {
    items?: InvoiceBuildItem[];
}

export interface InvoiceBuildItem {
    record?: InvoiceBuildPlanRecord;
    status?: InvoiceBuildStatus;
    ranges?: InvoiceBuildRange[];
}

export interface InvoiceBuildRange {
    from?: Dayjs;
    to?: Dayjs;
    generatorResult?: InvoiceGeneratorResult;
}

export interface InvoiceBuildPlanRecord {
    customerId?: number;
    customerCode?: string;
    customerName?: string;
    firstShipmentEventOn?: Dayjs;
    lastShipmentEventOn?: Dayjs;
    lastInvoiceTo?: Dayjs;
    hasPendingInvoice?: boolean;
    invoiceIntervalId?: number; // enum?: InvoiceInterval
}

export interface InvoiceGeneratorResult {
    isSuccess?: boolean;
    isEmpty?: boolean;
    message?: string;
    invoice?: Invoice;
}

export enum InvoiceBuildStatus {
    Pending = 1,
    InProgress, // radi se...
    Skipped, // ne treba raditi racun (sva razdoblja vec imaju racun)
    Complete, // napravljen racun
    Error // isli smo napraviti racun za razdoblje, ali je doslo do greskse
}




export function buildInvoice(): ApiCall<InvoiceBuild> {
    return ApiCall.put(kApi, "invoice", null);
}

export function buildInvoiceByCustomer(customerId: UrlParam): ApiCall<InvoiceBuild> {
    return ApiCall.put(kApi, `customer/${customerId}/invoice`, null);
}
import React from 'react';
import { Pagination, PageHeader, Table, Button, Space } from 'antd';
import { FilterForm, CustomerFilter } from "./FilterForm"
import { Customer, dsoCustomer } from "Models/Bus/Customer"
import { applyGridState, useDataSource, useStateManager } from "net-common-ui";
import { NewButton } from './NewButton';
import { Link } from 'react-router-dom';
import { Bux } from 'Components/Bux';
import withFilterDrawer from 'Components/withFilterDrawer';
import downloadStream from 'downloadStream';
import { FileExcelOutlined } from '@ant-design/icons';
import { kApi } from 'Api';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const [view, ds] = useDataSource(dsoCustomer());
    const [state, sm] = useStateManager<GridState<CustomerFilter>>();

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("code", "contains", filter.code)
                .set("name", "contains", filter.name)
        );
    });

    const handleFilterChange = (filter: CustomerFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    const handleDownloadXls = async () => {
        // filename is igonred if content disposition is successfully sniffed
        const result = await downloadStream(kApi, {
            url: "customer;xls",
            sniffContentDisposition: true,
            fileName: "customers.xlsx",
            params: { filter: ds.builder.filter.serialize() }
        });
        result.success && result.download();
    }

    return <Bux.Page>
        <Bux.Header>
            <PageHeader
                title={"Korisnici"}
                extra={[<NewButton key="new" onClose={id => id && ds.execute({ force: true })} />]} />
        </Bux.Header>
        <Bux.SubHeader>
            <Space>
                <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
                <Button disabled={!view.total} onClick={handleDownloadXls} icon={<FileExcelOutlined />}>Izvoz</Button>
            </Space>
        </Bux.SubHeader>
        <Bux.Content>
            <Table<Customer> pagination={false} rowKey="id" dataSource={view.data}>
                <Table.Column dataIndex="code" title="Šifra" width={90} render={(value, record: Customer) => (
                    <Link to={"/admin/customer/" + record.id}>{value}</Link>
                )} />
                <Table.Column dataIndex="name" title="Naziv" ellipsis />
                <Table.Column dataIndex="contractNumber" title="Ugovor" ellipsis />
                <Table.Column dataIndex="phone" title="Telefon" ellipsis />
                <Table.Column dataIndex="email" title="Email" ellipsis />
                <Table.Column dataIndex={["settlement", "postOffice", "code"]} title="PU" width={60} />
                <Table.Column dataIndex={["settlement", "name"]} title="Sjedište" ellipsis />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

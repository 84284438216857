import { Form, FormInstance, Input } from 'antd';
import { SenderSettlementComboBox } from 'Components/SettlementComboBox';
import { AddressAutoComplete } from 'Components/AddressAutoComplete';
import { dsoSettlementByServiceType } from 'Models/Codebook/Settlement';
import { useDataSourceRef } from 'net-common-ui';
import { AddressBookEntry } from 'Models/Bus/AddressBookEntry';

interface Props {
    serviceTypeId: number;
    form: FormInstance;
    disabled?: boolean;
}

export const SenderEditor = ({ serviceTypeId, disabled, form }: Props) => {
    const [settlementDs] = useDataSourceRef(dsoSettlementByServiceType(serviceTypeId), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });
    const rules = disabled ? [] : [{ required: true }];
    const rest = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    };

    const handleAddressBookChange = (entry: AddressBookEntry) => {
        if (entry) {
            form.setFieldsValue({
                sender: {
                    personName: entry.personName,
                    street: entry.street,
                    streetNo: entry.streetNo,
                    settlement: entry.settlement,
                    phone: entry.phone,
                    email: entry.email
                }
            });
        }
        else {
            form.setFieldsValue({
                sender: {
                    personName: "",
                    street: "",
                    streetNo: "",
                    settlement: null,
                    phone: "",
                    email: ""
                }
            });
        }
    }

    const handleAddToAddressBookChange = (value: boolean) => {
        form.setFieldsValue({
            sender: {
                saveAddressBookEntry: value
            }
        });
    }

    return <>
        <Form.Item name={["sender", "saveAddressBookEntry"]} hidden>
            <Input />
        </Form.Item>
        <Form.Item name={["sender", "name"]} label="Naziv" rules={rules} {...rest}>
            {/* <Input disabled={disabled} maxLength={200} /> */}
            <AddressAutoComplete
                mode="sender"
                disabled={disabled}
                maxLength={200}
                allowAddToAddressBook={!disabled && !form.getFieldValue("id")} // allow only for new shipment
                onEntryChange={handleAddressBookChange}
                onAddToAddressBookChange={handleAddToAddressBookChange}
            />
        </Form.Item>
        <Form.Item name={["sender", "personName"]} label="Ime i prezime" {...rest}>
            <Input disabled={disabled} maxLength={200} />
        </Form.Item>
        <Form.Item label="Adresa" {...rest} required={!disabled}>
            <Form.Item name={["sender", "street"]} noStyle rules={[{ required: true, message: "Ulica je obavezan unos." }]}>
                <Input disabled={disabled} placeholder="Ulica" maxLength={200} style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
            </Form.Item>
            <Form.Item name={["sender", "streetNo"]} noStyle rules={[{ required: true, message: "Kućni broj je obavezan unos." }]}>
                <Input disabled={disabled} placeholder="K.br." maxLength={20} style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
            </Form.Item>
        </Form.Item>
        <Form.Item name={["sender", "settlement"]} label="Mjesto" rules={rules} {...rest}>
            <SenderSettlementComboBox disabled={disabled} dataSource={settlementDs} />
        </Form.Item>
        <Form.Item name={["sender", "phone"]} label="Telefon" rules={rules} {...rest}>
            <Input disabled={disabled} maxLength={50} />
        </Form.Item>
        <Form.Item name={["sender", "email"]} label="Email" rules={disabled ? [] : [{ type: "email" }]} {...rest}>
            <Input disabled={disabled} maxLength={200} />
        </Form.Item>
    </>;
}


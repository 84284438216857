import { Button, Form, Input } from 'antd';
import { BoolRadioButtonSet } from "net-common-ui";

export interface AppAccountFilter {
    identifier?: string;
    email?: string;
    fullName?: string;
    isAdmin?: boolean;
}

interface FilterFormProps {
    initialFilter?: AppAccountFilter;
    onChange?: (filter: AppAccountFilter) => void;
}

export const FilterForm = ({ onChange, initialFilter = {} }: FilterFormProps) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} layout="vertical" name="filter" initialValues={initialFilter} onFinish={onChange}>

            <Form.Item name="identifier" label="Identifikator">
                <Input />
            </Form.Item>

            <Form.Item name="email" label="Email">
                <Input />
            </Form.Item>

            <Form.Item name="fullName" label="Ime i prezime">
                <Input />
            </Form.Item>

            <Form.Item name="isAdmin" label="Administrator">
                <BoolRadioButtonSet nullable />
            </Form.Item>

            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden />
        </Form>
    )
}

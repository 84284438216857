import { Settlement } from "Models/Codebook/Settlement";

interface Address {
    name?: string;
    personName?: string;
    street?: string;
    streetNo?: string;
    settlement?: Settlement;
}

export function getDisplayLine1({ name, personName }: Address): string {
    return name && personName
        ? name + ", " + personName
        : (name || personName || "");
}
export function getDisplayLine2({ street, streetNo, settlement }: Address): string {
    const segments: string[] = [];
    if (street) {
        segments.push(`${street} ${streetNo}`.trim());
    }
    if (settlement) {
        segments.push(`${settlement.postOffice.code} ${settlement.name}`);
    }
    return segments.join(", ");
}

export const AddressCell = ({ address }: { address: Address }) => {
    return <>
        {getDisplayLine1(address)}
        <br />
        {getDisplayLine2(address)}
    </>;
}

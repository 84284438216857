import { useState } from 'react';
import { Button, PageHeader, Typography, Tooltip, Tag, Table, message, Popover, List, Empty } from 'antd';
import { InvoiceBuild, InvoiceBuildItem, InvoiceBuildStatus, InvoiceBuildRange, buildInvoice } from "Models/Bus/InvoiceBuild"
import { Bux } from 'Components/Bux';
import {
    WarningTwoTone,
    StopTwoTone,
    ExclamationCircleTwoTone,
    CheckCircleTwoTone,
    CloseCircleTwoTone
} from '@ant-design/icons';
import { invoiceIntervalLabel } from 'Models/Enum';
import { DateDisplay } from "net-common-ui";
const { Paragraph, Text } = Typography;

export const Build = () => {
    const [building, setBuilding] = useState(false);
    const [result, setResult] = useState<InvoiceBuild>(null);

    const handleBuild = async () => {
        setBuilding(true);
        setResult(null);
        const result = await buildInvoice().execute();
        setBuilding(false);
        if (result.success) {
            const builtRanges = result.item.items.map(x => x.ranges.filter(r => r.generatorResult).length);
            const builtRangesSum = builtRanges.reduce((a, b) => a + b, 0);
            message.success(`Obrada uspješno završena. Kreirano ${builtRangesSum} faktura.`);
            setResult(result.item);
        }
    }


    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title="Obrada faktura" />
            </Bux.Header>
            <Bux.Content>
                {!building && !result && <Empty description="Prije izrade grupe naloga i pojedinih naloga za isplatu, poželjno je pogledati što će biti učinjeno.">
                    <Button type="primary" loading={building} onClick={handleBuild}>Započni obradu</Button>
                </Empty>}
                {building && <Paragraph>Izrada faktura u tijeku...</Paragraph>}

                {result && <Table<InvoiceBuildItem> dataSource={result.items} pagination={false} rowKey={row => row.record.customerId}>
                    <Table.Column dataIndex={["record", "customerCode"]} title="Šifra" />
                    <Table.Column dataIndex={["record", "customerName"]} title="Korisnik" />
                    <Table.Column dataIndex={["record", "invoiceIntervalId"]} title="Interval" render={value => invoiceIntervalLabel(value)} />
                    <Table.Column dataIndex={["record", "firstShipmentEventOn"]} title="Prva pošiljka" render={value => <DateDisplay value={value} />} />
                    <Table.Column dataIndex={["record", "lastShipmentEventOn"]} title="Zadnja pošiljka" render={value => <DateDisplay value={value} />} />
                    <Table.Column dataIndex={["record", "lastInvoiceTo"]} title="Zadnja faktura" render={(value, item: InvoiceBuildItem) => (<Text>
                        {item.record.hasPendingInvoice
                            ? <Tooltip title="Neverificirano! Nove fakture se ne mogu raditi.">
                                <WarningTwoTone twoToneColor="red" />
                            </Tooltip>
                            : null /*<CheckCircleTwoTone twoToneColor="green" />*/}
                        &nbsp;
                        {<DateDisplay value={value} />}
                    </Text>)} />
                    <Table.Column dataIndex="status" title="Status" render={(value, item: InvoiceBuildItem) =>
                        <StatusCell status={value} />
                    } />
                    <Table.Column key="ranges" title="Raspon" render={(_, item: InvoiceBuildItem) => (item.ranges.length
                        ? <List<InvoiceBuildRange>
                            size="small"
                            rowKey="from"
                            dataSource={item.ranges}
                            renderItem={range => <RangeListItem range={range} />}
                        />
                        : "-"
                    )} />
                </Table>}

            </Bux.Content>
        </Bux.Page>
    );
};

const StatusCell = ({ status }: { status: InvoiceBuildStatus }) => {
    switch (status) {
        default:
        case InvoiceBuildStatus.Skipped:
            return <Tag color="#bababa">Preskočen</Tag>;
        case InvoiceBuildStatus.Complete:
            return <Tag color="green">Ok</Tag>;
        case InvoiceBuildStatus.Error:
            return <Tag color="red">Greška</Tag>;
        // ovi se trenutno ne mogu dogodit jer vracamo samo last status - kad je obrada gotova, pa nema pending/progress
        // case InvoiceBuildStatus.InProgress:
        //     return <Tag color="#bababa">U obradi</Tag>;
        // case InvoiceBuildStatus.Pending:
        //     return <Tag color="#bababa">Čekanje</Tag>;
    }
}

const RangeListItem = ({ range }: { range: InvoiceBuildRange }) => {
    const { generatorResult, from, to } = range;
    let icon: JSX.Element;
    let message: string;

    if (generatorResult) {
        if (generatorResult.isEmpty) {
            icon = <ExclamationCircleTwoTone twoToneColor="#bababa" />;
            message = "Faktura prazna";
        }
        else if (!generatorResult.isSuccess) {
            icon = <CloseCircleTwoTone twoToneColor="red" />;
            message = generatorResult.message;
        }
        else { // success
            icon = <CheckCircleTwoTone twoToneColor="green" />;
            message = "Faktura uspješno generirana.";
        }
    }
    else {
        icon = <StopTwoTone twoToneColor="orange" />;
        message = "Prije generiranja fakture potrebno je verificirati prethodne fakture. U jednom trenutku je moguće imati 1 otvorenu fakturu po korisniku.";
    }

    return <List.Item>
        <Popover content={<Paragraph style={{ maxWidth: 300 }}>{message}</Paragraph>} title="Status raspona" placement="left">
            {icon}
        </Popover>
        &nbsp;
        {<DateDisplay value={from} />} - {<DateDisplay value={to} />}
    </List.Item>;
}

import { useState, useRef, useEffect, useMemo } from 'react';
import { PageHeader, Table, Row, Col, Space, Input, Typography, Button, message, Alert, Tooltip } from 'antd';
import Bux from "Components/Bux";
import { dsoShipment, Shipment } from 'Models/Logi/Shipment';
import { useDataSource } from "net-common-ui";
import { useHistory, useLocation } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { CloseCircleFilled } from "@ant-design/icons";
import { useCustomer } from 'Views/MeContext';
import { ShipmentRecipient } from 'Models/Logi/ShipmentRecipient';
import { ShipmentSender } from 'Models/Logi/ShipmentSender';
import { AddressCell } from 'Components/Address';
import { saveShipmentGroup, ShipmentGroup } from 'Models/Logi/ShipmentGroup';
import { DatePickerEx } from "Components/DatePickerEx";
const { Text } = Typography;

type IdValue = string | number;

function parseSelectedIds(queryParam: string): number[] {
    const result: number[] = [];
    if (queryParam) {
        const ids = queryParam.split(",");
        for (let i = 0; i < ids.length; ++i) {
            const id = parseInt(ids[i]);
            if (Number.isInteger(id) && !isNaN(id)) {
                result.push(id);
            }
        }
    }
    return result;
}

export const New = () => {
    const history = useHistory();
    const location = useLocation();
    const customer = useCustomer();
    const query = queryString.parse(location.search);
    const [view] = useDataSource(dsoShipment(), {
        execute: true,
        configure: builder => builder
            .setSize(null)
            .setQuery(q => q.sort.set("id", "desc"))
            .setQuery(b => b.filter.set("shipmentGroup.id", "isnull").set({ isDeleted: false, isCancelled: false }))
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<IdValue[]>(parseSelectedIds(query["ids"] as string));
    const [note, setNote] = useState("");
    const [pickupDate, setPickupDate] = useState(dayjs.utc());
    const [saving, setSaving] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const autoSelected = useRef(false);
    const customerId = customer.id;

    // Preselect all if nothing was received in 'ids' query param
    useEffect(() => {
        if (view.queried && !view.loading && !autoSelected.current && !selectedRowKeys.length) {
            autoSelected.current = true;
            setSelectedRowKeys(view.data.filter(item => !item.sendErrorMessage).map(item => item.id));
            setHasErrors(view.data.findIndex(s => !!s.sendErrorMessage) >= 0)
        }
    }, [selectedRowKeys, view]);

    const handleSave = async () => {
        setSaving(true);
        const model: ShipmentGroup = { customerId, pickupDate, note };
        const ids = selectedRowKeys.map(x => typeof x === "string" ? parseInt(x) : x);
        const result = await saveShipmentGroup(model, ids).execute();
        setSaving(false);
        if (result.success) {
            message.success(`Zahtjev za preuzimanje ${ids.length} pošiljaka uspješno predan.`)
            history.push("."); // . = up 1 level, .. = up 2 levels
        }
    }

    const table = useMemo(() => {
        return <Table<Shipment>
            dataSource={view.data}
            rowSelection={{
                type: "checkbox",
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                getCheckboxProps: shipment => ({
                    disabled: saving || !!shipment.sendErrorMessage
                })
            }}
            pagination={false}
            rowKey="id">
            <Table.Column dataIndex="code" title="Broj" render={(code, record: Shipment) => <>
                <Link to={"/pub/shipment/" + record.id}>{code}</Link>
                {record.sendErrorMessage && <Tooltip title={record.sendErrorMessage}>
                    <CloseCircleFilled style={{ color: "#d62d2d", marginLeft: 4 }} />
                </Tooltip>}
                <br />
                {record.isCancelled
                    ? <em className="text-warn">Storno</em>
                    : record.isDeleted
                        ? <em className="text-danger">Obrisano</em>
                        : record.shipmentGroup
                            ? <em>Poslano</em>
                            : null}
            </>} />
            <Table.Column title="Pošiljatelj" dataIndex="sender" render={(pt: ShipmentSender) => <AddressCell address={pt} />} />
            <Table.Column title="Primatelj" dataIndex="recipient" render={(pt: ShipmentRecipient) => <AddressCell address={pt} />} />
            <Table.Column title="Status" dataIndex="status" render={status => status.name} />
        </Table>

    }, [view, saving, selectedRowKeys, setSelectedRowKeys]);

    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title="Novi zahtjev"
                //extra={[<NewButton key="new" />]} 
                />
            </Bux.Header>
            <Bux.Content>
                <Row gutter={[8, 8]} style={{ marginBottom: 20 }}>
                    <Col span={24}>
                        <Alert
                            message="Pošiljke nakon što se pošalju više nije moguće uređivati! Prije predaje zahtjeva, molimo provjerite da su podaci svih uključenih pošiljaka ispravni."
                            type="info"
                            showIcon
                            closable />
                    </Col>
                    {hasErrors && <Col span={24}>
                        <Alert
                            message="Određene pošiljke imaju grešku i nije ih moguće poslati. Ispravite greške uređivanjem pošiljke!"
                            type="error"
                            showIcon />
                    </Col>}

                    <Col span={4}>Ukupno pošiljaka</Col>
                    <Col span={20}>
                        <Space>
                            <Text keyboard className={selectedRowKeys.length === view.data.length ? "text-success" : null}>{selectedRowKeys.length}</Text>
                            od
                            <Text keyboard>{view.data.length}</Text>
                            <Text type="secondary">(Moguće je odabrati koje pošiljke će biti uključene kroz listu pri dnu.)</Text>
                        </Space>
                    </Col>

                    <Col span={4}>Datum prikupa</Col>
                    <Col span={20}>
                        <DatePickerEx
                            value={pickupDate}
                            disabled={saving}
                            disabledDate={(currentDate: Dayjs) =>
                                currentDate < dayjs.utc().subtract(1, "day") ||
                                currentDate > dayjs.utc().add(7, "day")}
                            format="L"
                            onChange={value => setPickupDate(value)} />
                    </Col>

                    <Col span={4}>Napomena kuriru</Col>
                    <Col span={20}>
                        <Input.TextArea
                            maxLength={1000}
                            value={note}
                            disabled={saving}
                            onChange={e => setNote(e.target.value)} />
                    </Col>

                    <Col span={20} offset={4}>
                        <Button
                            type="primary"
                            disabled={!selectedRowKeys.length || hasErrors}
                            loading={saving}
                            onClick={handleSave}
                        >
                            Pošalji zahtjev
                        </Button>
                    </Col>
                </Row>

                {table}

            </Bux.Content>
        </Bux.Page>
    );
};

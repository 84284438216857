import { UrlParam, ApiCall } from "net-common-ui";
import { ShipmentSender } from "Models/Logi/ShipmentSender";
import { ShipmentRecipient } from "Models/Logi/ShipmentRecipient";
import { Package } from "Models/Logi/Package";
import { kApi } from "Api";
import { ServiceType } from "Models/Codebook/ServiceType";
import { Status } from "Models/Logi/Status";
import { Customer } from "Models/Bus/Customer";
import { ShipmentGroup } from "./ShipmentGroup";
import { ShipmentInvoice } from "./ShipmentInvoice/ShipmentInvoice";
import { ShipmentReturnInvoice } from "./ShipmentInvoice/ShipmentReturnInvoice";
import { ShipmentCod } from "./ShipmentCod";
import { Dayjs } from "dayjs";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { CodType } from "Models/Enum";

export interface Shipment {
    id?: number;
    code?: string;
    shipmentId?: number;
    customer?: Customer;
    shipmentGroup?: ShipmentGroup;

    // calc
    barcode?: string;
    status?: Status;
    statusOn?: Dayjs;
    statusEqual?: boolean;
    createdOn?: Dayjs;
    pickedOn?: Dayjs;
    returnStartedOn?: Dayjs;
    returnDeliveredOn?: Dayjs;
    deliveredOn?: Dayjs;
    weightVerifiedSetOn?: Dayjs;
    pickedOnSetOn?: Dayjs;
    returnStartedOnSetOn?: Dayjs;
    returnDeliveredOnSetOn?: Dayjs;
    deliveredOnSetOn?: Dayjs;
    expectedDeliveryDate?: Dayjs;

    packageCount?: number;
    isFinished?: boolean;

    serviceType?: ServiceType;
    sender?: ShipmentSender;
    recipient?: ShipmentRecipient;

    value?: number;
    deliveryDeadlineId?: 1 | 2 | 3;
    deliveryTypeId?: 1 | 2;

    useCod?: boolean;
    codTypeId?: CodType;
    codAmount?: number;
    codRefNumber?: string;

    useDeliveryBy9?: boolean;
    useDeliveryBy11?: boolean;
    useReturnSignedDoc?: boolean;
    returnSignedDocNumber?: string;
    usePersonalDelivery?: boolean;
    useSaturdayDelivery?: boolean;
    usePovratnica?: boolean;
    useSaturdayPickup?: boolean;
    useNonstandardFormat?: boolean;

    // ovo je u bazi
    useNotifySenderSms?: boolean;
    useNotifySenderEmail?: boolean;
    useNotifyRecipientSms?: boolean;
    useNotifyRecipientEmail?: boolean;
    // front-only, racuna se iz ova 4 iznad
    useNotifySms?: boolean; // umjetni prop, backend ga ne poznaje
    useNotifyEmail?: boolean; // umjetni prop, backend ga ne poznaje

    note?: string;
    contentNote?: string;
    refA?: string;
    refB?: string;
    refC?: string;

    isDeleted?: boolean;
    isCancelled?: boolean;
    isGratis?: boolean;

    sendErrorCode?: string;
    sendErrorMessage?: string;

    packages?: Package[];
    shipmentInvoice?: ShipmentInvoice;
    shipmentReturnInvoice?: ShipmentReturnInvoice;
    shipmentCod?: ShipmentCod;
}

export interface EditShipment extends Shipment {
    isSenderLocked: boolean;
}

export function getShipmentById(id: UrlParam): ApiCall<Shipment> {
    return ApiCall.get(kApi, `shipment/${id}`);
}

export function getShipmentInfoById(id: UrlParam): ApiCall<Shipment> {
    return ApiCall.get(kApi, `shipment/${id};info`);
}

export function saveShipment(model: Shipment, admin: boolean = false): ApiCall<Shipment> {
    return ApiCall.put(kApi, "shipment", model, { admin });
}

export function cancelShipment(id: UrlParam, isCancelled: boolean): ApiCall<Shipment> {
    return ApiCall.patch(kApi, `shipment/${id}/isCancelled`, isCancelled);
}

export function saveShipmentGratis(id: UrlParam, isGratis: boolean): ApiCall<Shipment> {
    return ApiCall.patch(kApi, `shipment/${id}/isGratis`, isGratis);
}

export function deleteShipment(id: UrlParam, isVirtual: boolean): ApiCall<Shipment> {
    return ApiCall.delete(kApi, `shipment/${id}`, { isVirtual });
}

export function dsoShipment(): DataSourceOptions<Shipment> {
    return {
        api: kApi,
        url: "shipment",
    };
}

export function dsoShipmentByCodTransfer(codTransferId: UrlParam): DataSourceOptions<Shipment> {
    return {
        api: kApi,
        url: `cod-transfer/${codTransferId}/shipment`
    };
}

import { Col, Form, Row, Typography } from 'antd';
import { PriceDiscountRange } from 'Models/Bus/PriceList/PriceDiscountRange';
import { FormInstance } from 'antd/lib/form';
import { DiscountEditFormItem } from './DiscountEditFormItem';

export interface ModelWrapper {
    ranges: PriceDiscountRange[];
}

interface Props {
    form: FormInstance;
    value: ModelWrapper;
}

export const DiscountEditForm = ({ form, value }: Props) => {
    return (
        <Row gutter={[8, 40]} justify="center">
            <Col sm={24} md={16}>
                <Form form={form} layout="vertical" name="form" initialValues={value}>
                    <Typography.Title level={3}>Rabatni razredi</Typography.Title>
                    <Form.Item name="ranges">
                        <DiscountEditFormItem />
                    </Form.Item>

                </Form>
            </Col>
        </Row>
    )
}

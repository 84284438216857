import { Alert, Table } from 'antd';
import { PriceInsuranceRange } from "Models/Bus/PriceList/PriceInsuranceRange";
import { CurrencyText, PercentText } from "Components/Text";
import { PriceList } from 'Models/Bus/PriceList/PriceList';


interface Props {
    priceList: PriceList;
    basePriceList?: PriceList;
}

export const InsuranceView = ({ priceList, basePriceList }: Props) => {
    const value = priceList.priceInsuranceRanges;
    const base = basePriceList?.priceInsuranceRanges;
    const actual = value.length || !base ? value : base;

    return actual.length
        ? <div>
            {!value.length && base && <Alert
                message="Standardni cjenik"
                description={<p>Koriste se parametri standardnog cjenika.</p>}
                type="info"
                showIcon
                style={{ marginBottom: 6 }}
            />}
            <Table<PriceInsuranceRange> rowKey="id" dataSource={actual}>
                <Table.Column dataIndex="valueFrom" title="Vrijednost od" render={v => <CurrencyText value={v} showSymbol />} />
                <Table.Column dataIndex="valueTo" title="Vrijednost do" render={v => <CurrencyText value={v} showSymbol />} />
                <Table.Column dataIndex="price" title="Cijena" render={v => <CurrencyText value={v} showSymbol />} />
                <Table.Column dataIndex="priceCoef" title="Koeficijent" render={v => <PercentText value={v} showSymbol />} />
            </Table>
        </div>
        : <Alert
            message="Upozorenje"
            description={<>
                <p>Cjenik nema definirane razrede naplate prema vrijednosti pošiljke.</p>
                <p>Ako se korisniku omogući unos pošiljaka, neće se obračunavati veća cijena ovisno o upisanoj vrijednosti pošiljke.</p>
            </>}
            type="warning"
            showIcon
        />;

}

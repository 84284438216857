import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { Customer } from "./Customer";
import { CodTransferGroup } from "./CodTransferGroup";
import { Shipment } from "Models/Logi/Shipment";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface CodTransfer {
    id?: number;
    code?: string;
    customer?: Customer;
    iban?: string;
    clearanceNumber?: string;
    amount?: number;
    createMailId?: number;
    codTransferGroup?: CodTransferGroup; // parent
    shipments?: Shipment[]; // children
    barcodeImage?: string; // base 64, readonly
}

export function getCodTransferBarcodeImageSrc(model: CodTransfer): string {
    return model.barcodeImage ? "data:image/png;base64," + model.barcodeImage : null;
}

export function getCodTransferById(id: UrlParam): ApiCall<CodTransfer> {
    return ApiCall.get(kApi, `cod-transfer/${id}`);
}

export function getCodTransferInfoById(id: UrlParam, includeBarcode: boolean = false): ApiCall<CodTransfer> {
    return ApiCall.get(kApi, `cod-transfer/${id};info`, { includeBarcode });
}

export function dsoCodTransfer(): DataSourceOptions<CodTransfer> {
    return {
        api: kApi,
        url: `cod-transfer`
    };
}

export function dsoCodTransferByCustomer(customerId: UrlParam): DataSourceOptions<CodTransfer> {
    return {
        api: kApi,
        url: `customer/${customerId}/cod-transfer`
    };
}

export function dsoCodTransferByCodTransferGroup(codTransferGroupId: UrlParam): DataSourceOptions<CodTransfer> {
    return {
        api: kApi,
        url: `cod-transfer-group/${codTransferGroupId}/cod-transfer;simple`
    };
}
import { distinct } from "net-common-ui";
import { PriceAdditionalService } from "./PriceAdditionalService";
import { PriceInsuranceRange } from "./PriceInsuranceRange";
import { PriceWeightRange } from "./PriceWeightRange";
import { PriceWeightExtra } from "./PriceWeightExtra";
import { PriceDiscountRange } from "./PriceDiscountRange";

export interface PriceList {
    id?: number;
    //note?: string;

    priceAdditionalService?: PriceAdditionalService;
    priceInsuranceRanges?: PriceInsuranceRange[];
    priceDiscountRanges?: PriceDiscountRange[];
    priceWeightRanges?: PriceWeightRange[];
    priceWeightExtras?: PriceWeightExtra[];

    // export function getInfoById(id: UrlParam): ApiCall<PriceList> {
    //     return ApiCall.get(kApi, PriceList, `price-list/${id};info`);
    // }
}

export function combinePriceList(value: PriceList, base: PriceList): PriceList {
    if (!value) return base;
    if (!base) return value;
    // find all used serviceTypeIds, merge weight for each separately
    const serviceTypeIds = distinct([
        ...value.priceWeightRanges.map(r => r.serviceTypeId),
        ...base.priceWeightRanges.map(r => r.serviceTypeId)
    ]);

    const weightRanges = [];
    const weightExtras = [];
    for (let i = 0; i < serviceTypeIds.length; i++) {
        const sid = serviceTypeIds[i];
        const useBase = value.priceWeightRanges.findIndex(r => r.serviceTypeId === sid) < 0;
        const plSource = useBase ? base : value;

        weightRanges.push(...plSource.priceWeightRanges.filter(r => r.serviceTypeId === sid));
        const extra = plSource.priceWeightExtras.find(r => r.serviceTypeId === sid);
        extra && weightExtras.push(extra);
    }

    const combined = {
        id: value.id,
        priceAdditionalService: value.priceAdditionalService || base.priceAdditionalService || null,
        priceInsuranceRanges: value.priceInsuranceRanges.length ? value.priceInsuranceRanges : base.priceInsuranceRanges,
        priceDiscountRanges: value.priceDiscountRanges.length ? value.priceDiscountRanges : base.priceDiscountRanges,
        priceWeightRanges: weightRanges,
        priceWeightExtras: weightExtras
    };
    return combined;
}
import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { PostOffice } from "./PostOffice";

export interface Settlement {
    id?: number;
    code?: string;
    name?: string;
    postOffice?: PostOffice;
    ispPostOffice?: PostOffice;
    isIsland?: boolean;
    isActive?: boolean;

    // used only by few endpoints
    isPickupAllowed?: boolean;
    isDeliveryAllowed?: boolean;
}

export function getSettlementById(id: UrlParam): ApiCall<Settlement> {
    return ApiCall.get(kApi, `settlement/${id}`);
}
export function dsoSettlement(): DataSourceOptions<Settlement> {
    return {
        api: kApi,
        url: "settlement"
    };
}
export function dsoSettlementByServiceType(serviceTypeId: UrlParam): DataSourceOptions<Settlement> {
    return {
        api: kApi,
        url: `service-type/${serviceTypeId}/settlement`
    };
}
import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { Status } from "Models/Logi/Status";
import { NotifChannel, NotifTarget } from "Models/Enum";

export interface CustomerNotifConfig {
    id?: number;
    key?: string; // "{Status?.Code}?:{NotifChannelId}?:{NotifTargetId}"

    customerId?: number;
    status?: Status;
    notifTargetId?: NotifTarget;
    notifChannelId?: NotifChannel;
    isEnabled?: boolean;
    message?: string;
    defaultMessage?: string;
}

export function getCustomerNotifConfigById(id: UrlParam): ApiCall<CustomerNotifConfig> {
    return ApiCall.get(kApi, `customer/${id}`);
}

export function getCustomerNotifConfigByCustomer(customerId: UrlParam): ApiCall<CustomerNotifConfig> {
    return ApiCall.get(kApi, `customer/${customerId}/customer-notif-config`);
}


export function saveCustomerNotifConfigMulti(customerId: UrlParam, model: CustomerNotifConfig[]): ApiCall<CustomerNotifConfig> {
    return ApiCall.put(kApi, `customer/${customerId}/customer-notif-config;multi`, model);
}

export function saveCustomerNotifConfig(model: CustomerNotifConfig): ApiCall<CustomerNotifConfig> {
    return ApiCall.put(kApi, "customer-notif-config", model);
}

export function saveCustomerNotifConfigEnabled(id: UrlParam, isEnabled: boolean): ApiCall<CustomerNotifConfig> {
    return ApiCall.patch(kApi, `customer-notif-config/${id}/isEnabled`, isEnabled);
}

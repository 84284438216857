import { useState } from 'react';
import { message, PageHeader } from 'antd';
import Bux from "Components/Bux";
import { CodTransferGroup, buildCodTransferGroup, buildCodTransferGroupPreview } from 'Models/Bus/CodTransferGroup';
import { Preview } from "./Preview";
import { Intro } from "./Intro";
import { Summary } from "./Summary";
import { Dayjs } from 'dayjs';

export const Build = () => {
    const [preview, setPreview] = useState<CodTransferGroup>(null);
    const [build, setBuild] = useState<CodTransferGroup>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleBuild = async (notifyCustomer: boolean) => {
        setLoading(true);
        const result = await buildCodTransferGroup({
            rangeFrom: preview.rangeFrom,
            rangeTo: preview.rangeTo,
            notifyCustomer
        }).execute();
        setLoading(false);
        if (result.success) {
            message.success("Grupa uspješno napravljena.")
            setBuild(result.item);
            setPreview(null);
        }
    }

    const handlePreview = async (rangeTo: Dayjs) => {
        setLoading(true);
        const result = await buildCodTransferGroupPreview({ rangeTo }).execute();
        setLoading(false);
        if (result.success) {
            message.success("Pregled uspješno učitan.")
            setPreview(result.item);
        }
    }

    const handlePreviewCancel = () => {
        setPreview(null);
    }

    return <Bux.Page>
        <Bux.Header>
            <PageHeader
                title={"Izrada isplatnih naloga"}
            //extra={<Button key="build" type="primary" onClick={handleBuild} block>Izradi</Button>}
            />
        </Bux.Header>
        <Bux.Content>
            {!preview && !build && <Intro onPreview={handlePreview} loading={loading} />}
            {preview && !build && <Preview group={preview} onBuild={handleBuild} onCancel={handlePreviewCancel} loading={loading} />}
            {build && <Summary group={build} />}
        </Bux.Content>
    </Bux.Page>;
};

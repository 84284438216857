import { Alert, Table } from 'antd';
import { PriceDiscountRange } from "Models/Bus/PriceList/PriceDiscountRange";
import { NumberText, PercentText } from "Components/Text";
import { PriceList } from 'Models/Bus/PriceList/PriceList';


interface Props {
    priceList: PriceList;
    basePriceList?: PriceList;
}

export const DiscountView = ({ priceList, basePriceList }: Props) => {
    const value = priceList.priceDiscountRanges;
    const base = basePriceList?.priceDiscountRanges;
    const actual = value.length || !base ? value : base;

    return actual.length
        ? <div>
            {!value.length && base && <Alert
                message="Standardni cjenik"
                description={<p>Koriste se parametri standardnog cjenika.</p>}
                type="info"
                showIcon
                style={{ marginBottom: 6 }}
            />}
            <Table<PriceDiscountRange> rowKey="id" dataSource={actual}>
                <Table.Column dataIndex="qtyFrom" title="Od (kom)" render={v => <NumberText value={v} />} />
                <Table.Column dataIndex="qtyTo" title="Do (kom)" render={v => <NumberText value={v} />} />
                <Table.Column dataIndex="priceCoef" title="Popust (%)" render={v => <PercentText value={v} showSymbol />} />
            </Table>
        </div>
        : <Alert
            message="Upozorenje"
            description={<>
                <p>Cjenik nema definiran rabat.</p>
                <p>Prilikom izrade fakture, konačna cijena se neće umanjivati.</p>
            </>}
            type="warning"
            showIcon
        />;

}

import { useState } from 'react';
import { getShipmentInfoById, saveShipment, Shipment } from 'Models/Logi/Shipment';
import { PackageForm } from './PackageForm';
import { Bux } from 'Components/Bux';
import { Button, Col, message, Row, Skeleton } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const PackageTab = ({ shipment, onChange }: { shipment: Shipment, onChange: (shipment: Shipment) => void }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Admin can edit if it has a group (client can no longer edit)
    // It is editable as long as invoice is not creatged
    const editable = shipment && shipment.shipmentGroup && !shipment.isDeleted && !shipment.isCancelled && !shipment.shipmentInvoice;

    const handleEdit = () => {
        setIsEditing(true);
    }

    const handleCancel = () => {
        setIsEditing(false);
    }

    const handleSave = (shipment: Shipment) => {
        setIsSaving(true);
        saveShipment(shipment, true).execute().then(result => {
            setIsSaving(false);
            if (result.success) {
                message.success("Pošiljka spremljena.");
                // reload jer su mozda computed polja izmjenjena
                getShipmentInfoById(shipment.id).execute().then(result => {
                    onChange(result.item);
                    setIsEditing(false);
                });
            }
        });
    }

    return <>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button key="edit" disabled={!editable || isEditing} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {!shipment
                ? <Skeleton active />
                : <PackageForm
                    disabled={!isEditing}
                    saving={isSaving}
                    shipment={shipment}
                    onSave={handleSave}
                    onCancel={handleCancel} />
            }
        </Bux.Content>
    </>;
}

import { kApi } from "Api";
import { ApiCall } from "net-common-ui";

export interface PriceDiscountRange {
    id?: number;
    priceListId?: number;
    qtyFrom?: number;
    qtyTo?: number;
    priceCoef?: number;
}

export function savePriceDiscountRange(priceListId: number, model: PriceDiscountRange[]): ApiCall<PriceDiscountRange> {
    return ApiCall.put(kApi, `price-list/${priceListId}/price-discount-range;multi`, model);
}

export function deletePriceDiscountRange(priceListId: number): ApiCall<PriceDiscountRange> {
    return ApiCall.delete(kApi, `price-list/${priceListId}/price-discount-range`);
}
import { useMemo } from "react";
import { DateDisplay, useActiveScreenSize } from "net-common-ui";
import { DashDailyShipmentStats } from "Models/Dash/DashDailyShipmentStats"
import { Table } from "antd";
import { NumberText, PercentText } from "Components/Text";

const ValueWidth = 56;

interface DeliveryQualityTableProps {
    value: DashDailyShipmentStats[],
    //orientation?: "horizontal" | "vertical";
}

export const DeliveryQualityTable = ({ value }: DeliveryQualityTableProps) => {


    const source = useMemo(() => value.reverse(), [value]);
    const [, breakpoints] = useActiveScreenSize();

    // grupa 2 je visible na SM i LG+ 
    const showGroupTwo = breakpoints.includes('lg') || (breakpoints.includes('sm') && !breakpoints.includes('md'));

    return (<Table<DashDailyShipmentStats>
        dataSource={source}
        rowKey="date"
        pagination={false}
        scroll={{
            y: 400
        }}
    >
        <Table.Column dataIndex="date" title="Datum" ellipsis render={date => (<DateDisplay value={date} />)} />
        <Table.ColumnGroup title="Prva dostava">
            <Table.Column width={ValueWidth} dataIndex="firstDeliveryQualityCoef" title="Kval" render={value => <PercentText value={value} showSymbol precision={0} />} />
            <Table.Column width={ValueWidth} dataIndex="averageFirstDeliveryWorkDays" title="R.Dan" render={value => <NumberText value={value} precision={1} />} />
            <Table.Column width={ValueWidth} dataIndex="averageFirstDeliveryDays" title="Dan" render={value => <NumberText value={value} precision={1} />} />
        </Table.ColumnGroup>

        {showGroupTwo && <Table.ColumnGroup title="Dostava">
            <Table.Column width={ValueWidth} dataIndex="deliveryQualityCoef" title="Kval" render={value => <PercentText value={value} showSymbol precision={0} />} />
            <Table.Column width={ValueWidth} dataIndex="averageDeliveryWorkDays" title="R.Dan" render={value => <NumberText value={value} precision={1} />} />
            <Table.Column width={ValueWidth} dataIndex="averageDeliveryDays" title="Dan" render={value => <NumberText value={value} precision={1} />} />
        </Table.ColumnGroup>}
    </Table>);
}

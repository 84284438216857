import { useReducer, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";
import getUserConfirmation from './components/getUserConfirmation';
import { MeContext, reducer, InitialState, useMeLoader } from "./MeContext"
// Views:
import { AdminRoot } from "./Admin/AdminRoot";
import { PubRoot } from "./Pub/PubRoot";
import { FwLink } from "./FwLink";

const App = () => {
    const [state, dispatch] = useReducer(reducer, InitialState);
    const meLoader = useMeLoader();
    const { appAccount, loading, invalid, customer } = state;

    useEffect(() => {
        meLoader(dispatch);
    }, [meLoader]);

    if (loading) return <p>Učitavanje korisnika...</p>;
    if (invalid) return <p>Greška kod učitavanja korisnika...</p>;

    // if admin AND customer is not set (customer is set via inspect) - user must unset it first to be able to return to admin!
    const allowPubArea = !!customer;
    const allowAdminArea = appAccount.isAdmin && !customer;

    return (
        <Router getUserConfirmation={getUserConfirmation} basename={process.env.PUBLIC_URL}>
            <MeContext.Provider value={{ state, dispatch }}>
                <Switch>
                    <Redirect from="/" to={allowAdminArea ? "/admin" : "/pub"} exact />
                    {allowPubArea && <Route path="/pub" component={PubRoot} />}
                    {allowAdminArea && <Route path="/admin" component={AdminRoot} />}
                    <Route path="/fwlink" exact component={FwLink} />
                    <Route>
                        <div style={{ margin: 10 }}>
                            <h1>Stranica nije pronađena</h1>
                            <p><Link to="/">Povratak na početnu stranicu</Link></p>
                        </div>
                    </Route>
                </Switch>
            </MeContext.Provider>
        </Router>
    );
}
export default App;

import React from 'react';
import { Link, Switch, Route, useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import { Menu, PageHeader } from 'antd';
import { MainTab } from "./MainTab";
import { PackageTab } from "./PackageTab";
import { TransactionTab } from "./TransactionTab";
import { NotifTab } from "./NotifTab";
import { EditShipmentContext } from '../Context';
import { Bux } from 'Components/Bux';

export const View = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const { state } = React.useContext(EditShipmentContext);
    const { model } = state;

    const menu = <Menu mode="horizontal" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]}>
        <Menu.Item key={match.url}>
            <span>Pošiljka</span>
            <Link to={match.url} />
        </Menu.Item>
        <Menu.Item key={match.url + "/package"}>
            <span>Paketi</span>
            <Link to={match.url + "/package"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/transaction"}>
            <span>Transakcije</span>
            <Link to={match.url + "/transaction"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/notif"}>
            <span>Obavijesti</span>
            <Link to={match.url + "/notif"} />
        </Menu.Item>
    </Menu>;

    const title = "Pošiljka " + model?.code + " (" + (model?.serviceType?.name || "N/A") + ")";
    return (<Bux.Page>
        <Bux.Header>
            <PageHeader title={title} footer={menu} />
        </Bux.Header>
        <Switch>
            <Route path={match.url} exact>
                <MainTab shipment={model} />
            </Route>
            <Route path={match.url + "/package"} exact>
                <PackageTab shipment={model} />
            </Route>
            <Route path={match.url + "/transaction"} exact>
                <TransactionTab shipment={model} />
            </Route>
            <Route path={match.url + "/notif"} exact>
                <NotifTab shipment={model} />
            </Route>
            <Route>
                <Redirect to={match.url} />
            </Route>
        </Switch>
    </Bux.Page>);
}


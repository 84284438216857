import { Switch, Route } from "react-router";

import { Faq } from "./Faq";
import { Doc } from "./Doc";

export const Help = ({ match }) => (
    <Switch>
        <Route path={match.url + "/faq"} exact component={Faq} />
        <Route path={match.url + "/doc"} exact component={Doc} />
    </Switch>);

//import React from 'react';

import dayjs, { Dayjs } from "dayjs";

export interface FilterFormProps {
    code: string;
    onSubmit: (type: "xlsx" | "pdf", filter: any) => void;
    running?: boolean;
}
export type FilterForm = (props: FilterFormProps) => JSX.Element;

export const DateRanges: Record<string, [Dayjs, Dayjs]> = {
    "Danas": [dayjs(), dayjs()],
    "Jučer": [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    "Zadnjih 5 dana": [dayjs().subtract(5, "days"), dayjs()],
    "Zadnih 10 dana": [dayjs().subtract(10, "days"), dayjs()],
    "Zadnih 30 dana": [dayjs().subtract(30, "days"), dayjs()],
    "Ovaj mjesec": [dayjs().startOf("month"), dayjs().endOf("month")],
}

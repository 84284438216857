import React from 'react';
import { List, Typography } from 'antd';
import { PriceList } from 'Models/Bus/PriceList/PriceList';
import { ServiceType } from 'Models/Codebook/ServiceType';
import { Customer } from 'Models/Bus/Customer';
import { Ok, Warn, Error } from "./StatusIcon";

interface SummaryItem {
    level: "error" | "ok" | "warn";
    text: React.ReactNode;
    hint?: React.ReactNode;
}

export const SummaryList = ({ title, items }: { title: string, items: SummaryItem[] }) => (
    <List<SummaryItem>
        header={<Typography.Title level={3}>{title}</Typography.Title>}
        footer={<div>Ukupno {items.filter(i => i.level === "warn").length} upozorenje i {items.filter(i => i.level === "error").length} problem.</div>}
        bordered
        dataSource={items}
        renderItem={({ level, text, hint }) => (
            <List.Item>
                <List.Item.Meta
                    avatar={level === "ok" ? <Ok /> : (level === "warn" ? <Warn /> : <Error />)}
                    title={text}
                    description={level === "ok" ? null : hint}
                />
            </List.Item>
        )}
    />);


interface PriceListSummaryProps {
    priceList: PriceList;
    serviceTypes?: ServiceType[];
}

export const PriceListSummary = React.memo(({ priceList, serviceTypes }: PriceListSummaryProps) => {
    const items: SummaryItem[] = [];

    if (serviceTypes) {
        items.push(...serviceTypes.map(st => {
            const hasRecord = priceList.priceWeightRanges.findIndex(pwr => pwr.serviceTypeId === st.id) >= 0;
            if (!hasRecord) {
                return { level: "warn", text: "Cjenik za " + st.name, hint: "Preporučeno je upisati cjenik za uslugu." } as SummaryItem;
            }

            const hasEmptyZones = priceList.priceWeightRanges.findIndex(pwr => pwr.serviceTypeId === st.id && (!pwr.priceZone1 || !pwr.priceZone2)) >= 0;

            return {
                level: hasEmptyZones ? "warn" : "ok",
                text: "Cjenik za " + st.name,
                hint: "Određene stavke imaju cijenu 0,00 EUR."
            } as SummaryItem;
        }));

        // ako je cjenik za uslugu upisan, dodajemo info ima li extra podatke
        items.push(...serviceTypes.filter(st => priceList.priceWeightRanges.findIndex(pwr => pwr.serviceTypeId === st.id) >= 0).map(st => ({
            level: priceList.priceWeightExtras.findIndex(pwr => pwr.serviceTypeId === st.id) >= 0 ? "ok" : "warn",
            text: "Prekoračenje za " + st.name,
            hint: "Preporučeno je upisati cjenik za prekoračenje mase za uslugu."
        } as SummaryItem)));
    }

    items.push({
        level: !priceList.priceInsuranceRanges.length ? "warn" : "ok",
        text: "Definirani rasponi za osiguranje",
        hint: "Preporučeno je definirati raspone za osiguranje."
    });

    items.push({
        level: !priceList.priceDiscountRanges.length ? "warn" : "ok",
        text: "Definirani rasponi za popuste",
        hint: "Preporučeno je definirati raspone za popuste."
    });

    items.push({
        level: !priceList.priceAdditionalService?.returnHandlingPrice && !priceList.priceAdditionalService?.returnHandlingPriceCoef ? "warn" : "ok",
        text: "Definirana cijena povratnog rukovanja",
        hint: "Preporučeno je definirati cijenu za povratno rukovanje."
    });


    return <SummaryList title="Stanje cjenika" items={items} />;
});


interface CustomerSummaryProps {
    customer: Customer;
    serviceTypes?: ServiceType[];
}

export const CustomerSummary = React.memo(({ customer, serviceTypes }: CustomerSummaryProps) => {
    const items: SummaryItem[] = [];

    items.push({
        level: customer.isActive ? "error" : "ok",
        text: "Korisnik aktivan",
        hint: "Potrebno je aktivirati korisnika, inače rad u aplikaciji neće biti omogućen."
    });

    items.push({
        level: !customer.appAccount?.isAccountEnabled ? "warn" : "ok",
        text: "Prijava omogućena",
        hint: "Ukoliko korisnički račun nije omogućen, prijava nije moguća."
    });

    if (serviceTypes) {
        items.push(...serviceTypes.map(st => ({
            level: customer.customerServiceTypes.findIndex(pwr => pwr.serviceType.id === st.id) >= 0 ? "ok" : "warn",
            text: "Postavke za uslugu " + st.name,
            hint: "Preporučeno je definirati postavke za uslugu."
        } as SummaryItem)));
    }

    return <SummaryList title="Stanje korisnika" items={items} />;
});

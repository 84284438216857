import { kApi } from "Api";
import { ApiCall } from "net-common-ui";
import { Dayjs } from "dayjs";

export interface InvoiceInsight {
    firstFreeDate: Dayjs;
}

export function getInvoiceInsight(): ApiCall<InvoiceInsight> {
    return ApiCall.get(kApi, `invoice/insight`);
}

import { kApi } from "Api";
import { ApiCall } from "net-common-ui";

export interface PriceInsuranceRange {
    id?: number;
    priceListId?: number;
    valueFrom?: number;
    valueTo?: number;
    price?: number;
    priceCoef?: number;
}

export function savePriceInsuranceRange(priceListId: number, model: PriceInsuranceRange[]): ApiCall<PriceInsuranceRange> {
    return ApiCall.put(kApi, `price-list/${priceListId}/price-insurance-range;multi`, model);
}

export function deletePriceInsuranceRange(priceListId: number): ApiCall<PriceInsuranceRange> {
    return ApiCall.delete(kApi, `price-list/${priceListId}/price-insurance-range`);
}

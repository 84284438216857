import { Shipment } from 'Models/Logi/Shipment';
import { Alert, Col, Row } from 'antd';
import * as ShipmentDesc from "Components/Shipment/Descriptions";

interface Props {
    shipment: Shipment;
}

export const ShipmentView = ({ shipment }: Props) => {
    const { sender, recipient } = shipment;

    return (<>
        {shipment.isDeleted && <Alert message="Pošiljka je obrisana!" type="error" showIcon />}
        {shipment.isCancelled && <Alert message="Pošiljka je stornirana!" type="warning" showIcon />}
        {shipment.isGratis && <Alert message={<span>Opcija <em>Gratis</em> je aktivna! Pošiljka neće biti fakturirana.</span>} type="info" showIcon />}
        <Row gutter={[8, 8]}>
            <Col sm={12} xs={24}>
                <ShipmentDesc.SenderDesc sender={sender} />
            </Col>
            <Col sm={12} xs={24}>
                <ShipmentDesc.RecipientDesc recipient={recipient} />
            </Col>
        </Row>

        <Row gutter={[8, 8]}>
            <Col sm={12} xs={24}>
                <ShipmentDesc.BasicDesc shipment={shipment} />
            </Col>
            <Col sm={12} xs={24}>
                <ShipmentDesc.EventsDesc shipment={shipment} />
            </Col>
        </Row>

        <Row gutter={[8, 8]}>
            <Col sm={12} xs={24}>
                <ShipmentDesc.ServicesDesc shipment={shipment} />
            </Col>
            <Col sm={12} xs={24}>
                <ShipmentDesc.InvoiceDesc shipmentInvoice={shipment.shipmentInvoice} shipmentReturnInvoice={shipment.shipmentReturnInvoice} />
            </Col>
        </Row>

    </>);
}

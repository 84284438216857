import React from 'react';
import { applyGridState, DateDisplay, useDataSource, useStateManager } from "net-common-ui";
import { Pagination, Row, Col, Table } from 'antd';
import { dsoInvoiceByCustomerId, getInvoiceNumberDisplayLabel, Invoice } from 'Models/Bus/Invoice';
import { Link } from 'react-router-dom';
import { Bux } from 'Components/Bux';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CurrencyText } from 'Components/Text';
import { ProfileContext } from '../Context';
import { GridState } from 'net-common-ui/dist/Data/GridState';
import { AppAccountFilter } from 'Views/Admin/Cfg/AppAccount/Grid/FilterForm';

export const InvoiceTab = () => {
    const context = React.useContext(ProfileContext);
    const [view, ds] = useDataSource(dsoInvoiceByCustomerId(context.state.model.id));
    const [, sm] = useStateManager<GridState<AppAccountFilter>>({ sort: { field: "number", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, null);
    });

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Link key="build" to="/admin/invoice/build">
                        Obrada <ArrowRightOutlined />
                    </Link>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            <Table<Invoice> dataSource={view.data} pagination={false} rowKey="id">
                <Table.Column dataIndex="number" title="Broj" render={(_, record: Invoice) => (
                    <Link to={"/admin/invoice/" + record.id}>{getInvoiceNumberDisplayLabel(record)}</Link>
                )} />
                <Table.Column dataIndex={["invoiceShipment", "from"]} title="Pošiljke od" render={value => <DateDisplay value={value} />} />
                <Table.Column dataIndex={["invoiceShipment", "to"]} title="Pošiljke do" render={value => <DateDisplay value={value} />} />
                <Table.Column dataIndex="dueOn" title="Rok" render={value => <DateDisplay value={value} />} />
                <Table.Column dataIndex="price" title="Cijena" render={value => <CurrencyText value={value} />} />
                <Table.Column dataIndex="discount" title="Rabat" render={value => <CurrencyText value={value} />} />
                <Table.Column dataIndex="tax" title="Porez" render={value => <CurrencyText value={value} />} />
                <Table.Column dataIndex="total" title="Ukupno" render={value => <CurrencyText value={value} />} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </>);
}


import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { CodTransfer } from "./CodTransfer";
import { ConsumerActor } from "net-common-ui/dist/Auth/ConsumerActor";

export interface CodTransferGroup {
    id?: number;
    code?: string;
    rangeFrom?: Dayjs;
    rangeTo?: Dayjs;
    createdBy?: ConsumerActor;
    createdOn?: Dayjs;
    codTransfers?: CodTransfer[];

    // computed below
    amount?: number;
    codTransferCount?: number;
}

/*
  public get isRangeValid(): boolean {
        const { rangeFrom, rangeTo } = this;
        return rangeFrom && rangeTo && rangeFrom.isBefore(rangeTo);
    }

    */


export function getCodTransferGroupById(id: UrlParam): ApiCall<CodTransferGroup> {
    return ApiCall.get(kApi, `cod-transfer-group/${id}`);
}

export function getCodTransferGroupLast(): ApiCall<CodTransferGroup> {
    return ApiCall.get(kApi, "`cod-transfer-group;last");
}

export function getCodTransferGroupInfoById(id: UrlParam): ApiCall<CodTransferGroup> {
    return ApiCall.get(kApi, `cod-transfer-group/${id};info`);
}

export function buildCodTransferGroupPreview(request: CodTransferGroupBuildRequest): ApiCall<CodTransferGroup> {
    return ApiCall.put(kApi, "cod-transfer-group/build-preview", request);
}

export function buildCodTransferGroup(request: CodTransferGroupBuildRequest): ApiCall<CodTransferGroup> {
    return ApiCall.put(kApi, "cod-transfer-group/build", request);
}


export function dsoCodTransferGroup(): DataSourceOptions<CodTransferGroup> {
    return {
        api: kApi,
        url: "cod-transfer-group"
    };
}


export interface CodTransferGroupBuildRequest {
    rangeFrom?: Dayjs;
    rangeTo?: Dayjs;
    notifyCustomer?: boolean;
}

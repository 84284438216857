import React from 'react';
import { WarningOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

interface Props {
    extra?: React.ReactNode;
}

export const Ok = ({ extra }: Props) => StatusIcon(<CheckOutlined className="text-success" />, extra);
export const Warn = ({ extra }: Props) => StatusIcon(<WarningOutlined className="text-warn" />, extra);
export const Error = ({ extra }: Props) => StatusIcon(<CloseOutlined className="text-danger" />, extra);

const StatusIcon = (icon: React.ReactNode, extra: React.ReactNode) => {
    return (<>
        {icon}
        {extra && <>&nbsp;{extra}</>}
    </>);
}

import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface Status {
    id?: number;
    logicalOrder?: number;
    code?: string;
    name?: string;
    isActive?: boolean;
}

export function getStatusById(id: UrlParam): ApiCall<Status> {
    return ApiCall.get(kApi, `status/${id}`);
}
export function dsoStatus(): DataSourceOptions<Status> {
    return {
        api: kApi,
        url: "status"
    };
}
/*
export function makeStatusSimple(): DataSourceOptions<Status> {
    return new SimpleRemoteDataSource({
        model: Status,
        api: kApi,
        apiConfig: {
            method: "GET",
            url: "status",
            params: { page: 1 }
        }
    }).setQuery(q => q.sort.set("LogicalOrder", "asc"));
}*/
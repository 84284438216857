import React, { useCallback, useState } from "react";
import Bux from "Components/Bux";
import { DashDailyShipmentStats, getDashDailyShipmentStats } from "Models/Dash/DashDailyShipmentStats"
import { DashDailyCustomerStats, getDashDailyCustomerStats } from "Models/Dash/DashDailyCustomerStats"
import { Card, Col, Row } from "antd";
import { ShipmentDailyCountChart } from "Components/Dash/ShipmentDailyCountChart";
import { DeliveryQualityChart } from "Components/Dash/DeliveryQualityChart";
import { DeliveryQualityTable } from "Components/Dash/DeliveryQualityTable"
import { CustomerShipmentCountTable } from "Components/Dash/CustomerShipmentCountTable";
import { Settings, DashboardSettings } from "Components/Dash/Settings";
import { SearchByBarcode } from "Components/Dash/SearchByBarcode";
import { ShipmentPriceTrendChart } from "Components/Dash/ShipmentPriceTrendChart";
import { DashShipmentPriceTrend, getDashShipmentPriceTrend } from "Models/Dash/DashShipmentPriceTrend";
import { ShipmentPriceTrendSummary } from "Components/Dash/ShipmentPriceTrendSummary";
import dayjs from "dayjs";

const initialSettings: DashboardSettings = {
    range: [dayjs().startOf("month"), dayjs()] // current month
}


export const Dash = React.memo(() => {

    const [loading, setLoading] = useState(false);
    const [dailyShipmentStats, setDailyShipmentStats] = useState<DashDailyShipmentStats[]>(null);
    const [dailyCustomerStats, setDailyCustomerStats] = useState<DashDailyCustomerStats[]>(null);
    const [shipmentPriceTrend, setShipmentPriceTrend] = useState<DashShipmentPriceTrend[]>(null);

    const handleChange = useCallback((settings: DashboardSettings) => {
        async function run() {
            setLoading(true);

            const a = await getDashDailyShipmentStats(settings.range).execute();
            setDailyShipmentStats(a.data);

            const b = await getDashDailyCustomerStats(settings.range).execute();
            setDailyCustomerStats(b.data);

            const c = await getDashShipmentPriceTrend(null, settings.range).execute();
            setShipmentPriceTrend(c.data);

            setLoading(false);
        }
        run();
    }, []);

    return (<Bux.Page className="spec-dashboard">
        <Bux.Header>
            <Settings loading={loading} onChange={handleChange} initialValue={initialSettings} />
        </Bux.Header>
        <Bux.Content>

            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Card title="Pretraga po barkodu" size="small" bordered>
                        <SearchByBarcode />
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title="Kreirane pošiljke" size="small" bordered>
                        <ShipmentDailyCountChart value={dailyShipmentStats || []} />
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title="Brzina prve dostave" size="small" bordered>
                        <DeliveryQualityChart value={dailyShipmentStats || []} />
                    </Card>
                </Col>

                <Col sm={24} md={12}>
                    <Card title="Kvaliteta dostave" size="small" bordered>
                        <DeliveryQualityTable value={dailyShipmentStats || []} />
                    </Card>
                </Col>

                <Col sm={24} md={12}>
                    <Card title="Aktivnost korisnika" size="small" bordered>
                        <CustomerShipmentCountTable value={dailyCustomerStats || []} />
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title="Trend cijene" size="small" bordered>
                        <Row>
                            <Col sm={24} md={8}>
                                <ShipmentPriceTrendSummary value={shipmentPriceTrend || []} />
                            </Col>
                            <Col sm={24} md={16}>
                                <ShipmentPriceTrendChart value={shipmentPriceTrend || []} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>


        </Bux.Content>
        {/* <Bux.Footer></Bux.Footer> */}
    </Bux.Page>);
});

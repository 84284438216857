import React from 'react';
import { ProfileContext } from '../Context';
import { InvoiceRecord } from "Models/Bus/InvoiceRecord";
import { Bux } from 'Components/Bux';
import { Table } from 'antd';
import { CurrencyText, PercentText } from "Components/Text";

export const RecordTab = () => {
    const { state } = React.useContext(ProfileContext);
    const { model } = state;

    return (<>
        <Bux.Content>
            <Table<InvoiceRecord> dataSource={model.invoiceRecords} rowKey="id">
                <Table.Column title="R.br." dataIndex="ordinal" />
                <Table.Column title="Šifra" dataIndex="code" />
                <Table.Column title="Naziv" dataIndex="name" />
                <Table.Column title="Jed" dataIndex="unit" />
                <Table.Column title="Kol" dataIndex="quantity" />
                <Table.Column title="Cijena" dataIndex="price" render={value => <CurrencyText value={value} showSymbol />} />
                <Table.Column title="Porez" dataIndex="taxCoef" render={value => <PercentText value={value} showSymbol />} />
            </Table>
        </Bux.Content>
    </>);
}

import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CodeInput } from "net-common-ui";

export interface CustomerFilter {
    code?: string;
    name?: string;
    test?: any[];
    isActive?: boolean;
}

interface FilterFormProps {
    initialFilter?: CustomerFilter;
    onChange?: (filter: CustomerFilter) => void;
    form?: FormInstance;
}

export const FilterForm = ({ onChange, initialFilter = {}, form }: FilterFormProps) => {
    return (
        <Form
            form={form}
            layout="vertical"
            name="filter"
            initialValues={initialFilter}
            onFinish={onChange}>

            <Form.Item name="code" label="Šifra">
                <CodeInput></CodeInput>
            </Form.Item>

            <Form.Item name="name" label="Naziv">
                <Input></Input>
            </Form.Item>

            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden />
        </Form>
    )
}

import { kApi } from "Api";
import { ApiCall } from "net-common-ui";

export interface PriceAdditionalService {
    id?: number;
    priceListId?: number;
    deliveryBy9Price?: number;
    deliveryBy9PriceCoef?: number;
    deliveryBy11Price?: number;
    deliveryBy11PriceCoef?: number;
    nonstandardFormatPrice?: number;
    nonstandardFormatPriceCoef?: number;
    povratnicaPrice?: number;
    povratnicaPriceCoef?: number;
    returnSignedDocPrice?: number;
    returnSignedDocPriceCoef?: number;
    codPrice?: number;
    codPriceCoef?: number;
    saturdayHandlingPrice?: number;
    saturdayHandlingPriceCoef?: number;
    personalDeliveryPrice?: number;
    personalDeliveryPriceCoef?: number;
    returnHandlingPrice?: number;
    returnHandlingPriceCoef?: number;

    smsPrice?: number;
    smsPriceCoef?: number;
    emailPrice?: number;
    emailPriceCoef?: number;
}

export function savePriceAdditionalService(priceListId: number, model: PriceAdditionalService): ApiCall<PriceAdditionalService> {
    return ApiCall.put(kApi, `price-list/${priceListId}/price-additional-service`, model);
}
export function deletePriceAdditionalService(priceListId: number): ApiCall<PriceAdditionalService> {
    return ApiCall.delete(kApi, `price-list/${priceListId}/price-additional-service`);
}

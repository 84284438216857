import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { EditShipmentContext, useEditShipmentReducer } from './Context';
import { useCustomer } from 'Views/MeContext';
import { newShipmentFactory } from "./logic";
import queryString from 'query-string';
import { Root } from './Root';
import { getShipmentInfoById } from 'Models/Logi/Shipment';

export function Profile() {
    const params = useParams<{ id: string }>();
    const customer = useCustomer();
    const [state, dispatch] = useEditShipmentReducer();
    const location = useLocation();

    useEffect(() => {
        dispatch({ type: "SHIPMENT_LOADING" });
        if (params.id) {
            // edit mode
            getShipmentInfoById(params.id).execute().then(result => {
                if (result.success) {
                    dispatch({ type: "SHIPMENT_LOADED", payload: result.item });
                }
                else {
                    // passed Id param coult not be loaded...
                    dispatch({ type: "INVALID_STATE", payload: "Pošiljka nije pronađena." });
                }
            });
        }
        else {
            // create mode
            const serviceTypeId = parseInt(queryString.parse(location.search).serviceTypeId as string);
            const serviceType = serviceTypeId && customer.customerServiceTypes.find(cst => cst.serviceType.id === serviceTypeId)?.serviceType;

            if (!serviceType) {
                dispatch({ type: "INVALID_STATE", payload: "Nepoznata vrsta usluge." });
            }
            else {
                const newShipment = newShipmentFactory(customer, serviceType);
                // timeout here to allow ShipmentForm component to reset state
                setTimeout(() => {
                    dispatch({ type: "SHIPMENT_LOADED", payload: newShipment });
                }, 0);
            }
        }
    }, [params.id, dispatch, customer, location.search]);

    return (<EditShipmentContext.Provider value={{ state, dispatch }}>
        <Root />
    </EditShipmentContext.Provider>);
};


import { useEffect, useState } from 'react';
import Bux from "Components/Bux";
import { Col, Descriptions, PageHeader, Row, Skeleton, Space } from 'antd';
import { Redirect, useParams } from 'react-router-dom';
import { CurrencyText, PercentText } from 'Components/Text';
import { DateDisplay } from "net-common-ui";
import { getInvoiceInfoById, Invoice } from 'Models/Bus/Invoice';
import { DownloadInvoiceButton } from 'Components/DownloadInvoiceButton';

export function Profile() {
    const params = useParams<{ id: string }>();
    const [invoice, setInvoice] = useState<Invoice>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInvoiceInfoById(params.id).execute().then(result => {
            setInvoice(result.item);
            setLoading(false);
        });
    }, [params.id]);

    if (!loading && !invoice) {
        return <Redirect to="/pub/transaction/invoice" />;
    }

    return <Bux.Page>
        <Bux.Header>
            <PageHeader title="Faktura" />
        </Bux.Header>
        {invoice
            ? <Bux.Content>
                <Row gutter={8}>

                    <Col span={12}>
                        <Descriptions bordered size="small" column={1}>
                            <Descriptions.Item label="Broj">{invoice.fullNumberText}</Descriptions.Item>
                            <Descriptions.Item label="Izdano"><DateDisplay value={invoice.verifiedOn} /></Descriptions.Item>
                            <Descriptions.Item label="Rok plaćanja"><DateDisplay value={invoice.dueOn} /> ({invoice.dueOnOffset} dana)</Descriptions.Item>
                            <Descriptions.Item label="Pošiljke">
                                {<DateDisplay value={invoice.invoiceShipment.from} />}
                                &nbsp;-&nbsp;
                                {<DateDisplay value={invoice.invoiceShipment.to} />}
                            </Descriptions.Item>
                            <Descriptions.Item label="Poziv na broj">HR00 {invoice.clearanceNumber}</Descriptions.Item>

                            <Descriptions.Item label="Datoteke">
                                <Space direction="vertical">
                                    <DownloadInvoiceButton invoiceId={invoice.id} />
                                </Space>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>

                    <Col span={12}>
                        <Descriptions bordered size="small" column={1}>
                            <Descriptions.Item label="Iznos"><CurrencyText value={invoice.price} showSymbol /></Descriptions.Item>
                            <Descriptions.Item label="Rabat"><CurrencyText value={invoice.discount} showSymbol /></Descriptions.Item>
                            <Descriptions.Item label="Porezna osnovica"><CurrencyText value={invoice.total} showSymbol /></Descriptions.Item>
                            <Descriptions.Item label="Porez">
                                <CurrencyText value={invoice.tax} showSymbol />
                                &nbsp;
                                (<PercentText value={invoice.taxCoef} showSymbol />)
                            </Descriptions.Item>
                            <Descriptions.Item label="Ukupno">
                                <strong>
                                    <CurrencyText value={invoice.totalWithTax} showSymbol />
                                </strong>
                            </Descriptions.Item>

                        </Descriptions>
                    </Col>

                </Row>
            </Bux.Content>
            : <Bux.Content>
                <Skeleton active />
            </Bux.Content>}
    </Bux.Page>;
}

import { Button, Col, Row } from 'antd';
import { User } from "net-common-ui";
import React from 'react';


interface ErrorPageProps {
    error: string;
    user?: User;
}

export const ErrorPage = ({ error, user }: ErrorPageProps) => {
    const { title, message, buttonLabel } = mapError(error, user);
    return <Row justify="center" style={{ width: "100%", marginTop: 100 }}>
        <Col xs={16} sm={16} md={16} lg={8}>
            <h2>{title}</h2>
            <p>
                {message}
            </p>
            {buttonLabel &&
                <p>
                    <Button onClick={() => document.location.replace('')} type="primary" className="mb-margin-top-md">{buttonLabel}</Button>
                </p>
            }
        </Col>
    </Row>;
}

function mapError(error: string, user?: User) {
    let message: string;
    let title: string;
    let buttonLabel: string;

    switch (error) {
        case "NotFound":
            title = "Nije pronađeno";
            message = "Stranica nije pronađena.";
            buttonLabel = "Natrag na početak";
            break;
        case "Unauthenticated":
            title = "Unauthenticated";
            message = "Morate se prijaviti kako biste otvorili aplikaciju";
            buttonLabel = "Natrag na prijavu";
            break;
        case "Unauthorized":
            title = "Zabranjen pristup";
            message = "Nemate dovoljne ovlasti za prikaz tražene stranice.";
            buttonLabel = "Natrag na početak";
            break;
        case "AppInitError":
            title = "Greška pri pokretanju aplikacije";
            message = "Failed to initialize application.";
            buttonLabel = "Pokušaj ponovno";
            break;
        default:
            title = "Greška";
            message = error;
            buttonLabel = "Pokušaj ponovno";
            break;
    }
    return {
        title,
        message,
        buttonLabel
    }
}

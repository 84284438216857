import { useState } from 'react';
import { Button, Modal, Checkbox, message } from 'antd';
import {
    CarryOutOutlined
} from '@ant-design/icons';
import { Invoice, payInvoice } from 'Models/Bus/Invoice';
import dayjs, { Dayjs } from 'dayjs';
import { DatePickerEx } from "Components/DatePickerEx";

interface Props {
    invoiceId: number;
    verifiedOn: Dayjs;
    onClick: (invoice: Invoice) => void;
    value: Dayjs;
}

export const UpdatePaidButton = ({ invoiceId, verifiedOn, value, onClick }: Props) => {
    const [isPaid, setIsPaid] = useState<boolean>(true);
    const [paidDate, setPaidDate] = useState<Dayjs>(value || dayjs());
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);

    // const handlePay = async () => {
    //     const result = await Invoice.pay(model.id, paidDate).execute();
    //     if (result.success) {
    //         message.success("Datum plaćanja uspješno ažuriran.");
    //         dispatch({ type: "INVOICE_PAID", payload: result.item });
    //     }
    // }

    const handleShow = () => {
        setVisible(true);
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const handleOk = async () => {
        setSaving(true);
        setPaidDate(paidDate);

        const result = await payInvoice(invoiceId, paidDate).execute();

        setSaving(false);
        setVisible(false);

        if (result.success) {
            message.success("Datum plaćanja uspješno ažuriran.");
            onClick(result.item);
        }
    }

    return <>
        <Button loading={saving} disabled={!verifiedOn} type={value ? "default" : "primary"} block onClick={handleShow} icon={<CarryOutOutlined />}>Plaćeno</Button>
        <Modal
            title="Ažuriranje fakture"
            visible={visible}
            onOk={handleOk}
            confirmLoading={saving}
            onCancel={handleCancel}
            destroyOnClose
            closable={!saving}
        >
            <p>Odaberite datum plaćanja. Ukoliko vrijednost ostane prazna, faktura će biti označena kao neplaćena!</p>
            <div>
                <Checkbox disabled={saving} checked={isPaid} onChange={e => setIsPaid(e.target.checked)}>Plaćeno</Checkbox>
                {isPaid && <DatePickerEx
                    allowClear={false}
                    disabled={saving}
                    placeholder="Datum plaćanja"
                    value={paidDate}
                    disabledDate={dt => dt.isBefore(verifiedOn, "day") || dt.isAfter(dayjs(), "day")}
                    onChange={value => setPaidDate(value)} />}
            </div>
        </Modal>
    </>;
}

import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { PriceList } from "./PriceList";

export interface StandardPriceList {
    id?: number;
    priceListId?: number;
    ordinal?: number;
    isActive?: boolean;
    isPublished?: boolean;
    invoiceCount?: number;
    validFrom?: Dayjs;
    validTo?: Dayjs;
    priceList?: PriceList;
}

export function getStandardPriceListById(id: UrlParam): ApiCall<StandardPriceList> {
    return ApiCall.get(kApi, `standard-price-list/${id}`);
}

export function getStandardPriceListInfoById(id: UrlParam): ApiCall<StandardPriceList> {
    return ApiCall.get(kApi, `standard-price-list/${id};info`);
}

export function saveStandardPriceList(): ApiCall<StandardPriceList> {
    // Ovo je post jer je uvijek create. nema parametara...
    return ApiCall.post(kApi, "standard-price-list");
}

export function publishStandardPriceList(id: UrlParam, isPublished: boolean): ApiCall<any> {
    return ApiCall.patch(kApi, `standard-price-list/${id}/isPublished`, isPublished);
}

export function deleteStandardPriceList(id: UrlParam): ApiCall<any> {
    return ApiCall.delete(kApi, null, `standard-price-list/${id}`);
}

export function dsoStandardPriceList(): DataSourceOptions<StandardPriceList> {
    return {
        api: kApi,
        url: "standard-price-list"
    };
}
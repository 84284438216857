import { useMemo } from 'react';
import { Descriptions } from 'antd';
import { CustomerNotifConfig } from 'Models/Bus/CustomerNotifConfig';
import { parseMessage } from './util';
import { NotifChannel } from 'Models/Enum';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';

interface Props {
    value: CustomerNotifConfig;
}

const defaultProps: Partial<DescriptionsItemProps> = {
    labelStyle: {
        //width: 100
    }
};

export const MessageView = ({ value }: Props) => {
    const defaults = useMemo(() => parseMessage(value.defaultMessage), [value]);
    const model = useMemo(() => ({
        ...defaults,
        ...parseMessage(value.message)
    }), [value, defaults]);



    if (value.notifChannelId === NotifChannel.Sms) {
        return <Descriptions bordered size="small" column={1} title={null}>
            <Descriptions.Item label="Poruka" {...defaultProps}><Pre>{model.body}</Pre></Descriptions.Item>
            <Descriptions.Item label="Znakova" {...defaultProps}>{model.body.length} (nakon umetanja varijabli, moguća je drugačija vrijednost)</Descriptions.Item>
        </Descriptions>;
    }
    else {
        return <Descriptions bordered size="small" column={1} title={null}>
            <Descriptions.Item label="Subject" {...defaultProps}><Pre>{model.subject}</Pre></Descriptions.Item>
            <Descriptions.Item label="Naslov" {...defaultProps}><Pre>{model.title}</Pre></Descriptions.Item>
            <Descriptions.Item label="Naslov" {...defaultProps}><Pre>{model.subtitle}</Pre></Descriptions.Item>
            <Descriptions.Item label="Poruka" {...defaultProps}><Pre>{model.body}</Pre></Descriptions.Item>
        </Descriptions>;
    }
}

const Pre = ({ children }) => (
    <pre style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{children}</pre>
);
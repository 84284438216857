import { Pagination, PageHeader, Table } from 'antd';
import { dsoInvoiceByCustomerId, Invoice } from "Models/Bus/Invoice"
import { DateDisplay, useDataSource, useStateManager, applyGridState } from "net-common-ui";
import { Bux } from 'Components/Bux';
import { CurrencyText } from "Components/Text";
import { useCustomer } from "Views/MeContext";
import { DownloadInvoiceButton } from 'Components/DownloadInvoiceButton';
import { Link } from 'react-router-dom';
import { GridState } from 'net-common-ui/dist/Data/GridState';

export function Grid() {
    const customer = useCustomer();

    const [view, ds] = useDataSource(dsoInvoiceByCustomerId(customer.id));
    const [, sm] = useStateManager<GridState>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, null);
    });

    // const handleFilterChange = (filter: AppAccountFilter): void => {
    //     sm.update(
    //         (draft) => {
    //             draft.filter = filter;
    //             draft.page = 1;
    //         },
    //         { executeDataSources: "force" },
    //     );
    // };


    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title="Fakture" />
            </Bux.Header>
            <Bux.Content>
                <Table<Invoice> dataSource={ds.view.data} rowKey="id" pagination={false}>
                    <Table.Column dataIndex="fullNumberText" title="Broj"
                        render={(code, record: Invoice) => <Link to={"invoice/" + record.id}>{code}</Link>} />
                    <Table.Column dataIndex="verifiedOn" title="Datum" render={value => <DateDisplay value={value} />} />
                    <Table.Column dataIndex="dueOn" title="Rok" render={value => <DateDisplay value={value} />} />
                    <Table.Column dataIndex={["invoiceShipment", "from"]} title="Pošiljke od" render={value => <DateDisplay value={value} />} />
                    <Table.Column dataIndex={["invoiceShipment", "to"]} title="Pošiljke do" render={value => <DateDisplay value={value} />} />
                    <Table.Column dataIndex="price" title="Cijena" render={value => <CurrencyText value={value} />} />
                    <Table.Column dataIndex="discount" title="Rabat" render={value => <CurrencyText value={value} />} />
                    <Table.Column dataIndex="tax" title="Porez" render={value => <CurrencyText value={value} />} />
                    <Table.Column dataIndex="totalWithTax" title="Ukupno (EUR)" render={value => <CurrencyText value={value} showSymbol />} />
                    <Table.Column key="action" width={100} render={(_, invoice: Invoice) => (
                        <DownloadInvoiceButton invoiceId={invoice.id} showAsLink />
                    )} />
                </Table>
            </Bux.Content>
            <Bux.Footer>
                <Pagination
                    disabled={view.loading}
                    onChange={(page) =>
                        sm.update((draft) => {
                            draft.page = page;
                        })
                    }
                    current={view.page}
                    total={view.total}
                    pageSize={view.size}
                    showSizeChanger={false}
                    showTotal={total => "Ukupno: " + total}
                />            
            </Bux.Footer>
        </Bux.Page>);
};


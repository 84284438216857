import { ApiCall, UrlParam } from "net-common-ui";
import { Status } from "Models/Logi/Status";
import { kApi } from "Api";
import { ConsumerActor } from "net-common-ui/dist/Auth/ConsumerActor";
import { Dayjs } from "dayjs";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface Package {
    id?: number;
    shipmentId?: number;
    ordinal?: number;
    statusOn?: Dayjs;
    status?: Status;
    statusMeta?: string;
    statusNote?: string;
    barcode?: string;

    weight?: number;
    weightVerified?: number;
    weightVerifiedSetOn?: Dayjs;
    volumeX?: number;
    volumeY?: number;
    volumeZ?: number;

    refA?: string;
    refB?: string;
    refC?: string;

    labelCreatedOn?: Dayjs;
    labelCreatedBy?: ConsumerActor;

    expectedDeliveryDate?: Dayjs;
}
/*
public getVolumeText(): string {
    const { volumeX, volumeY, volumeZ } = this;
    return (volumeX || volumeY || volumeZ)
        ? `${volumeX}x${volumeY}x${volumeZ}`
        : "";
}
*/

export function getPackageById(id: UrlParam): ApiCall<Package> {
    return ApiCall.get(kApi, `package/${id}`);
}

export function dsoPackageByShipmentId(shipmentId: UrlParam): DataSourceOptions<Package> {
    return {
        api: kApi,
        url: `shipment/${shipmentId}/package`,
    };
}
import { Pagination, PageHeader, Table } from 'antd';
import { FilterForm, PriceListFilter } from "./FilterForm"
import { dsoStandardPriceList, StandardPriceList } from "Models/Bus/PriceList/StandardPriceList"
import { applyGridState, DateDisplay, useDataSource, useStateManager } from "net-common-ui";
import { NewButton } from './NewButton';
import { Link } from 'react-router-dom';
import { Bux } from 'Components/Bux';
import { getStatusTag } from '../../Common/Badges';
import withFilterDrawer from 'Components/withFilterDrawer';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const [view, ds] = useDataSource(dsoStandardPriceList());
    const [state, sm] = useStateManager<GridState<PriceListFilter>>({ sort: { field: "ordinal", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("isPublished", "eq", filter.isPublished)
        );
    });

    const handleFilterChange = (filter: PriceListFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };



    return <Bux.Page>
        <Bux.Header>
            <PageHeader
                title={"Standardni cjenici"}
                extra={[<NewButton key="new" onClose={saved => saved && ds.execute({ force: true })} />]} />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<StandardPriceList> rowKey="id" pagination={false} dataSource={view.data}>
                <Table.Column dataIndex="ordinal" title="Redni broj" width={180} render={(ordinal, record: StandardPriceList) => (
                    <Link to={"/admin/price-list/standard/" + record.id}>Standardni Cjenik #{ordinal}</Link>
                )} />
                <Table.Column dataIndex="invoiceCount" title="Izdano faktura" ellipsis />
                <Table.Column dataIndex="validFrom" title="Vrijedi od" ellipsis render={value => <DateDisplay value={value} />} />
                <Table.Column dataIndex="validTo" title="Vrijedi do" ellipsis render={value => <DateDisplay value={value} />} />
                <Table.Column title="Status" width={100} render={(_, record: StandardPriceList) => getStatusTag(record)} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
}

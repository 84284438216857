import { Form } from 'antd';
import { PriceAdditionalService } from 'Models/Bus/PriceList/PriceAdditionalService';
import { InputPercent } from 'Components/InputPercent';
import { InputMoney } from 'Components/InputMoney';
import { FormInstance } from 'antd/lib/form';

interface Props {
    form: FormInstance;
    value: PriceAdditionalService;
}

export const AdditionalServiceEditForm = ({ form, value }: Props) => {
    return (
        <Form form={form} layout="horizontal" name="form" initialValues={value} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="Dostava do 9">
                <Form.Item name="deliveryBy9Price" fieldKey="deliveryBy9Price" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="deliveryBy9PriceCoef" fieldKey="deliveryBy9PriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Dostava do 11">
                <Form.Item name="deliveryBy11Price" fieldKey="deliveryBy11Price" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="deliveryBy11PriceCoef" fieldKey="deliveryBy11PriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Nestandardni format">
                <Form.Item name="nonstandardFormatPrice" fieldKey="nonstandardFormatPrice" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="nonstandardFormatPriceCoef" fieldKey="nonstandardFormatPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Povratnica">
                <Form.Item name="povratnicaPrice" fieldKey="povratnicaPrice" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="povratnicaPriceCoef" fieldKey="povratnicaPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Povrat potpisanog dokumenta">
                <Form.Item name="returnSignedDocPrice" fieldKey="returnSignedDocPrice" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="returnSignedDocPriceCoef" fieldKey="returnSignedDocPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Otkupnina">
                <Form.Item name="codPrice" fieldKey="codPrice" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="codPriceCoef" fieldKey="codPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Uručenje/dostava subotom">
                <Form.Item name="saturdayHandlingPrice" fieldKey="saturdayHandlingPrice" noStyle>
                    <InputMoney placeholder="Fiksno" disabled />
                </Form.Item>
                <Form.Item name="saturdayHandlingPriceCoef" fieldKey="saturdayHandlingPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Uručenje osobno">
                <Form.Item name="personalDeliveryPrice" fieldKey="personalDeliveryPrice" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="personalDeliveryPriceCoef" fieldKey="personalDeliveryPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Povratno rukovanje">
                <Form.Item name="returnHandlingPrice" fieldKey="returnHandlingPrice" noStyle>
                    <InputMoney placeholder="Fiksno" disabled />
                </Form.Item>
                <Form.Item name="returnHandlingPriceCoef" fieldKey="returnHandlingPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} />
                </Form.Item>
            </Form.Item>


            <Form.Item label="SMS">
                <Form.Item name="smsPrice" fieldKey="smsPrice" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="smsPriceCoef" fieldKey="smsPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>

            <Form.Item label="Email">
                <Form.Item name="emailPrice" fieldKey="emailPrice" noStyle>
                    <InputMoney placeholder="Fiksno" />
                </Form.Item>
                <Form.Item name="emailPriceCoef" fieldKey="emailPriceCoef" noStyle>
                    <InputPercent placeholder="%" style={{ marginLeft: 10 }} disabled />
                </Form.Item>
            </Form.Item>
        </Form>
    )
}

import React, { useState } from 'react';
import { Button, Row, Col, InputNumber } from 'antd';
import { PriceDiscountRange } from "Models/Bus/PriceList/PriceDiscountRange"
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { InputPercent } from "Components/InputPercent";

interface Props {
    value?: PriceDiscountRange[];
    onChange?: (value: PriceDiscountRange[]) => void;
}

export const DiscountEditFormItem = React.forwardRef(({ value, onChange }: Props, ref: any) => {

    // executing fromJSON(...) to get a copy, since react goes batshit crazy if we touch anything inside props
    const [ranges, setRanges] = useState(value || []);

    const setCalcRanges = (ranges: PriceDiscountRange[]): void => {
        for (let i = 0; i < ranges.length; ++i) {
            const prev = i < 1 ? null : ranges[i - 1];
            //const next = i < ranges.length - 1 ? ranges[i + 1] : null;
            ranges[i].qtyFrom = prev
                ? prev.qtyTo !== undefined
                    ? prev.qtyTo + 1 // add 1 qty to the next range, so no overlap
                    : undefined
                : 0;
        }
        setRanges(ranges);
        onChange && onChange(ranges);
    }

    const handleAdd = () => {
        const newRange: PriceDiscountRange = {};
        newRange.id = ranges.length ? Math.max(...ranges.map(r => r.id)) + 1 : 1;
        setCalcRanges([...ranges, newRange]);
    }
    const handleRemove = (id: number) => {
        const index = ranges.findIndex(r => r.id === id);
        ranges.splice(index, 1);
        setCalcRanges([...ranges]);
    }
    const handleChange = (value: PriceDiscountRange) => {
        const index = ranges.findIndex(r => r.id === value.id);
        ranges[index] = value;
        setCalcRanges([...ranges]);
    }

    // pre-create first row...
    if (ranges.length < 1) handleAdd();

    return (
        <div ref={ref}>
            <Row gutter={[8, 8]}>
                <Col span={5}>Od (kom)</Col>
                <Col span={5}>Do (kom)</Col>
                <Col span={5}>Rabat (%)</Col>
                <Col span={4}></Col>
            </Row>

            {ranges.map((range, iRange) => {
                return <RangeElement
                    key={range.id}
                    value={range}
                    isLast={iRange === ranges.length - 1}
                    isFirst={iRange === 0}
                    onRemove={handleRemove} onChange={handleChange} />
            })}

            <Button type="dashed" onClick={handleAdd} block style={{ width: 448 }} icon={<PlusOutlined />}>Dodaj redak</Button>

        </div>
    )
});

interface RangeElementProps {
    value: PriceDiscountRange;
    isFirst: boolean;
    isLast: boolean;
    onChange: (value: PriceDiscountRange) => void;
    onRemove: (id: number) => void;
}
const RangeElement = ({ value, onChange, onRemove, isFirst }: RangeElementProps) => {

    const handleChange = (diff: Partial<PriceDiscountRange>) => {
        onChange({ ...value, ...diff });
    }

    return <Row gutter={[8, 8]}>
        <Col span={5}>
            <InputNumber
                disabled
                style={{ width: "100%" }}
                placeholder="kom"
                value={value.qtyFrom} />
        </Col>
        <Col span={5}>
            <InputNumber
                style={{ width: "100%" }}
                placeholder="kom"
                value={value.qtyTo}
                onChange={(fieldValue: number) => handleChange({ qtyTo: fieldValue })} />
        </Col>
        <Col span={5}>
            <InputPercent
                style={{ width: "100%" }}
                value={value.priceCoef}
                onChange={(fieldValue: number) => handleChange({ priceCoef: fieldValue })} />
        </Col>

        {!isFirst && <Button onClick={() => onRemove(value.id)} icon={<MinusCircleOutlined />}>Ukloni</Button>}
    </Row >;
}

import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface AppAccount {
    id?: number;
    customerId?: number;
    inspectCustomerId?: number;
    fullName?: string;
    isAccountEnabled?: boolean;
    isAdmin?: boolean;
    identifier?: string;
    email?: string;
    password?: string; // used when creating new
}

export function getAppAccountById(id: UrlParam): ApiCall<AppAccount> {
    return ApiCall.get(kApi, `app-account/${id}`);
}

export function getAppAccountByCustomerId(customerId: UrlParam): ApiCall<AppAccount> {
    return ApiCall.get(kApi, `customer/${customerId}/app-account`);
}

export function saveAppAccount(model: AppAccount): ApiCall<AppAccount> {
    return ApiCall.put(kApi, "app-account", model);
}

export function deleteAppAccount(id: UrlParam): ApiCall<AppAccount> {
    return ApiCall.delete(kApi, `app-account/${id}`);
}

export function inspectCustomer(id: UrlParam, customerId: UrlParam): ApiCall<AppAccount> {
    return ApiCall.patch(kApi, `app-account/${id}/inspectCustomer`, customerId);
}

export function dsoAppAccount(): DataSourceOptions<AppAccount> {
    return {
        api: kApi,
        url: "app-account"
    };
}
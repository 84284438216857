import React, { useState } from 'react';
import { Form, Button, Row, Col, Typography, Divider, Radio, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { CustomerServiceType, saveCustomerServiceType } from 'Models/Bus/CustomerServiceType';
import Checkbox from 'antd/es/checkbox';
import { CodType, DeliveryDeadline, deliveryDeadlineLabel } from 'Models/Enum';
import { CodTypeSelect } from 'Components/CodTypeSelect';

interface Props {
    customerServiceType: CustomerServiceType;
    onUpdate: (updated: boolean, customerServiceType?: CustomerServiceType) => void;
}

export const Edit = ({ customerServiceType, onUpdate }: Props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    //const isServiceBox = customerServiceType.serviceType.code === "Box";
    const isServiceCargo = customerServiceType.serviceType.code === "Cargo";

    const handleCancel = () => {
        onUpdate(false);
    }
    const handleSave = () => {
        form.validateFields().then((model: CustomerServiceType) => {
            setSaving(true);
            saveCustomerServiceType({ ...customerServiceType, ...model }).execute().then(result => {
                setSaving(false);
                if (result.success) {
                    message.success("Postavke usluge za korisnika uspješno spremljene.");
                    onUpdate(true, result.item);
                }
            });
        });

    }
    return (
        <Form form={form} layout="vertical" name="form" onFinish={handleSave} initialValues={customerServiceType}>
            <Row gutter={[8, 40]} justify="center">
                <Col span={8}>
                    <Typography.Title level={3}>Postavke usluge</Typography.Title>
                    <Form.Item name="isActive" valuePropName="checked">
                        <Checkbox>Usluga omogućena</Checkbox>
                    </Form.Item>
                    <Divider />
                    <Form.Item name="allowMultiPackage" valuePropName="checked">
                        <Checkbox disabled={isServiceCargo}>Omogući skupne pošiljke</Checkbox>
                    </Form.Item>
                    <Divider />
                    <Form.Item name="minDeliveryDeadlineId" label="Minimalni rok uručenja">
                        <Radio.Group className="radio-group-vertical">
                            <Radio value={DeliveryDeadline.Unknown} disabled={isServiceCargo}>Neograničeno</Radio>
                            <Radio value={DeliveryDeadline.D1} disabled={isServiceCargo}>{deliveryDeadlineLabel(DeliveryDeadline.D1)}</Radio>
                            <Radio value={DeliveryDeadline.D2} disabled={isServiceCargo}>{deliveryDeadlineLabel(DeliveryDeadline.D2)}</Radio>
                            <Radio value={DeliveryDeadline.D3}>{deliveryDeadlineLabel(DeliveryDeadline.D3)}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                <Col span={8} offset={1}>
                    <Typography.Title level={3}>Dodatne usluge</Typography.Title>
                    <Form.Item name="allowDeliveryBy9" valuePropName="checked">
                        <Checkbox disabled={isServiceCargo}>Dostava do 9h</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowDeliveryBy11" valuePropName="checked">
                        <Checkbox disabled={isServiceCargo}>Dostava do 11h</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowCod" valuePropName="checked">
                        <Checkbox>Otkupnina</Checkbox>
                    </Form.Item>

                    <Form.Item noStyle dependencies={["allowCod"]}>
                        {() => {
                            const allowCod = form.getFieldValue("allowCod");
                            return <Form.Item name="codTypeId" style={{ marginLeft: 24 }} rules={[{
                                validator: (rule: any, value: CodType, cb: (msg?: string) => void) => {
                                    allowCod && !value ? cb("Polje je obavezno ako su omogućene otkupne pošiljke.") : cb();
                                }
                            }]}>
                                <CodTypeSelect disabled={!allowCod} mode="multiple" placeholder="Dozvoljene vrste otkupnine" />
                            </Form.Item>;
                        }}
                    </Form.Item>

                    <Form.Item name="allowReturnSignedDoc" valuePropName="checked">
                        <Checkbox>Povrat potpisanog dokumenta</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowPersonalDelivery" valuePropName="checked">
                        <Checkbox>Uručiti osobno</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowPovratnica" valuePropName="checked">
                        <Checkbox>Povratnica</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNonstandardFormat" valuePropName="checked">
                        <Checkbox>Nestandardni format</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowSaturdayDelivery" valuePropName="checked">
                        <Checkbox>Dostava subotom</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowSaturdayPickup" valuePropName="checked">
                        <Checkbox>Prikup subotom</Checkbox>
                    </Form.Item>

                    <Form.Item name="allowNotifySenderSms" valuePropName="checked">
                        <Checkbox>Obavijest pošiljatelju - SMS</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNotifySenderEmail" valuePropName="checked">
                        <Checkbox>Obavijest pošiljatelju - Email</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNotifyRecipientSms" valuePropName="checked">
                        <Checkbox>Obavijest primatelju - SMS</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNotifyRecipientEmail" valuePropName="checked">
                        <Checkbox>Obavijest primatelju - Email</Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8} justify="center">
                <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
                <Col span={4}><Button disabled={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
            </Row>
        </Form>
    )
}

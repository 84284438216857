import { useMemo, useState } from 'react';
import { Pagination, Button, PageHeader, message, Space } from 'antd';
import { FilterForm, ShipmentFilter, applyShipmentFilter } from "Components/Shipment/FilterForm"
import { useDataSource, useStateManager, applyGridState } from "net-common-ui";
import Bux from "Components/Bux";
import { useRouteMatch, Link, useHistory, useLocation } from 'react-router-dom';
import { PlusOutlined, ArrowRightOutlined, BarcodeOutlined, SendOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useCustomer } from 'Views/MeContext';
import downloadStream from 'downloadStream';
import { kApi } from 'Api';
import withFilterDrawer from 'Components/withFilterDrawer';
import { ShipmentTable } from 'Components/Shipment/Table';
import { dsoShipment } from 'Models/Logi/Shipment';
import { GridState } from 'net-common-ui/dist/Data/GridState';
import { usePreset } from 'Components/usePreset';

interface FilterPreset<TFilter> {
    key: string;
    title: string;
    icon?: React.ReactNode;
    filter: TFilter;
}

const presets: FilterPreset<ShipmentFilter>[] = [
    { key: "unsent", title: "U pripremi", filter: { statusCodes: ["CRE"] } },
    { key: "cod", title: "Otkupne", filter: { useCod: true } }
];

const FilterDrawer = withFilterDrawer(FilterForm);

const getEmptyState = (presetValue: ShipmentFilter): GridState<ShipmentFilter> => {
    return {
        sort: { field: "id", dir: "desc" },
        filter: presetValue
    };
}

export const Grid = () => {
    const preset = usePreset();
    const presetValue = presets.find(p => p.key === preset)?.filter;
    return <GridInternal key={preset || "none"} preset={preset} presetValue={presetValue} />;
}

const GridInternal = ({ preset, presetValue }: { preset: string, presetValue: ShipmentFilter }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const customer = useCustomer();

    const [view, ds] = useDataSource(dsoShipment());//, { execute: !preset });
    const [state, sm] = useStateManager<GridState<ShipmentFilter>>(getEmptyState(presetValue), {
        storeKey: location.pathname + "?preset=" + (preset || "none"),
        loadTransform: state => {
            if (state) {
                // ovo osigurava da preset filter uvijek bude loadan
                if (!state.filter) state.filter = presetValue;
                else Object.assign(state.filter, presetValue);
                return state;
            }
        }
        // withFilterDrawer is buggy when combined with state manager. it needs a rewrite, but its a low prio
        //autoLoad: false,
        //autoSave: false
    });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) => {
            applyShipmentFilter(fb, filter);
            fb.set("isDeleted", "eq", false); // hardcoded: never show deleted shipments to customers
        });
    });

    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [downloading, setDownloading] = useState(false);

    const sendableSelectedIds = useMemo(() => selectedIds
        .map(id => ds.queryable.find(x => x.id === id))
        .filter(s => s && !s.shipmentGroup && !s.isDeleted && !s.isCancelled)
        .map(s => s.id), [ds, selectedIds]);

    const handleFilterChange = (filter: ShipmentFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" }
        );
    };

    const handleGenerateLabels = async () => {
        if (!downloading) {
            const url = `shipment/label?format=${customer.labelFormatId}&ids=${selectedIds.join(',')}`;
            setDownloading(true);
            try {
                const result = await downloadStream(kApi, { url, fileName: "shipment_labels.pdf" });
                result.success && result.download();
            } catch {
                message.error("Greška prilikom izrade adresnica.");
            }
            setDownloading(false);
        }
    };

    const handleSend = () => {
        history.push("/pub/shipment-group/new?ids=" + sendableSelectedIds.join(','))
    }

    const handleDownloadXls = async () => {
        // filename is igonred if content disposition is successfully sniffed
        const result = await downloadStream(kApi, {
            url: "shipment;xls",
            sniffContentDisposition: true,
            fileName: "shipments.xlsx",
            params: { filter: ds.builder.filter.serialize() }
        });
        result.success && result.download();
    }


    const actions = customer.customerServiceTypes.map(cst => (
        <Button
            type="primary"
            key={cst.serviceType.id}
            icon={<PlusOutlined />}
            onClick={() => {
                history.push(match.url + "/new?serviceTypeId=" + cst.serviceType.id)
            }}
        >
            {cst.serviceType.name}
        </Button>));

    actions.push(<Link key="send" to="/pub/shipment-group/new">Slanje <ArrowRightOutlined /></Link>)

    return <Bux.Page key={preset}>
        <Bux.Header>
            <PageHeader
                title={"Pošiljke"}
                extra={actions}
            />
        </Bux.Header>
        <Bux.SubHeader>
            <Space>
                <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} initialFilter={presetValue} />
                <Button disabled={!view.total} onClick={handleDownloadXls} icon={<FileExcelOutlined />}>
                    Izvoz
                </Button>
                <Button disabled={!selectedIds.length} loading={downloading} onClick={handleGenerateLabels} icon={<BarcodeOutlined />}>
                    Adresnice ({selectedIds.length})
                </Button>
                <Button disabled={!sendableSelectedIds.length} onClick={handleSend} icon={<SendOutlined />}>
                    Pošalji ({sendableSelectedIds.length})
                </Button>
            </Space>
        </Bux.SubHeader>
        <Bux.Content>
            <ShipmentTable dataView={view} onSelectionChange={(selectedRowKeys: number[]) => setSelectedIds(selectedRowKeys)} />
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

import queryString from "query-string";
import { Redirect } from "react-router";

export const FwLink = () => {
    const qs = queryString.parse(window.location.search);

    // example: /fwlink?invoice=4
    if (qs.invoice) {
        return <Redirect to={`/pub/transaction/invoice/${qs.invoice}`} />;
    }
    if (qs.codtransfer) {
        return <Redirect to={`/pub/transaction/cod-transfer/${qs.codtransfer}`} />;
    }

    // default
    return <Redirect to="/" />;
}

import { PageHeader } from 'antd';
import { Bux } from 'Components/Bux';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EditShipmentContext } from './Context';
import { Edit } from "./Edit"
import { View } from "./View"

export const Root = () => {
    const { state } = React.useContext(EditShipmentContext);
    const { model, loading, error, saved, editable } = state;

    // ne smijemo renderat formu dok nema modela, inace initial values za form su prazni
    if (error || loading) {
        return (<Bux.Page>
            <Bux.Header>
                <PageHeader title="Pošiljka" />
            </Bux.Header>
            {loading
                ? <Bux.Content>
                    Učitavanje podataka u tijeku...
                </Bux.Content>
                : <Bux.Content>
                    <p>{error}</p>
                    <p><Link to="/pub/shipment">Povratak na listu pošiljaka</Link></p>
                </Bux.Content>}
        </Bux.Page>);
    }
    if (saved) {
        return null;
    }
    if (!loading && !model) {
        return <Redirect to="/pub/shipment" />;
    }

    return editable ? <Edit /> : <View />;
};

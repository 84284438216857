import Bux from "Components/Bux";
import { Alert, Col, PageHeader, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
const { Paragraph, Title } = Typography;

export const Help = () => <Bux.Page>
    <Bux.Header>
        <PageHeader title="Upravljanje otkupninama" />
    </Bux.Header>
    <Bux.Content>
        <Row justify="center">
            <Col span={24}>
                <Title level={3}>Pošiljke</Title>
                <Paragraph>
                    S otkupninama se upravlja samo s pošiljkama koje:
                    <ul>
                        <li>Odabrana dodatna usluga "Otkupna pošiljka"</li>
                        <li>Upisana otkupnina veća od 0,00 EUR</li>
                        <li>Zahtjev za preuzimanjem poslan</li>
                        <li>Nije obrisana ili stornirana</li>
                    </ul>
                </Paragraph>

                <Title level={3}>Uparivanje</Title>
                <Paragraph>
                    Pošiljka koja je poslana od tog trenutka postaje validna za uprarivanje otkupnog iznosa. Uparivanje se može
                    raditi ručno kroz administracijsko uređivanje pošiljke ili uvozom izvještaja kurira (preporuka):
                    <ul>
                        <li><Link to="/admin/cod/carrier-transfer-import">Sučelje za uvoz i obradu naloga kurira</Link></li>
                        <li><Link to="/admin/shipment">Sučelje za pojedinačno ažuriranje pošiljaka</Link></li>
                    </ul>
                </Paragraph>
                <Paragraph>
                    <Alert type="warning" showIcon message="Ako se pošiljka upari sa otkupnim podacima, automatski se prebacuje u status Uručeno primatelju!" />
                </Paragraph>
                <Paragraph>
                    Kada se pošiljke upare, dodatni atributi se popune za te pošiljke. Na ovaj način se prate sljedeći atributi:
                    <ul>
                        <li>Tražena otkupnina - vrijednost koju je korisnik upisao tokom kreiranja pošiljke</li>
                        <li>Uplaćena otkupnina - vrijednost koju je kurir uplatio za pošiljku</li>
                        <li>Vrijeme uplate i transfera</li>
                    </ul>
                    Obje vrijednosti je moguće ažurirati (i putem uvoza i ručno na pojedinoj pošiljci).
                </Paragraph>

                <Title level={3}>Isplata</Title>
                <Paragraph>
                    <Alert type="info" showIcon message="Samo pošiljke gdje su oba iznosa jednaka mogu ići dalje u procesu - na isplatu korisnicima." />
                </Paragraph>
                <Paragraph>
                    Isplata se radi za sve pošiljke koje su validne za isplatu, i do sada nisu isplaćene, bez obzira na vrijeme. Isplata se može raditi bilo
                    koji dan, ali se preporuča da se radi maksimalno jednom dnevno, kako datumi na potvrdama ne bi bili zbunjujući (više potvrda sa istim datumom).
                    <ul>
                        <li><Link to="/admin/cod/transfer-group/build">Sučelje za izradu naloga za isplatu korisnicima</Link></li>
                    </ul>
                </Paragraph>
                <Paragraph>
                    Kreiranje naloga za isplatu se radi kroz proces od 2 koraka:
                    <ol>
                        <li>Pregled validnih pošiljaka i grupa</li>
                        <li>Kreiranje grupe naloga i pojedinih naloga</li>
                    </ol>
                    Ukoliko na prvom koraku sustav zaključi da niti jedna pošiljka nije spremna za isplatu, drugi korak neće biti moguće započeti.
                </Paragraph>
                <Paragraph>
                    Kada se započne proces izrade nove grupe naloga, automatski se uzimaju sve validne pošiljke, grupiraju po korisnicima - i za svakog korisnika
                    se radi nalog za isplatu sa validnim pošiljkama tog korisnika. Rezultat ovog procesa je uvijek isti:
                    <ul>
                        <li>Jedna grupa naloga</li>
                        <li>X naloga za isplatu, gdje je X broj korisnika sa validnim pošiljkama</li>
                    </ul>
                </Paragraph>
                <Paragraph>
                    Grupa naloga je entitet namijenjen administratorima kako bi lakše grupirali naloge za isplatu. Nalozi kreirani tokom jednog postupka izrade će uvijek
                    biti unutar iste grupe. Korisnicima su dostupni na uvid njihovi nalozi, ali nikad i grupa (jer grupa sadrži više korisnika).
                </Paragraph>
                <Paragraph>
                    <Alert type="warning" showIcon message="Kada se napravi nalog za isplatu, podatke o otkupnini više nije moguće ažurirati!" />
                </Paragraph>
            </Col>
        </Row>
    </Bux.Content>
</Bux.Page>

import { useEffect, useState } from 'react';
import Bux from "Components/Bux";
import { Button, Col, Descriptions, PageHeader, Row, Skeleton, Table, Typography } from 'antd';
import { Link, Redirect, useParams } from 'react-router-dom';
import { CodTransfer, getCodTransferBarcodeImageSrc, getCodTransferInfoById } from 'Models/Bus/CodTransfer';
import { CurrencyText } from 'Components/Text';
import { DateDisplay } from "net-common-ui";
import { Shipment } from 'Models/Logi/Shipment';
import { kApi } from 'Api';
import { DownloadOutlined } from "@ant-design/icons";
import downloadStream from 'downloadStream';
import { MailDisplay } from 'Components/MailDisplay';
import { Ok, Error } from 'Components/StatusIcon';
const { Title } = Typography;

export const Profile = () => {
    const params = useParams<{ id: string }>();
    const [codTransfer, setCodTransfer] = useState<CodTransfer>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCodTransferInfoById(params.id, true).execute().then(result => {
            setCodTransfer(result.item);
            setLoading(false);
        });
    }, [params.id]);

    if (!loading && !codTransfer) {
        return <Redirect to="/admin/cod/transfer" />;
    }

    const handleDownloadPdf = async () => {
        let url = `/cod-transfer/${codTransfer.id}/pdf`;
        // filename is igonred if content disposition is successfully sniffed
        const result = await downloadStream(kApi, { url, sniffContentDisposition: true, fileName: "transfer.pdf" });
        result.success && result.download();
    }

    const customer = codTransfer?.customer;
    const codTransferGroup = codTransfer?.codTransferGroup;

    return <Bux.Page>
        <Bux.Header>
            <PageHeader title="Nalog za isplatu" />
        </Bux.Header>
        {loading
            ? <Bux.Content>
                <Skeleton active />
            </Bux.Content>
            : <Bux.Content>
                <Row gutter={8}>
                    <Col span={12}>
                        <Descriptions bordered column={1} size="small" title="Nalog">
                            <Descriptions.Item label="Šifra">{codTransfer.code}</Descriptions.Item>
                            <Descriptions.Item label="Iznos"><CurrencyText value={codTransfer.amount} showSymbol style={{ fontWeight: "bold" }} /></Descriptions.Item>
                            <Descriptions.Item label="Poziv na broj">{codTransfer.clearanceNumber}</Descriptions.Item>
                            <Descriptions.Item label="IBAN za isplatu">{codTransfer.iban}</Descriptions.Item>
                            <Descriptions.Item label="Pošiljaka">{codTransfer.shipments.length}</Descriptions.Item>
                            <Descriptions.Item label="Obavijest">
                                {codTransfer.createMailId
                                    ? <><Ok /> Obavijest poslana <MailDisplay mailId={codTransfer.createMailId}>(Prikaži detalje)</MailDisplay></>
                                    : <Error extra="Obavijest nije poslana." />}
                            </Descriptions.Item>
                            <Descriptions.Item label="Barkod">
                                {codTransfer.barcodeImage
                                    ? <img src={getCodTransferBarcodeImageSrc(codTransfer)} alt="Barcode" />
                                    : <span>Nedostupno</span>}
                            </Descriptions.Item>
                            <Descriptions.Item label="Pdf Nalog">
                                <Button type="primary" onClick={handleDownloadPdf} icon={<DownloadOutlined />}>Preuzmi</Button>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions bordered column={1} size="small" title="Grupa naloga">
                            <Descriptions.Item label="Šifra"><Link to={`/admin/cod/transfer-group/${codTransferGroup.id}`}>{codTransferGroup.code}</Link></Descriptions.Item>
                            <Descriptions.Item label="Razdoblje">
                                <DateDisplay value={codTransferGroup.rangeFrom} />
                                &nbsp;-&nbsp;
                                <DateDisplay value={codTransferGroup.rangeTo} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Vrijeme"><DateDisplay value={codTransferGroup.createdOn} showTime /></Descriptions.Item>
                            <Descriptions.Item label="Kreirao">{codTransferGroup.createdBy.name}</Descriptions.Item>
                        </Descriptions>
                        <Descriptions bordered column={1} size="small" title="Korisnik">
                            <Descriptions.Item label="Šifra"><Link to={`/admin/customer/${customer.id}`}>{customer.code}</Link></Descriptions.Item>
                            <Descriptions.Item label="Naziv">{customer.name}</Descriptions.Item>
                            <Descriptions.Item label="IBAN">{codTransfer.iban ?? customer.iban}</Descriptions.Item>
                            <Descriptions.Item label="Ulica">{customer.street} {customer.streetNo}</Descriptions.Item>
                            <Descriptions.Item label="Mjesto">{customer.settlement.postOffice.code} {customer.settlement.name}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Title level={3}>Pošiljke</Title>
                        <Table<Shipment>
                            size="small"
                            dataSource={codTransfer.shipments}
                            pagination={false}
                            //showHeader={false}
                            rowKey="id">
                            <Table.Column title="Pošiljka" dataIndex="code" width={80} />
                            <Table.Column title="Barkod" dataIndex="barcode" width={120} />
                            <Table.Column title="Usluga" dataIndex={["serviceType", "name"]} ellipsis />
                            <Table.Column title="Paketa" dataIndex="packageCount" ellipsis />
                            <Table.Column title="Prim. platio" dataIndex={["shipmentCod", "carrierPaidOn"]} ellipsis render={value => <DateDisplay value={value} />} />
                            <Table.Column title="Kurir platio" dataIndex={["shipmentCod", "carrierTransferOn"]} ellipsis render={value => <DateDisplay value={value} />} />
                            <Table.Column title="Sravnjeno" dataIndex={["shipmentCod", "carrierTransferOn"]} ellipsis render={value => <DateDisplay value={value} />} />
                            <Table.Column title="Iznos" dataIndex={["shipmentCod", "customerAmount"]} width={90} render={value => <CurrencyText value={value} showSymbol />} />
                        </Table>
                    </Col>
                </Row>
            </Bux.Content>}
    </Bux.Page>;
}

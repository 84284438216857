import { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { CustomerPriceList, publishCustomerPriceList } from 'Models/Bus/PriceList/CustomerPriceList';
import dayjs from 'dayjs';
import { DatePickerEx } from "Components/DatePickerEx";

interface Props {
    customerPriceList: CustomerPriceList;
    onClose: (saved?: boolean) => void;
    disabled?: boolean;
}

export const PublishButton = ({ customerPriceList, disabled, onClose }: Props) => {
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [validFrom, setValidFrom] = useState(dayjs());

    const handleSave = () => {
        setSaving(true);
        publishCustomerPriceList(customerPriceList.id, true, validFrom).execute().then(result => {
            setSaving(false);
            if (result.success) {
                message.success("Cjenik uspješno objavljen.")
                setVisible(false);
                onClose(true);
            }
        });
    }

    const handleClose = () => {
        setVisible(false);
        onClose(false);
    }

    return (<>
        <Button block type="primary" onClick={() => setVisible(true)} disabled={disabled || saving} icon={<CheckOutlined />}>Objavi</Button>

        <Modal
            title="Objava cjenika"
            visible={visible}
            onOk={handleSave}
            okText="Objavi"
            confirmLoading={saving}
            destroyOnClose
            width={500}
            onCancel={handleClose}
        >
            <p>
                Objavi cjenik #{customerPriceList.ordinal}? Nakon što se objavi, cjenik nije moguće naknadno uređivati.
            </p>
            <div>
                Datum sa kojim cjenik postaje aktivan:
                <br />
                <DatePickerEx format="L" value={validFrom} onChange={value => setValidFrom(value)} disabledDate={date => date.isBefore(dayjs(), "day")} />
            </div>
        </Modal>
    </>);
}

import React, { useEffect, useState } from 'react';
import { Button, Skeleton, Collapse, Space, Tag, message, Alert } from 'antd';
import { Bux } from 'Components/Bux';
import { ProfileContext } from '../Context';
import { CustomerNotifConfig, getCustomerNotifConfigByCustomer, saveCustomerNotifConfig, saveCustomerNotifConfigEnabled } from 'Models/Bus/CustomerNotifConfig';
import { getTitle } from "./util";
import { MessageEdit } from './MessageEdit';
import { MessageView } from './MessageView';
import { NotifChannel } from 'Models/Enum';
import { CheckCircleOutlined, CloseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { MessageTest } from './MessageTest';

const { Panel } = Collapse;

export const NotifConfigTab = () => {
    const { state, dispatch } = React.useContext(ProfileContext);
    const { notifConfig, model } = state;
    const [loading, setLoading] = useState(false);
    const [testModel, setTestModel] = useState<CustomerNotifConfig>(null);

    const customerId = model.id;
    const smsEnabled = model.customerServiceTypes && model.customerServiceTypes.findIndex(st => st.allowNotifySenderSms || st.allowNotifyRecipientSms) >= 0;
    const emailEnabled = model.customerServiceTypes && model.customerServiceTypes.findIndex(st => st.allowNotifySenderEmail || st.allowNotifyRecipientEmail) >= 0;

    useEffect(() => {
        if (!notifConfig && !loading) {
            setLoading(true);
            getCustomerNotifConfigByCustomer(customerId).execute().then(result => {
                dispatch({ type: "NOTIF_LOADED", payload: result.data });
                setLoading(false);
            });
        }
    }, [setLoading, dispatch, loading, customerId, notifConfig]);



    const handleToggle = async (nc: CustomerNotifConfig, isEnabled: boolean) => {
        let model: CustomerNotifConfig = {
            ...nc,
            customerId,
            isEnabled
        };

        if (nc.id) {
            // if we have id, we can only patch it
            await saveCustomerNotifConfigEnabled(nc.id, isEnabled).execute();
        }
        else {
            // dont have id, must create
            const saveResult = await saveCustomerNotifConfig(model).execute();
            model.id = saveResult.item.id;
        }

        message.success("Obavijesti uspješno " + (isEnabled ? "aktivirane." : "deaktivirane."));
        dispatch({ type: "NOTIF_SAVED", payload: model });
    }

    const handleContentUpdate = (nc: CustomerNotifConfig) => {
        dispatch({ type: "NOTIF_SAVED", payload: nc });
    }

    const handleResetDefaults = (nc: CustomerNotifConfig) => {
        const newModel: CustomerNotifConfig = { ...nc, message: null };
        saveCustomerNotifConfig(newModel).execute().then(result => {
            setLoading(false);
            if (result.success) {
                message.success("Sadržaj obavijesti uspješno vraćen na zadane vrijednosti.");
                dispatch({ type: "NOTIF_SAVED", payload: newModel });
            }
        });
    }

    const handleTest = (value: CustomerNotifConfig) => {
        // nova instanca forsira otvaranje drawera.. da samo prosljedimo value, drugi klik na isti botun ne bi radio nista
        setTestModel(value);
    }

    return (<>
        {/* <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button disabled={editing} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader> */}
        <Bux.Content>
            <MessageTest model={testModel} />

            {loading || !notifConfig
                ? <Skeleton active />
                : <Collapse defaultActiveKey={['1']}>
                    {notifConfig.map(nc => <Panel
                        key={nc.key}
                        header={getTitle(nc)}
                        extra={<Tag color={nc.isEnabled ? "green" : "volcano"}>{nc.isEnabled ? "Aktivno" : "Neaktivno"}</Tag>}
                    >
                        <Space direction="vertical">
                            {nc.notifChannelId === NotifChannel.Email && !emailEnabled &&
                                <Alert showIcon type="warning" message="Niti jedna usluga nema omogućenu aktivaciju email obavijesti!" />}
                            {nc.notifChannelId === NotifChannel.Sms && !smsEnabled &&
                                <Alert showIcon type="warning" message="Niti jedna usluga nema omogućenu aktivaciju SMS obavijesti!" />}

                            <MessageView value={nc} />

                            <Space direction="horizontal">
                                {nc.isEnabled
                                    ? <Button type="ghost" danger onClick={() => handleToggle(nc, false)} icon={<CloseCircleOutlined />}>Deaktiviraj</Button>
                                    : <Button type="primary" onClick={() => handleToggle(nc, true)} icon={<CheckCircleOutlined />}>Aktiviraj</Button>}
                                <MessageEdit value={nc} onUpdate={handleContentUpdate} />
                                {nc.message && <Button type="ghost" danger onClick={() => handleResetDefaults(nc)}>Koristi zadani sadržaj</Button>}
                                <Button style={{ float: "left" }} icon={<PlayCircleOutlined />} onClick={() => handleTest(nc)}>Test</Button>,
                            </Space>
                        </Space>
                    </Panel>)}
                </Collapse>
            }
        </Bux.Content>
    </>);
}

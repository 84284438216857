import { Form, Row, Col, Checkbox, Typography, Divider, Radio } from 'antd';
import { CustomerServiceType } from 'Models/Bus/CustomerServiceType';
import { DeliveryDeadline, deliveryDeadlineLabel } from 'Models/Enum';
import { CodTypeSelect } from 'Components/CodTypeSelect';

interface Props {
    customerServiceType: CustomerServiceType;
}

export const View = ({ customerServiceType }: Props) => {
    return (
        <Form layout="vertical" name="form" initialValues={customerServiceType}>
            <Row gutter={[8, 40]} justify="center">
                <Col span={8}>
                    <Typography.Title level={3}>Postavke usluge</Typography.Title>
                    <Form.Item name="isActive" valuePropName="checked">
                        <Checkbox disabled>Usluga omogućena</Checkbox>
                    </Form.Item>
                    <Divider />
                    <Form.Item name="allowMultiPackage" valuePropName="checked">
                        <Checkbox disabled>Omogući skupne pošiljke</Checkbox>
                    </Form.Item>
                    <Divider />
                    <Form.Item name="minDeliveryDeadlineId" label="Minimalni rok uručenja">
                        <Radio.Group className="radio-group-vertical" disabled>
                            <Radio value={DeliveryDeadline.Unknown}>Neograničeno</Radio>
                            <Radio value={DeliveryDeadline.D1}>{deliveryDeadlineLabel(DeliveryDeadline.D1)}</Radio>
                            <Radio value={DeliveryDeadline.D2}>{deliveryDeadlineLabel(DeliveryDeadline.D2)}</Radio>
                            <Radio value={DeliveryDeadline.D3}>{deliveryDeadlineLabel(DeliveryDeadline.D3)}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                <Col span={8} offset={1}>
                    <Typography.Title level={3}>Dodatne usluge</Typography.Title>
                    <Form.Item name="allowDeliveryBy9" valuePropName="checked">
                        <Checkbox disabled>Dostava do 9h</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowDeliveryBy11" valuePropName="checked">
                        <Checkbox disabled>Dostava do 11h</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowCod" valuePropName="checked">
                        <Checkbox disabled>Otkupnina</Checkbox>
                    </Form.Item>
                    <Form.Item name="codTypeId" style={{ marginLeft: 24 }}>
                        <CodTypeSelect mode="multiple" disabled />
                    </Form.Item>
                    <Form.Item name="allowReturnSignedDoc" valuePropName="checked">
                        <Checkbox disabled>Povrat potpisanog dokumenta</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowPersonalDelivery" valuePropName="checked">
                        <Checkbox disabled>Uručiti osobno</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowPovratnica" valuePropName="checked">
                        <Checkbox disabled>Povratnica</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNonstandardFormat" valuePropName="checked">
                        <Checkbox disabled>Nestandardni format</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowSaturdayDelivery" valuePropName="checked">
                        <Checkbox disabled>Dostava subotom</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowSaturdayPickup" valuePropName="checked">
                        <Checkbox disabled>Prikup subotom</Checkbox>
                    </Form.Item>

                    <Form.Item name="allowNotifySenderSms" valuePropName="checked">
                        <Checkbox disabled>Obavijest pošiljatelju - SMS</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNotifySenderEmail" valuePropName="checked">
                        <Checkbox disabled>Obavijest pošiljatelju - Email</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNotifyRecipientSms" valuePropName="checked">
                        <Checkbox disabled>Obavijest primatelju - SMS</Checkbox>
                    </Form.Item>
                    <Form.Item name="allowNotifyRecipientEmail" valuePropName="checked">
                        <Checkbox disabled>Obavijest primatelju - Email</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

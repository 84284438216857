import React, { useEffect, useState } from 'react';
import { Bux } from 'Components/Bux';
import { ProfileContext } from '../Context';
import { SummaryList, CustomerSummary, PriceListSummary } from "Components/Summary";
import { Button, Col, message, Row } from 'antd';
import { useAppAccount, useMeLoader } from 'Views/MeContext';
import { useHistory } from 'react-router-dom';
import { ExperimentOutlined } from '@ant-design/icons';
import { getCustomerPriceListInfoById } from 'Models/Bus/PriceList/CustomerPriceList';
import { PriceList } from 'Models/Bus/PriceList/PriceList';
import { combinePriceList } from 'Models/Bus/PriceList/PriceList';

export const SummaryTab = () => {
    const { state } = React.useContext(ProfileContext);
    const { model, serviceTypes } = state;

    const history = useHistory();
    const meLoader = useMeLoader();

    const [priceList, setPriceList] = useState<PriceList>(null);

    useEffect(() => {
        if (model.activeCustomerPriceListId) {
            getCustomerPriceListInfoById(model.activeCustomerPriceListId).execute().then(result => {
                setPriceList(result.success ? combinePriceList(result.item.priceList, result.item.standardPriceList.priceList) : null);
            });
        }
        else {
            setPriceList(null);
        }
    }, [model.activeCustomerPriceListId])


    // current user
    const appAccount = useAppAccount();
    // is current user already inspecting this customer
    const isInpecting = appAccount.inspectCustomerId === model.id;

    const handleInspect = async () => {
        // axios interceptor will append to haders, see Api.ts
        sessionStorage.setItem("CustomerId", String(model.id));
        meLoader();
        history.push("/");
        message.success("Kontekst postavljen na korisnika: " + model.name);
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button disabled={isInpecting} type="primary" danger onClick={handleInspect} block icon={<ExperimentOutlined />}>Ulaz</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            <Row gutter={16}>
                <Col span={12}>
                    <CustomerSummary customer={model} serviceTypes={serviceTypes} />
                </Col>
                <Col span={12}>
                    {priceList
                        ? <PriceListSummary priceList={priceList} serviceTypes={serviceTypes} />
                        : <SummaryList title="Stanje cjenika" items={[{
                            level: "error",
                            text: "Korisnik ima aktivan cjenik",
                            hint: "Ako korisnik nema aktivan cjenik, fakture neće biti izrađene."
                        }]} />}
                </Col>
            </Row>
        </Bux.Content>
    </>);
}

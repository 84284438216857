import { Pagination, Table, PageHeader, Tag } from 'antd';
import { FilterForm, AppAccountFilter } from "./FilterForm"
import { applyGridState, useDataSource, useStateManager } from "net-common-ui";
import Bux from "Components/Bux";
import { Link } from 'react-router-dom';
import { AppAccount, dsoAppAccount } from 'Models/Bus/AppAccount';
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import withFilterDrawer from 'Components/withFilterDrawer';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const [view, ds] = useDataSource(dsoAppAccount());
    const [state, sm] = useStateManager<GridState<AppAccountFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb
                .set("identifier", "eq", filter.identifier)
                .set("email", "contains", filter.email)
                .set("fullName", "contains", filter.fullName)
                .set("isAdmin", "eq", filter.isAdmin)
        );
    });

    const handleFilterChange = (filter: AppAccountFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    return <Bux.Page>
        <Bux.Header>
            <PageHeader title={"Korisnički računi"} />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<AppAccount>
                dataSource={view.data}
                pagination={false}
                rowKey="id">
                {/* <Table.Column width={280} dataIndex="identifier" title="Identifikator" render={(value, record: AppAccount) => (
                        <Link to={"TODO dialog/" + record.id}>{value}</Link>
                    )} /> */}
                <Table.Column dataIndex="identifier" title="Identifikator" ellipsis />
                <Table.Column dataIndex="email" title="Email" ellipsis />
                <Table.Column dataIndex="fullName" title="Ime i prezime" ellipsis />
                <Table.Column dataIndex="isAccountEnabled" title="Aktivan" width={70} render={value => value ? <CheckOutlined /> : <CloseOutlined />} />
                <Table.Column dataIndex="isAdmin" title="Vrsta" width={100} render={(value, record: AppAccount) => value
                    ? <Tag color="volcano">Admin</Tag>
                    : <Tag><Link to={`/admin/customer/` + record.customerId}>Korisnik</Link></Tag>} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

import { Col, Descriptions, Empty, Row, Typography } from 'antd';
import { ShipmentCod } from 'Models/Logi/ShipmentCod';
import { CurrencyText } from 'Components/Text';
import { DateDisplay } from "net-common-ui";
import { CodType, labelCodType } from 'Models/Enum';
const { Title } = Typography;

interface Props {
    useCod: boolean;
    codTypeId: CodType;
    shipmentCod: ShipmentCod;
}

export const CodView = ({ useCod, codTypeId, shipmentCod }: Props) => {

    if (!useCod || codTypeId === CodType.None) {
        return (
            <Empty
                style={{ marginTop: 20 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Pošiljka nema otkupninu." />
        );
    }
    else {
        return (
            <Row gutter={8} justify="center">
                <Col span={12}>
                    <Title level={3}>Uplate/isplate</Title>
                    <Descriptions bordered size="small" column={1}>
                        <Descriptions.Item label="Vrsta">{labelCodType(codTypeId)}</Descriptions.Item>
                        <Descriptions.Item label="Traženi iznos"><CurrencyText value={shipmentCod.customerAmount} showSymbol /></Descriptions.Item>
                        <Descriptions.Item label="Uplaćeni iznos"><CurrencyText value={shipmentCod.carrierAmount} showSymbol /></Descriptions.Item>
                        <Descriptions.Item label="Vrijeme uplate (primamatelj - kurir)"><DateDisplay value={shipmentCod.carrierPaidOn} /></Descriptions.Item>
                        <Descriptions.Item label="Šifra prijenosa (kurir - Logex)">{shipmentCod.carrierTransferCode}</Descriptions.Item>
                        <Descriptions.Item label="Vrijeme prijenosa (kurir - Logex)"><DateDisplay value={shipmentCod.carrierTransferOn} /></Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={12}>
                    <Title level={3}>Status</Title>
                    <Descriptions bordered size="small" column={1}>
                        <Descriptions.Item label="Isplaćeno korisniku">{shipmentCod.codTransfer ? "Da" : "Ne"}</Descriptions.Item>
                        <Descriptions.Item label="Moguća isplata">{shipmentCod.carrierAmount === shipmentCod.customerAmount ? "Da" : "Ne"}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>);
    }
}

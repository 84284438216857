import { Form, Button } from 'antd';
import { Dayjs } from 'dayjs';
import { FilterFormProps, DateRanges } from "../types";
import { FileExcelOutlined } from "@ant-design/icons";
import { DatePickerEx } from "Components/DatePickerEx";

interface FormModel {
    dateRange?: [Dayjs, Dayjs];
}

export function FilterForm({ code, onSubmit, running }: FilterFormProps) {
    const [form] = Form.useForm();
    const transform = () => {
        form.validateFields().then((filter: FormModel) => {
            onSubmit("xlsx", {
                from: filter.dateRange[0].format("YYYY-MM-DD"),
                to: filter.dateRange[1].add(1, "day").format("YYYY-MM-DD")
            });
        });
    }

    return <Form form={form} layout="vertical" name={"filter-" + code} onFinish={transform} initialValues={{}}>
        <Form.Item>
            Izvještaj uključuje pošiljke koje su:
            <ul>
                <li>otkupne</li>
                <li>uručene primatelju</li>
                <li>nisu obrisane/stornirane</li>
                <li>nemaju unesen iznos otkupnine (kurir-logex) ili iznos nije jednak traženom iznosu</li>
            </ul>
        </Form.Item>
        <Form.Item name="dateRange" label="Vrijeme preuzimanja pošiljke" rules={[{ required: true }]}>
            <DatePickerEx.RangePicker ranges={DateRanges} format="L" />
        </Form.Item>
        <Form.Item>
            <Button type="primary" ghost disabled={running} onClick={() => form.resetFields()}>Očisti</Button>
            &nbsp;
            <Button htmlType="submit" type="primary" loading={running} icon={<FileExcelOutlined />}>Generiraj izvještaj</Button>
        </Form.Item>
    </Form>
}

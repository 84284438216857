import React from 'react';
import { Col, Descriptions, Row, Typography } from 'antd';
import { ProfileContext } from '../Context';
import { getStatusTag } from '../../../Common/Badges';
import { PriceListSummary } from "Components/Summary";
import { DateDisplay } from "net-common-ui";

export const View = () => {
    const { state } = React.useContext(ProfileContext);
    const { model, serviceTypes } = state;

    return (
        <Row gutter={16}>
            <Col span={12}>
                <Descriptions bordered size="small" column={1} title={<Typography.Title level={3}>Osnovo</Typography.Title>}>
                    <Descriptions.Item label="Broj">{model.ordinal}</Descriptions.Item>
                    <Descriptions.Item label="Status">{getStatusTag(model)}</Descriptions.Item>

                    <Descriptions.Item label="Objavljen">{model.isPublished ? "Da" : "Ne"}</Descriptions.Item>
                    <Descriptions.Item label="Vrijedi od"><DateDisplay value={model.validFrom} /></Descriptions.Item>
                    <Descriptions.Item label="Vrijedi do"><DateDisplay value={model.validTo} /></Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={12}>
                <PriceListSummary priceList={model.priceList} serviceTypes={serviceTypes} />
            </Col>
        </Row>

    )
}

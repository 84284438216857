import { kApi } from "Api";
import { ApiCall } from "net-common-ui";

export interface AppInfo {
    appVersion?: string;
    dbRevision?: string;
}

export function getAppInfo(): ApiCall<AppInfo> {
    return ApiCall.get(kApi, "system/app-info");
}

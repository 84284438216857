import React from "react";
import Bux from "Components/Bux";
import { useRouteMatch, Switch, Route, useLocation, Link, useHistory, Redirect } from "react-router-dom";
import { Descriptions, Menu, message, Popover, Tooltip } from "antd";
import { useAuth } from "net-common-ui";
import {
    DropboxOutlined,
    PoweroffOutlined,
    SendOutlined,
    PlusOutlined,
    BarsOutlined,
    ExperimentOutlined,
    EuroOutlined,
    AuditOutlined,
    QuestionCircleOutlined,
    FilePdfOutlined,
    NotificationOutlined,
    DatabaseOutlined,
    DashboardOutlined
} from "@ant-design/icons";
import { Dash } from "./Dash";
import { Shipment } from "./Shipment";
import { Transaction } from "./Transaction";
import { ShipmentGroup } from "./ShipmentGroup";
import { ShipmentNotifLog } from "./ShipmentNotifLog";
import { AddressBook } from "./AddressBook";
import { Help } from "./Help";
import { useCustomer, useAppAccount, useMeLoader } from "Views/MeContext";
import Config from "Config";

interface MenuItemEntry {
    label: string;
    route: string;
    icon: React.ReactNode;
    items?: MenuItemEntry[];
}

export const PubRoot = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const auth = useAuth();
    const meLoader = useMeLoader();
    const customer = useCustomer();
    const appAccount = useAppAccount();

    const menuItems: MenuItemEntry[] = [
        { label: "Dashboard", route: "/dash", icon: <DashboardOutlined /> },
        {
            label: "Pošiljke", route: "/shipment", icon: <DropboxOutlined />, items: [
                ...customer.customerServiceTypes.map(cst => ({
                    label: cst.serviceType.name,
                    route: "/shipment/new?serviceTypeId=" + cst.serviceType.id,
                    icon: <PlusOutlined />
                })),
                { label: "U pripremi", route: "/shipment?preset=unsent", icon: <BarsOutlined /> },
                { label: "S otkupninom", route: "/shipment?preset=cod", icon: <BarsOutlined /> },
                { label: "Sve", route: "/shipment", icon: <BarsOutlined /> },
                { label: "Obavijesti", route: "/shipment-notif-log", icon: <NotificationOutlined /> },
            ]
        },
        {
            label: "Slanje", route: "/shipment-group", icon: <SendOutlined />, items: [
                { label: "Novi zahtjev", route: "/shipment-group/new", icon: <PlusOutlined /> },
                { label: "Arhiva", route: "/shipment-group", icon: <BarsOutlined /> },
            ]
        },
        {
            label: "Transakcije", route: "/transaction", icon: <EuroOutlined />, items: [
                { label: "Fakture", route: "/transaction/invoice", icon: <AuditOutlined /> },
                { label: "Otkupnine", route: "/transaction/cod-transfer", icon: <AuditOutlined /> },
            ]
        },
        { label: "Adresar", route: "/address-book", icon: <DatabaseOutlined /> },
        {
            label: "Pomoć", route: null, icon: <QuestionCircleOutlined />, items: [
                { label: "Česta pitanja", route: "/help/faq", icon: <AuditOutlined /> },
                { label: "Dokumenti", route: "/help/doc", icon: <FilePdfOutlined /> }
            ]
        }
    ];

    // open any top level item whos child is currently active, and always keep open "/shipment"
    const defaultOpenKeys = [...menuItems.filter(mi => location.pathname.startsWith(match.url + mi.route)).map(mi => mi.route), "/shipment"]
        .map(route => match.url + route);

    // must use .search also so we include menuitems like shipment/new?type=1
    let selected = [location.pathname + location.search];
    //console.log("SELECTED", selected)

    const handleBackToAdmin = () => {
        // axios interceptor will append to haders, see Api.ts
        sessionStorage.setItem("CustomerId", null);
        meLoader();
        history.push("/admin/customer/" + customer.id); // go back to the profile where we came from
        message.success("Kontekst vraćen na administratora");
    }

    return (
        <Bux.Layout>
            <Bux.MenuBar>
                <div className="spec-logo">
                    <img src="/assets/logo.svg" alt="Logex" />
                </div>

                {Config.envLabel === "Test" && <Tooltip title="Ovo je test okolina i u potpunosti je odvojena od produkcije!"><div className="spec-menu-env">TEST</div></Tooltip>}
                {Config.envLabel === "Development" && <div className="spec-menu-env">DEV</div>}

                <Popover placement="right" overlayClassName="spec-menu-customer-popover"
                    content={<Descriptions bordered size="small" column={1}>
                        <Descriptions.Item label="Šifra">{customer.code}</Descriptions.Item>
                        <Descriptions.Item label="Naziv">{customer.name}</Descriptions.Item>
                        <Descriptions.Item label="Sjedište">
                            {customer.street} {customer.streetNo}
                            <br />
                            {customer.settlement.postOffice.code} {customer.settlement.name}
                        </Descriptions.Item>
                    </Descriptions>}
                >
                    <div className="spec-menu-customer">
                        <div>{customer.code}</div>
                        <div>{customer.name}</div>
                    </div>
                </Popover>

                <Menu theme="dark" mode="inline" selectedKeys={selected} defaultOpenKeys={defaultOpenKeys}>

                    {menuItems.map(mi => {
                        if (mi.items) {
                            //console.log(mi.items.map(sub =>match.url + sub.route));
                            return (
                                <Menu.SubMenu key={match.url + mi.route + "-root"} icon={mi.icon} title={mi.label}>
                                    {mi.items.map(sub => (
                                        <Menu.Item key={match.url + sub.route} icon={sub.icon}>
                                            <span data-test={match.url + sub.route} />
                                            {sub.label}
                                            <Link to={match.url + sub.route} />
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            );
                        }
                        return (
                            <Menu.Item key={mi.route} icon={mi.icon}>
                                {mi.label}
                                <Link to={match.url + mi.route} />
                            </Menu.Item>
                        );
                    })}
                    {appAccount.isAdmin &&
                        <Menu.Item key="/admin" icon={<ExperimentOutlined />} danger onClick={handleBackToAdmin}>
                            Admin
                            {/* <Link to="/admin" />  -- no link, custom click handler */}
                        </Menu.Item>}
                    <Menu.Item key="/identity/logout" icon={<PoweroffOutlined />} onClick={auth.actions.signout}>Odjava</Menu.Item>
                </Menu>
            </Bux.MenuBar>

            <Switch>
                <Redirect from={match.url + "/"} to={match.url + "/dash"} exact />
                <Route key="/dash" path={match.url + "/dash"} component={Dash} />
                <Route key="/shipment" path={match.url + "/shipment"} component={Shipment} />
                <Route key="/shipment-notif-log" path={match.url + "/shipment-notif-log"} component={ShipmentNotifLog} />
                <Route key="/shipment-group" path={match.url + "/shipment-group"} component={ShipmentGroup} />
                <Route key="/transaction" path={match.url + "/transaction"} component={Transaction} />
                <Route key="/address-book" path={match.url + "/address-book"} component={AddressBook} />
                <Route key="/help" path={match.url + "/help"} component={Help} />
            </Switch>
        </Bux.Layout>);
}

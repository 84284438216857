import { Form, Button, InputNumber } from 'antd';
import { Dayjs } from 'dayjs';
import { FilterFormProps, DateRanges } from "../types";
import { FileExcelOutlined } from "@ant-design/icons";
import { DatePickerEx } from "Components/DatePickerEx";

interface FormModel {
    dateRange?: [Dayjs, Dayjs];
    days?: number;
}

const defaultValue: FormModel = {
    days: 1
}

export function FilterForm({ code, onSubmit, running }: FilterFormProps) {
    const [form] = Form.useForm();
    const transform = () => {
        form.validateFields().then((filter: FormModel) => {
            onSubmit("xlsx", {
                from: filter.dateRange[0].format("YYYY-MM-DD"),
                to: filter.dateRange[1].add(1, "day").format("YYYY-MM-DD"),
                days: filter.days
            });
        });
    }

    return <Form form={form} layout="vertical" name={"filter-" + code} onFinish={transform} initialValues={defaultValue}>
        <Form.Item>
            Izvještaj uključuje pakete koji:
            <ul>
                <li>Nemaju konačan status</li>
                <li>pripadaju pošiljci koja je prikupljena</li>
                <li>pripadaju pošiljci koja nije obrisana/stornirana</li>
            </ul>
        </Form.Item>
        <Form.Item name="dateRange" label="Vrijeme preuzimanja pošiljke" rules={[{ required: true }]}>
            <DatePickerEx.RangePicker ranges={DateRanges} format="L" />
        </Form.Item>
        <Form.Item name="days" label="Neaktivno dana" rules={[{ required: true }]}>
            <InputNumber min={1} max={30} precision={0} step={1} />
        </Form.Item>
        <Form.Item>
            <Button type="primary" ghost disabled={running} onClick={() => form.resetFields()}>Očisti</Button>
            &nbsp;
            <Button htmlType="submit" type="primary" loading={running} icon={<FileExcelOutlined />}>Generiraj izvještaj</Button>
        </Form.Item>
    </Form>
}

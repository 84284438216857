import React from 'react';
import { Shipment } from 'Models/Logi/Shipment';
import { Alert, Col, Descriptions, Row, Typography } from 'antd';
import * as ShipmentDesc from "Components/Shipment/Descriptions";
import { Bux } from 'Components/Bux';
import { StatusHistoryPopover } from 'Components/StatusHistoryPopover';
import { StatusDisplay } from 'Components/StatusDisplay';
import { StornoButton } from "Components/Shipment/ActionButton";
import { EditShipmentContext } from '../Context';
const { Paragraph, Title } = Typography;

const texts: { key: keyof Shipment, title: string }[] = [
    { key: "contentNote", title: "Opis sadržaja" },
    { key: "note", title: "Napomena" },
    { key: "refA", title: "Ref A" },
    { key: "refB", title: "Ref B" },
    { key: "refC", title: "Ref C" }
];

export const MainTab = ({ shipment }: { shipment: Shipment }) => {
    const { dispatch } = React.useContext(EditShipmentContext);
    const handleStorno = async () => {
        dispatch({ type: "SHIPMENT_STORNO" });
    }

    return <>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <StornoButton shipment={shipment} key="storno" onExecuted={handleStorno} block />
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {shipment.isDeleted && <Alert message="Pošiljka je obrisana!" type="error" showIcon />}
            {shipment.isCancelled && <Alert message="Pošiljka je stornirana!" type="warning" showIcon />}
            {shipment.isGratis && <Alert message={<span>Opcija <em>Gratis</em> je aktivna! Pošiljka neće biti fakturirana.</span>} type="info" showIcon />}

            <Row gutter={[8, 8]}>
                <Col sm={12} xs={24}>
                    <ShipmentDesc.BasicDesc shipment={shipment} />
                </Col>
                <Col sm={12} xs={24}>
                    <Descriptions bordered size="small" column={1} title="Status paketa">
                        {shipment.packages.map(pack => <Descriptions.Item label={pack.barcode} key={pack.id}>
                            <StatusHistoryPopover shipmentId={pack.shipmentId} packageId={pack.id}><StatusDisplay value={pack.status} /></StatusHistoryPopover>
                        </Descriptions.Item>)}
                    </Descriptions>
                    <ShipmentDesc.EventsDesc shipment={shipment} />
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col sm={12} xs={24}>
                    <ShipmentDesc.SenderDesc sender={shipment.sender} />
                </Col>
                <Col sm={12} xs={24}>
                    <ShipmentDesc.RecipientDesc recipient={shipment.recipient} />
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col sm={12} xs={24}>
                    <ShipmentDesc.ServicesDesc shipment={shipment} />
                </Col>
                <Col sm={12} xs={24}>
                    <ShipmentDesc.GroupDesc shipmentGroup={shipment.shipmentGroup} />
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                {texts.map(t => shipment[t.key] && <Col span={12} key={String(t.key)}>
                    <Title level={3}>{t.title}</Title>
                    <Paragraph ellipsis>{shipment[t.key]}</Paragraph>
                </Col>)}
            </Row>
        </Bux.Content>
    </>;
}

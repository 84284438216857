import { Typography } from "antd";
import { Status } from "Models/Logi/Status";
const { Text } = Typography;


interface Props {
    value: Status;
}

export const StatusDisplay = ({ value }: Props) => {
    return <Text className={getClassName(value.code)}>{value.name}</Text>;
}

function getClassName(statusCode: string): string {
    switch (statusCode) {
        case "DPR":
        case "REC":
            return "text-success"; // green  

        case "DPO":
        case "NREC": return "text-danger"; // red

        default: return null;
    }
}

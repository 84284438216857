import React from 'react';
import produce from 'immer';
import { StandardPriceList } from 'Models/Bus/PriceList/StandardPriceList';
import { ServiceType } from 'Models/Codebook/ServiceType';
import { PriceInsuranceRange } from 'Models/Bus/PriceList/PriceInsuranceRange';
import { PriceWeightRange } from 'Models/Bus/PriceList/PriceWeightRange';
import { PriceWeightExtra } from 'Models/Bus/PriceList/PriceWeightExtra';
import { PriceAdditionalService } from 'Models/Bus/PriceList/PriceAdditionalService';
import dayjs from 'dayjs';
import { PriceDiscountRange } from 'Models/Bus/PriceList/PriceDiscountRange';

export interface ProfileState {
    model: StandardPriceList;
    serviceTypes: ServiceType[];
    editable: boolean;
    deletable: boolean;
    loading: boolean;
}

type Action =
    { type: "PL_LOADING" } |
    { type: "PL_LOADED", payload: StandardPriceList } |
    { type: "PL_SAVED", payload: StandardPriceList } |
    { type: "ST_LOADED", payload: ServiceType[] } |
    { type: "INSURANCERANGES_SAVED", payload: PriceInsuranceRange[] } |
    { type: "DISCOUNTRANGES_SAVED", payload: PriceDiscountRange[] } |
    { type: "WEIGHTRANGES_SAVED", payload: { serviceTypeId: number, ranges: PriceWeightRange[] } } |
    { type: "WEIGHTEXTRAS_SAVED", payload: { serviceTypeId: number, extra: PriceWeightExtra } } |
    { type: "ADDSVCS_SAVED", payload: PriceAdditionalService }
    ;


export const InitialState: ProfileState = {
    model: null,
    serviceTypes: [],
    editable: false,
    deletable: false,
    loading: true
};

interface ProfileContextValue {
    dispatch: React.Dispatch<Action>;
    state: ProfileState;
}

export const ProfileContext = React.createContext<ProfileContextValue>(null);

export const reducer = produce((draft: ProfileState, action: Action) => {
    //console.log("Reducer type " + action.type)
    switch (action.type) {
        case "PL_LOADING":
            draft.loading = true;
            break;
        case "PL_LOADED":
            draft.model = action.payload;
            draft.loading = false;
            if (action.payload) {
                draft.editable = !action.payload.isPublished;
                // allow delete aslong as its not active
                draft.deletable = !action.payload.isPublished || action.payload.validFrom.isAfter(dayjs());
            }
            break;
        case "PL_SAVED":
            draft.model = action.payload;
            break;
        case "ST_LOADED":
            draft.serviceTypes = action.payload;
            break;
        case "INSURANCERANGES_SAVED":
            draft.model.priceList.priceInsuranceRanges = action.payload;
            break;
        case "DISCOUNTRANGES_SAVED":
            draft.model.priceList.priceDiscountRanges = action.payload;
            break;
        case "WEIGHTRANGES_SAVED":
            // keep other servicetypes, replace only items belonging to specified action.payload.serviceTypeId 
            draft.model.priceList.priceWeightRanges = [
                ...draft.model.priceList.priceWeightRanges.filter(r => r.serviceTypeId !== action.payload.serviceTypeId),
                ...action.payload.ranges
            ];
            break;
        case "WEIGHTEXTRAS_SAVED":
            // keep other servicetypes, replace only items belonging to specified action.payload.serviceTypeId
            draft.model.priceList.priceWeightExtras = [
                ...draft.model.priceList.priceWeightExtras.filter(r => r.serviceTypeId !== action.payload.serviceTypeId),
                action.payload.extra
            ];
            break;
        case "ADDSVCS_SAVED":
            draft.model.priceList.priceAdditionalService = action.payload;
            break;
    }
}, InitialState as any) as ((state: ProfileState, action: Action) => ProfileState);

import { UrlParam, ApiCall } from "net-common-ui";
import { kApi } from "Api";
import { PackageLog } from "./PackageLog";
import { PackageStatusLog } from "./PackageStatusLog";
import { ShipmentMainLog } from "./ShipmentMainLog";
import { ShipmentEventLog } from "./ShipmentEventLog";
import { ShipmentSenderLog } from "./ShipmentSenderLog";
import { ShipmentRecipientLog } from "./ShipmentRecipientLog";
import { ShipmentCodLog } from "./ShipmentCodLog";
import { ShipmentMessageLog } from "./ShipmentMessageLog";
import { ShipmentNotifLog } from "./ShipmentNotifLog";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface ShipmentLog {
    shipmentId?: number;
    events?: ShipmentEventLog[];
    mains?: ShipmentMainLog[];
    senders?: ShipmentSenderLog[];
    recipients?: ShipmentRecipientLog[];
    packages?: PackageLog[];
    packageStatuses?: PackageStatusLog[];
    cods?: ShipmentCodLog[];
    messages?: ShipmentMessageLog[];
    notifs?: ShipmentNotifLog[];
}

export function getShipmentLogById(shipmentId: UrlParam): ApiCall<ShipmentLog> {
    return ApiCall.get(kApi, `shipment/${shipmentId}/log`);
}

export function dsoShipmentLogByShipment(shipmentId: UrlParam): DataSourceOptions<ShipmentLog> {
    return {
        api: kApi,
        url: `shipment/${shipmentId}/log`
    };
}
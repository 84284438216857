import { kApi } from "Api";
import { ApiCall } from "net-common-ui";
import { Customer } from "./Customer";
import { AppAccount } from "./AppAccount";

export interface Me {
    customer?: Customer;
    appAccount?: AppAccount;
}

export function getMeInfo(): ApiCall<Me> {
    return ApiCall.get(kApi, "me;info");
}
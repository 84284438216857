import { Form, Input, Row, Col } from 'antd';
import { InputMoney } from 'Components/InputMoney';

interface Props {
    disabled?: boolean;
}

export const ContentDescriptionEditor = ({ disabled }: Props) => {
    const rest = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    };

    return <>
        <Row gutter={[8, 0]}>
            <Col span={12}>
                <Form.Item name="value" fieldKey="value" label="Vrijednost" {...rest}>
                    <InputMoney disabled={disabled} />
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={[8, 0]}>
            <Col span={12}>
                <Form.Item name="contentNote" fieldKey="contentNote" label="Opis sadržaja" {...rest}>
                    <Input.TextArea disabled={disabled} maxLength={500} />
                </Form.Item>
                <Form.Item name="note" fieldKey="note" label="Napomena" {...rest}>
                    <Input.TextArea disabled={disabled} maxLength={500} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name="refA" fieldKey="refA" label="Polje A" {...rest}>
                    <Input disabled={disabled} maxLength={100} />
                </Form.Item>
                <Form.Item name="refB" fieldKey="refB" label="Polje B" {...rest}>
                    <Input disabled={disabled} maxLength={100} />
                </Form.Item>
                <Form.Item name="refC" fieldKey="refC" label="Polje C" {...rest}>
                    <Input disabled={disabled} maxLength={100} />
                </Form.Item>
            </Col>
        </Row>
    </>;
}


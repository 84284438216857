import { useLocation } from "react-router";
import queryString from 'query-string';

export function usePreset(): string {
    const location = useLocation();
    const qs = queryString.parse(location.search);
    const preset = qs["preset"];
    const result = Array.isArray(preset)
        ? (preset.length > 0 ? preset[0] : undefined)
        : preset;

    return result;
}
import { Descriptions, Spin } from "antd";
import { DashCustomerInfobar } from "Models/Dash/DashCustomerInfobar";
import { CurrencyText, PercentText, WeightText } from "Components/Text";

export const CustomerInfobar = ({ value }: { value: DashCustomerInfobar }) => {

    if (!value)
        return <Spin />;

    return (<Descriptions bordered size="small" column={1} title={null}>
        <Descriptions.Item label="Poslano pošiljaka">{value.shipmentCount}</Descriptions.Item>
        <Descriptions.Item label="Poslano paketa">{value.packageCount}</Descriptions.Item>
        <Descriptions.Item label="Otkupnih pošiljaka">
            {value.shipmentWithCodCount}
            &nbsp;
            (<PercentText value={value.shipmentWithCodCount / value.shipmentCount} showSymbol />)</Descriptions.Item>
        <Descriptions.Item label="Prosječna masa paketa"><WeightText value={value.packageAverageWeight} showSymbol /></Descriptions.Item>
        <Descriptions.Item label="Prosječna cijena pošiljke">
            <CurrencyText value={value.shipmentTotalWithDiscountAverage} showSymbol />
            {value.shipmentTotalWithDiscountAverage && " + PDV"}
        </Descriptions.Item>

        <Descriptions.Item label="Isplaćeni otkupni iznos"><CurrencyText value={value.codAmountTransferred} showSymbol /></Descriptions.Item>
        <Descriptions.Item label="Otkupni iznos za isplatu"><CurrencyText value={value.codAmountPending} showSymbol /></Descriptions.Item>

        <Descriptions.Item label="Iznos zadnje fakture">
            <CurrencyText value={value.invoiceLastTotal} showSymbol />
            {value.invoiceLastTotal && " + PDV"}
        </Descriptions.Item>
        <Descriptions.Item label="Trenutno dugovanje">
            <CurrencyText value={value.invoicePendingTotal} showSymbol />
            {value.invoicePendingTotal && " + PDV"}
        </Descriptions.Item>

    </Descriptions>);
}

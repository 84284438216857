import React, { useEffect, useState } from 'react';
import Bux from "Components/Bux";
import { Button, Col, Descriptions, PageHeader, Row, Skeleton, Table, Typography } from 'antd';
import { Link, Redirect, useParams } from 'react-router-dom';
import { CodTransferGroup, getCodTransferGroupInfoById } from 'Models/Bus/CodTransferGroup';
import { CodTransfer } from 'Models/Bus/CodTransfer';
import { CurrencyText } from 'Components/Text';
import { DateDisplay } from "net-common-ui";
import downloadStream from 'downloadStream';
import { kApi } from 'Api';
import { DownloadOutlined } from '@ant-design/icons';
import { Customer } from 'Models/Bus/Customer';

const { Title } = Typography;

export const Profile = () => {
    const params = useParams<{ id: string }>();
    const [codTransferGroup, setCodTransferGroup] = useState<CodTransferGroup>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCodTransferGroupInfoById(params.id).execute().then(result => {
            setCodTransferGroup(result.item);
            setLoading(false);
        });
    }, [params.id]);

    if (!loading && !codTransferGroup) {
        return <Redirect to="/admin/cod/transfer-group" />;
    }

    const handleDownloadPdf = async () => {
        let url = `/cod-transfer-group/${codTransferGroup.id}/pdf`;
        // filename is igonred if content disposition is successfully sniffed
        const result = await downloadStream(kApi, { url, sniffContentDisposition: true, fileName: "transfer_group.pdf" });
        result.success && result.download();
    }

    return <Bux.Page>
        <Bux.Header>
            <PageHeader title="Grupa naloga za isplatu" />
        </Bux.Header>
        {loading
            ? <Bux.Content>
                <Skeleton active />
            </Bux.Content>
            : <Bux.Content>
                <Row gutter={8}>
                    <Col span={12}>
                        <Descriptions bordered column={1} size="small" title="Grupa">
                            <Descriptions.Item label="Šifra">{codTransferGroup.code}</Descriptions.Item>
                            <Descriptions.Item label="Razdoblje">
                                <DateDisplay value={codTransferGroup.rangeFrom} />
                                &nbsp;-&nbsp;
                                <DateDisplay value={codTransferGroup.rangeTo} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Iznos"><CurrencyText value={codTransferGroup.amount} showSymbol style={{ fontWeight: "bold" }} /></Descriptions.Item>
                            <Descriptions.Item label="Vrijeme"><DateDisplay value={codTransferGroup.createdOn} /></Descriptions.Item>
                            <Descriptions.Item label="Kreirao">{codTransferGroup.createdBy.name}</Descriptions.Item>
                            <Descriptions.Item label="Pdf Lista">
                                <Button type="primary" onClick={handleDownloadPdf} icon={<DownloadOutlined />}>Preuzmi</Button>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Title level={3}>Nalozi</Title>
                        <Table<CodTransfer>
                            dataSource={codTransferGroup.codTransfers}
                            pagination={false}
                            rowKey={record => record.customer.id}
                        >
                            <Table.Column title="Šifra transfera" dataIndex="code" width={120}
                                render={(code, record: CodTransfer) => <Link to={"/admin/cod/transfer/" + record.id}>{code}</Link>} />
                            <Table.Column dataIndex="customer" title="Korisnik" ellipsis render={(customer: Customer) => (
                                <span><Link to={"/admin/customer/" + customer.id}>{customer.code}</Link> {customer.name}</span>
                            )} />
                            <Table.Column title="Poziv na broj" dataIndex="clearanceNumber" ellipsis />
                            <Table.Column title="Iznos" dataIndex="amount" width={120} render={value => <CurrencyText value={value} showSymbol />} />
                        </Table>
                    </Col>
                </Row>
            </Bux.Content>}
    </Bux.Page>;
}

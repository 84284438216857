import { FormEventHandler, useState } from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { getPackageById } from "Models/Logi/Package";
import { useHistory } from "react-router";
import { useActiveScreenSize } from "net-common-ui";

export const SearchByBarcode = () => {
    const history = useHistory();
    const [barcode, setBarcode] = useState<string>("");
    const [error, setError] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [screnSize] = useActiveScreenSize();
    const showButtonText = screnSize !== "mobile";

    const handleSearch: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // HP barkod je len13 i ima check digit, ali mozda jednog dana ne bude samo HP
        if (barcode.length < 10 || !isNaN(parseInt(barcode))) {
            setError("Neispravan barkod.");
        }
        else {
            setLoading(true);
            const response = await getPackageById(barcode).execute({ errorHandler: false });
            setLoading(false);
            if (response.item) {
                history.push("./shipment/" + response.item.shipmentId);
            }
            else {
                setError("Paket " + barcode + " nije pronađen.");
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBarcode(e.target.value);
        setError(null);
    }

    return <form style={{ margin: 4 }} onSubmit={handleSearch}>
        <Space direction="horizontal">
            Barkod:
            <Input style={{ width: 140 }} value={barcode} onChange={handleInputChange} disabled={loading} />
            <Button type="default" htmlType="submit" disabled={loading} loading={loading} icon={<SearchOutlined />}>
                {showButtonText && "Traži"}
            </Button>
            {error}
        </Space>
    </form>
}

import { Customer } from "Models/Bus/Customer"
import { CodeInput, useDataSourceRef } from "net-common-ui";
import { Form, Input, Row, Col, Typography } from 'antd';
import { invoiceIntervalLabel, labelFormatLabel } from 'Models/Enum';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { dsoSettlement } from "Models/Codebook/Settlement";

interface Props {
    customer: Customer;
}

export const View = ({ customer }: Props) => {
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });

    return (
        <Form layout="vertical" name="form" initialValues={customer}>
            <Row gutter={[8, 40]} justify="center">
                <Col span={8}>
                    <Typography.Title level={3}>Osnovno</Typography.Title>
                    <Form.Item name="code" label="Šifra">
                        <CodeInput disabled />
                    </Form.Item>
                    <Form.Item name="name" label="Naziv">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="contractNumber" label="Broj ugovora">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="oib" label="OIB">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="iban" label="IBAN">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="codIban" label="IBAN za otkupnine">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="Interval fakturiranja">
                        <Input disabled value={invoiceIntervalLabel(customer.invoiceIntervalId)} />
                    </Form.Item>
                    <Form.Item name="invoiceDueOnOffset" label="Valuta plaćanja (dana)">
                        <Input placeholder="30" disabled />
                    </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                    <Typography.Title level={3}>Sjedište</Typography.Title>
                    <Form.Item label="Adresa">
                        <Form.Item name="street" noStyle>
                            <Input disabled placeholder="Ulica" style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
                        </Form.Item>
                        <Form.Item name="streetNo" noStyle>
                            <Input disabled placeholder="K.br." style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="settlement" label="Mjesto">
                        <SettlementComboBox disabled dataSource={settlementDs} />
                    </Form.Item>

                    <Typography.Title level={3}>Kontakt</Typography.Title>
                    <Form.Item name="phone" label="Telefon">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="emailInvoice" label="Email za fakture">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="emailCodTransfer" label="Email za transfere otkupnina">
                        <Input disabled />
                    </Form.Item>

                    <Typography.Title level={3}>Ostalo</Typography.Title>
                    <Form.Item label="Vrsta adresnica">
                        <Input disabled value={labelFormatLabel(customer.labelFormatId)} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

import React from 'react';
import { Row, Col, Button, Form, Typography, Checkbox, Input, message, Popover, Descriptions, Alert } from 'antd';
import { EditShipmentContext } from '../Context';
import { SenderEditor } from './SenderEditor';
import { RecipientEditor } from './RecipientEditor';
import { SaveOutlined, MinusCircleOutlined, PlusOutlined, WarningTwoTone } from '@ant-design/icons';
import { InputMoney } from 'Components/InputMoney';
import { saveShipment, Shipment } from 'Models/Logi/Shipment';
import { InputWeight } from 'Components/InputWeight';
import { CodTypeSelect } from 'Components/CodTypeSelect';
//import InputSize from 'Components/InputSize';
import { getIsSenderLocked } from '../logic';
import { useCustomer } from 'Views/MeContext';
import { ContentDescriptionEditor } from "./ContentDescriptionEditor";
import { useHistory } from 'react-router';
import { DateDisplay } from "net-common-ui";
import { CustomerServiceType } from 'Models/Bus/CustomerServiceType';
import downloadStream from 'downloadStream';
import { kApi } from 'Api';
import { Store } from 'antd/lib/form/interface';
import { Package } from 'Models/Logi/Package';
const { Title } = Typography;

function shouldRenderServicesSection(cst: CustomerServiceType) {
    return cst.allowCod ||
        cst.allowCod ||
        cst.allowReturnSignedDoc ||
        cst.allowPersonalDelivery ||
        cst.allowSaturdayDelivery ||
        cst.allowPovratnica ||
        cst.allowSaturdayPickup ||
        cst.allowNonstandardFormat ||
        cst.allowNotifyRecipientEmail ||
        cst.allowNotifyRecipientSms ||
        cst.allowNotifySenderSms ||
        cst.allowNotifySenderEmail;
}

// helper to ensure falsy/truthy values are properly converted to a boolean - so we avoid null values on backend
function toBool(value: any): boolean {
    return !!value;
}

export function ShipmentForm() {
    const customer = useCustomer();
    const history = useHistory();
    const { state, dispatch } = React.useContext(EditShipmentContext);
    const { model, saving, editable } = state;
    const [form] = Form.useForm();

    const isSenderLocked = getIsSenderLocked(customer);
    const cst = customer.customerServiceTypes.find(x => x.customerId === customer.id && x.serviceType.id === model.serviceType.id);

    const handleSave = async (update: Store, generateLabels?: boolean) => {
        const merged: Shipment = {
            ...model,
            ...update,
            useNotifySenderEmail: toBool(update.useNotifyEmail && cst.allowNotifySenderEmail),
            useNotifyRecipientEmail: toBool(update.useNotifyEmail && cst.allowNotifyRecipientEmail),
            useNotifySenderSms: toBool(update.useNotifySms && cst.allowNotifySenderSms),
            useNotifyRecipientSms: toBool(update.useNotifySms && cst.allowNotifyRecipientSms)
        };

        // if (!merged.useCod) {
        //     merged.codTypeId = CodType.None;
        // }

        // console.log("SAVE", merged);
        // dispatch({ type: "SHIPMENT_SAVE_ERROR" });
        // return;


        //console.log("save", merged);
        dispatch({ type: "SHIPMENT_SAVING" })
        const result = await saveShipment(merged).execute();
        if (result.success) {
            // persist codtype to be used for next shipment
            sessionStorage["LastCodTypeId"] = merged.codTypeId;

            dispatch({ type: "SHIPMENT_SAVED", payload: result.item });

            if (generateLabels) {
                const url = `shipment/label?format=${customer.labelFormatId}&ids=${result.item.id}`
                try {
                    const result = await downloadStream(kApi, { url, fileName: "shipment_labels.pdf" });
                    result.success && result.download();
                } catch {
                    message.error("Greška prilikom izrade adresnica.");
                }
            }

            message.success("Pošiljka uspješno spremljena.");
            //history.push("."); // redir to parent (grid)
            history.push("/pub/shipment?preset=unsent");
        }
        else {
            dispatch({ type: "SHIPMENT_SAVE_ERROR" });
        }
    }
    const handleSaveAndLabel = () => {
        form.validateFields().then(values => {
            handleSave(values, true);
        });
    }

    const handleCancel = () => {
        history.push("/pub/shipment?preset=unsent");
    }

    const handleChange = (diff: Partial<Shipment>, all: Shipment) => {
        // delivery by 9/11 are mutually exclusive:
        if (diff.useDeliveryBy9 === true) form.setFieldsValue({ useDeliveryBy11: false });
        if (diff.useDeliveryBy11 === true) form.setFieldsValue({ useDeliveryBy9: false });
        // saturday delivery/pickup are mutually exclusive:
        if (diff.useSaturdayDelivery === true) form.setFieldsValue({ useSaturdayPickup: false });
        if (diff.useSaturdayPickup === true) form.setFieldsValue({ useSaturdayDelivery: false });
    }

    return (
        <Form labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} name="shipment-form" form={form} onFinish={handleSave} initialValues={model} onValuesChange={handleChange}>
            {model.sendErrorMessage && <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Alert
                        message={<>
                            <div>Ova pošiljka ima grešku pri slanju zahtjeva kuriru. Ispravite problem, te spremite pošiljku. Nakon toga biti će moguće ponovno pokušati slanje.</div>
                            <ul>{model.sendErrorMessage.split(/[\r\n,]/gi).map(item => <li>{item.trim()}</li>)}</ul>
                        </>}
                        type="error"
                        showIcon />
                </Col>
            </Row>}

            <Row gutter={[8, 40]} justify="center">
                <Col span={12}>
                    <Title level={3}>Pošiljatelj</Title>
                    <SenderEditor form={form} serviceTypeId={model.serviceType.id} disabled={isSenderLocked || !editable} />
                </Col>
                <Col span={12}>
                    <Title level={3}>Primatelj</Title>
                    <RecipientEditor form={form} serviceTypeId={model.serviceType.id} disabled={!editable} />
                </Col>
            </Row>


            {/* Za sada zakucano na d+3
            <Row>
                <Col span={24}>
                    <Form.Item label="Rok uručenja" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                        <Form.Item name="deliveryDeadlineId" fieldKey="deliveryDeadlineId" noStyle>
                            <Radio.Group disabled={!editable}>
                                <Radio value={1}>D+1</Radio>
                                <Radio value={2}>D+2</Radio>
                                <Radio value={3}>D+3</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="useDeliveryBy9" fieldKey="useDeliveryBy9" valuePropName="checked" noStyle>
                            <Checkbox disabled={!editable} style={{ marginLeft: 40 }}>Dostava do 9h</Checkbox>
                        </Form.Item>
                        <Form.Item name="useDeliveryBy11" fieldKey="useDeliveryBy11" valuePropName="checked" noStyle>
                            <Checkbox disabled={!editable}>Dostava do 11h</Checkbox>
                        </Form.Item>
                    </Form.Item>
                </Col>
            </Row> */}

            <Title level={3}>Opis pošiljke</Title>

            <ContentDescriptionEditor disabled={!editable} />

            {shouldRenderServicesSection(cst) && <>
                {/* Row elementi imaju marginLeft: 14 tako da checkbox ne bude naljepljen na rub */}
                <Title level={3}>Usluge</Title>
                <Row style={{ marginLeft: 14 }}>
                    {cst.allowNonstandardFormat && <Col span={8}><Form.Item name="useNonstandardFormat" valuePropName="checked">
                        <Checkbox disabled={!editable}>Nestandardni format</Checkbox></Form.Item>
                    </Col>}
                    {cst.allowPersonalDelivery && <Col span={8}><Form.Item name="usePersonalDelivery" valuePropName="checked">
                        <Checkbox disabled={!editable}>Uručiti osobno</Checkbox></Form.Item>
                    </Col>}
                    {cst.allowPovratnica && <Col span={8}><Form.Item name="usePovratnica" valuePropName="checked">
                        <Checkbox disabled={!editable}>Povratnica</Checkbox></Form.Item>
                    </Col>}
                    {cst.allowSaturdayPickup && <Col span={8}><Form.Item name="useSaturdayPickup" valuePropName="checked">
                        <Checkbox disabled={!editable}>Prikup subotom</Checkbox></Form.Item>
                    </Col>}
                    {cst.allowSaturdayDelivery && <Col span={8}><Form.Item name="useSaturdayDelivery" valuePropName="checked">
                        <Checkbox disabled={!editable}>Uručenje subotom</Checkbox></Form.Item>
                    </Col>}
                    {(cst.allowNotifySenderSms || cst.allowNotifyRecipientSms) && <Col span={8}><Form.Item name="useNotifySms" valuePropName="checked">
                        <Checkbox disabled={!editable}>SMS obavijesti</Checkbox></Form.Item>
                    </Col>}
                    {(cst.allowNotifySenderEmail || cst.allowNotifyRecipientEmail) && <Col span={8}><Form.Item name="useNotifyEmail" valuePropName="checked">
                        <Checkbox disabled={!editable}>Email obavijesti</Checkbox></Form.Item>
                    </Col>}
                </Row>
                {cst.allowCod && <Row style={{ marginLeft: 14 }}>
                    <Col span={24}>
                        <Form.Item>
                            <Form.Item name="useCod" valuePropName="checked" noStyle>
                                <Checkbox disabled={!editable}>Otkupnina:</Checkbox>
                            </Form.Item>
                            <Form.Item noStyle dependencies={["useCod"]}>
                                {() => {
                                    //const cod = form.getFieldValue("useReturnSignedDoc");
                                    return (<>
                                        <Form.Item name="codAmount" noStyle>
                                            <InputMoney
                                                disabled={!editable || !form.getFieldValue("useCod")}
                                                style={{ display: 'inline-block', marginLeft: 6, width: 140 }} />
                                        </Form.Item>
                                        <Form.Item name="codRefNumber" noStyle>
                                            <Input placeholder="Referentni broj"
                                                disabled={!editable || !form.getFieldValue("useCod")}
                                                style={{ display: 'inline-block', marginLeft: 6, width: 200 }} />
                                        </Form.Item>
                                        <Form.Item name="codTypeId" noStyle>
                                            <CodTypeSelect
                                                disabled={!editable || !form.getFieldValue("useCod")}
                                                style={{ display: 'inline-block', marginLeft: 6, width: 170 }}
                                                limit={cst.codTypeId}
                                                placeholder="Vrsta"
                                            />
                                        </Form.Item>
                                    </>);
                                }}
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>}
                {cst.allowReturnSignedDoc && <Row style={{ marginLeft: 14 }}>
                    <Col span={24}>
                        <Form.Item>
                            <Form.Item name="useReturnSignedDoc" valuePropName="checked" noStyle >
                                <Checkbox disabled={!editable}>Povrat dokumenta:</Checkbox>
                            </Form.Item>
                            <Form.Item noStyle dependencies={["useReturnSignedDoc"]}>
                                {() => {
                                    const rsd = form.getFieldValue("useReturnSignedDoc");
                                    return <Form.Item name="returnSignedDocNumber" noStyle rules={[{ required: rsd, whitespace: rsd }]}>
                                        <Input placeholder="Broj dokumenta"
                                            disabled={!editable || !rsd}
                                            style={{ display: 'inline-block', marginLeft: 20, width: 312 }} />
                                    </Form.Item>
                                }}
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>}
            </>}

            <Title level={3}>Paketi</Title>

            <Row gutter={[8, 0]}>
                <Col span={1}>Br.</Col>
                <Col span={4}>Barcode</Col>
                <Col span={3}>Masa (gr)</Col>
                {/* <Col span={6}>Dimenzije (DxŠxV)</Col> */}
                <Col span={7}>Ref A</Col>
                <Col span={7}>Ref B</Col>
                <Col span={2}></Col>
            </Row>
            <Form.List name="packages">
                {(fields, { add, remove }) => (<>
                    {fields.map(field => {
                        const packages = form.getFieldValue("packages");
                        const pack: Package = packages[field.name];
                        //console.log(pack)
                        return (
                            <Row gutter={[8, 0]} key={field.key}>
                                <Col span={1}>{field.fieldKey + 1}.</Col>
                                <Col span={4}>
                                    <Form.Item name={[field.name, 'barcode']}>
                                        <Input
                                            placeholder="Popunjava sustav"
                                            disabled
                                            suffix={pack && pack.labelCreatedOn &&
                                                <Popover content={
                                                    <Descriptions style={{ width: 250 }} column={1}>
                                                        <Descriptions.Item label="Ispisao">{pack.labelCreatedBy.name}</Descriptions.Item>
                                                        <Descriptions.Item label="Vrijeme"><DateDisplay value={pack.labelCreatedOn} showTime /></Descriptions.Item>
                                                    </Descriptions>
                                                } title="Adresnica ispisana">
                                                    <WarningTwoTone twoToneColor="#2980B9" />
                                                </Popover>
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item name={[field.name, 'weight']} rules={[{ max: 31500, type: "number", message: "Max 31,5 kg." }]}>
                                        <InputWeight disabled={!editable} />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={6}>
                                <Form.Item>
                                    <Form.Item name={[field.name, 'volumeX']} noStyle >
                                        <InputSize disabled={!editable} placeholder="cm" style={{ display: 'inline-block', width: "33%" }} />
                                    </Form.Item>
                                    <Form.Item name={[field.name, 'volumeY']} noStyle >
                                        <InputSize disabled={!editable} placeholder="cm" style={{ display: 'inline-block', width: "34%" }} />
                                    </Form.Item>
                                    <Form.Item name={[field.name, 'volumeZ']} noStyle >
                                        <InputSize disabled={!editable} placeholder="cm" style={{ display: 'inline-block', width: "33%" }} />
                                    </Form.Item>
                                </Form.Item>
                            </Col> */}
                                <Col span={7}>
                                    <Form.Item name={[field.name, 'refA']} >
                                        <Input disabled={!editable} maxLength={100} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item name={[field.name, 'refB']} >
                                        <Input disabled={!editable} maxLength={100} />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        onClick={() => { remove(field.name); }}
                                        block
                                        disabled={!editable || fields.length < 2}
                                        icon={<MinusCircleOutlined />}
                                    />
                                </Col>
                            </Row>
                        )
                    })}
                    <Row justify="end">
                        <Col span={4}>
                            <Form.Item>
                                <Button
                                    block
                                    type="dashed"
                                    onClick={() => { add(); }}
                                    disabled={!editable || !cst.allowMultiPackage}
                                    icon={<PlusOutlined />}
                                >Dodaj paket</Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </>)}
            </Form.List>

            {editable &&
                <Row gutter={8} justify="center">
                    <Col span={4}><Button disabled={saving} onClick={handleCancel} block>Odustani</Button></Col>
                    <Col span={4}><Button loading={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
                    <Col span={4}><Button disabled={saving} onClick={handleSaveAndLabel} block icon={<SaveOutlined />}>Spremi + adresnice</Button></Col>
                </Row>}
            {!editable &&
                <Row gutter={8} justify="center">
                    <Col span={4}><Button disabled={saving} onClick={handleCancel} block>Zatvori</Button></Col>
                </Row>}

        </Form>
    );
};


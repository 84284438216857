import { kApi } from "Api";
import { UrlParam } from "net-common-ui";
import { CustomerNotifConfig } from "Models/Bus/CustomerNotifConfig"
import { Mail } from "Models/Bus/Mail"
import { Sms } from "Models/Bus/Sms"
import { Customer } from "Models/Bus/Customer";
import { Dayjs } from "dayjs";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface ShipmentNotifLog {
    id?: number;
    shipmentEventLogId?: number;
    packageStatusLogId?: number;
    shipmentId?: number;
    barcode?: string;
    isPending?: boolean;
    customerNotifConfig?: CustomerNotifConfig;
    customer?: Customer;

    errorMessage?: string;
    mail?: Mail;
    sms?: Sms;

    createdOn?: Dayjs;
}

export function dsoShipmentNotifLog(): DataSourceOptions<ShipmentNotifLog> {
    return {
        api: kApi,
        url: "shipment-notif-log"
    };
}

export function dsoShipmentNotifLogByCustomerId(customerId: UrlParam): DataSourceOptions<ShipmentNotifLog> {
    return {
        api: kApi,
        url: `customer/${customerId}/shipment-notif-log`
    };
}

export function dsoShipmentNotifLogByShipmentId(shipmentId: UrlParam): DataSourceOptions<ShipmentNotifLog> {
    return {
        api: kApi,
        url: `shipment/${shipmentId}/shipment-notif-log`
    };
}
export async function antOibValidator(rule: any, value: string) {
    if (value && !validateOib(value)) {
        throw new Error('Vrijednost nije ispravan OIB');
    }
}

function validateOib(oib: string) {
    // has to be integer and length of 11
    if (oib.length !== 11 || !Number.isInteger(parseInt(oib, 10)))
        return false;

    var a = 10;
    for (var i = 0; i < 10; i++) {
        a = (a + parseInt(oib.substring(i, i + 1), 10)) % 10;
        if (a === 0) { a = 10; }
        a = (a * 2) % 11;
    }
    var ctrlDigit = 11 - a;
    if (ctrlDigit === 10) ctrlDigit = 0;
    return ctrlDigit === parseInt(oib.substring(10, 11), 10);
}

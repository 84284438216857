import { Button, Checkbox, Form, Input } from 'antd';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { dsoSettlement, Settlement } from 'Models/Codebook/Settlement';
import { useDataSourceRef } from "net-common-ui";
import { FormInstance } from 'antd/lib/form';

export interface AddressBookFilter {
    personName?: string;
    name?: string;
    street?: string;
    settlement?: Settlement;
    phone?: string;
    email?: string;
    isSender?: boolean;
    isRecipient?: boolean;
}

interface FilterFormProps {
    initialFilter?: AddressBookFilter;
    onChange?: (filter: AddressBookFilter) => void;
    form?: FormInstance;
}

export const FilterForm = ({ onChange, initialFilter = {}, form }: FilterFormProps) => {
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });

    return (
        <Form form={form} layout="vertical" name="filter" initialValues={initialFilter} onFinish={onChange}>

            <Form.Item name="name" label="Naziv">
                <Input />
            </Form.Item>

            <Form.Item name="personName" label="Ime i prezime">
                <Input />
            </Form.Item>

            <Form.Item name="street" label="Ulica">
                <Input />
            </Form.Item>

            <Form.Item name="settlement" label="Mjesto">
                <SettlementComboBox dataSource={settlementDs} />
            </Form.Item>

            <Form.Item name="phone" label="Telefon">
                <Input />
            </Form.Item>

            <Form.Item name="email" label="Email">
                <Input />
            </Form.Item>

            <Form.Item name="isSender" valuePropName='checked'>
                <Checkbox>Pošiljatelj</Checkbox>
            </Form.Item>
            <Form.Item name="isRecipient" valuePropName='checked'>
                <Checkbox>Primatelj</Checkbox>
            </Form.Item>


            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden />
        </Form>
    )
}

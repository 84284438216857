import { useEffect, useReducer } from 'react';
import { getInvoiceInfoById, getInvoiceNumberDisplayLabel } from 'Models/Bus/Invoice';
import { Link, useParams, Switch, Route, Redirect } from 'react-router-dom';
import { MainTab } from "./MainTab";
import { RecordTab } from "./RecordTab";
import { Bux } from 'Components/Bux';
import { Menu, PageHeader, Skeleton } from 'antd';
import { ProfileContext, reducer, InitialState } from './Context';

export const Profile = ({ match, location }) => {
    const params = useParams<{ id: string }>();
    const [state, dispatch] = useReducer(reducer, InitialState);
    const { model, loading } = state;

    useEffect(() => {
        dispatch({ type: "INVOICE_LOADING" });
        getInvoiceInfoById(params.id).execute().then(result => {
            dispatch({ type: "INVOICE_LOADED", payload: result.item });
        });
    }, [params.id]);

    if (!loading && !model) {
        return <Redirect to="/admin/invoice" />
    }

    const menu = <Menu mode="horizontal" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]}>
        <Menu.Item key={match.url}>
            <span>Profil</span>
            <Link to={match.url} />
        </Menu.Item>
        <Menu.Item key={match.url + "/records"}>
            <span>Stavke</span>
            <Link to={match.url + "/records"} />
        </Menu.Item>
    </Menu>;

    return <ProfileContext.Provider value={{ state, dispatch }}>
        {loading
            ? <Skeleton active paragraph={{ rows: 8 }} />
            : <Bux.Page>
                <Bux.Header>
                    <PageHeader title={`Faktura #${getInvoiceNumberDisplayLabel(model)}`} subTitle={model.customer.name} footer={menu} />
                </Bux.Header>
                <Switch>
                    <Route path={match.url} exact><MainTab /></Route>
                    <Route path={match.url + "/records"} exact><RecordTab /></Route>
                </Switch>

            </Bux.Page>}

    </ProfileContext.Provider>;
}


import { Switch, Route } from "react-router";

import { Grid } from "./Grid";
//import Profile from "./Profile";

export const Routes = ({ match }) => (
    <Switch>
        <Route path={match.url} exact component={Grid} />
        {/* <Route path={match.url + "/:id(\\d+)"} component={Profile} /> */}
    </Switch>);

import React, { useEffect } from "react";
import { DateDisplay, useDataSource } from "net-common-ui";
import { Popover, Spin, Timeline, Typography } from "antd";
import { Status } from "Models/Logi/Status";
import {
    LockTwoTone,
    DeleteTwoTone,
    QuestionCircleOutlined,
    MailOutlined,
    PhoneOutlined
} from '@ant-design/icons';
import { MailDisplay } from "./MailDisplay";
import { SmsDisplay } from "./SmsDisplay";
import { dsoPackageStatusLogByPackageId } from "Models/Logi/ShipmentLog/PackageStatusLog";
import { dsoShipmentNotifLogByShipmentId } from "Models/Logi/ShipmentLog/ShipmentNotifLog";
const { Text } = Typography;


interface Props {
    children?: React.ReactNode;
    shipmentId: number,
    packageId: number
}

export const StatusHistoryPopover = ({ children, ...rest }: Props) => {
    return <Popover
        content={<StatusHistory {...rest} />}
        title="Status pošiljke"
        placement="bottom"
        mouseEnterDelay={.5}
        mouseLeaveDelay={.25}
    >
        <Text style={{ cursor: "help" }}>
            {children}&nbsp;<QuestionCircleOutlined />
        </Text>
    </Popover>;
}

const StatusHistory = ({ shipmentId, packageId }: Props) => {
    const [view, ds] = useDataSource(dsoPackageStatusLogByPackageId(shipmentId, packageId)); // no exec
    const [viewNotif, dsNotif] = useDataSource(dsoShipmentNotifLogByShipmentId(shipmentId)); // no exec

    // shitty code: to allow popover to open. without this it wouldnt open before 2nd hover (when ajax was done)
    useEffect(() => {
        setTimeout(() => {
            ds.execute();
            dsNotif.execute();
        }, 500);
    }, [ds, dsNotif]);

    return !view.loading && view.queried && !viewNotif.loading && viewNotif.queried
        ? <Timeline mode="left" style={{ width: 560, marginLeft: -140 }}>
            {view.data.map(log => {
                const notifs = viewNotif.data.filter(n => n.shipmentEventLogId === log.shipmentEventLogId && (n.sms || n.mail));

                return <Timeline.Item
                    key={log.id}
                    label={<DateDisplay value={log.statusOn} showTime />}
                    {...getItemProps(log.status)}
                >
                    {log.status.name}

                    {log.statusNote && <div><Text type="secondary">{log.statusNote}</Text></div>}

                    {notifs.map(notif => <div key={notif.id}>
                        {notif.mail && <MailDisplay mailId={notif.mail.id}><Text type="secondary"><MailOutlined /> {notif.mail.toAddressGdpr}</Text></MailDisplay>}
                        {notif.sms && <SmsDisplay smsId={notif.sms.id}><Text type="secondary"><PhoneOutlined /> {notif.sms.toAddressGdpr}</Text></SmsDisplay>}
                    </div>)}
                </Timeline.Item>;
            })}
        </Timeline>
        : <Spin />;
}

function getItemProps(status: Status): { className?: string, dot?: string | React.ReactNode } {

    switch (status.code) {
        case "SNT": return { dot: <LockTwoTone /> };

        case "DPR":
        case "REC": return { className: "text-success" }; // green  
        case "DPO":
        case "NREC": return { className: "text-danger" }; // red

        case "STO": return { dot: <DeleteTwoTone twoToneColor="#AF601A" /> };
        default: return {};
    }

}

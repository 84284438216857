import { useState } from 'react';
import { CustomerSender, saveCustomerSender } from "Models/Bus/CustomerSender"
import { Form, Button, Row, Col, Checkbox, Typography, Input, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { dsoSettlement } from 'Models/Codebook/Settlement';
import { useDataSourceRef } from 'net-common-ui';

interface Props {
    customerSender: CustomerSender;
    onUpdate: (updated: boolean, customerSender?: CustomerSender) => void;
}

export const Edit = ({ customerSender, onUpdate }: Props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [isLocked, setIsLocked] = useState(customerSender.isSenderLocked);
    const [pointVisible, setPointVisible] = useState(customerSender.isSenderLocked && !customerSender.useHqPoint);
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });

    const handleCancel = () => {
        onUpdate(false);
    }
    const handleSave = () => {
        form.validateFields().then((model: CustomerSender) => {
            setSaving(true);
            saveCustomerSender({ ...customerSender, ...model }).execute().then(result => {
                setSaving(false);
                if (result.success) {
                    message.success("Postavke pošiljatelja uspješno spremljene.");
                    onUpdate(true, result.item);
                }
            });
        });
    }
    const handleValuesChange = (diff: Partial<CustomerSender>, model: CustomerSender) => {
        setPointVisible(model.isSenderLocked && !model.useHqPoint);
        setIsLocked(model.isSenderLocked);
    }

    return (
        <Form form={form} layout="vertical" name="form" onFinish={handleSave} initialValues={customerSender} onValuesChange={handleValuesChange}>
            <Row gutter={[8, 40]} justify="center">
                <Col span={8}>
                    <Typography.Title level={3}>Postavke pošiljatelja</Typography.Title>
                    <Form.Item name="isSenderLocked" valuePropName="checked">
                        <Checkbox>Pošiljatelj zaključan</Checkbox>
                    </Form.Item>
                    <Form.Item name="useHqPoint" valuePropName="checked">
                        <Checkbox disabled={!isLocked}>Koristi adresu sjedišta korisnika</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8} offset={1}>
                    {pointVisible && <>
                        <Typography.Title level={3}>Zaključani pošiljatelj</Typography.Title>

                        <Form.Item name="personName" label="Ime i prezime">
                            <Input maxLength={200} />
                        </Form.Item>
                        <Form.Item name="name" label="Naziv" rules={[{ required: true }]}>
                            <Input maxLength={200} />
                        </Form.Item>
                        <Form.Item label="Adresa">
                            <Form.Item name="street" noStyle rules={[{ required: true, message: "Ulica je obavezan unos." }]}>
                                <Input placeholder="Ulica" maxLength={200} style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
                            </Form.Item>
                            <Form.Item name="streetNo" noStyle rules={[{ required: true, message: "Kućni broj je obavezan unos." }]}>
                                <Input placeholder="K.br." maxLength={20} style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item name="settlement" label="Mjesto" rules={[{ required: true }]}>
                            <SettlementComboBox dataSource={settlementDs} />
                        </Form.Item>
                        <Form.Item name="phone" label="Telefon" rules={[{ required: true }]}>
                            <Input maxLength={50} />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
                            <Input maxLength={200} />
                        </Form.Item>
                    </>
                    }
                </Col>
            </Row>

            <Row gutter={8} justify="center">
                <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
                <Col span={4}><Button disabled={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
            </Row>
        </Form>
    )
}

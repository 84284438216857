import React, { useMemo } from "react";
import { DashDailyShipmentStats } from "Models/Dash/DashDailyShipmentStats"
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface DeliveryQualityChartProps {
    value: DashDailyShipmentStats[],
    orientation?: "horizontal" | "vertical"
};

export const DeliveryQualityChart = ({ value, orientation = "horizontal" }: DeliveryQualityChartProps) => {
    const ishorizontal = orientation === "horizontal";
    const chartRef = React.useRef<{ chart: Highcharts.Chart, container: any }>();

    // if (chartRef.current?.chart) {
    //     chartRef.current.chart.showLoading("blka")
    // }
    const options: Highcharts.Options = useMemo(() => {
        const legend: Highcharts.LegendOptions = ishorizontal
            ? {
                floating: false,
                verticalAlign: "top",
                align: "right",
                layout: "vertical",
                margin: 0,
                reversed: true
            }
            : {
                floating: false,
                verticalAlign: "top",
                align: "right",
                reversed: true
            };
        const plotOptions: (Highcharts.PlotColumnOptions & Highcharts.PlotBarOptions) = {
            stacking: 'percent',
            groupPadding: 0.05,
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: "{point.percentage:.0f}%",
                // color: "#ffffff",
                // style: {
                //     textOutline: 'none'
                // },
                filter: {
                    property: 'percentage',
                    operator: '>',
                    value: 0
                }
            }
        };

        return {
            title: {
                text: null
            },
            chart: {
                type: ishorizontal ? 'column' : "bar",
                height: ishorizontal ? 240 : 500
            },
            credits: {
                enabled: false
            },

            xAxis: {
                //categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
                categories: value.map(v => v.date.format("DD.MM")),
                title: { text: ishorizontal ? null : "Datum" }
            },
            yAxis: {
                min: 0,
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                tickPositions: [0, 25, 50, 75, 100],
                // stackLabels: {
                //     enabled: true,
                //     style: {
                //         fontWeight: 'bold',
                //         color: ( // theme
                //             Highcharts.defaultOptions.title.style &&
                //             Highcharts.defaultOptions.title.style.color
                //         ) || 'gray'
                //     }
                // }

                stackLabels: {
                    enabled: false
                }
            },

            plotOptions: {
                column: plotOptions,
                bar: plotOptions
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            legend: legend,

            series: [{
                name: '> D+3',
                data: value.map(v => v.firstDeliveryInDay4Plus || 0),
                color: "#f59898"//"#d62d2d"
            }, {
                name: 'D+3',
                data: value.map(v => v.firstDeliveryInDay3 || 0),
                color: "#ebb591"//"#e8782d"
            }, {
                name: 'D+2',
                data: value.map(v => v.firstDeliveryInDay2 || 0),
                color: "#a5c4f0"//"#3a76cc"
            }, {
                name: 'D+1',
                data: value.map(v => v.firstDeliveryInDay1 || 0),
                color: "#a4edb7"//"#2ca841"
            }]
        } as Highcharts.Options;
    }, [value, ishorizontal]);

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
    />;
}

import React, { useReducer } from 'react';
import { Shipment } from 'Models/Logi/Shipment';
import produce from 'immer';

export interface EditShipmentState {
    model: Shipment;

    error: string;
    editable: boolean;
    loading: boolean;
    create: boolean;
    saving: boolean;
    saved: boolean;
}

type Action =
    { type: "SHIPMENT_LOADING" } |
    { type: "SHIPMENT_LOADED", payload: Shipment } |
    { type: "SHIPMENT_SAVING" } |
    { type: "SHIPMENT_SAVED", payload: Shipment } |
    { type: "SHIPMENT_SAVE_ERROR" } |
    { type: "SHIPMENT_STORNO" } |
    { type: "INVALID_STATE", payload: string }
    ;


export const InitialState: EditShipmentState = {
    model: null,
    error: null,
    editable: false,
    loading: true,
    create: false,
    saving: false,
    saved: false
};

interface EditShipmentContextValue {
    dispatch: React.Dispatch<Action>;
    state: EditShipmentState;
}

export const EditShipmentContext = React.createContext<EditShipmentContextValue>(null);

export const reducer = produce((draft: EditShipmentState, action: Action) => {
    //console.log("Reducer type " + action.type)
    switch (action.type) {
        case "SHIPMENT_LOADING":
            draft.loading = true;
            draft.error = null;
            break;
        case "SHIPMENT_LOADED":
            draft.model = action.payload;
            draft.model.useNotifyEmail = action.payload.useNotifyEmail ?? (action.payload.useNotifyRecipientEmail || action.payload.useNotifySenderEmail);
            draft.model.useNotifySms = action.payload.useNotifySms ?? (action.payload.useNotifyRecipientSms || action.payload.useNotifySenderSms);
            draft.model.packages.sort((a, b) => b.ordinal - a.ordinal);
            draft.create = !action.payload.id;
            draft.loading = false;
            draft.editable = !action.payload.shipmentGroup && !action.payload.isDeleted && !action.payload.isCancelled;
            break;
        case "SHIPMENT_SAVING":
            draft.saving = true;
            break;
        case "SHIPMENT_SAVE_ERROR":
            draft.saving = false;
            break;
        case "SHIPMENT_SAVED":
            draft.model = action.payload;
            draft.create = false;
            draft.saving = false;
            draft.saved = true;
            break;
        case "SHIPMENT_STORNO":
            draft.model.isCancelled = true;
            break;
        case "INVALID_STATE":
            draft.error = action.payload
            break;
    }
}, InitialState as any) as ((state: EditShipmentState, action: Action) => EditShipmentState);


export const useEditShipmentReducer = () => {
    return useReducer(reducer, InitialState);
}

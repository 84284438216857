import { Shipment } from 'Models/Logi/Shipment';
import { Col, Row } from 'antd';
import * as ShipmentDesc from "Components/Shipment/Descriptions";
import { Bux } from 'Components/Bux';

export function PackageTab({ shipment }: { shipment: Shipment }) {
    return <Bux.Content>
        <Row gutter={[8, 8]}>
            {shipment.packages.map(pack => (
                <Col sm={12} xs={24} key={pack.id}>
                    <ShipmentDesc.PackageDesc pack={pack} packageCount={shipment.packages.length} />
                </Col>))}
        </Row>
    </Bux.Content>;
}

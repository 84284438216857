import { DashDailyCustomerStats } from "Models/Dash/DashDailyCustomerStats"
import { Table } from "antd";

export const CustomerShipmentCountTable = ({ value }: { value: DashDailyCustomerStats[] }) => {

    return (<Table<DashDailyCustomerStats>
        dataSource={value}
        rowKey="customerId"
        pagination={false}
        scroll={{
            y: 400
        }}
    >
        <Table.Column width={80} dataIndex="customerCode" title="Šifra" />
        <Table.Column dataIndex="customerName" title="Korisnik" ellipsis />
        <Table.Column width={80} dataIndex="shipmentCount" title="Pošiljaka" />
    </Table>);
}

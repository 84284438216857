import { kApi } from "Api";
import { ApiCall } from "net-common-ui";
import { CustomerNotifConfig } from "./CustomerNotifConfig";

export interface CustomerNotifConfigTest {
    emailSubject?: string;
    emailBodyHtml?: string;
    smsBody?: string;
    errorMessage?: string;
    isSuccess?: boolean;
}


export function testCustomerNotifConfig(model: CustomerNotifConfig): ApiCall<CustomerNotifConfigTest> {
    return ApiCall.put(kApi, "customer-notif-config;test", model);
}
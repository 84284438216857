import React from "react";
import Bux from "Components/Bux";
import { Link, useLocation, useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import { Menu, Tooltip } from "antd";
import { useAuth } from "net-common-ui";
import {
    BarsOutlined,
    PoweroffOutlined,
    ClusterOutlined,
    ExperimentOutlined,
    EuroOutlined,
    AuditOutlined,
    ToolOutlined,
    FileExcelOutlined,
    DashboardOutlined,
    NotificationOutlined,
    DropboxOutlined,
    UploadOutlined
} from "@ant-design/icons";
import { Shipment } from './Shipment';
import { Customer } from './Customer';
import { PriceList } from './PriceList';
import { InvoiceList } from './Invoice';
import { Cod } from "./Cod";
import { Cfg } from "./Cfg";
import { Rpt } from "./Rpt";
import { Dash } from "./Dash";
import { ShipmentNotifLog } from "./ShipmentNotifLog";
import { useAppAccount, useCustomer } from "Views/MeContext";
import Config from "Config";

interface MenuItemEntry {
    label: string;
    route: string;
    icon?: React.ReactNode;
    items?: MenuItemEntry[];
}

const menuItems: MenuItemEntry[] = [
    { label: "Dashboard", route: "/dash", icon: <DashboardOutlined /> },
    {
        label: "Pošiljke", route: "/shipment", icon: <DropboxOutlined />, items: [
            { label: "Sve", route: "/shipment", icon: <BarsOutlined /> },
            { label: "Uvoz mase", route: "/shipment/import-weight", icon: <UploadOutlined /> },
            { label: "Obavijesti", route: "/shipment-notif-log", icon: <NotificationOutlined /> },
        ]
    },
    { label: "Korisnici", route: "/customer", icon: <ClusterOutlined /> },
    { label: "Cjenik", route: "/price-list/standard", icon: <AuditOutlined /> },
    { label: "Fakture", route: "/invoice", icon: <AuditOutlined /> },
    {
        label: "Otkupnine", route: "/cod", icon: <EuroOutlined />, items: [
            { label: "Uvoz isplate kurira", route: "/cod/carrier-transfer-import" },
            { label: "Izrada transfera", route: "/cod/transfer-group/build" },
            { label: "Transferi korisnicima", route: "/cod/transfer" },
            { label: "Grupe transfera", route: "/cod/transfer-group" },
            { label: "Pomoć", route: "/cod/help" }
        ]
    },
    { label: "Izvještaji", route: "/rpt", icon: <FileExcelOutlined /> },
    {
        label: "Postavke", route: "/cfg", icon: <ToolOutlined />, items: [
            { label: "Korisnički računi", route: "/cfg/account" },
            { label: "Države", route: "/cfg/country" },
            { label: "Regije", route: "/cfg/region" },
            { label: "Poštanski uredi", route: "/cfg/post-office" },
            { label: "Mjesta", route: "/cfg/settlement" },
            { label: "Administracija", route: "/cfg/management" }
        ]
    }
];


export const AdminRoot = () => {
    const location = useLocation();
    const match = useRouteMatch();
    const auth = useAuth();
    const customer = useCustomer();
    const appAccount = useAppAccount();

    return (
        <Bux.Layout>
            <Bux.MenuBar>
                <div className="spec-logo">
                    <img src="/assets/logo.svg" alt="Logex" />
                </div>
                {Config.envLabel === "Test" && <Tooltip title="Ovo je test okolina i u potpunosti je odvojena od produkcije!"><div className="spec-menu-env">TEST</div></Tooltip>}
                {Config.envLabel === "Development" && <div className="spec-menu-env">DEV</div>}
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
                    {menuItems.map(mi => {
                        if (mi.items) {
                            return (
                                <Menu.SubMenu key={match.url + mi.route + "-root"} icon={mi.icon} title={mi.label}>
                                    {mi.items.map(sub => (
                                        <Menu.Item key={match.url + sub.route} icon={sub.icon}>
                                            {sub.label}
                                            <Link to={match.url + sub.route} />
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            );
                        }
                        return (
                            <Menu.Item key={mi.route} icon={mi.icon}>
                                {mi.label}
                                <Link to={match.url + mi.route} />
                            </Menu.Item>
                        );
                    })}

                    {appAccount.inspectCustomerId &&
                        <Menu.Item key="/pub" icon={<ExperimentOutlined />} danger>
                            {customer.name}
                            <Link to="/pub" />
                        </Menu.Item>}
                    <Menu.Item key="/identity/logout" icon={<PoweroffOutlined />} onClick={auth.actions.signout}>Odjava</Menu.Item>
                </Menu>
            </Bux.MenuBar>

            <Switch>
                <Redirect from={match.url + "/"} to={match.url + "/dash"} exact />
                <Route key="/dash" path={match.url + "/dash"} component={Dash} />
                <Route key="/shipment" path={match.url + "/shipment"} component={Shipment} />
                <Route key="/customer" path={match.url + "/customer"} component={Customer} />
                <Route key="/price-list" path={match.url + "/price-list"} component={PriceList} />
                <Route key="/invoice" path={match.url + "/invoice"} component={InvoiceList} />
                <Route key="/cod" path={match.url + "/cod"} component={Cod} />
                <Route key="/cfg" path={match.url + "/cfg"} component={Cfg} />
                <Route key="/rpt" path={match.url + "/rpt"} component={Rpt} />
                <Route key="/shipment-notif-log" path={match.url + "/shipment-notif-log"} component={ShipmentNotifLog} />
            </Switch>

        </Bux.Layout>);
}

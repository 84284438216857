import { Switch, Route } from "react-router";

import { Grid } from "./Grid";
import { New } from "./New";

export const ShipmentGroup = ({ match }) => (
    <Switch>
        <Route path={match.url} exact component={Grid} />
        <Route path={match.url + "/new"} exact component={New} />
    </Switch>);

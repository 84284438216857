import { kApi } from "Api";
import { Settlement } from "Models/Codebook/Settlement";
import { ApiCall, UrlParam } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface AddressBookEntry {
    id?: number;
    customerId?: number;
    personName?: string;
    name?: string;
    street?: string;
    streetNo?: string;
    settlement?: Settlement;
    phone?: string;
    email?: string;

    isSender?: boolean;
    isRecipient?: boolean;
    isReadOnly?: boolean;
}

export function getAddressBookEntryById(id: UrlParam): ApiCall<AddressBookEntry> {
    return ApiCall.get(kApi, `address-book-entry/${id}`);
}

export function saveAddressBookEntry(model: AddressBookEntry): ApiCall<AddressBookEntry> {
    return ApiCall.put(kApi, "address-book-entry", model);
}

export function deleteAddressBookEntry(id: UrlParam): ApiCall<AddressBookEntry> {
    return ApiCall.delete(kApi, `address-book-entry/${id}`);
}

export function dsoAddressBookEntryByCustomer(customerId: UrlParam): DataSourceOptions<AddressBookEntry> {
    return {
        api: kApi,
        url: `customer/${customerId}/address-book-entry`
    };
}


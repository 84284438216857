import { useMemo } from "react";
import { DashShipmentPriceTrend } from "Models/Dash/DashShipmentPriceTrend"
import { Alert, Descriptions } from "antd";
import { CurrencyText, NumberText } from "Components/Text";



export const ShipmentPriceTrendSummary = ({ value }: { value: DashShipmentPriceTrend[] }) => {
    const agg = useMemo(() => aggregate(value), [value]);

    return <>
        <Descriptions bordered size="small" column={1} title={null}>
            <Descriptions.Item label="Ukupno pošiljaka:"><NumberText value={agg.totalSum} /></Descriptions.Item>
            <Descriptions.Item label="Prosječna cijena:"><strong><CurrencyText value={agg.averageSum} showSymbol /></strong></Descriptions.Item>
            <Descriptions.Item label="Najniža cijena:"><CurrencyText value={agg.min} showSymbol /></Descriptions.Item>
            <Descriptions.Item label="Najviša cijena:"><CurrencyText value={agg.max} showSymbol /></Descriptions.Item>
        </Descriptions>
        <Alert type="info" style={{ border: 0 }} message={<>
            <strong>Napomena!</strong>
            &nbsp;
            Tekuće obračunsko razdoblje se ne prikazuje. Biti će uključeno prilikom izdavanja nove fakture.
        </>} />
    </>;
}

function aggregate(value: DashShipmentPriceTrend[]) {
    let averageSum = 0, totalSum = 0, min = null, max = null;
    for (let i = 0; i < value.length; ++i) {
        const v = value[i];
        averageSum += v.average;
        totalSum += v.total;
        if (min === null || v.minimum < min) min = v.minimum;
        if (max === null || v.maximum > max) max = v.maximum;
    }

    return {
        totalSum,
        averageSum: averageSum / value.length,
        min,
        max
    };
}
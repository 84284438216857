import React from "react";
import dayjs from "dayjs";
import { Tag, Tooltip } from "antd";
import { CustomerPriceList } from "Models/Bus/PriceList/CustomerPriceList";
import { StandardPriceList } from "Models/Bus/PriceList/StandardPriceList";
import { DateDisplay } from "net-common-ui";

const tagProps: { style: React.CSSProperties } = { style: { cursor: "help" } };

export const getStatusTag = (pl: StandardPriceList | CustomerPriceList) => {
    if (!pl.isPublished) {
        return <Tooltip title="Cjenik je u pripremi (nije objavljen). U ovom statusu cjenik se može bez ograničenja uređivati, sve dok se ne objavi.">
            <Tag color="orange" {...tagProps}>Draft</Tag>
        </Tooltip>;
    }
    else if (pl.isActive) {
        return <Tooltip title="Cjenik se trenutno koristi pri izradi faktura.">
            <Tag color="green" {...tagProps}>Aktivan</Tag>
        </Tooltip>;
    }
    else if (pl.validFrom.isAfter(dayjs())) {
        return <Tooltip title={<span>Cjenik je objavljen, ali još nije aktivan. Automatski počinje vrijediti na dan: <DateDisplay value={pl.validFrom} />.</span>}>
            <Tag color="blue" {...tagProps}>Budući</Tag>
        </Tooltip>;
    }
    else {
        return <Tooltip title="Cjenik je arhiviran. Koristio se u prošlosti, ali više nije aktivan.">
            <Tag>Arhiva</Tag>
        </Tooltip>;
    }
};

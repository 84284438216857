import React from 'react';
import { Row, Col, Button, message, Alert, Descriptions } from 'antd';
import { ProfileContext } from '../Context';
import { Bux } from 'Components/Bux';
import {
    DeleteOutlined
} from '@ant-design/icons';
import { deleteInvoice, Invoice } from 'Models/Bus/Invoice';
import { CurrencyText, PercentText } from "Components/Text";
import { useHistory, Link } from 'react-router-dom';
import { DateDisplay } from "net-common-ui";
import { UpdatePaidButton } from "./UpdatePaidButton";
import { UpdateVerifyButton } from "./UpdateVerifyButton";
import { MailDisplay } from "Components/MailDisplay";
import { Ok, Warn, Error } from "Components/StatusIcon";
import { DownloadInvoiceButton } from 'Components/DownloadInvoiceButton';

export const MainTab = () => {
    const { state, dispatch } = React.useContext(ProfileContext);
    const { model } = state;
    const history = useHistory();

    const handlePay = async (invoice: Invoice) => {
        dispatch({ type: "INVOICE_PAID", payload: invoice });
    }

    const handleVerify = async (invoice: Invoice) => {
        dispatch({ type: "INVOICE_VERIFIED", payload: invoice });
    }

    const handleDelete = async () => {
        const result = await deleteInvoice(model.id).execute();
        if (result.success) {
            message.success("Faktura uspješno obrisana.");
            history.replace("/admin/invoice");
        }
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                {!model.isVerified && <Col span={4}>
                    <Button onClick={handleDelete} block danger icon={<DeleteOutlined />}>Obriši</Button>
                </Col>}
                {model.isVerified && <Col span={4}>
                    <UpdatePaidButton invoiceId={model.id} verifiedOn={model.verifiedOn} value={model.paidDate} onClick={handlePay} />
                </Col>}
                <Col span={4}>
                    <UpdateVerifyButton isVerified={model.isVerified} invoiceId={model.id} customerId={model.customer.id} onSave={handleVerify} />
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {!model.isVerified && <Alert
                message="Faktura nije verificirana!"
                style={{ marginBottom: 6 }}
                description={<>
                    <p>
                        Verificirajte fakturu kako biste završili proces i
                        omogućili daljnje kreiranje fakture za korisnika. Verifikacija će:
                    </p>
                    <ul>
                        <li>Dodjeliti broj fakture (računa)</li>
                        <li>Generirati i trajno pohraniti sve dokumente</li>
                        <li>Korisniku će faktura postati vidljiva</li>
                        <li>Modifikacije (i brisanje) više neće biti moguće</li>
                        <li>Biti će moguće generirati sljedeće fakture za korisnika</li>
                    </ul>
                </>}
                type="warning"
                showIcon
            />}


            <Descriptions bordered size="small" column={2}>
                <Descriptions.Item label="Broj">{model.number || "TBD"}</Descriptions.Item>
                <Descriptions.Item label="Godina">{model.year}</Descriptions.Item>

                <Descriptions.Item label="Uređaj">{model.deviceNumber}</Descriptions.Item>
                <Descriptions.Item label="Lokacija">{model.locationNumber}</Descriptions.Item>

                <Descriptions.Item label="Pošiljke" span={2}>
                    {<DateDisplay value={model.invoiceShipment.from} />}
                    &nbsp;-&nbsp;
                    {<DateDisplay value={model.invoiceShipment.to} />}
                </Descriptions.Item>

                <Descriptions.Item label="Kreiran">{<DateDisplay value={model.createdOn} />}</Descriptions.Item>
                <Descriptions.Item label="Kreirao">{model.createdBy.name}</Descriptions.Item>

                {model.isVerified
                    ? <>
                        <Descriptions.Item label="Verificirano">
                            <Ok /> <DateDisplay value={model.verifiedOn} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Verificirao">{model.verifiedBy.name}</Descriptions.Item>
                    </>
                    : <Descriptions.Item label="Verificiran" span={2}>
                        <Warn /> NE
                    </Descriptions.Item>}

                <Descriptions.Item label="Plaćeno">
                    {model.isPaid
                        ? <Ok extra={<DateDisplay value={model.paidDate} />} />
                        : <Warn extra="NE" />}
                </Descriptions.Item>
                <Descriptions.Item label="Rok plaćanja">
                    {model.isVerified
                        ? <span><DateDisplay value={model.dueOn} /> ({model.dueOnOffset} dana)</span>
                        : model.dueOnOffset + " dana od verifikacije"}
                </Descriptions.Item>

                <Descriptions.Item label="Poziv na broj" span={2}>HR00 {model.clearanceNumber}</Descriptions.Item>
                <Descriptions.Item label="Iznos" span={2}><CurrencyText value={model.price} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Rabat" span={2}><CurrencyText value={model.discount} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Porezna osnovica" span={2}><CurrencyText value={model.total} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Porez"><CurrencyText value={model.tax} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Porezna stopa"><PercentText value={model.taxCoef} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Ukupno" span={2}>
                    <strong>
                        <CurrencyText value={model.totalWithTax} showSymbol />
                    </strong>
                </Descriptions.Item>


                <Descriptions.Item label="Cjenik">
                    <Link to={"/admin/price-list/customer/" + model.customerPriceListId}>Prikaži cjenik</Link>
                </Descriptions.Item>

                <Descriptions.Item label="Obavijesti">
                    {model.verifyMailId
                        ? <><Ok /> Obavijest poslana <MailDisplay mailId={model.verifyMailId}>(Prikaži detalje)</MailDisplay></>
                        : <Error extra="Obavijest nije poslana." />}
                </Descriptions.Item>

                <Descriptions.Item label="Datoteke" span={2}>
                    {!model.isVerified && <Alert message="Faktura nije verificirana, sadržaj datoteke nije konačan!" type="warning" showIcon style={{ marginBottom: 6 }} />}
                    <DownloadInvoiceButton invoiceId={model.id} />
                </Descriptions.Item>
            </Descriptions>
        </Bux.Content>
    </>);
}

import { Pagination, Table, PageHeader, Tag, Button, Modal, message } from 'antd';
import { FilterForm, AddressBookFilter } from "./FilterForm"
import { applyGridState, useDataSource, useStateManager } from "net-common-ui";
import Bux from "Components/Bux";
import withFilterDrawer from 'Components/withFilterDrawer';
import { GridState } from 'net-common-ui/dist/Data/GridState';
import { AddressBookEntry, deleteAddressBookEntry, dsoAddressBookEntryByCustomer } from 'Models/Bus/AddressBookEntry';
import { useCustomer } from 'Views/MeContext';
import { Settlement } from 'Models/Codebook/Settlement';
import { SwapLeftOutlined, SwapRightOutlined, SwapOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { useState } from 'react';
import { AddressEditDialog } from "Components/AddressEditDialog"

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const customer = useCustomer();
    const [view, ds] = useDataSource(dsoAddressBookEntryByCustomer(customer.id));
    const [state, sm] = useStateManager<GridState<AddressBookFilter>>({ sort: { field: "id", dir: "desc" } });
    const [editingRecord, setEditingRecord] = useState<AddressBookEntry>(null);

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) => {
            fb
                .set("name", "eq", filter.name)
                .set("personName", "contains", filter.personName)
                .set("street", "contains", filter.street)
                .set("settlement.id", "eq", filter.settlement?.id)
                .set("phone", "eq", filter.phone)
                .set("email", "eq", filter.email);
            if (filter.isSender) {
                fb.set("isSender", "eq", true);
            }
            if (filter.isRecipient) {
                fb.set("isRecipient", "eq", true);
            }
        });
    });

    const handleFilterChange = (filter: AddressBookFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    const handleEditDialogClose = async (record: AddressBookEntry) => {
        setEditingRecord(null);

        if (record) {
            ds.execute({ force: true });
        }
    }

    const handleDelete = async (id: number) => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Obrisati adresu?</p>,
            okType: "primary",
            okText: "Obriši",
            okButtonProps: { danger: true },
            async onOk() {
                const result = await deleteAddressBookEntry(id).execute();
                if (result.success) {
                    message.success("Adresa uspješno obrisana.");
                    ds.execute({ force: true });
                }
            }
        });
    }

    return <Bux.Page>
        <Bux.Header>
            <PageHeader
                title="Adresar"
                extra={<Button type="primary" icon={<PlusOutlined />} onClick={() => setEditingRecord({})}>Dodaj</Button>} />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<AddressBookEntry>
                dataSource={view.data}
                pagination={false}
                rowKey={record => record.id || "0"}>
                <Table.Column width={280} dataIndex="name" title="Naziv" render={(value, record: AddressBookEntry) => (
                    value || <i>bez naziva</i>
                )} />
                {/* <Table.Column dataIndex="personName" title="Ime i prezime" ellipsis /> */}
                <Table.Column dataIndex="street" title="Ulica" ellipsis />
                <Table.Column dataIndex="settlement" title="Mjesto" ellipsis render={(value: Settlement) => `${value?.postOffice?.code} ${value?.name}`.trim()} />
                <Table.Column key="info" title="Info" width={110} render={(_, record: AddressBookEntry) => <EntryTag record={record} />} />
                <Table.Column key="actions" title="Akcije" width={110} render={(_, record: AddressBookEntry) => <>
                    {!record.isReadOnly && <Button type="link" onClick={() => setEditingRecord(record)} style={{ paddingLeft: 4, paddingRight: 4 }}>Uredi</Button>}
                    {!record.isReadOnly && <Button type="link" onClick={() => handleDelete(record.id)} style={{ paddingLeft: 4, paddingRight: 4 }}>Obriši</Button>}
                </>} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
        <AddressEditDialog
            value={editingRecord}
            visible={!!editingRecord}
            onClose={handleEditDialogClose}
            key={editingRecord?.id || "0"}
            allowOptionsEdit
        />
    </Bux.Page>;
};

const EntryTag = ({ record }: { record: AddressBookEntry }) => {
    let text: string;
    let icon: React.ReactNode;

    if (record.isReadOnly) {
        text = "Sjedište";
        icon = <SwapRightOutlined />;
    }
    else if (record.isSender && !record.isRecipient) {
        text = "Pošiljatelj";
        icon = <SwapRightOutlined />;
    }
    else if (record.isRecipient && !record.isSender) {
        text = "Primatelj";
        icon = <SwapLeftOutlined />;
    }
    else if (record.isSender && record.isRecipient) {
        text = "Poš/Prim";
        icon = <SwapOutlined />;
    }
    else {
        text = "";
        icon = null;
    }

    //return <Tooltip title={text}>{icon}</Tooltip>;
    return <Tag icon={icon}>{text}</Tag>
}
import React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

type InputWeightProps = Omit<InputNumberProps, "min" | "precision">;

export const InputWeight = React.forwardRef(({ ...props }: InputWeightProps, ref: any) => {
    return <InputNumber
        ref={ref}
        min={0}
        precision={0}
        placeholder="gr"
        autoComplete="off"
        autoCorrect="off"
        {...props}
    />;
});

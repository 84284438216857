import React, { useState } from 'react';
import { Row, Col, Button, Modal, message } from 'antd';
import { ProfileContext } from '../Context';
import { Bux } from 'Components/Bux';
import { DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { View } from './View';
import { PublishButton } from "./PublishButton";
import { deleteCustomerPriceList, getCustomerPriceListInfoById } from 'Models/Bus/PriceList/CustomerPriceList';
import { WarningOutlined } from '@ant-design/icons';

export const MainTab = () => {
    const [editing] = useState(false);
    const { state, dispatch } = React.useContext(ProfileContext);
    const { model, deletable } = state;
    const history = useHistory();

    const handleDelete = () => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Obrisati cjenik #{model.id}? Ova akcija je nepovratna!</p>,
            okType: "primary",
            okText: "Obriši",
            okButtonProps: { danger: true },
            async onOk() {
                const result = await deleteCustomerPriceList(model.id).execute()
                if (result.success) {
                    message.success("Korisnički cjenik uspješno obrisan.");
                    history.replace(`/admin/customer/${model.customer.id}/price-list`);
                }
            }
        });
    }

    const handlePublish = async (saved: boolean) => {
        if (saved) {
            dispatch({ type: "PL_LOADING" });
            const priceList = await getCustomerPriceListInfoById(model.id).execute();
            dispatch({ type: "PL_LOADED", payload: priceList.item });
        }
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <PublishButton customerPriceList={model} onClose={handlePublish} disabled={model.isPublished} />
                </Col>
                <Col span={4}>
                    <Button disabled={editing || !deletable} onClick={handleDelete} block danger icon={<DeleteOutlined />}>Obriši</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            <View />
        </Bux.Content>
    </>);
}

import { Button, Checkbox, Collapse, Form, Input } from 'antd';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { dsoSettlement, Settlement } from 'Models/Codebook/Settlement';
import { CustomerComboBox } from 'Components/CustomerComboBox';
import { Customer, dsoCustomer } from 'Models/Bus/Customer';
import { BoolRadioButtonSet, FilterBuilder, useDataSourceRef } from "net-common-ui";
import dayjs, { Dayjs } from 'dayjs';
import { FormInstance } from 'antd/lib/form';
import { StatusComboBox } from 'Components/StatusComboBox';
import { useIsAdmin } from 'Views/MeContext';
import { dsoStatus } from 'Models/Logi/Status';
import { DatePickerEx } from 'Components/DatePickerEx';
const { RangePicker } = DatePickerEx;

export interface ShipmentFilter {
    id?: string;
    barcode?: string;
    customer?: Customer[];

    statusCodes?: string[];
    statusOn?: [Dayjs, Dayjs];
    showDeleted?: boolean;
    showCancelled?: boolean;

    senderName?: string;
    senderSettlement?: Settlement;

    recipientName?: string;
    recipientSettlement?: Settlement;

    useCod?: boolean;
    //codAmount?: number;
    codRefNumber?: string;
    useDeliveryBy9?: boolean;
    useDeliveryBy11?: boolean;
    useReturnSignedDoc?: boolean;
    returnSignedDocNumber?: string;
    usePersonalDelivery?: boolean;
    useSaturdayDelivery?: boolean;
    usePovratnica?: boolean;
    useSaturdayPickup?: boolean;
    useNonstandardFormat?: boolean;

    createdOn?: [Dayjs, Dayjs];
    pickedOn?: [Dayjs, Dayjs];
    returnDeliveredOn?: [Dayjs, Dayjs];
    deliveredOn?: [Dayjs, Dayjs];

}

interface FilterFormProps {
    form?: FormInstance;
    initialFilter?: ShipmentFilter;
    onChange?: (filter: ShipmentFilter) => void;
}

const ranges: Record<string, [Dayjs, Dayjs]> = {
    "Danas": [dayjs(), dayjs()],
    "Zadnjih 5 dana": [dayjs().subtract(5, "days"), dayjs()],
    "Zadnih 10 dana": [dayjs().subtract(10, "days"), dayjs()],
    "Ovaj mjesec": [dayjs().startOf("month"), dayjs().endOf("month")],
}

export const FilterForm = ({ form, onChange, initialFilter = {} }: FilterFormProps) => {
    const admin = useIsAdmin();
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });
    const [customerDs] = useDataSourceRef(dsoCustomer());
    const [statusDs] = useDataSourceRef(dsoStatus(), {
        configure: b => b.setQuery(q => q.sort.set("LogicalOrder", "asc"))
    });

    return (
        <Form form={form} layout="vertical" name="filter" initialValues={initialFilter} onFinish={onChange}>

            <Collapse defaultActiveKey={['basic']}>
                <Collapse.Panel header="Osnovno" key="basic">
                    <Form.Item name="barcode" label="Barkod">
                        <Input />
                    </Form.Item>
                    <Form.Item name="id" label="Broj pošiljke">
                        <Input addonBefore="S" />
                    </Form.Item>
                    {admin && <Form.Item name="customer" label="Korisnik">
                        <CustomerComboBox dataSource={customerDs} multiple />
                    </Form.Item>}

                    <Form.Item name="statusCodes" label="Status">
                        <StatusComboBox multiple scalar="code" dataSource={statusDs} />
                    </Form.Item>
                    <Form.Item name="statusOn" label="Vrijeme statusa">
                        <RangePicker allowEmpty={[true, true]} ranges={ranges} format="L" />
                    </Form.Item>
                    <Form.Item name="showCancelled" valuePropName="checked">
                        <Checkbox>Prikaži stornirane pošiljke</Checkbox>
                    </Form.Item>
                    {admin && <Form.Item name="showDeleted" valuePropName="checked">
                        <Checkbox>Prikaži obrisane pošiljke</Checkbox>
                    </Form.Item>}
                </Collapse.Panel>

                <Collapse.Panel header="Pošiljatelj" key="sender">
                    <Form.Item name="senderName" label="Pošiljatelj">
                        <Input />
                    </Form.Item>
                    <Form.Item name="senderSettlement" label="Mjesto pošiljatelja">
                        <SettlementComboBox dataSource={settlementDs} />
                    </Form.Item>
                </Collapse.Panel>

                <Collapse.Panel header="Primatelj" key="recipient">
                    <Form.Item name="recipientName" label="Primatelj">
                        <Input />
                    </Form.Item>
                    <Form.Item name="recipientSettlement" label="Mjesto primatelja">
                        <SettlementComboBox dataSource={settlementDs} />
                    </Form.Item>
                </Collapse.Panel>

                <Collapse.Panel header="Usluge" key="services">
                    <Form.Item name="useDeliveryBy9" label="Dostava do 9h"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="useDeliveryBy11" label="Dostava do 11h"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="useReturnSignedDoc" label="Povrat potpisanog dokumenta"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="returnSignedDocNumber" label="Broj dokumenta"><Input /></Form.Item>
                    <Form.Item name="usePersonalDelivery" label="Uručenje osobno"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="useSaturdayDelivery" label="Uručenje subotom"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="useSaturdayPickup" label="Prikup subotom"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="usePovratnica" label="Povratnica"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="useNonstandardFormat" label="Nestandardni format"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="useCod" label="Otkupna pošiljka"><BoolRadioButtonSet nullable /></Form.Item>
                    <Form.Item name="codRefNumber" label="Ref. broj uplate"><Input /></Form.Item>
                </Collapse.Panel>

                {admin && <Collapse.Panel header="Događaji" key="events">
                    <Form.Item name="createdOn" label="Vrijeme kreiranja">
                        <RangePicker allowEmpty={[true, true]} ranges={ranges} format="L" />
                    </Form.Item>
                    <Form.Item name="pickedOn" label="Vrijeme preuzimanja">
                        <RangePicker allowEmpty={[true, true]} ranges={ranges} format="L" />
                    </Form.Item>
                    <Form.Item name="returnDeliveredOn" label="Vrijeme povrata">
                        <RangePicker allowEmpty={[true, true]} ranges={ranges} format="L" />
                    </Form.Item>
                    <Form.Item name="deliveredOn" label="Vrijeme uručenja">
                        <RangePicker allowEmpty={[true, true]} ranges={ranges} format="L" />
                    </Form.Item>
                </Collapse.Panel>}

                {/* <Collapse.Panel header="This is panel header 1" key="1">
                </Collapse.Panel> */}
            </Collapse>

            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden />
        </Form>
    )
}

export const applyShipmentFilter = (builder: FilterBuilder, filter: ShipmentFilter) => {
    builder
        .set("id", "eq", parseInt(filter.id) ? filter.id : null)
        .set("barcode", "eq", filter.barcode)
        .set("customer.id", "in", filter.customer && filter.customer.map(c => c.id))

        .set("status.code", "in", filter.statusCodes)
        .set("statusOn", "gte", filter.statusOn?.length > 0 ? filter.statusOn[0].format("YYYY-MM-DD") : null)
        .append("statusOn", "lte", filter.statusOn?.length > 1 ? filter.statusOn[1].add(1, "day").format("YYYY-MM-DD") : null)
        .set("isDeleted", "eq", filter.showDeleted ? null : false)
        .set("isCancelled", "eq", filter.showCancelled ? null : false)

        .set("sender.name", "contains", filter.senderName)
        .set("sender.settlement.id", "eq", filter.senderSettlement?.id)
        .set("recipient.name", "contains", filter.recipientName)
        .set("recipient.settlement.id", "eq", filter.recipientSettlement?.id)

        .set("useCod", "eq", filter.useCod)
        .set("codRefNumber", "contains", filter.codRefNumber)
        .set("useDeliveryBy9", "eq", filter.useDeliveryBy9)
        .set("useDeliveryBy11", "eq", filter.useDeliveryBy11)
        .set("useReturnSignedDoc", "eq", filter.useReturnSignedDoc)
        .set("returnSignedDocNumber", "contains", filter.returnSignedDocNumber)
        .set("usePersonalDelivery", "eq", filter.usePersonalDelivery)
        .set("useSaturdayDelivery", "eq", filter.useSaturdayDelivery)
        .set("usePovratnica", "eq", filter.usePovratnica)
        .set("useSaturdayPickup", "eq", filter.useSaturdayPickup)
        .set("useNonstandardFormat", "eq", filter.useNonstandardFormat)
        .setBetweenDates("createdOn", filter.createdOn)
        .setBetweenDates("pickedOn", filter.pickedOn)
        .setBetweenDates("returnDeliveredOn", filter.returnDeliveredOn)
        .setBetweenDates("deliveredOn", filter.deliveredOn)

    // .set("createdOn", "gte", filter.createdOn?.length > 0 ? filter.createdOn[0].format("YYYY-MM-DD") : null)
    // .append("createdOn", "lte", filter.createdOn?.length > 1 ? filter.createdOn[1].add(1, "day").format("YYYY-MM-DD") : null)
    // .set("pickedOn", "gte", filter.pickedOn?.length > 0 ? filter.pickedOn[0].format("YYYY-MM-DD") : null)
    // .append("pickedOn", "lte", filter.pickedOn?.length > 1 ? filter.pickedOn[1].add(1, "day").format("YYYY-MM-DD") : null)
    // .set("returnDeliveredOn", "gte", filter.returnDeliveredOn?.length > 0 ? filter.returnDeliveredOn[0].format("YYYY-MM-DD") : null)
    // .append("returnDeliveredOn", "lte", filter.returnDeliveredOn?.length > 1 ? filter.returnDeliveredOn[1].add(1, "day").format("YYYY-MM-DD") : null)
    // .set("deliveredOn", "gte", filter.deliveredOn?.length > 0 ? filter.deliveredOn[0].format("YYYY-MM-DD") : null)
    // .append("deliveredOn", "lte", filter.deliveredOn?.length > 1 ? filter.deliveredOn[1].add(1, "day").format("YYYY-MM-DD") : null)
}

import React, { useMemo } from "react";
import { DashShipmentPriceTrend } from "Models/Dash/DashShipmentPriceTrend"
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Spin } from "antd";

more(Highcharts);

function dlFormatter(this: Highcharts.PointLabelObject, options: Highcharts.DataLabelsOptions): (number | string | null | undefined) {
    if (this.series.name === "Prosjek") {
        return this.point.y.toFixed(2);
    }
    return undefined;
}

export const ShipmentPriceTrendChart = ({ value }: { value: DashShipmentPriceTrend[] }) => {

    const chartRef = React.useRef<{ chart: Highcharts.Chart, container: any }>();

    // if (chartRef.current?.chart) {
    //     chartRef.current.chart.showLoading("blka")
    // }
    const options: Highcharts.Options = useMemo(() => ({
        title: {
            text: null
        },
        chart: {
            //type: "arearange",
            height: 240
        },
        credits: {
            enabled: false
        },


        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%m/%Y}',
            }
        },
        yAxis: {
            title: {
                text: "EUR"
            }
        },

        plotOptions: {
            series: {
                pointStart: value.length > 0 ? Date.UTC(value[0].year, value[0].month - 1, 1) : new Date(),
                pointIntervalUnit: 'month',
                dataLabels: {
                    enabled: true,
                    formatter: dlFormatter,
                    rotation: 45,
                    y: -16
                }
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: ' EUR'
        },
        series: [{
            name: 'Prosjek',
            data: value.map(v => [Date.UTC(v.year, v.month - 1, 1), v.average]),
            color: "#2ca841",
            zIndex: 1,
            marker: {
                fillColor: 'black',
                lineWidth: 2
            }

        }, {
            name: 'Raspom cijene',
            data: value.map(v => [Date.UTC(v.year, v.month - 1, 1), v.minimum, v.maximum]),
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            //color: Highcharts.getOptions().colors[0],
            color: "#2ca841",
            fillOpacity: 0.2,
            zIndex: 0,
            marker: {
                enabled: false
            }
        }]
    } as Highcharts.Options), [value]);

    if (!value) return <Spin />

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
    />;
}

import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";

export interface ServiceType {
    id?: number;
    code?: string;
    name?: string;
    allowMultiPackage?: boolean;
    isActive?: boolean;
}

export function getServiceTypeById(id: UrlParam): ApiCall<ServiceType> {
    return ApiCall.get(kApi, `service-type/${id}`);
}

export function dsoServiceType(): DataSourceOptions<ServiceType> {
    return {
        api: kApi,
        url: "service-type"
    };
}
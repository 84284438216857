import React, { useMemo } from 'react';
import { Col, Descriptions, Row, Typography } from 'antd';
import { ProfileContext } from '../Context';
import { getStatusTag } from '../../../Common/Badges';
import { PriceListSummary } from "Components/Summary";
import { DateDisplay } from "net-common-ui";
import { Link } from 'react-router-dom';
import { combinePriceList } from 'Models/Bus/PriceList/PriceList';

export const View = () => {
    const { state } = React.useContext(ProfileContext);
    const { model, serviceTypes } = state;
    const { standardPriceList } = model;
    const combinedPriceList = useMemo(() => combinePriceList(model.priceList, standardPriceList.priceList), [model.priceList, standardPriceList]);

    return (
        <Row gutter={16}>
            <Col span={12}>
                <Descriptions bordered size="small" column={1} title={<Typography.Title level={3}>Osnovo</Typography.Title>}>
                    <Descriptions.Item label="Korisnik">
                        <Link to={"/admin/customer/" + model.customer.id}>{model.customer.code}</Link> {model.customer.name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Broj">#{model.ordinal}</Descriptions.Item>
                    <Descriptions.Item label="Standardni cjenik">
                        <Link to={"/admin/price-list/standard/" + standardPriceList.id}>#{standardPriceList.ordinal}</Link>
                        &nbsp;
                        (<DateDisplay value={standardPriceList.validFrom} />)
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">{getStatusTag(model)}</Descriptions.Item>

                    <Descriptions.Item label="Vrijedi od"><DateDisplay value={model.validFrom} /></Descriptions.Item>
                    <Descriptions.Item label="Vrijedi do"><DateDisplay value={model.validTo} /></Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={12}>
                <PriceListSummary priceList={combinedPriceList} serviceTypes={serviceTypes} />
            </Col>
        </Row>

    )
}

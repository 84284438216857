import React, { } from 'react';
import { DateDisplay, useDataSource, useStateManager, applyGridState } from "net-common-ui";
import { NewButton } from './NewButton';
import { Row, Col, Pagination, Table } from 'antd';
import { CustomerPriceList, dsoCustomerPriceListByCustomerId } from 'Models/Bus/PriceList/CustomerPriceList';
import { Link } from 'react-router-dom';
import { Bux } from 'Components/Bux';
import { ProfileContext } from '../Context';
import { getStatusTag } from "../../../PriceList/Common/Badges"
import { GridState } from 'net-common-ui/dist/Data/GridState';

export const PriceListTab = () => {
    const model = React.useContext(ProfileContext).state.model;

    const [view, ds] = useDataSource(dsoCustomerPriceListByCustomerId(model.id), { execute: true });
    const [, sm] = useStateManager<GridState<any>>({ sort: { field: "ordinal", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, null);
    });

    // const handleFilterChange = (filter: AppAccountFilter) => {
    //     sm.update(
    //         (draft) => {
    //             draft.filter = filter;
    //             draft.page = 1;
    //         },
    //         { executeDataSources: "force" },
    //     );
    // };


    const handleClose = (created: boolean) => {
        if (created) ds.execute({ force: true });
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <NewButton customer={model} onClose={handleClose} />
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            <Table<CustomerPriceList> rowKey="id" pagination={false} dataSource={view.data}>
                <Table.Column dataIndex="ordinal" title="Redni broj" render={(ordinal, record: CustomerPriceList) => (
                    <Link to={"/admin/price-list/customer/" + record.id}>Cjenik #{ordinal}</Link>
                )} />
                <Table.Column dataIndex="standardPriceList" title="Standardni cjenik" render={(spl: CustomerPriceList) => (
                    <Link to={"/admin/price-list/standard/" + spl.id}>Cjenik #{spl.ordinal}</Link>
                )} />
                <Table.Column dataIndex={["customer", "name"]} title="Korisnik" />
                <Table.Column dataIndex="invoiceCount" title="Izdano faktura" />
                <Table.Column dataIndex="validFrom" title="Vrijedi od" render={value => <DateDisplay value={value} />} />
                <Table.Column dataIndex="validTo" title="Vrijedi do" render={value => <DateDisplay value={value} />} />
                <Table.Column title="Status" render={(_, record: CustomerPriceList) => getStatusTag(record)} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </>);
}

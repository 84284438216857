import { useState } from 'react';
import { Button, Col, Empty, Row, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import utc from "dayjs/plugin/utc";
import { DatePickerEx } from "Components/DatePickerEx";

dayjs.extend(utc); // load utc plugin

interface Props {
    loading: boolean;
    onPreview: (rangeTo: Dayjs) => void;
}

export const Intro = ({ loading, onPreview }: Props) => {
    const [rangeTo, setRangeTo] = useState<Dayjs>(dayjs().utc().subtract(1, "day").startOf("day"));
    const maxValue = dayjs().startOf("day");

    return <Row justify="center">
        <Col span={24}>
            <Empty
                //imageStyle={{ height: 60 }}
                description={
                    <span>
                        Prije izrade grupe naloga i pojedinih naloga za isplatu, poželjno je pogledati što će biti učinjeno.
                    </span>
                }
            >
                <Typography.Paragraph>
                    Isplate do (uključivo): &nbsp;
                    <DatePickerEx value={rangeTo} onChange={setRangeTo} disabledDate={d => d.isAfter(maxValue) || d.isSame(maxValue)} showToday={false} format="L" />
                </Typography.Paragraph>
                <Button type="primary" loading={loading} onClick={() => onPreview(rangeTo)}>Prikaži pregled</Button>
            </Empty>

        </Col>
    </Row>;
};

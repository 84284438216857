import { Form, Button, Checkbox } from 'antd';
import { Dayjs } from 'dayjs';
import { FilterFormProps, DateRanges } from "../types";
import { FilePdfOutlined } from "@ant-design/icons";
import { DatePickerEx } from "Components/DatePickerEx";

interface FormModel {
    firstPageOnly?: boolean;
    verifiedOnRange?: [Dayjs, Dayjs];
}

export function FilterForm({ code, onSubmit, running }: FilterFormProps) {
    const [form] = Form.useForm();
    const transform = () => {
        form.validateFields().then((filter: FormModel) => {
            onSubmit("pdf", {
                firstPageOnly: filter.firstPageOnly,
                verifiedFrom: filter.verifiedOnRange[0].format("YYYY-MM-DD"),
                verifiedTo: filter.verifiedOnRange[1].add(1, "day").format("YYYY-MM-DD")
            });
        });
    }

    return <Form form={form} layout="vertical" name={"filter-" + code} onFinish={transform} initialValues={{ firstPageOnly: true }}>
        <Form.Item>
            Izvještaj uključuje verificirane fakture u odabranom razdoblju.
            Ukoliko niti jedna faktura ne zadovoljava filter, izvještaj će biti prazan.
        </Form.Item>
        <Form.Item name="verifiedOnRange" label="Vrijeme verificiranja fakture" rules={[{ required: true }]}>
            <DatePickerEx.RangePicker ranges={DateRanges} format="L" />
        </Form.Item>
        <Form.Item name={["firstPageOnly"]} valuePropName="checked">
            <Checkbox>Samo prva stranica</Checkbox>
        </Form.Item>
        <Form.Item>
            <Button type="primary" ghost disabled={running} onClick={() => form.resetFields()}>Očisti</Button>
            &nbsp;
            <Button htmlType="submit" type="primary" loading={running} icon={<FilePdfOutlined />}>Generiraj izvještaj</Button>
        </Form.Item>
    </Form>
}

import { useState } from "react";
import { Button, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { kApi } from "Api";
import { getAuthManager, parseJson } from "net-common-ui";
import { UploadProps } from "antd/lib/upload";
import { ExcelState } from "Models/ExcelState";
import { Dayjs } from "dayjs";

interface Props {
    date: Dayjs;
    onComplete: (result: ExcelState) => void;
    onSuccess?: (success: ExcelState) => void;
    onError?: (error: ExcelState) => void;
}

export const UploadButton = ({ date, onComplete, onSuccess, onError }: Props) => {
    const [uploading, setUploading] = useState(false);

    const handleResult = (response: any) => {
        const ers = response as ExcelState;

        if (ers.isSuccess) {
            // all good
            message.success("Obrada datoteke uspješna.");
            onSuccess && onSuccess(ers);
        }
        else {
            message.error("Greška kod uvoza.");
            onError && onError(ers);
        }
        onComplete(ers);
    };

    let endpoint = kApi.defaults.baseURL + "/import/shipment-weight";
    if (date) {
        endpoint += "?date=" + date.utc().format("YYYY-MM-DDTHH:mm:ss") + "z";
    }

    const props: UploadProps = {
        name: 'file',
        action: endpoint,
        showUploadList: false,
        headers: {
            "Authorization": "Bearer " + getAuthManager().bearer
        },
        onChange(info) {
            switch (info.file.status) {
                case "uploading":
                    setUploading(true);
                    break;

                case "done":
                case "error":
                    setUploading(false);
                    // for errors (problem model): info.file.response.state
                    // for success: info.file.response
                    if (info.file.response.state) {
                        handleResult(info.file.response.state);
                    }
                    else {
                        // use custom parseJson to handle dates correctly
                        const response = parseJson(info.file.xhr.responseText);
                        handleResult(response);
                    }
                    break;
            }
        }
    };

    return (<Upload {...props}>
        <Button loading={uploading} type="primary" icon={<UploadOutlined />}>Uvoz</Button>
    </Upload>);
}

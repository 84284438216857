import { Pagination, Table, PageHeader, Menu, Dropdown, Button } from 'antd';
import { FilterForm, CodTransferFilter } from "./FilterForm"
import { DateDisplay, useDataSource, useStateManager, applyGridState } from "net-common-ui";
import Bux from "Components/Bux";
import { CodTransfer, dsoCodTransfer } from 'Models/Bus/CodTransfer';
import { CurrencyText } from 'Components/Text';
import { Link } from 'react-router-dom';
import withFilterDrawer from 'Components/withFilterDrawer';
import { DownOutlined } from '@ant-design/icons';
import downloadStream from 'downloadStream';
import { kApi } from 'Api';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const downloadFile = async (url: string, defaultFileName: string) => {
    // filename is igonred if content disposition is successfully sniffed
    const result = await downloadStream(kApi, { url, sniffContentDisposition: true, fileName: defaultFileName });
    result.success && result.download();
}

const getFileMenu = (codTransferId: number) => (
    <Menu>
        <Menu.Item key="pdf" onClick={() => downloadFile(`/cod-transfer/${codTransferId}/pdf`, "invoice.pdf")}>
            Nalog (Pdf)
        </Menu.Item>
    </Menu>
);

const FilterDrawer = withFilterDrawer(FilterForm);

export function Grid() {
    const [view, ds] = useDataSource(dsoCodTransfer());
    const [state, sm] = useStateManager<GridState<CodTransferFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("id", "eq", parseInt(filter.id) ? filter.id : null)
        );
    });

    const handleFilterChange = (filter: CodTransferFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    return <Bux.Page>
        <Bux.Header>
            <PageHeader title={"Isplate otkupnih iznosa"} />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<CodTransfer>
                size="small"
                dataSource={view.data}
                pagination={false}
                //showHeader={false}
                rowKey="id">

                <Table.Column title="Šifra" dataIndex="code" width={90}
                    render={(code, record: CodTransfer) => <Link to={"cod-transfer/" + record.id}>{code}</Link>} />

                <Table.Column title="Poziv na broj" dataIndex="clearanceNumber" ellipsis />

                <Table.Column title="Vrijeme" dataIndex={["codTransferGroup", "createdOn"]} ellipsis
                    render={value => <DateDisplay value={value} showTime />} />
                <Table.Column title="Kreirao" dataIndex={["codTransferGroup", "createdBy", "name"]} ellipsis />

                <Table.Column title="Iznos" dataIndex="amount" width={120} render={value => <CurrencyText value={value} showSymbol />} />

                <Table.Column key="action" width={100} render={(_, codTransfer: CodTransfer) => (
                    <Dropdown overlay={getFileMenu(codTransfer.id)} trigger={['click']}>
                        <Button type="link" onClick={e => e.preventDefault()} icon={<DownOutlined />}>
                            Preuzmi
                        </Button>
                    </Dropdown>
                )} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />        </Bux.Footer>
    </Bux.Page>;
};

import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { ServiceType } from "Models/Codebook/ServiceType";
import { CodType, DeliveryDeadline } from 'Models/Enum';

export interface CustomerServiceType {
    customerId?: number;
    serviceType?: ServiceType;

    minDeliveryDeadlineId?: number;
    allowMultiPackage?: boolean;

    allowDeliveryBy9?: boolean;
    allowDeliveryBy11?: boolean;
    allowCod?: boolean;
    codTypeId?: CodType;
    allowReturnSignedDoc?: boolean;
    allowPersonalDelivery?: boolean;
    allowSaturdayDelivery?: boolean;
    allowPovratnica?: boolean;
    allowSaturdayPickup?: boolean;
    allowNonstandardFormat?: boolean;
    allowNotifySenderSms?: boolean;
    allowNotifySenderEmail?: boolean;
    allowNotifyRecipientSms?: boolean;
    allowNotifyRecipientEmail?: boolean;

    isActive?: boolean;
}

export function getCustomerServiceTypeDefault(customerId: number, serviceType: ServiceType): CustomerServiceType {
    return {
        customerId,
        serviceType,
        minDeliveryDeadlineId: serviceType.code === "Cargo" ? DeliveryDeadline.D3 : DeliveryDeadline.Unknown,
        allowMultiPackage: false,
        allowDeliveryBy9: false,
        allowDeliveryBy11: false,
        allowCod: false,
        codTypeId: CodType.None,
        allowReturnSignedDoc: false,
        allowPersonalDelivery: false,
        allowSaturdayDelivery: false,
        allowPovratnica: false,
        allowSaturdayPickup: false,
        allowNonstandardFormat: false,
        allowNotifySenderEmail: false,
        allowNotifySenderSms: false,
        allowNotifyRecipientEmail: false,
        allowNotifyRecipientSms: false,
        isActive: false,
    };
}

export function getCustomerServiceTypeByCustomerId(customerId: UrlParam, serviceTypeId: UrlParam): ApiCall<CustomerServiceType> {
    return ApiCall.get(kApi, `customer/${customerId}/service-type/${serviceTypeId}`);
}

export function saveCustomerServiceType(model: CustomerServiceType): ApiCall<CustomerServiceType> {
    return ApiCall.put(kApi, `customer/${model.customerId}/service-type/${model.serviceType.id}`, model);
}
import React, { useState } from 'react';
import { Button, Descriptions, Drawer } from 'antd';
import { getSmsById, Sms } from "Models/Bus/Sms"
import { DateDisplay } from "net-common-ui";
import { Ok, Error } from "./StatusIcon";

interface Props {
    smsId: number;
}

export const SmsDisplay = ({ smsId, children }: React.PropsWithChildren<Props>) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sms, setSms] = useState<Sms>(null);

    const showDrawer = async () => {
        setLoading(true);
        const result = await getSmsById(smsId, true).execute();
        setSms(result.item);
        setLoading(false);
        setVisible(true);

    };
    const onClose = () => {
        setVisible(false);
    };

    return <>
        <Button disabled={loading} type="link" onClick={showDrawer} style={{ padding: 0, height: "auto" }}>{children || "Prikaži detalje"}</Button>
        <Drawer
            title="Detalji SMS poruke"
            placement="right"
            closable
            onClose={onClose}
            width={500}
            visible={visible}
            bodyStyle={{ padding: 6 }}
        >
            {!sms && <p>Greška prilikom učitavanja podataka o SMS poruci</p>}
            {sms && <div>
                <Descriptions bordered size="small" column={1} title={null}>
                    <Descriptions.Item label="Primatelj">{sms.toAddressGdpr}</Descriptions.Item>
                    <Descriptions.Item label="Kreirano"><DateDisplay value={sms.createdOn} showTime /></Descriptions.Item>
                    <Descriptions.Item label="Obrađeno"><DateDisplay value={sms.processedOn} showTime /></Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {sms.errorMessage ? <Error extra={sms.errorMessage} /> : <Ok extra="Uspješno poslano" />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Sadžaj">{sms.body}</Descriptions.Item>
                </Descriptions>
            </div>}
        </Drawer>
    </>;
}

import { Switch, Route, Redirect } from "react-router";
import { Invoice } from "./Invoice";
import { CodTransfer } from "./CodTransfer";

export const Transaction = ({ match }) => (
    <Switch>
        <Route path={match.url} exact>
            <Redirect to={match.url + "/invoice"} />
        </Route>
        <Route path={match.url + "/invoice"} component={Invoice} />
        <Route path={match.url + "/cod-transfer"} component={CodTransfer} />
    </Switch>);

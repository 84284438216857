import { Switch, Route } from "react-router";

import { Customer } from "./Customer";
import { Standard } from "./Standard";

export const PriceList = ({ match }) => (
    <Switch>
        <Route path={match.url + "/standard"} component={Standard} />
        <Route path={match.url + "/customer"} component={Customer} />
    </Switch>);

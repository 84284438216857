import { Pagination, PageHeader } from 'antd';
import { FilterForm, ShipmenNotifLogFilter } from "./FilterForm"
import { useDataSource, useStateManager, applyGridState } from "net-common-ui";
import Bux from "Components/Bux";
import withFilterDrawer from 'Components/withFilterDrawer';
import { dsoShipmentNotifLogByCustomerId } from 'Models/Logi/ShipmentLog/ShipmentNotifLog';
import { useCustomer } from 'Views/MeContext';
import { ShipmentNotifTable } from "Components/Shipment/NotifTable";
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const customer = useCustomer();

    const [view, ds] = useDataSource(dsoShipmentNotifLogByCustomerId(customer.id));
    const [state, sm] = useStateManager<GridState<ShipmenNotifLogFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("barcode", "eq", filter.barcode)
        );
    });

    const handleFilterChange = (filter: ShipmenNotifLogFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };


    return <Bux.Page>
        <Bux.Header>
            <PageHeader title={"Obavijesti"} />
        </Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <ShipmentNotifTable dataView={view} />
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />        </Bux.Footer>
    </Bux.Page>;
};

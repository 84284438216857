import { useMemo, useState } from 'react';
import { PriceInsuranceRange } from 'Models/Bus/PriceList/PriceInsuranceRange';
import { InputMoney } from 'Components/InputMoney';
import { Alert, Descriptions } from 'antd';
import { CurrencyText, PercentText } from 'Components/Text';

export const InsuranceExample = ({ ranges }: { ranges: PriceInsuranceRange[] }) => {
    const [value, setValue] = useState(5000);
    const range = useMemo(() => {
        return ranges?.find(r => r.valueFrom <= value && value <= r.valueTo);
    }, [ranges, value]);

    const { price = 0, priceCoef = 0, valueFrom = 0, valueTo = 0 } = range || {};

    const price1 = price;
    const price2 = (value - valueFrom) * priceCoef;

    return <Descriptions column={1} bordered>
        <Descriptions.Item label="Vrijednost pošiljke"><InputMoney value={value} onChange={v => setValue(v as number)} /> EUR</Descriptions.Item>
        <Descriptions.Item label="Koristi se razred">
            {range
                ? <span><CurrencyText value={valueFrom} showSymbol /> - <CurrencyText value={valueTo} showSymbol /></span>
                : <Alert type="warning" message="Niti jedan razred ne odgovara upisanoj vrijednosti!" />}
        </Descriptions.Item>
        <Descriptions.Item label="Fiksna cijena">
            <CurrencyText value={price1} showSymbol style={{ fontWeight: "bold" }} />
        </Descriptions.Item>
        <Descriptions.Item label={<span>Uvećanje</span>}>
            <CurrencyText value={value} showSymbol /> - <CurrencyText value={valueFrom} showSymbol />
            &nbsp;=&nbsp;
            <CurrencyText value={value - valueFrom} showSymbol /> x <PercentText value={priceCoef} showSymbol />
            &nbsp;=&nbsp;
            <CurrencyText value={price2} showSymbol style={{ fontWeight: "bold" }} />
        </Descriptions.Item>
        <Descriptions.Item label="Ukupno">
            <CurrencyText value={price1 + price2} showSymbol style={{ fontWeight: "bold" }} />
        </Descriptions.Item>
    </Descriptions>;
}

import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { Settlement } from "Models/Codebook/Settlement";

export interface CustomerSender {
    customerId?: number;
    isSenderLocked?: boolean;
    useHqPoint?: boolean;

    name?: string;
    personName?: string;
    street?: string;
    streetNo?: string;
    settlement?: Settlement;
    phone?: string;
    email?: string;
}

export function getCustomerSenderByCustomerId(customerId: UrlParam): ApiCall<CustomerSender> {
    return ApiCall.get(kApi, `customer/${customerId}/customer-sender`);
}

export function saveCustomerSender(model: CustomerSender): ApiCall<CustomerSender> {
    return ApiCall.put(kApi, `customer/${model.customerId}/customer-sender`, model);
}

import React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

type InputMoneyProps = Omit<InputNumberProps, "precision" | "decimalSeparator">;

export const InputMoney = React.forwardRef(({ min, ...props }: InputMoneyProps, ref: any) => {
    // default width: 200ox

    return <InputNumber
        ref={ref}
        min={min === undefined ? 0 : min} // default min to 0
        precision={2}
        placeholder="0,00 EUR"
        decimalSeparator=","
        autoComplete="off"
        autoCorrect="off"
        {...props}
    />
});

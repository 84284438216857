import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";

export interface Mail {
    id?: number;
    sourceKey?: string;
    toAddress?: string;
    toAddressGdpr?: string;
    subject?: string;
    bodyText?: string;
    bodyHtml?: string;
    attachmentFileMetadataId?: number;
    createdOn?: Dayjs;
    processedOn?: Dayjs;
    attemptCount?: number;
    errorMessage?: string;
    isPending?: boolean;
    isSuccess?: boolean;
}

export function getMailById(id: UrlParam, includeBody: boolean = false): ApiCall<Mail> {
    return ApiCall.get(kApi, `mail/${id}`, { includeBody });
}
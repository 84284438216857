import { CSSProperties } from 'react';
import NumberFormat from 'react-number-format';

interface TextProps {
    value?: number;
    showSymbol?: boolean | string;
    style?: CSSProperties;
    precision?: number;
}

function getSuffix(showSymbol: boolean | string, defaultSymbol: string, space: boolean) {
    if (showSymbol) {
        const symbol = typeof showSymbol === "string" ? showSymbol : defaultSymbol;
        return (space ? " " : "") + symbol;
    }
    return "";
}

export const CurrencyText = ({ value, showSymbol, style }: TextProps) => <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator="."
    decimalSeparator=","
    fixedDecimalScale
    decimalScale={2}
    suffix={getSuffix(showSymbol, "EUR", true)}
    style={style} />;

export const PercentText = ({ value, showSymbol, style, precision }: TextProps) => <NumberFormat
    value={value * 100}
    displayType={'text'}
    decimalSeparator=","
    fixedDecimalScale
    decimalScale={precision ?? 2}
    suffix={getSuffix(showSymbol, "%", false)}
    style={style} />;

export const NumberText = ({ value, style, precision }: TextProps) => <NumberFormat
    value={value}
    displayType={'text'}
    decimalSeparator=","
    fixedDecimalScale
    decimalScale={precision ?? 0}
    style={style} />;

export const WeightText = ({ value, showSymbol, style, kg }: TextProps & { kg?: boolean }) => <NumberFormat
    value={kg ? value / 1000 : value}
    displayType={'text'}
    decimalSeparator=","
    fixedDecimalScale
    decimalScale={kg ? 3 : 0}
    suffix={getSuffix(showSymbol, kg ? "kg" : "gr", true)}
    style={style} />;

import { kApi } from "Api";
import { UrlParam, ApiCall } from "net-common-ui";
import { Dayjs } from "dayjs";
import { CustomerSender } from "./CustomerSender";
import { CustomerServiceType } from "./CustomerServiceType";
import { AppAccount } from "./AppAccount";
import { Settlement } from "Models/Codebook/Settlement";
import { LabelFormat } from "Models/Enum";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { ConsumerActor } from "net-common-ui/dist/Auth/ConsumerActor";

export interface Customer {
    id?: number;
    code?: number;
    name?: string;
    oib?: string;
    invoiceIntervalId?: number;
    invoiceDueOnOffset?: number;
    contractNumber?: string;
    iban?: string;
    codIban?: string;
    note?: string;
    createdBy?: ConsumerActor;
    createdOn?: Dayjs;
    isActive?: boolean;
    activeCustomerPriceListId?: number;
    appAccount?: AppAccount;
    customerSender?: CustomerSender;
    customerServiceTypes?: CustomerServiceType[];

    street?: string;
    streetNo?: string;
    settlement?: Settlement;
    phone?: string;
    email?: string;
    emailInvoice?: string;
    emailCodTransfer?: string;
    labelFormatId?: LabelFormat;
}

export function getCustomerById(id: UrlParam): ApiCall<Customer> {
    return ApiCall.get(kApi, `customer/${id}`);
}

export function getCustomerInfoById(id: UrlParam): ApiCall<Customer> {
    return ApiCall.get(kApi, `customer/${id};info`);
}

export function getCustomerMe(): ApiCall<Customer> {
    return ApiCall.get(kApi, "me/customer");
}

export function getCustomerInfoMe(): ApiCall<Customer> {
    return ApiCall.get(kApi, "me/customer;info");
}

export function saveCustomer(model: Customer): ApiCall<Customer> {
    return ApiCall.put(kApi, "customer", model);
}

export function saveCustomerNote(id: UrlParam, note: string): ApiCall<Customer> {
    return ApiCall.patch(kApi, `customer/${id}/note`, note);
}

export function dsoCustomer(): DataSourceOptions<Customer> {
    return {
        api: kApi,
        url: "customer"
    };
}
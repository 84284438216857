import { useState } from 'react';
import { AppAccount, saveAppAccount } from "Models/Bus/AppAccount"
import { Form, Button, Row, Col, Checkbox, Input, Typography, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

interface Props {
    appAccount: AppAccount;
    customerId: number;
    onUpdate: (updated: boolean, appAccount?: AppAccount) => void;
}

export const Edit = ({ appAccount, customerId, onUpdate }: Props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const isInsert = !appAccount;
    const model: AppAccount = appAccount || {
        customerId,
        isAdmin: false,
        isAccountEnabled: true
    };

    const handleCancel = () => {
        onUpdate(false);
    }
    const handleSave = () => {
        form.validateFields().then((fields: AppAccount) => {
            setSaving(true);
            saveAppAccount({ ...model, ...fields }).execute().then(result => {
                setSaving(false);
                if (result.success) {
                    message.success("Korisnički račun uspješno spremljen.");
                    onUpdate(true, result.item);
                }
            });
        });
    }

    return (
        <Form form={form} layout="vertical" name="form" onFinish={handleSave} initialValues={model}>
            <Row gutter={[8, 40]} justify="center">
                <Col span={8}>
                    <Typography.Title level={3}>Korisnički račun</Typography.Title>
                    <Form.Item name="identifier" label="Identifikator">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="fullName" label="Ime i prezime" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type: "email" }]}>
                        <Input />
                    </Form.Item>
                    {isInsert && <Form.Item name="password" label="Lozinka" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>}
                    <Form.Item name="isAccountEnabled" valuePropName="checked">
                        <Checkbox>Račun omogućen (prijava je moguća)</Checkbox>
                    </Form.Item>

                </Col>
            </Row>

            <Row gutter={8} justify="center">
                <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
                <Col span={4}><Button disabled={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
            </Row>
        </Form>
    )
}

import { useEffect, useState } from 'react';
import { Checkbox, Col, Empty, PageHeader, Row, Typography } from 'antd';
import { UploadButton } from "./UploadButton"
import { Bux } from 'Components/Bux';
import { ExcelStateDisplay } from 'Components/ExcelStateDisplay';
import { ExcelState } from 'Models/ExcelState';
import { DatePickerEx } from 'Components/DatePickerEx';
import dayjs, { Dayjs } from 'dayjs';
import { getInvoiceInsight } from "Models/Bus/InvoiceInsight"

export const WeightImport = () => {
    const [ers, setErs] = useState<ExcelState>(null);
    const [useCustomDate, setUseCustomDate] = useState<boolean>(false);
    const [date, setDate] = useState<Dayjs>(dayjs());
    const [minDate, setMinDate] = useState<Dayjs>(null);

    useEffect(() => {
        getInvoiceInsight().execute().then(result => {
            if (result.success) {
                setMinDate(result.item.firstFreeDate);
            }
        })
    }, []);

    const disabledDate = (value: Dayjs) => {
        return (minDate && value.isBefore(minDate)) || value.isAfter(dayjs().add(1, "day").startOf("day"));
    }

    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title={"Uvoz verificirane mase"} />
            </Bux.Header>
            <Bux.Content>
                {!ers && <Row justify="center">
                    <Col md={12} sm={18} xs={24} >
                        <Empty description="Uvezite izvještaj sa verificiranom masom.">
                            <Typography.Paragraph style={{ textAlign: "left" }}>
                                <Typography.Paragraph>
                                    Ažurirati će se svi paketi koji postoje u Excel datoteci. Uparuje se koristeći "Barkod" polje. Vrijednost iz stupca "Masa" se postavlja
                                    kao verificirana masa. Datum i vrijeme postavljanja verif. mase će utjecati na datum fakturiranja pošiljke. Datum se automatski postavlja
                                    na trenutno vrijeme. Ukoliko trenutno vrijeme nije poželjno, moguće ga je premostiti koristeći opciju ispod.
                                </Typography.Paragraph>
                                <Checkbox checked={useCustomDate} onChange={x => setUseCustomDate(x.target.checked)}>
                                    Vrijeme sravnjivanja:
                                </Checkbox>
                                <DatePickerEx disabled={!useCustomDate} value={date} onChange={setDate} disabledDate={disabledDate} showToday={false} showTime format="L LTS" />
                                <Typography.Paragraph type="secondary">Odabrano vrijeme ne može biti unutar perioda koji je već pokriven fakturom.</Typography.Paragraph>
                            </Typography.Paragraph>
                            <UploadButton key="upload" date={useCustomDate ? date : null} onComplete={ers => setErs(ers)} />
                        </Empty>
                    </Col>
                </Row>}
                {ers && <ExcelStateDisplay result={ers} backHref="/admin/shipment" />}
            </Bux.Content>
        </Bux.Page>
    );
};

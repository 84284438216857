import { Customer } from 'Models/Bus/Customer';
import { Shipment } from 'Models/Logi/Shipment';
import { ServiceType } from 'Models/Codebook/ServiceType';
import { Package } from 'Models/Logi/Package';
import { CodType, DeliveryType } from 'Models/Enum';
import { ShipmentSender } from 'Models/Logi/ShipmentSender';
//import { Settlement } from 'Models/Codebook/Settlement';

export function getIsSenderLocked(customer: Customer) {
    return customer?.customerSender?.isSenderLocked ?? false;
}

function getSender(customer: Customer): ShipmentSender {
    if (getIsSenderLocked(customer)) {
        const ccs = customer.customerSender;

        if (ccs.useHqPoint) {
            return {
                name: customer.name,
                personName: null,
                street: customer.street,
                streetNo: customer.streetNo,
                settlement: customer.settlement,
                email: customer.email,
                phone: customer.phone
            };
        }
        else {
            return {
                name: ccs.name,
                personName: ccs.personName,
                street: ccs.street,
                streetNo: ccs.streetNo,
                settlement: ccs.settlement,
                email: ccs.email,
                phone: ccs.phone
            };
        }
    }

    // if (sessionStorage["LastSender"]) {
    //     try {
    //         return JSON.parse(sessionStorage["LastSender"]);
    //     }
    //     catch
    //     {
    //     }
    // }

    return null;
}

export function newShipmentFactory(customer: Customer, serviceType: ServiceType): Shipment {

    const pack: Package = { ordinal: 1 };
    const cst = customer.customerServiceTypes.find(x => x.customerId === customer.id && x.serviceType.id === serviceType.id);

    let codTypeId: CodType;

    if (sessionStorage["LastCodTypeId"] && (cst.codTypeId & sessionStorage["LastCodTypeId"]) !== 0) {
        codTypeId = parseInt(sessionStorage["LastCodTypeId"]);
    }
    else {
        // https://stackoverflow.com/questions/12247186/find-the-lowest-set-bit
        codTypeId = cst.codTypeId & -cst.codTypeId;
    }
    //console.log(customer);

    //draft.model["useNotifyEmail"] = action.payload.useNotifyRecipientEmail || action.payload.useNotifySenderEmail;
    // draft.model["useNotifySms"] = action.payload.useNotifyRecipientSms || action.payload.useNotifySenderSms;


    const shipment: Partial<Shipment> = {
        deliveryDeadlineId: 3, // d+3
        useDeliveryBy11: false,
        useDeliveryBy9: false,
        useNotifySms: cst.allowNotifySenderSms || cst.allowNotifyRecipientSms,
        useNotifyEmail: cst.allowNotifySenderEmail || cst.allowNotifyRecipientEmail,
        customer: customer,
        serviceType,
        deliveryTypeId: DeliveryType.Address,
        sender: getSender(customer),
        codTypeId: codTypeId,
        //sender: PointModel.fromJSON({  personName: "bob", name: "bobco", street: "str", streetNo: "1", 
        //settlement: Settlement.fromJSON({ id: 1, name: "Zagreb", postOffice: { id: 1, code: "10000", name: "Zagreb" } }) 
        //}),
        //recipient: PointModel.fromJSON({  personName: "alice", name: "aliceco", street: "str", streetNo: "1", 
        //settlement: Settlement.fromJSON({ id: 7, name: "Podvinje", postOffice: { id: 2, code: "35000", name: "Slavonski Brod" } }) 
        //}),
        packages: [pack]
    };

    var result = shipment;
    return result;
};


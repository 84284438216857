import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ProfileContext } from '../Context';
import { Bux } from 'Components/Bux';
import { InsuranceEdit } from "Views/Admin/PriceList/Common/InsuranceEdit"
import { InsuranceView } from 'Views/Admin/PriceList/Common/InsuranceView';
import { PriceInsuranceRange } from 'Models/Bus/PriceList/PriceInsuranceRange';

export const InsuranceTab = () => {
    const { state, dispatch } = React.useContext(ProfileContext);
    const { editable } = state;
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    }
    const handleEditFinish = (saved: boolean, range?: PriceInsuranceRange[]) => {
        setIsEditing(false);
        saved && dispatch({ type: "INSURANCERANGES_SAVED", payload: range });
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button disabled={!editable || isEditing} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {isEditing
                ? <InsuranceEdit
                    priceList={state.model.priceList}
                    onFinish={handleEditFinish}
                />
                : <InsuranceView
                    priceList={state.model.priceList}
                />}
        </Bux.Content>
    </>);
}

import axios, { AxiosRequestConfig } from 'axios';
import Config from "Config";
import { authInterceptor, errorInterceptor, jsonPatchInterceptor } from "net-common-ui";

export const kApi = axios.create({ baseURL: Config.api.k });
kApi.interceptors.request.use(authInterceptor);
kApi.interceptors.request.use(jsonPatchInterceptor);
kApi.interceptors.response.use(response => response, errorInterceptor)


// append custom (business) headers
kApi.interceptors.request.use(function (config: AxiosRequestConfig): AxiosRequestConfig {
    config.headers["x-customerid"] = sessionStorage.getItem("CustomerId");
    return config;
});


import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useCallback } from 'react';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

interface Props {
    useStandard: boolean;
    onChange: (useStandard: boolean) => void;
}

export const PriceListSourceRadioGroup = ({ useStandard, onChange }: Props) => {

    const handleChange = useCallback((e: RadioChangeEvent) => {
        onChange(e.target.value === 1);
    }, [onChange]);

    return (<Radio.Group onChange={handleChange} value={useStandard ? 1 : 2}>
        <Radio style={radioStyle} value={1}>Koristi postavke standardnog cjenika</Radio>
        <Radio style={radioStyle} value={2}>Koristi zasebne postavke za korisnika</Radio>
    </Radio.Group>);
}

import React, { useState } from 'react';
import { Edit } from './Edit';
import { View } from './View';
import { Row, Col, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Bux } from 'Components/Bux';
import { ServiceType } from 'Models/Codebook/ServiceType';
import { CustomerServiceType, getCustomerServiceTypeDefault } from 'Models/Bus/CustomerServiceType';
import { ProfileContext } from '../Context';
import { useMemo } from 'react';

interface Props {
    serviceType: ServiceType;
}

export const ServiceTab = ({ serviceType }: Props) => {
    const { state, dispatch } = React.useContext(ProfileContext);
    const { model } = state;
    const [editing, setEditing] = useState(false);

    const cst = useMemo(() => {
        const value = model.customerServiceTypes.find(cst => cst.serviceType.id === serviceType.id)
            || getCustomerServiceTypeDefault(model.id, serviceType);
        return { ...value, serviceType };
    }, [serviceType, model]);

    const handleEdit = () => {
        setEditing(true);
    }
    const handleUpdate = (updated: boolean, customerServiceType: CustomerServiceType) => {
        if (updated) {
            dispatch({ type: "CST_SAVED", payload: customerServiceType });
        }
        setEditing(false);
    }


    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button disabled={editing} type="primary" onClick={handleEdit} block icon={<EditOutlined />}>Uredi</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            {editing
                ? <Edit customerServiceType={cst} onUpdate={handleUpdate} />
                : <View customerServiceType={cst} />
            }
        </Bux.Content>
    </>);
}

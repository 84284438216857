import { Typography, Checkbox, Col, Form, Row, Popover, Descriptions, Button, message } from 'antd';
import { InputMoney } from 'Components/InputMoney';
import { DateDisplay } from "net-common-ui";
import { getShipmentCodAllowUpdate, ShipmentCod } from 'Models/Logi/ShipmentCod';
import { CurrencyText } from 'Components/Text';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { CodType } from 'Models/Enum';

const { Text, Paragraph } = Typography;

interface Props {
    value: ShipmentCod[];
    saving: boolean;
    onSave: (model: ShipmentCod[]) => void;
    onCancel: () => void;
}

function getStatusCell(item: ShipmentCod) {
    if (!item.shipmentId) {
        return <Error text="Pošiljka nije pronađena" />;
    }
    else {
        // shipment does not have cod option defined - treated same as if not found
        if (!item.shipment.useCod) return <Error text="Pošiljka nije otkupna" />;
        if (item.shipment.codTypeId !== CodType.BezNaloga) return <Error text="Pošiljka je otkupna, ali nije bez naloga." />;

        // already imported
        if (item.existingShipmentCod) return <AlreadyImported shipmentCod={item.existingShipmentCod} />;

        // final fallback, status info
        return <StatusInfo statusCode={item.shipment.status.code} />;
    }
}

export const EditForm = ({ value, saving, onSave, onCancel }: Props) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields().then((values: { items: ShipmentCod[] }) => {
            const model = values.items
                .filter(m => m.shouldUpdate); // take only checked items for save!

            if (model.length) {
                onSave(model);
            }
            else {
                message.error("Niti jedan zapis nije odabran.");
            }

        });
    }
    return (
        <Form form={form} initialValues={{ items: value }} onFinish={handleSubmit}>
            <Row gutter={[8, 24]} className="spec-table-header">
                <Col span={1}></Col>
                <Col span={3}>Barkod</Col>
                <Col span={2}>Prim. platio</Col>
                <Col span={2}>Kurir uplatio</Col>
                <Col span={2}>Uručeno</Col>
                <Col span={2}>Primljeno</Col>
                <Col span={2}>Traženo</Col>
                <Col span={4}>Status</Col>
                <Col span={6}>Napomena</Col>
            </Row>
            <Form.List name="items">
                {fields => fields.map(field => {
                    const item = value[field.name];
                    const disabled = saving || !getShipmentCodAllowUpdate(item);
                    return (
                        <Row gutter={8} key={field.key}>
                            <Col span={1}>
                                <Form.Item noStyle name={[field.name, 'shouldUpdate']} valuePropName="checked">
                                    <Checkbox disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={3}>{item.barcode}</Col>
                            <Col span={2}><DateDisplay value={item.carrierPaidOn} /></Col>
                            <Col span={2}><DateDisplay value={item.carrierTransferOn} /></Col>
                            <Col span={2}><DateDisplay value={item.deliveredOn} /></Col>
                            <Col span={2}>
                                <Form.Item noStyle name={[field.name, 'carrierAmount']} rules={[{ required: true }]}>
                                    <InputMoney disabled={disabled} />
                                </Form.Item>
                            </Col>
                            {item.shipmentId
                                ? <>
                                    <Col span={2}>
                                        <Form.Item noStyle name={[field.name, 'customerAmount']}>
                                            {/* <InputMoney disabled={disabled} /> */}
                                            <InputMoney disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}><DateDisplay value={item.shipment.statusOn} /> - {item.shipment.status.name}</Col>
                                </>
                                : <Col span={6}></Col>}


                            <Col span={6}>
                                {getStatusCell(item)}
                            </Col>

                        </Row>
                    )
                })}
            </Form.List>

            <Row gutter={8} justify="center" style={{ marginTop: 14 }}>
                <Col span={4}><Button disabled={saving} type="primary" onClick={onCancel} block ghost>Odustani</Button></Col>
                <Col span={4}><Button loading={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
            </Row>
        </Form>
    );
};

const StatusInfo = ({ statusCode }: { statusCode: string }) => {
    if (statusCode === "CRE") {
        return <Paragraph>Pošiljka u pripremi</Paragraph>;
    }
    else if (statusCode !== "DPR") {
        return <Paragraph>Status će biti ažuriran</Paragraph>;
    }
    return null;
}

const Error = ({ text }: { text: string }) => <Paragraph>
    <InfoCircleOutlined className="text-danger" />
    &nbsp;
    <Text type="danger">{text}</Text>
</Paragraph>;

const AlreadyImported = ({ shipmentCod }: { shipmentCod: ShipmentCod }) => {
    const { codTransfer } = shipmentCod;
    const title = codTransfer ? "Pošiljka je isplaćena" : "Pošiljka već uparena";
    return <Paragraph style={{ cursor: "help" }}>
        <Popover
            content={<Descriptions bordered column={2} size="small">
                <Descriptions.Item label="Primljeni iznos"><CurrencyText value={shipmentCod.carrierAmount} showSymbol /></Descriptions.Item>
                <Descriptions.Item label="Traženi iznos"><CurrencyText value={shipmentCod.customerAmount} showSymbol /></Descriptions.Item>
                {shipmentCod.carrierTransferCode
                    ? <>
                        <Descriptions.Item label={<Text strong>Nalog</Text>} span={2}>&nbsp;</Descriptions.Item>
                        <Descriptions.Item label="Šifra naloga">{shipmentCod.carrierTransferCode}</Descriptions.Item>
                        <Descriptions.Item label="Iznos"><CurrencyText value={shipmentCod.carrierAmount} showSymbol /></Descriptions.Item>
                        <Descriptions.Item label="Primatelj platio"><DateDisplay value={shipmentCod.carrierPaidOn} /></Descriptions.Item>
                        <Descriptions.Item label="Kurir uplatio"><DateDisplay value={shipmentCod.carrierTransferOn} /></Descriptions.Item>
                    </>
                    : <Descriptions.Item label={<Text strong>Nalog</Text>} span={2}>Nalog nije uvezen!</Descriptions.Item>}
                {codTransfer
                    ? <>
                        <Descriptions.Item label={<Text strong>Isplata korisniku</Text>} span={2}>&nbsp;</Descriptions.Item>
                        <Descriptions.Item label="Iznos" span={2}><CurrencyText value={codTransfer.amount} showSymbol /></Descriptions.Item>
                        <Descriptions.Item label="Vrijeme"><DateDisplay value={codTransfer.codTransferGroup.createdOn} /></Descriptions.Item>
                        <Descriptions.Item label="Isplatio">{codTransfer.codTransferGroup.createdBy.name}</Descriptions.Item>
                        <Descriptions.Item label="Grupa"><Link to={`/admin/cod/transfer-group/${codTransfer.codTransferGroup.id}`}>{codTransfer.codTransferGroup.code}</Link></Descriptions.Item>
                        <Descriptions.Item label="Nalog"><Link to={`/admin/cod/transfer/${codTransfer.id}`}>{codTransfer.code}</Link></Descriptions.Item>
                    </>
                    : <Descriptions.Item label={<Text strong>Isplata korisniku</Text>} span={2}>Isplata nije formirana!</Descriptions.Item>}
            </Descriptions>
            }
            title={title}
        >
            <InfoCircleOutlined />
            &nbsp;
            {codTransfer
                ? <Text type="success">Pošiljka je isplaćena</Text>
                : <Text type="warning">Pošiljka već uparena</Text>}
        </Popover>
    </Paragraph>;
}

import React from 'react';
import { ComboBox, FilterBuilder } from "net-common-ui";
import { Customer } from 'Models/Bus/Customer';
import { ComboBoxProps } from 'net-common-ui/dist/Components/ComboBox/ComboBox';

// Functions are in this scope (not inside component) because they are stateless, and all instances
// of CustomerComboBox may reuse same function. 


// Fixed way of displaying dropdown items
const itemTemplate = (model: Customer) => {
    return <React.Fragment>
        {model.name}
    </React.Fragment>
}
// Fixed way of displaying selected items
const selectionTemplate = (model: Customer) => {
    return model.name;
}

// Fixed way of filtering data for all CustomerComboBoxes
const filter = (filter: FilterBuilder<Customer>, term: string) => {
    filter.set("name", "contains", term);
}

// Props we expose on this control are the same as ComboBox, but we remove a few with Omit<...>
type Props = Omit<ComboBoxProps<Customer>, "children" | "itemTemplate" | "selectionTemplate" | "filter" | "filterField">;

// Simply wrap ComboBox, set some fixed fields, and leave rest as params as usual
export const CustomerComboBox = React.forwardRef((props: Props, ref: React.Ref<any>) =>
    <ComboBox<Customer>
        ref={ref}
        selectionTemplate={selectionTemplate}
        itemTemplate={itemTemplate}
        filter={filter}
        {...props}
    />);


import { Col, Form, Row, Typography } from 'antd';
import { PriceWeightRange } from 'Models/Bus/PriceList/PriceWeightRange';
import { PriceWeightExtra } from 'Models/Bus/PriceList/PriceWeightExtra';
import { FormInstance } from 'antd/lib/form';
import { WeightEditFormItem } from './WeightEditFormItem';
import { InputWeight } from "Components/InputWeight";
import { InputMoney } from 'Components/InputMoney';

export interface ModelWrapper {
    ranges: PriceWeightRange[];
    extra: PriceWeightExtra;
}

interface Props {
    form: FormInstance;
    value: ModelWrapper;
}

export const WeightEditForm = ({ form, value }: Props) => {
    return (
        <Row gutter={[8, 40]} justify="center">
            <Col sm={24} md={16}>

                <Form form={form} layout="vertical" name="form" initialValues={value}>
                    <Typography.Title level={3}>Razredi cijena pošiljaka</Typography.Title>
                    <Form.Item name="ranges">
                        <WeightEditFormItem />
                    </Form.Item>

                    <Typography.Title level={3}>Cijena po masi iznad definiranih razreda</Typography.Title>
                    <Form.Item name={["extra", "weightIncrement"]} label="Za svakih (g)">
                        <InputWeight />
                    </Form.Item>
                    <Form.Item name={["extra", "price"]} label="Uvećaj za (EUR)">
                        <InputMoney />
                    </Form.Item>
                </Form>

            </Col>
        </Row>
    )
}

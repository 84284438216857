import { Shipment } from 'Models/Logi/Shipment';
import { Col, Descriptions, Row } from 'antd';
import { Bux } from 'Components/Bux';
import { DateDisplay } from "net-common-ui";
import { CurrencyText } from 'Components/Text';
import * as ShipmentDesc from "Components/Shipment/Descriptions";
import { Link } from 'react-router-dom';
import { CodType, labelCodType } from 'Models/Enum';

export function TransactionTab({ shipment }: { shipment: Shipment }) {
    const shipmentCod = shipment.shipmentCod;
    const ct = shipmentCod?.codTransfer;
    const ctg = ct?.codTransferGroup;

    return <Bux.Content>
        <Row gutter={[8, 8]}>
            <Col sm={12} xs={24}>
                <ShipmentDesc.InvoiceDesc shipmentInvoice={shipment.shipmentInvoice} shipmentReturnInvoice={shipment.shipmentReturnInvoice} />
            </Col>
            {shipment.useCod && <Col sm={12} xs={24}>
                <Descriptions bordered size="small" column={1} title="Otkupnina">
                    <Descriptions.Item label="Vrsta">{labelCodType(shipment.codTypeId)}</Descriptions.Item>
                    <Descriptions.Item label="Iznos na pošiljci"><CurrencyText value={shipment.codAmount} showSymbol /></Descriptions.Item>
                    <Descriptions.Item label="Ref. broj">{shipment.codRefNumber}</Descriptions.Item>
                    {shipment.codTypeId === CodType.BezNaloga && <>
                        <Descriptions.Item label="Traženi iznos"><CurrencyText value={shipmentCod?.customerAmount} showSymbol /></Descriptions.Item>
                        <Descriptions.Item label="Za isplatu"><CurrencyText value={shipmentCod?.carrierAmount} showSymbol /></Descriptions.Item>

                        <Descriptions.Item label="Isplaćeno">{ct ? "Da" : "Ne"}</Descriptions.Item>
                        <Descriptions.Item label="Nalog">
                            {ct && <Link to={"/pub/transaction/cod-transfer/" + ct.id}>{ct.code}</Link>}
                        </Descriptions.Item>
                        <Descriptions.Item label="Datum isplate"><DateDisplay value={ctg?.createdOn} /></Descriptions.Item>
                        <Descriptions.Item label="Raspon naloga">
                            {ctg && <><DateDisplay value={ctg.rangeFrom} /> - <DateDisplay value={ctg.rangeTo} /></>}
                        </Descriptions.Item>
                    </>}
                </Descriptions>
            </Col>}
        </Row>
    </Bux.Content>;
}

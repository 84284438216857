import React from 'react';
import { Customer } from 'Models/Bus/Customer';
import produce from 'immer';
import { ServiceType } from 'Models/Codebook/ServiceType';
import { CustomerServiceType } from 'Models/Bus/CustomerServiceType';
import { CustomerSender } from 'Models/Bus/CustomerSender';
import { AppAccount } from 'Models/Bus/AppAccount';
import { CustomerNotifConfig } from 'Models/Bus/CustomerNotifConfig';

export interface ProfileState {
    id: number;
    model: Customer;
    serviceTypes: ServiceType[];
    notifConfig: CustomerNotifConfig[];
    loading: boolean;
    loadingError: boolean;
}

type Action =
    { type: "LOAD_FAILED" } | // any load...
    { type: "CUST_LOADING" } |
    { type: "CUST_LOADED", payload: Customer } |
    { type: "ST_LOADED", payload: ServiceType[] } |
    { type: "NOTIF_LOADED", payload: CustomerNotifConfig[] } |

    { type: "CUST_SAVED", payload: Customer } |
    { type: "CST_SAVED", payload: CustomerServiceType } |
    { type: "CUST_SENDER_SAVED", payload: CustomerSender } |
    { type: "APPACCOUNT_SAVED", payload: AppAccount } |
    { type: "NOTIF_SAVED", payload: CustomerNotifConfig } |

    { type: "APPACCOUNT_DELETED" }
    // { type: "INSURANCERANGES_SAVED", payload: PriceInsuranceRange[] } |
    // { type: "DISCOUNTRANGES_SAVED", payload: PriceDiscountRange[] } |
    // { type: "WEIGHTRANGES_SAVED", payload: { serviceTypeId: number, ranges: PriceWeightRange[] } } |
    // { type: "WEIGHTEXTRAS_SAVED", payload: { serviceTypeId: number, extra: PriceWeightExtra } } |
    // { type: "ADDSVCS_SAVED", payload: PriceAdditionalService }
    ;


export const InitialState: ProfileState = {
    id: null,
    model: null,
    notifConfig: null,
    serviceTypes: [],
    loading: true,
    loadingError: false
};

interface ProfileContextValue {
    dispatch: React.Dispatch<Action>;
    state: ProfileState;
}

export const ProfileContext = React.createContext<ProfileContextValue>(null);

function customerNotifConfigModelSorter(a: CustomerNotifConfig, b: CustomerNotifConfig): number {
    return a.notifChannelId + a.notifTargetId + a.status.logicalOrder - (b.notifChannelId + b.notifTargetId + b.status.logicalOrder);
}

export const reducer = produce((draft: ProfileState, action: Action) => {
    //console.log("Reducer type " + action.type)
    switch (action.type) {
        case "CUST_LOADING":
            draft.loading = true;
            break;
        case "CUST_LOADED":
            draft.id = action.payload.id;
            draft.model = action.payload;
            draft.loading = false;
            break;
        case "LOAD_FAILED":
            draft.loading = false;
            draft.loadingError = true;
            break;
        case "CUST_SAVED":
            // keep related resources
            const { appAccount, customerSender, customerServiceTypes } = draft.model;
            draft.model = action.payload;
            draft.model.appAccount = appAccount;
            draft.model.customerSender = customerSender;
            draft.model.customerServiceTypes = customerServiceTypes;
            break;
        case "ST_LOADED":
            draft.serviceTypes = action.payload;
            break;
        case "NOTIF_LOADED":
            draft.notifConfig = action.payload;
            draft.notifConfig.sort(customerNotifConfigModelSorter);
            break;
        case "CST_SAVED":
            draft.model.customerServiceTypes = [
                // all existing, without updated one
                ...draft.model.customerServiceTypes.filter(cst => cst.serviceType.id !== action.payload.serviceType.id),
                // add updated one
                action.payload
            ];
            break;
        case "CUST_SENDER_SAVED":
            draft.model.customerSender = action.payload;
            break;
        case "APPACCOUNT_SAVED":
            draft.model.appAccount = action.payload;
            break;
        case "APPACCOUNT_DELETED":
            draft.model.appAccount = null;
            break;

        case "NOTIF_SAVED":
            const idx = draft.notifConfig.findIndex(nc =>
                (nc.id === action.payload.id) ||
                // ova varijanta je tu za slucajeve kad se stvori novi record (stari nije imao id, pa ne radi match po id)
                (nc.key === action.payload.key));
            draft.notifConfig[idx] = action.payload;
            break;
        // case "DISCOUNTRANGES_SAVED":
        //     draft.model.priceDiscountRanges = action.payload;
        //     break;
        // case "WEIGHTRANGES_SAVED":
        //     // keep other servicetypes, replace only items belonging to specified action.payload.serviceTypeId 
        //     draft.model.priceWeightRanges = [
        //         ...draft.model.priceWeightRanges.filter(r => r.serviceTypeId !== action.payload.serviceTypeId),
        //         ...action.payload.ranges
        //     ];
        //     break;
        // case "WEIGHTEXTRAS_SAVED":
        //     // keep other servicetypes, replace only items belonging to specified action.payload.serviceTypeId
        //     draft.model.priceWeightExtras = [
        //         ...draft.model.priceWeightExtras.filter(r => r.serviceTypeId !== action.payload.serviceTypeId),
        //         action.payload.extra
        //     ];
        //     break;
        // case "ADDSVCS_SAVED":
        //     draft.model.priceAdditionalService = action.payload;
        //     break;
    }
}, InitialState as any) as ((state: ProfileState, action: Action) => ProfileState);

import React from 'react';
import { Row, Col, Button, Modal, message } from 'antd';
import { ProfileContext } from '../Context';
import { Bux } from 'Components/Bux';
import { WarningOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { View } from './View';
import { deleteStandardPriceList, getStandardPriceListInfoById, publishStandardPriceList } from 'Models/Bus/PriceList/StandardPriceList';


export const MainTab = () => {
    const { state, dispatch } = React.useContext(ProfileContext);
    const { model, editable, deletable } = state;
    const history = useHistory();

    const handlePublish = () => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Objavi standardni cjenik #{model.id}? Nakon što se objavi, cjenik nije moguće naknadno uređivati.</p>,
            okType: "primary",
            okText: "Objavi",
            async onOk() {
                const result = await publishStandardPriceList(model.id, true).execute();
                if (result.success) {
                    message.success("Standardni cjenik uspješno objavljen.");
                    dispatch({ type: "PL_LOADING" });
                    const StandardPriceList = await getStandardPriceListInfoById(model.id).execute();
                    dispatch({ type: "PL_LOADED", payload: StandardPriceList.item });
                }
            }
        });

    }

    const handleDelete = () => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Obrisati cjenik #{model.id}? Ova akcija je nepovratna!</p>,
            okType: "primary",
            okText: "Obriši",
            okButtonProps: { danger: true },
            async onOk() {
                const result = await deleteStandardPriceList(model.id).execute()
                if (result.success) {
                    message.success("Standardni cjenik uspješno obrisan.");
                    history.replace("/admin/standard-price-list");
                }
            }
        });
    }

    return (<>
        <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <Button disabled={!deletable} onClick={handleDelete} block danger icon={<DeleteOutlined />}>Obriši</Button>
                </Col>
                <Col span={4}>
                    <Button disabled={!editable} type="primary" onClick={handlePublish} block icon={<CheckOutlined />}>Objavi</Button>
                </Col>
            </Row>
        </Bux.SubHeader>
        <Bux.Content>
            <View />
        </Bux.Content>
    </>);
}

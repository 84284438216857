//import React from 'react';
import { CustomerNotifConfig } from 'Models/Bus/CustomerNotifConfig';
import { labelNotifChannel, labelNotifTarget } from 'Models/Enum';


export function getTitle(nc: CustomerNotifConfig) {
    return `${labelNotifTarget(nc.notifTargetId)} - ${labelNotifChannel(nc.notifChannelId)} - ${nc.status.name}`;
}

export interface Message {
    subject?: string;
    title?: string;
    subtitle?: string;
    body?: string;
}

export function parseMessage(message: string): Message {
    try {
        return JSON.parse(message || "{}");
    }
    catch {
        console.warn("Failed to parse message json: " + message)
        return {};
    }
}
export function serializeMessage(obj: Message, defaults: Message): string {
    const keys = Object.keys(obj);
    let equal = true;
    for (let i = 0; i < keys.length; ++i) {
        const key = keys[i];
        if (!obj[key]) obj[key] = defaults[key];
        equal = equal && obj[key] === defaults[key];
    }

    // if obj equals defaults, return null so defaults are used
    if (equal) {
        return null;
    }

    return JSON.stringify(obj);
}

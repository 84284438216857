import { useState } from 'react';
import { Typography, PageHeader, Row, Col, Button, Empty } from 'antd';
import { Bux } from 'Components/Bux';
import downloadStream from 'downloadStream';
import { kApi } from 'Api';
import * as ShipmentInvalidCod from "./Shipment/InvalidCod";
import * as ShipmentNotBilled from "./Shipment/NotBilled";
import * as PackageNotCollected from "./Pack/NotCollected";
import * as PackageNotWeighted from "./Pack/NotWeighted";
import * as PackageInactive from "./Pack/Inactive";
import * as InvoiceCombined from "./Invoice/Combined";
import { FilterForm } from './types';

const { Title } = Typography;


interface ReportMetadataGroup {
    title: string;
    items: ReportMetadata[];
}
interface ReportMetadata {
    title: string;
    route: string;
    form: FilterForm;
}

const Reports: ReportMetadataGroup[] = [{
    title: "Pošiljke",
    items: [{
        title: "Nefakturirane pošiljke",
        route: "rpt/shipment-not-billed;xls",
        form: ShipmentNotBilled.FilterForm
    }, {
        title: "Neuplaćene i neispravne otkupnine",
        route: "rpt/shipment-invalid-cod;xls",
        form: ShipmentInvalidCod.FilterForm
    }]
}, {
    title: "Paketi",
    items: [{
        title: "Neprikupljeni paketi",
        route: "rpt/package-not-collected;xls",
        form: PackageNotCollected.FilterForm
    }, {
        title: "Nevagani paketi",
        route: "rpt/package-not-weighted;xls",
        form: PackageNotWeighted.FilterForm
    }, {
        title: "Neaktivni paketi",
        route: "rpt/package-inactive;xls",
        form: PackageInactive.FilterForm
    }]
}, {
    title: "Fakture",
    items: [{
        title: "Skupno",
        route: "rpt/invoice-combined;pdf",
        form: InvoiceCombined.FilterForm
    }]
}];

export const Rpt = () => {
    const [report, setReport] = useState<ReportMetadata>(null);
    const [running, setRunning] = useState(false)

    const ReportForm = report?.form;

    const render = async (type: "xlsx" | "pdf", filter: any) => {
        setRunning(true);

        // filename is igonred if content disposition is successfully sniffed
        const result = await downloadStream(kApi, {
            url: report.route,
            method: "post",
            data: filter,
            sniffContentDisposition: true,
            fileName: "report." + type
        });
        result.success && result.download();

        setRunning(false);
    }

    return <Bux.Page>
        <Bux.Header><PageHeader title="Izvještaji" /></Bux.Header>
        <Bux.Content>
            <Row>
                <Col span={8}>
                    {Reports.map((group, groupIndex) => <div key={groupIndex}>
                        <Title level={4}>{group.title}</Title>
                        <ul>
                            {group.items.map((rpt, rptIndex) => <li key={rptIndex}>
                                <Button
                                    type="link"
                                    onClick={() => setReport(rpt)}
                                    style={rpt === report ? { fontWeight: "bold" } : null}
                                >
                                    {rpt.title}
                                </Button>
                            </li>)}
                        </ul>
                    </div>)}
                </Col>
                {report
                    ? <Col span={16}>
                        <Title level={4}>{report.title}</Title>
                        <ReportForm code={report.route} onSubmit={render} running={running} />
                    </Col>
                    : <Col span={16}>
                        <Empty description="Odaberite izvještaj sa lijeve strane." />
                    </Col>}
            </Row>
        </Bux.Content>
    </Bux.Page>;
};

import React, { useCallback, useMemo } from "react";
import { PickerTimeProps, PickerDateProps, PickerProps, RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { useConfig, dateOnly } from "net-common-ui";

dayjs.extend(utc); // load utc plugin
dayjs.extend(localeData); // access locale meta
dayjs.extend(localizedFormat); // access locale meta
dayjs.extend(dateOnly); // load dateOnly plugin

function dayjsToUtcDate(value: Dayjs): Dayjs {
    if (value.isUTC()) {
        return value.startOf('day').dateOnly();
    }
    else {
        // disgusting way of switching to utc without moving the date
        return dayjs.utc(value.format("YYYY-MM-DD")).dateOnly();
    }
}

//window["dayjs"] = dayjs;

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
type DatePickerProps = PickerProps<Dayjs>;
type MonthPickerProps = Omit<PickerDateProps<Dayjs>, 'picker'>;
type WeekPickerProps = Omit<PickerDateProps<Dayjs>, 'picker'>;

type AntDatePickerProps = PickerProps<Dayjs> & PickerDateProps<Dayjs>;// & PickerTimeProps<Dayjs>;
type AntRangePickerProps = RangePickerDateProps<Dayjs> & { showTime?: any };


type DatePickerPropsEx<T> = React.FunctionComponent<T> & {
    WeekPicker: typeof WeekPickerEx;
    MonthPicker: typeof MonthPickerEx;
    YearPicker: typeof YearPickerEx;
    RangePicker: typeof RangePickerEx;
    TimePicker: typeof TimePickerEx;
    QuarterPicker: typeof QuarterPickerEx;
}

const mapFormat = (format: any): any => {
    // Dayjs locale hack: https://microblink.atlassian.net/browse/CS-436
    // kad picker ima null value interno koristi Dayjs() sto ne koristi globalni locale
    if (format && typeof format === "string") {
        const fmt = (dayjs() as any).$locale().formats;
        // fmt is: 
        // {
        //     "LT": "H:mm",
        //     "LTS": "H:mm:ss",
        //     "L": "DD.MM.YYYY",
        //     "LL": "D. MMMM YYYY",
        //     "LLL": "D. MMMM YYYY H:mm",
        //     "LLLL": "dddd, D. MMMM YYYY H:mm"
        // }

        // order is important!
        fmt && ["LTS", "LT", "LLLL", "LLL", "LL", "L"].forEach(key => {
            format = format.replace(key, fmt[key]);
        });
    }
    return format;
}
const mapDateValue = (value: Dayjs, showTime: boolean, locale: string): Dayjs => {
    if (value) {
        // if time is not used, make sure date is utc and without time
        if (!showTime && !value.isUTC()) {
            value = dayjsToUtcDate(value);
        }
        else if (showTime && value.isUTC()) {
            value = value.local();
        }

        value.locale(locale);
    }
    return value;
}
const mapDateValues = (values: [Dayjs, Dayjs], showTime: boolean, locale: string): [Dayjs, Dayjs] => {
    if (values) {
        return values.map(v => mapDateValue(v, showTime, locale)) as [Dayjs, Dayjs];
    }
    else {
        return values;
    }
}

const WeekPickerEx = (props: WeekPickerProps) =>
    <DatePicker.WeekPicker {...props} />;

const MonthPickerEx = (props: MonthPickerProps) =>
    <DatePicker.MonthPicker {...props} />;

const YearPickerEx = (props: PickerDateProps<Dayjs>) =>
    <DatePicker.YearPicker {...props} />;

const RangePickerEx = ({ format, value, onChange, showTime, ...rest }: AntRangePickerProps) => {
    const locale = useConfig().cultureCode.substr(0, 2);
    const tFormat = format ? format : (showTime ? "L LTS" : "L");
    const tValue = useMemo(() => mapDateValues(value, !!showTime, locale), [value, locale, showTime]);
    const tOnChange = useCallback((values: [Dayjs, Dayjs], formatString: [string, string]) => onChange && onChange(mapDateValues(values, !!showTime, locale), formatString), [onChange, locale, showTime]);

    return <DatePicker.RangePicker
        format={mapFormat(tFormat)}
        onChange={tOnChange}
        value={tValue}
        showTime={showTime}
        {...rest} />;
}

const TimePickerEx = (props: Omit<PickerTimeProps<Dayjs>, 'picker'>) =>
    <DatePicker.TimePicker {...props} />

const QuarterPickerEx = (props: Omit<PickerTimeProps<Dayjs>, 'picker'>) =>
    <DatePicker.QuarterPicker {...props} />


export const DatePickerEx: DatePickerPropsEx<DatePickerProps> = ({ format, showTime, value, onChange, ...rest }: AntDatePickerProps) => {
    const locale = useConfig().cultureCode.substr(0, 2);
    const tFormat = format ? format : (showTime ? "L LTS" : "L");
    const tValue = useMemo(() => mapDateValue(value, !!showTime, locale), [value, locale, showTime]);
    const tOnChange = useCallback((value: Dayjs | null, dateString: string) => onChange && onChange(mapDateValue(value, !!showTime, locale), dateString), [onChange, locale, showTime]);

    return <DatePicker
        format={mapFormat(tFormat)}
        onChange={tOnChange}
        value={tValue}
        showTime={showTime}
        {...rest} />;
}

DatePickerEx.WeekPicker = WeekPickerEx;
DatePickerEx.MonthPicker = MonthPickerEx;
DatePickerEx.YearPicker = YearPickerEx;
DatePickerEx.RangePicker = RangePickerEx;
DatePickerEx.TimePicker = TimePickerEx;
DatePickerEx.QuarterPicker = QuarterPickerEx;

import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { PriceInsuranceRange } from "Models/Bus/PriceList/PriceInsuranceRange"
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { InputMoney } from "Components/InputMoney";
import { InputPercent } from "Components/InputPercent";

interface Props {
    value?: PriceInsuranceRange[];
    onChange?: (value: PriceInsuranceRange[]) => void;
}

export const InsuranceEditFormItem = React.forwardRef(({ value, onChange }: Props, ref: any) => {

    // executing fromJSON(...) to get a copy, since react goes batshit crazy if we touch anything inside props
    const [ranges, setRanges] = useState(value || []);

    const setCalcRanges = (ranges: PriceInsuranceRange[]): void => {
        for (let i = 0; i < ranges.length; ++i) {
            const prev = i < 1 ? null : ranges[i - 1];
            //const next = i < ranges.length - 1 ? ranges[i + 1] : null;
            ranges[i].valueFrom = prev
                ? prev.valueTo !== undefined
                    ? prev.valueTo + 0.01 // add 1 cent to the next range, so no overlap
                    : undefined
                : 0;
            // // make sure last one has coef, others have price
            // if (next == null) ranges[i].price = undefined;
            // else ranges[i].priceCoef = undefined;
        }
        setRanges(ranges);
        onChange && onChange(ranges);
    }

    const handleAdd = () => {
        const newRange: PriceInsuranceRange = {};
        newRange.id = ranges.length ? Math.max(...ranges.map(r => r.id)) + 1 : 1;
        setCalcRanges([...ranges, newRange]);
    }
    const handleRemove = (id: number) => {
        const index = ranges.findIndex(r => r.id === id);
        ranges.splice(index, 1);
        setCalcRanges([...ranges]);
    }
    const handleChange = (value: PriceInsuranceRange) => {
        const index = ranges.findIndex(r => r.id === value.id);
        ranges[index] = value;
        setCalcRanges([...ranges]);
    }

    // pre-create first row...
    if (ranges.length < 1) handleAdd();

    return (
        <div ref={ref}>
            <Row gutter={[8, 8]}>
                <Col span={5}>Od (EUR)</Col>
                <Col span={5}>Do (EUR)</Col>
                <Col span={5}>Uvećanje (EUR)</Col>
                <Col span={5}>Uvećanje (%)</Col>
                <Col span={4}></Col>
            </Row>

            {ranges.map((range, iRange) => {
                return <RangeElement
                    key={range.id}
                    value={range}
                    isLast={iRange === ranges.length - 1}
                    isFirst={iRange === 0}
                    onRemove={handleRemove} onChange={handleChange} />
            })}

            <Row gutter={[8, 8]}>
                <Col span={10}>
                    <Button type="dashed" onClick={handleAdd} block icon={<PlusOutlined />}>Dodaj</Button>
                </Col>
            </Row>

        </div>
    )
});

interface RangeElementProps {
    value: PriceInsuranceRange;
    isFirst: boolean;
    isLast: boolean;
    onChange: (value: PriceInsuranceRange) => void;
    onRemove: (id: number) => void;
}
const RangeElement = ({ value, onChange, onRemove, isFirst }: RangeElementProps) => {

    const handleChange = (diff: Partial<PriceInsuranceRange>) => {
        onChange({ ...value, ...diff });
    }

    return <Row gutter={[8, 8]}>
        <Col span={5}>
            <InputMoney
                disabled
                style={{ width: "100%" }}
                value={value.valueFrom} />
        </Col>
        <Col span={5}>
            <InputMoney
                style={{ width: "100%" }}
                value={value.valueTo}
                onChange={(fieldValue: number) => handleChange({ valueTo: fieldValue })} />
        </Col>
        <Col span={5}>
            <InputMoney
                style={{ width: "100%" }}
                value={value.price}
                onChange={(fieldValue: number) => handleChange({ price: fieldValue })} />
        </Col>
        <Col span={5}>
            <InputPercent
                style={{ width: "100%" }}
                value={value.priceCoef}
                onChange={(fieldValue: number) => handleChange({ priceCoef: fieldValue })} />
        </Col>
        <Col span={4}>
            {!isFirst && <Button block onClick={() => onRemove(value.id)}>
                <MinusCircleOutlined />
            </Button>}
        </Col>
    </Row>;
}

import React from 'react';
import { ShipmentLog } from 'Models/Logi/ShipmentLog/ShipmentLog';
import { Col, Row, Tag, Typography } from 'antd';
import { Shipment } from 'Models/Logi/Shipment';
import { ShipmentSenderLog } from 'Models/Logi/ShipmentLog/ShipmentSenderLog';
import { ShipmentRecipientLog } from 'Models/Logi/ShipmentLog/ShipmentRecipientLog';
import { ShipmentMainLog } from 'Models/Logi/ShipmentLog/ShipmentMainLog';
import { CurrencyText, NumberText, PercentText, WeightText } from 'Components/Text';
import { CheckOutlined, CloseOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { deliveryDeadlineLabel, deliveryTypeLabel, labelCodType, labelNotifChannel, labelNotifTarget } from 'Models/Enum';
import { DateDisplay } from "net-common-ui";
import { PackageLog } from 'Models/Logi/ShipmentLog/PackageLog';
import { ShipmentCodLog } from 'Models/Logi/ShipmentLog/ShipmentCodLog';
import { ShipmentMessageLog } from 'Models/Logi/ShipmentLog/ShipmentMessageLog';
import { ShipmentNotifLog } from 'Models/Logi/ShipmentLog/ShipmentNotifLog';
import { getPostOfficefullName } from 'Models/Codebook/PostOffice';
const { Text, Title, Paragraph } = Typography;

interface Pair<T> {
    current: T;
    previous?: T;
}

enum FormatHint {
    Boolean,
    Number,
    Currency,
    Percent,
    Weight,
    DateTime,
    Date,
    String,
    LongString
}
type Format = FormatHint | ((value: any) => any);

function empty(value: any): boolean {
    return value === undefined || value === null;
}

function formatValue(value: any, format?: Format): React.ReactNode {
    if (empty(value)) {
        return <Text type="secondary" style={{ fontStyle: "italic" }}>prazno</Text>;
    }

    if (typeof format === "function") {
        value = format(value);
    }
    else if (!format) {
        switch (typeof value) {
            case "number": format = FormatHint.Number; break;
            case "boolean": format = FormatHint.Boolean; break;
            //case "object":
        }
    }

    switch (format) {
        case FormatHint.Boolean: return value ? <CheckOutlined /> : <CloseOutlined />;
        case FormatHint.Number: return <NumberText value={value} />;
        case FormatHint.Currency: return <CurrencyText value={value} showSymbol />;
        case FormatHint.Percent: return <PercentText value={value} showSymbol />;
        case FormatHint.Weight: return <WeightText value={value} showSymbol />;
        case FormatHint.DateTime: return <DateDisplay value={value} showTime />;
        case FormatHint.Date: return <DateDisplay value={value} />;
        case FormatHint.LongString: return <Paragraph style={{ margin: 0 }} ellipsis={{ rows: 1, expandable: true }}>{value}</Paragraph>
        default: return <span>{String(value)}</span>;
    }
}

export const EventPanel = ({ shipment, log, eventLogId }: { shipment: Shipment, log: ShipmentLog, eventLogId: number }) => {

    const currSender = log.senders.find(s => s.shipmentEventLogId === eventLogId);
    const prevSender = currSender && log.senders.find(s => s.shipmentEventLogId < currSender.shipmentEventLogId);

    const currRecipient = log.recipients.find(s => s.shipmentEventLogId === eventLogId);
    const prevRecipient = currRecipient && log.recipients.find(s => s.shipmentEventLogId < currRecipient.shipmentEventLogId);

    const currMain = log.mains.find(s => s.shipmentEventLogId === eventLogId);
    const prevMain = currMain && log.mains.find(s => s.shipmentEventLogId < currMain.shipmentEventLogId);

    const currCod = log.cods.find(s => s.shipmentEventLogId === eventLogId);
    const prevCod = currCod && log.cods.find(s => s.shipmentEventLogId < currCod.shipmentEventLogId);

    const allPackages: Pair<PackageLog>[] = log.packages.map((p, i) => ({
        current: p,
        previous: i + 1 < log.packages.length ? log.packages[i + 1] : null
    }));

    const packages: Pair<PackageLog>[] = allPackages.filter(x => x.current.shipmentEventLogId === eventLogId);
    // const packages: Pair<PackageLog>[] = log.packages.filter(s => s.shipmentEventLogId === eventLogId).map(p => ({
    //     current: p,
    //     previous: log.packages.find(s => s.packageId === p.packageId && s.shipmentEventLogId < p.shipmentEventLogId)
    // }));

    const messages: ShipmentMessageLog[] = log.messages.filter(m => m.shipmentEventLogId === eventLogId);
    const notifs: ShipmentNotifLog[] = log.notifs.filter(m => m.shipmentEventLogId === eventLogId);

    return <div>
        {messages.length > 0 && <MessageSection messages={messages} />}
        {currSender && <SenderSection pair={{ current: currSender, previous: prevSender }} />}
        {currRecipient && <RecipientSection pair={{ current: currRecipient, previous: prevRecipient }} />}
        {currMain && <MainSection pair={{ current: currMain, previous: prevMain }} />}
        {currCod && <CodSection pair={{ current: currCod, previous: prevCod }} />}
        {packages.length > 0 && <PackageSection pairs={packages} />}
        {!currSender && !currRecipient && !currMain && !currCod && !packages.length && <p>Bez izmjena</p>}
        {notifs.length > 0 && <NotifSection notifs={notifs} />}
    </div>;
}


const MessageSection = ({ messages }: { messages: ShipmentMessageLog[] }) => {
    return <>
        <Title level={4}>Poruke događaja</Title>
        <Row gutter={[4, 4]}>
            {messages.map(m => <Col span={24} key={m.id}>{m.message}</Col>)}
        </Row>
    </>;
}


const SenderSection = ({ pair }: { pair: Pair<ShipmentSenderLog> }) => {
    const { current, previous } = pair;
    return <>
        <Title level={4}>Pošiljatelj</Title>
        <DiffRow label="Naziv" current={current.name} previous={previous?.name} />
        <DiffRow label="Ime i prezime" current={current.personName} previous={previous?.personName} />
        <DiffRow label="OIB" current={current.oib} previous={previous?.oib} />
        <DiffRow label="Naselje" current={current.settlement?.name} previous={previous?.settlement?.name} />
        <DiffRow label="Poštanski ured" current={getPostOfficefullName(current.settlement?.postOffice)} previous={getPostOfficefullName(previous?.settlement?.postOffice)} />
        <DiffRow label="Poštanski ured" current={getPostOfficefullName(current.postOffice)} previous={getPostOfficefullName(previous?.postOffice)} />
        <DiffRow label="Ulica" current={current.street} previous={previous?.street} />
        <DiffRow label="Kućni broj" current={current.streetNo} previous={previous?.streetNo} />
        <DiffRow label="Telefon" current={current.phone} previous={previous?.phone} />
        <DiffRow label="Email" current={current.email} previous={previous?.email} />
    </>;
}

const RecipientSection = ({ pair }: { pair: Pair<ShipmentRecipientLog> }) => {
    const { current, previous } = pair;
    return <>
        <Title level={4}>Primatelj</Title>
        <DiffRow label="Naziv" current={current.name} previous={previous?.name} />
        <DiffRow label="Ime i prezime" current={current.personName} previous={previous?.personName} />
        <DiffRow label="OIB" current={current.oib} previous={previous?.oib} />
        <DiffRow label="Naselje" current={current.settlement?.name} previous={previous?.settlement?.name} />
        <DiffRow label="Poštanski ured" current={getPostOfficefullName(current.settlement?.postOffice)} previous={getPostOfficefullName(previous?.settlement?.postOffice)} />
        <DiffRow label="Poštanski ured" current={getPostOfficefullName(current.postOffice)} previous={getPostOfficefullName(previous?.postOffice)} />
        <DiffRow label="Ulica" current={current.street} previous={previous?.street} />
        <DiffRow label="Kućni broj" current={current.streetNo} previous={previous?.streetNo} />
        <DiffRow label="Telefon" current={current.phone} previous={previous?.phone} />
        <DiffRow label="Email" current={current.email} previous={previous?.email} />
    </>;
}

const MainSection = ({ pair }: { pair: Pair<ShipmentMainLog> }) => {
    const { current, previous } = pair;
    return <>
        <Title level={4}>Osnovno</Title>
        <DiffRow label="Vrijednost" current={current.value} previous={previous?.value} format={FormatHint.Currency} />
        <DiffRow label="Rok uručenja" current={current.deliveryDeadlineId} previous={previous?.deliveryDeadlineId} format={deliveryDeadlineLabel} />
        <DiffRow label="Dostava" current={current.deliveryTypeId} previous={previous?.deliveryTypeId} format={deliveryTypeLabel} />
        <DiffRow label="Otkupnina" current={current.useCod} previous={previous?.useCod} format={FormatHint.Boolean} />
        <DiffRow label="Otkupnina" current={current.codTypeId} previous={previous?.codTypeId} format={labelCodType} />
        <DiffRow label="Iznos otkupnine" current={current.codAmount} previous={previous?.codAmount} format={FormatHint.Currency} />
        <DiffRow label="Otk. ref. broj" current={current.codRefNumber} previous={previous?.codRefNumber} />

        <DiffRow label="Dostava do 9h" current={current.useDeliveryBy9} previous={previous?.useDeliveryBy9} format={FormatHint.Boolean} />
        <DiffRow label="Dostava do 11h" current={current.useDeliveryBy11} previous={previous?.useDeliveryBy11} format={FormatHint.Boolean} />
        <DiffRow label="Povrat potp. dok." current={current.useReturnSignedDoc} previous={previous?.useReturnSignedDoc} format={FormatHint.Boolean} />
        <DiffRow label="Uručiti osobno" current={current.usePersonalDelivery} previous={previous?.usePersonalDelivery} format={FormatHint.Boolean} />
        <DiffRow label="Prikup subotom" current={current.useSaturdayPickup} previous={previous?.useSaturdayPickup} format={FormatHint.Boolean} />
        <DiffRow label="Uručenje subotom" current={current.useSaturdayDelivery} previous={previous?.useSaturdayDelivery} format={FormatHint.Boolean} />
        <DiffRow label="Povratnica" current={current.usePovratnica} previous={previous?.usePovratnica} format={FormatHint.Boolean} />
        <DiffRow label="Nestandardni format" current={current.useNonstandardFormat} previous={previous?.useNonstandardFormat} format={FormatHint.Boolean} />
        <DiffRow label="Obavijest pošiljatelju - SMS" current={current.useNotifySenderSms} previous={previous?.useNotifySenderSms} format={FormatHint.Boolean} />
        <DiffRow label="Obavijest pošiljatelju - Email" current={current.useNotifySenderEmail} previous={previous?.useNotifySenderEmail} format={FormatHint.Boolean} />
        <DiffRow label="Obavijest primatelju - SMS" current={current.useNotifyRecipientSms} previous={previous?.useNotifyRecipientSms} format={FormatHint.Boolean} />
        <DiffRow label="Obavijest primatelju - Email" current={current.useNotifyRecipientEmail} previous={previous?.useNotifyRecipientEmail} format={FormatHint.Boolean} />

        <DiffRow label="Napomena" current={current.note} previous={previous?.note} format={FormatHint.LongString} />
        <DiffRow label="Opis sadržaja" current={current.contentNote} previous={previous?.contentNote} format={FormatHint.LongString} />
        <DiffRow label="Ref A" current={current.refA} previous={previous?.refA} format={FormatHint.LongString} />
        <DiffRow label="Ref B" current={current.refB} previous={previous?.refB} format={FormatHint.LongString} />
        <DiffRow label="Ref C" current={current.refC} previous={previous?.refC} format={FormatHint.LongString} />
        <DiffRow label="Storno" current={current.isCancelled} previous={previous?.isCancelled} format={FormatHint.Boolean} />
        <DiffRow label="Gratis" current={current.isGratis} previous={previous?.isGratis} format={FormatHint.Boolean} />
    </>;
}

const CodSection = ({ pair }: { pair: Pair<ShipmentCodLog> }) => {
    const { current, previous } = pair;
    return <>
        <Title level={4}>Otkupnina</Title>
        <DiffRow label="Traženi iznos" current={current.customerAmount} previous={previous?.customerAmount} format={FormatHint.Currency} />
        <DiffRow label="Primljeni iznos" current={current.carrierAmount} previous={previous?.carrierAmount} format={FormatHint.Currency} />
        <DiffRow label="Primatelj platio" current={current.carrierPaidOn} previous={previous?.carrierPaidOn} format={FormatHint.Date} />
        <DiffRow label="Kurir platio" current={current.carrierTransferOn} previous={previous?.carrierTransferOn} format={FormatHint.Date} />
        <DiffRow label="Kurir šifra uplate" current={current.carrierTransferCode} previous={previous?.carrierTransferCode} />
        <DiffRow label="Isplaćeno kupcu" current={!!current.codTransferId} previous={!!previous?.codTransferId} format={FormatHint.Boolean} />
    </>;
}


const PackageSection = ({ pairs }: { pairs: Pair<PackageLog>[] }) => {
    pairs.sort((a, b) => a.current.ordinal - b.current.ordinal);
    return <>
        {pairs.map(pair => {
            const { current, previous } = pair;
            // key je packageId + statusId jer u 1 event logu moze biti vise status updatea (carrier sync)
            return <div key={`${current.packageId}-${current.status?.id}`}>
                <Title level={4}>Paket: {current.barcode}</Title>
                <DiffRow label="Redni broj" current={current.ordinal} previous={previous?.ordinal} format={FormatHint.Number} />
                <DiffRow label="Status" current={current.status.name} previous={previous?.status?.name} showWhenEqual />
                <DiffRow label="Napomena status" current={current.statusNote} previous={previous?.statusNote} />
                <DiffRow label="Vrijeme statusa" current={current.statusOn} previous={previous?.statusOn} format={FormatHint.DateTime} />
                <DiffRow label="Najavljena masa" current={current.weight} previous={previous?.weight} format={FormatHint.Weight} />
                <DiffRow label="Masa" current={current.weightVerified} previous={previous?.weightVerified} format={FormatHint.Weight} />
                <DiffRow label="Vrijeme postavljanja masa" current={current.weightVerifiedSetOn} previous={previous?.weightVerifiedSetOn} format={FormatHint.DateTime} />
                <DiffRow label="volumen X" current={current.volumeX} previous={previous?.volumeX} format={FormatHint.Number} />
                <DiffRow label="volumen Y" current={current.volumeY} previous={previous?.volumeY} format={FormatHint.Number} />
                <DiffRow label="volumen Z" current={current.volumeZ} previous={previous?.volumeZ} format={FormatHint.Number} />
                <DiffRow label="Ref A" current={current.refA} previous={previous?.refA} format={FormatHint.LongString} />
                <DiffRow label="Ref B" current={current.refB} previous={previous?.refB} format={FormatHint.LongString} />
                <DiffRow label="Ref C" current={current.refC} previous={previous?.refC} format={FormatHint.LongString} />
            </div>;
        })}
    </>;
}

const NotifSection = ({ notifs }: { notifs: ShipmentNotifLog[] }) => {
    return <>
        <Title level={4}>Obavijesti</Title>
        <Row gutter={[4, 4]}>
            {notifs.map(n => <>
                <Col span={4} key={n.id + "-target"}>
                    {labelNotifTarget(n.customerNotifConfig.notifTargetId)}
                </Col>
                <Col span={4} key={n.id + "-channel"}>
                    {labelNotifChannel(n.customerNotifConfig.notifChannelId)}
                </Col>
                <Col span={12} key={n.id + "-address"}>
                    {n.sms && n.sms.toAddressGdpr}
                    {n.mail && n.mail.toAddressGdpr}
                </Col>
                <Col span={4} key={n.id + "-status"} style={{ textAlign: "right" }}>
                    {n.isPending
                        ? <Tag color="blue">U obradi</Tag>
                        : (n.errorMessage
                            ? <Tag color="volcano">Greška</Tag>
                            : <Tag color="green">Poslano</Tag>)}
                </Col>
            </>)}
        </Row>
    </>;
}

const DiffRow = ({ label, current, previous, format, showWhenEqual }: { label: any, current: any, previous?: any, format?: Format, showWhenEqual?: boolean }) => {
    if (!current && !previous) {
        return null;
    }

    const hasCurrent = !empty(current);
    const hasPrevious = !empty(previous);

    // Convert to string, otherwise dayjs instances are never equal
    const equal = hasPrevious && String(previous) === String(current);
    const diff = hasPrevious && !equal;
    const added = !hasPrevious;
    const removed = !hasCurrent;

    if (equal && !showWhenEqual) {
        return null;
    }

    return <Row gutter={[4, 4]} style={{ borderBottom: "dashed 1px #ddd", paddingTop: 2, paddingBottom: 2 }}>
        <Col span={5}>
            {label}
        </Col>
        <Col span={7} style={{ textAlign: "end" }}>
            {formatValue(previous, format)}
        </Col>
        <Col span={1} style={{ textAlign: "center" }}>
            <Text type="secondary">
                {!equal && <DoubleRightOutlined />}
            </Text>
        </Col>
        <Col span={7}>
            {formatValue(current, format)}
        </Col>
        <Col span={4} style={{ textAlign: "end" }}>
            {added && <Tag color="green">Novo</Tag>}
            {equal && <Tag>Jednako</Tag>}
            {diff && <Tag color="blue">Izmjena</Tag>}
            {removed && <Tag color="volcano">Uklonjeno</Tag>}
        </Col>
    </Row>;
}

import { Alert, Col, PageHeader, Row } from 'antd';
import { Bux } from 'Components/Bux';
import React from 'react';
import { EditShipmentContext } from '../Context';
import { ShipmentForm } from "./ShipmentForm"
import { DeleteButton } from "Components/Shipment/ActionButton";
import { useHistory } from 'react-router-dom';

//export default ShipmentForm;

export const Edit = () => {
    const history = useHistory();
    const { state } = React.useContext(EditShipmentContext);
    const { model, create } = state;
    const title = (state.create ? "Nova pošiljka" : "Pošiljka " + model?.code) + " (" + (model?.serviceType?.name || "N/A") + ")";
    const isLabelCreated = model.packages.findIndex(p => !!p.labelCreatedBy) >= 0;

    const handleDelete = async () => {
        history.push("/pub/shipment?preset=unsent");
    }

    return (<Bux.Page>
        <Bux.Header>
            <PageHeader title={title} />
        </Bux.Header>
        {!create && <Bux.SubHeader>
            <Row gutter={16} justify="end">
                <Col span={4}>
                    <DeleteButton shipment={model} key="delete" onExecuted={handleDelete} block />
                </Col>
            </Row>
        </Bux.SubHeader>}
        <Bux.Content>
            {isLabelCreated && <Alert
                message="Za pošiljku su prethodno ispisane adresnice. U slučaju izmjene pošiljke, adresnice je potrebno ponovno ispisati, a prethodne ukloniti sa paketa."
                type="warning"
                showIcon
            />}
            <ShipmentForm />
        </Bux.Content>
    </Bux.Page>);
};


import { Button, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CustomerComboBox } from "Components/CustomerComboBox";
import { Customer, dsoCustomer } from 'Models/Bus/Customer';
import { BoolRadioButtonSet, useDataSourceRef } from "net-common-ui";

export interface InvoiceFilter {
    customer?: Customer;
    isPaid?: boolean;
}

interface FilterFormProps {
    initialFilter?: InvoiceFilter;
    onChange?: (filter: InvoiceFilter) => void;
    form?: FormInstance;
}

export const FilterForm = ({ onChange, initialFilter = {}, form }: FilterFormProps) => {
    const [customerDs] = useDataSourceRef(dsoCustomer());
    return (
        <Form
            form={form}
            layout="vertical"
            name="filter"
            initialValues={initialFilter}
            onFinish={onChange}>

            <Form.Item name="customer" label="Korisnik">
                <CustomerComboBox dataSource={customerDs} />
            </Form.Item>

            <Form.Item name="isPaid" label="Plaćeno">
                <BoolRadioButtonSet nullable />
            </Form.Item>

            {/* This makes enter submit the form - button is not visible! */}
            <Button htmlType="submit" hidden />
        </Form>
    )
}

import { useEffect, useReducer } from 'react';
import { getCustomerInfoById } from "Models/Bus/Customer"
import { Link, useParams, Switch, Route } from 'react-router-dom';
import { SummaryTab } from "./SummaryTab";
import { MainTab } from "./MainTab/Layout";
import { SenderTab } from "./SenderTab/Layout";
import { ServiceTab } from "./ServiceTab/Layout";
import { PriceListTab } from "./PriceListTab/Grid";
import { AppAccountTab } from "./AppAccountTab/Layout";
import { InvoiceTab } from "./InvoiceTab/Grid";
import { NotifConfigTab } from "./NotifConfigTab/Layout";
import { Bux } from 'Components/Bux';
import { ProfileContext, reducer, InitialState } from './Context';
import { Menu, PageHeader, Result, Skeleton } from 'antd';
import { dsoServiceType } from 'Models/Codebook/ServiceType';
import { DataSource } from 'net-common-ui';

export const Profile = ({ match, location }) => {
    const params = useParams<{ id: string }>();
    const [state, dispatch] = useReducer(reducer, InitialState);
    const { model, serviceTypes, loading, loadingError } = state;

    useEffect(() => {
        dispatch({ type: "CUST_LOADING" });
        getCustomerInfoById(params.id).execute().then(result => {
            if (result.success) {
                dispatch({ type: "CUST_LOADED", payload: result.item });
            }
            else {
                dispatch({ type: "LOAD_FAILED" });
            }
        });
        new DataSource(dsoServiceType()).setSize(null).execute().then(result => {
            if (result.success) {
                dispatch({ type: "ST_LOADED", payload: result.data });
            }
            else {
                dispatch({ type: "LOAD_FAILED" });
            }
        });
    }, [params.id]);


    const menu = <Menu mode="horizontal" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]}>
        <Menu.Item key={match.url}>
            <span>Profil</span>
            <Link to={match.url} />
        </Menu.Item>
        <Menu.Item key={match.url + "/main"}>
            <span>Osnovno</span>
            <Link to={match.url + "/main"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/account"}>
            <span>Prijava</span>
            <Link to={match.url + "/account"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/sender"}>
            <span>Pošiljatelj</span>
            <Link to={match.url + "/sender"} />
        </Menu.Item>
        {serviceTypes.map(st => (
            <Menu.Item key={match.url + "/service/" + st.id}>
                <span>{st.name}</span>
                <Link to={match.url + "/service/" + st.id} />
            </Menu.Item>
        ))}
        <Menu.Item key={match.url + "/notif-config"}>
            <span>Obavijest</span>
            <Link to={match.url + "/notif-config"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/price-list"}>
            <span>Cjenici</span>
            <Link to={match.url + "/price-list"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/invoice"}>
            <span>Fakture</span>
            <Link to={match.url + "/invoice"} />
        </Menu.Item>
    </Menu>;

    return (
        <ProfileContext.Provider value={{ state, dispatch }}>
            {loading && <Skeleton active paragraph={{ rows: 8 }} />}
            {!loading && loadingError && <Bux size="expand" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Result
                    title="Greška prilikom učitavanja korisnika."
                    extra={<Link to="/admin/customer">Povratak na listu korisnika</Link>}
                />
            </Bux>}

            {!loading && !loadingError && <Bux.Page>
                <Bux.Header>
                    <PageHeader title={`Korisnik: ${model.name}`} footer={menu} />
                </Bux.Header>
                <Switch>
                    <Route path={match.url} exact>
                        <SummaryTab />
                    </Route>
                    <Route path={match.url + "/main"} exact>
                        <MainTab />
                    </Route>
                    <Route path={match.url + "/account"} exact>
                        <AppAccountTab />
                    </Route>
                    <Route path={match.url + "/sender"} exact>
                        <SenderTab />
                    </Route>
                    {serviceTypes.map(st => (
                        <Route key={st.id} path={match.url + "/service/" + st.id} exact>
                            <ServiceTab serviceType={st} />
                        </Route>
                    ))}
                    <Route path={match.url + "/notif-config"}>
                        <NotifConfigTab />
                    </Route>
                    <Route path={match.url + "/price-list"}>
                        <PriceListTab />
                    </Route>
                    <Route path={match.url + "/invoice"}>
                        <InvoiceTab />
                    </Route>
                </Switch>

            </Bux.Page>}
        </ProfileContext.Provider >);
}

import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { ApiCall } from "net-common-ui";

export interface DashShipmentPriceTrend {
    year: number;
    month: number;
    average: number;
    minimum: number;
    maximum: number;
    total: number;
}

export function getDashShipmentPriceTrend(customerId: number, range: [Dayjs, Dayjs]): ApiCall<DashShipmentPriceTrend> {
    return ApiCall.get(kApi, "dash/shipment-price-trend", {
        customerId,
        from: range[0].format("YYYY-MM-DD"),
        to: range[1].format("YYYY-MM-DD")
    })
}

import { Switch, Route } from 'react-router';
import { CarrierTransferImport } from './CarrierTransferImport';
import { CodTransferGroup } from './CodTransferGroup';
import { CodTransfer } from './CodTransfer';
import { Help } from "./Help";

export const Cod = ({ match }) => <Switch>
    <Route path={match.url + "/carrier-transfer-import"} exact component={CarrierTransferImport} />
    <Route path={match.url + "/transfer-group"} component={CodTransferGroup} />
    <Route path={match.url + "/transfer"} component={CodTransfer} />
    <Route path={match.url + "/help"} component={Help} />
</Switch>;

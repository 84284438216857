import React from 'react';
import { Pagination, PageHeader, Table } from 'antd';
import { FilterForm, SettlementFilter } from "./FilterForm"
import { dsoSettlement, Settlement } from "Models/Codebook/Settlement"
import { applyGridState, useDataSource, useStateManager } from "net-common-ui";
import { Bux } from 'Components/Bux';
import withFilterDrawer from 'Components/withFilterDrawer';
import { PostOffice } from 'Models/Codebook/PostOffice';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const [view, ds] = useDataSource(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });
    const [state, sm] = useStateManager<GridState<SettlementFilter>>({ sort: { field: "id", dir: "desc" } });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            fb.set("code", "contains", filter.code)
                .set("name", "contains", filter.name)
                .set("postOffice.id", "in", filter.postOffice ? filter.postOffice.map(po => po.id) : null)
                .set("ispPostOffice.id", "in", filter.ispPostOffice ? filter.ispPostOffice.map(po => po.id) : null)
        );
    });

    const handleFilterChange = (filter: SettlementFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    return <Bux.Page>
        <Bux.Header><PageHeader title={"Naselja"} /></Bux.Header>
        <Bux.SubHeader>
            <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
        </Bux.SubHeader>
        <Bux.Content>
            <Table<Settlement> rowKey="id" pagination={false} dataSource={view.data}>
                <Table.Column dataIndex="id" title="Id" />
                <Table.Column dataIndex="code" title="Šifra" />
                <Table.Column dataIndex="name" title="Naziv" />
                <Table.Column dataIndex="postOffice" title="PU" render={(po: PostOffice) => po.code + " " + po.name} />
                <Table.Column dataIndex="ispPostOffice" title="Isporučni PU" render={(po: PostOffice) => po.code + " " + po.name} />
            </Table>
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

import { useState } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { saveCustomerPriceList } from 'Models/Bus/PriceList/CustomerPriceList';
import { Customer } from 'Models/Bus/Customer';

interface Props {
    customer: Customer;
    onClose: (created: boolean) => void;
}

export const NewButton = ({ customer, onClose }: Props) => {
    const [saving, setSaving] = useState(false);

    const handleSave = async () => {
        setSaving(true);
        const result = await saveCustomerPriceList({ customer }).execute()
        setSaving(false);
        if (result.success) {
            message.success("Cjenik uspješno kreiran.")
            onClose(true);
        }
    };
    return (
        <Button block type="primary" onClick={handleSave} disabled={saving} icon={<PlusOutlined />}>Novi cjenik</Button>
    );
}

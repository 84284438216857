import { PageHeader } from 'antd';
import Bux from "Components/Bux";
import Config from "Config";
import { dsoServiceType } from 'Models/Codebook/ServiceType';
import { useDataSource } from "net-common-ui";

interface Item {
    title: string,
    href: string;
}

const staticItems: Item[] = [
    {
        title: "GDPR",
        href: "https://logexpublic.blob.core.windows.net/docs/GDPR_Logex_web.pdf"
    },
    {
        title: "Izjava o privatnosti i sigurnosti",
        href: "https://logexpublic.blob.core.windows.net/docs/Izjava_privatnosti_sigurnosti_Logex_web.pdf"
    },
    {
        title: "Korisničke upute",
        href: "https://logexpublic.blob.core.windows.net/docs/Logex_Korisnicke_upute.pdf"
    },
    {
        title: "Upute za pakiranje",
        href: "https://logexpublic.blob.core.windows.net/docs/Upute_pakiranje.pdf"
    },
    {
        title: "Upute za pakiranje (vizualno)",
        href: "https://logexpublic.blob.core.windows.net/docs/Upute_pakiranje_vizualno.pdf"
    },
    {
        title: "Uvjeti korištenja stranica logex.hr",
        href: "https://logexpublic.blob.core.windows.net/docs/Uvjeti_koristenja_Logex_web.pdf"
    },
    {
        title: "Uvjeti korištenja usluga iz portfelja Logex usluge d.o.o.",
        href: "https://logexpublic.blob.core.windows.net/docs/Uvjeti_koristenja_usluga_iz_portfelja_Logex_usluge_web.pdf"
    }
];


export const Doc = () => {
    const [serviceTypeView] = useDataSource(dsoServiceType(), { execute: true });
    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title="Dokumenti" />
            </Bux.Header>
            <Bux.Content>
                <ul>
                    {staticItems.map(({ title, href }, i) => (
                        <li><a href={href} target="_blank" rel="noreferrer">{title}</a></li>
                    ))}
                </ul>
                <ul>
                    {serviceTypeView.data.map(st => (
                        <li key={st.id}><a
                            href={`${Config.api.k}/service-type/${st.id}/zone/1/settlement;pdf`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Naselja u I zoni ({st.name})
                        </a>
                        </li>
                    ))}
                </ul>
            </Bux.Content>
        </Bux.Page>);
};

import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Callback = ((message: string, callback: (ok: boolean) => void) => void);

const callback: Callback = function (message: string, callback: (ok: boolean) => void): void {
    Modal.confirm({
        title: 'Leave current page?',
        icon: <ExclamationCircleOutlined />,
        content: <p>{message}</p>,
        okText: "Ok",
        cancelText: 'Cancel',
        onCancel: () => callback(false),
        onOk: () => callback(true)
    });
}

export default callback;
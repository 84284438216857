import { useState } from 'react';
import { Form, Button, Row, Col, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { deletePriceInsuranceRange, PriceInsuranceRange, savePriceInsuranceRange } from "Models/Bus/PriceList/PriceInsuranceRange";
import { PriceListSourceRadioGroup } from './PriceListSourceRadioGroup';
import { InsuranceEditForm, ModelWrapper } from './InsuranceEditForm';
import { PriceList } from 'Models/Bus/PriceList/PriceList';
import clone from 'just-clone';

interface Props {
    priceList: PriceList;
    basePriceList?: PriceList;
    onFinish?: (saved?: boolean, range?: PriceInsuranceRange[]) => void;
}

export const InsuranceEdit = ({ priceList, basePriceList, onFinish }: Props) => {
    const value = priceList.priceInsuranceRanges;
    const base = basePriceList?.priceInsuranceRanges;
    const actual = clone(value.length || !base ? value : base);

    const [useStandard, setUseStandard] = useState(!value.length && !!base);
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();

    const handleCancel = () => {
        onFinish();
    }
    const handleSubmit = async () => {
        const apiCall = useStandard
            ? deletePriceInsuranceRange(priceList.id)
            : savePriceInsuranceRange(priceList.id, (form.getFieldsValue() as ModelWrapper).ranges);

        setSaving(true);
        const result = await apiCall.execute();

        setSaving(false);
        if (result.success) {
            message.success("Cijene osiguranja uspješno spremljene.");
            onFinish(true, result.data);
        }
    }

    return (<>
        {base && <PriceListSourceRadioGroup useStandard={useStandard} onChange={setUseStandard} />}

        {!useStandard && <InsuranceEditForm form={form} value={{ ranges: actual }} />}

        <Row gutter={8} justify="center">
            <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
            <Col span={4}><Button disabled={saving} type="primary" onClick={handleSubmit} block icon={<SaveOutlined />}>Spremi</Button></Col>
        </Row>
    </>);
}

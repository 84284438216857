import { useEffect, useReducer } from 'react';
import { getCustomerPriceListInfoById } from "Models/Bus/PriceList/CustomerPriceList"
import { dsoServiceType } from 'Models/Codebook/ServiceType';
import { Link, useParams, Switch, Route, Redirect } from 'react-router-dom';
import { ProfileContext, reducer, InitialState } from './Context';
import { MainTab } from "./MainTab";
import { WeightTab } from "./WeightTab";
import { InsuranceTab } from "./InsuranceTab";
import { DiscountTab } from "./DiscountTab";
import { AdditionalServiceTab } from "./AdditionalServiceTab";
import { Bux } from 'Components/Bux';
import { Menu, PageHeader, Skeleton } from 'antd';
import { DataSource } from 'net-common-ui';

export const Profile = ({ match, location }) => {
    const params = useParams<{ id: string }>();
    const [state, dispatch] = useReducer(reducer, InitialState);

    const { model, serviceTypes, loading } = state;

    useEffect(() => {
        dispatch({ type: "PL_LOADING" });
        getCustomerPriceListInfoById(params.id).execute().then(result => {
            dispatch({ type: "PL_LOADED", payload: result.item });
        });
        new DataSource(dsoServiceType()).setSize(null).execute().then(result => {
            dispatch({ type: "ST_LOADED", payload: result.data });
        });
    }, [params.id]);

    if (!loading && !model) {
        return <Redirect to="/admin/customer" />
    }

    const menu = <Menu mode="horizontal" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]}>
        <Menu.Item key={match.url}>
            <span>Profil</span>
            <Link to={match.url} />
        </Menu.Item>
        {serviceTypes.map(st => (
            <Menu.Item key={match.url + "/weight/" + st.id}>
                <span>{st.name}</span>
                <Link to={match.url + "/weight/" + st.id} />
            </Menu.Item>
        ))}
        <Menu.Item key={match.url + "/insurance"}>
            <span>Osiguranje</span>
            <Link to={match.url + "/insurance"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/additional-service"}>
            <span>Dodatne usluge</span>
            <Link to={match.url + "/additional-service"} />
        </Menu.Item>
        <Menu.Item key={match.url + "/discount"}>
            <span>Rabat</span>
            <Link to={match.url + "/discount"} />
        </Menu.Item>
    </Menu>;

    return (<ProfileContext.Provider value={{ state, dispatch }}>
        {loading
            ? <Skeleton active paragraph={{ rows: 8 }} />
            : <Bux.Page>
                <Bux.Header>
                    <PageHeader title={`Cjenik #${model.ordinal}`} subTitle={model.customer.code + " " + model.customer.name} footer={menu} />
                </Bux.Header>
                <Switch>
                    <Route path={match.url} exact>
                        <MainTab />
                    </Route>
                    {serviceTypes.map(st => (
                        <Route key={st.id} path={match.url + "/weight/" + st.id} exact>
                            <WeightTab serviceType={st} />
                        </Route>
                    ))}
                    <Route path={match.url + "/insurance"} exact>
                        <InsuranceTab />
                    </Route>
                    <Route path={match.url + "/additional-service"} exact>
                        <AdditionalServiceTab />
                    </Route>
                    <Route path={match.url + "/discount"} exact>
                        <DiscountTab />
                    </Route>
                </Switch>

            </Bux.Page>}

    </ProfileContext.Provider>);
}


import React, { useMemo, useState } from 'react';
import { Button, Result, Table, Tabs } from 'antd';
import { ExcelState, ExcelStateEntry } from "Models/ExcelState";
import { useHistory } from 'react-router-dom';
//import Bux from './Bux';
//const { Title, Paragraph } = Typography;


type Props = React.PropsWithChildren<{
    result: ExcelState;
    subTitle?: string;
    backHref?: string;
    backOnClick?: () => void;
}>;

export const ExcelStateDisplay = ({ result, subTitle, backHref, backOnClick, children }: Props) => {
    const [activeKey, setActiveKey] = useState(children && result.isSuccess ? "custom" : "info");
    const showCustom = result.isSuccess && !!children;
    const showBack = !!(backOnClick || backHref);
    const messages = useMemo(() => result.messages.map((m, i) => ({ id: i, ...m })), [result.messages]);
    const history = useHistory();

    const handleBack = () => {
        backHref && history.push(backHref);
        backOnClick && backOnClick();
    }

    return (<>
        <Tabs activeKey={activeKey} onChange={setActiveKey}>
            <Tabs.TabPane tab="Info" key="info">
                <Result
                    status={result.isSuccess ? "success" : "error"}
                    title={result.isSuccess ? "Obrada uspješno završena" : "Greška tokom obrade"}
                    subTitle={subTitle ?? "Poruke obrade su dostupne kroz tab 'Poruke'."}
                    extra={[
                        showBack ? <Button key="back" onClick={handleBack}>Povratak</Button> : null,
                        showCustom
                            ? <Button type="primary" key="custom" onClick={() => setActiveKey("custom")}>Obrada</Button>
                            : <Button key="messages" onClick={() => setActiveKey("messages")}>Poruke</Button>
                    ]}
                />
            </Tabs.TabPane>

            {showCustom && <Tabs.TabPane tab="Obrada" key="custom">
                {children}
            </Tabs.TabPane>}

            <Tabs.TabPane tab="Poruke" key="messages">
                <Table<ExcelStateEntry> rowKey="id" pagination={false} dataSource={messages}>
                    <Table.Column dataIndex="level" width={90} title="Level" render={level => <Level level={level} />} />
                    <Table.Column dataIndex="sheet" width={100} ellipsis title="Sheet" />
                    <Table.Column dataIndex="cell" width={80} title="Row/Cell" />
                    <Table.Column dataIndex="message" title="Poruka" ellipsis />
                </Table>
            </Tabs.TabPane>

        </Tabs>
    </>);
}

const Level = ({ level }: { level: "I" | "W" | "E" }) => {
    let label: string;
    switch (level) {
        case "I": label = "Info"; break;
        case "W": label = "Upozorenje"; break;
        case "E": label = "Greška"; break;
    }
    return <span>{label}</span>;
}

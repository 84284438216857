import { kApi } from "Api";
import { ApiCall } from "net-common-ui";

export interface PriceWeightExtra {
    id?: number;
    priceListId?: number;
    serviceTypeId?: number;
    weightIncrement?: number;
    price?: number;
}

export function savePriceWeightExtra(priceListId: number, serviceTypeId: number, model: PriceWeightExtra): ApiCall<PriceWeightExtra> {
    return ApiCall.put(kApi, `price-list/${priceListId}/service-type/${serviceTypeId}/price-weight-extra`, model);
}

export function deletePriceWeightExtra(priceListId: number, serviceTypeId: number): ApiCall<PriceWeightExtra> {
    return ApiCall.delete(kApi, `price-list/${priceListId}/service-type/${serviceTypeId}/price-weight-extra`);
}
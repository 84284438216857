import { kApi } from "Api";
import { Dayjs } from "dayjs";
import { UrlParam, ApiCall } from "net-common-ui";
import { DataSourceOptions } from "net-common-ui/dist/Data/DataSource";
import { Customer } from "../Customer";
import { PriceList } from "./PriceList";
import { StandardPriceList } from "./StandardPriceList";

export interface CustomerPriceList {
    id?: number;
    ordinal?: number;
    isActive?: boolean;
    isPublished?: boolean;
    invoiceCount?: number;
    validFrom?: Dayjs;
    validTo?: Dayjs;

    customer?: Customer;
    standardPriceList?: StandardPriceList;
    priceList?: PriceList;
}

export function getCustomerPriceListById(id: UrlParam): ApiCall<CustomerPriceList> {
    return ApiCall.get(kApi, `customer-price-list/${id}`);
}

export function getCustomerPriceListInfoById(id: UrlParam): ApiCall<CustomerPriceList> {
    return ApiCall.get(kApi, `customer-price-list/${id};info`);
}

export function saveCustomerPriceList(model: CustomerPriceList): ApiCall<CustomerPriceList> {
    return ApiCall.post(kApi, "customer-price-list", model);
}

export function publishCustomerPriceList(id: UrlParam, isPublished: boolean, validFrom?: Dayjs): ApiCall<any> {
    return ApiCall.patch(kApi, `customer-price-list/${id}/isPublished`, isPublished, {
        validFrom: validFrom?.format("YYYY-MM-DD")
    });
}

export function deleteCustomerPriceList(id: UrlParam): ApiCall<any> {
    return ApiCall.delete(kApi, null, `customer-price-list/${id}`);
}

export function dsoCustomerPriceListByCustomerId(customerId: UrlParam): DataSourceOptions<CustomerPriceList> {
    return {
        api: kApi,
        url: `customer/${customerId}/customer-price-list`
    };
}
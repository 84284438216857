import { Button, message, Space } from "antd";
import { useEffect, useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { useActiveScreenSize } from "net-common-ui";
import dayjs, { Dayjs } from "dayjs";
import { DatePickerEx } from "Components/DatePickerEx";

export const DateRanges: Record<string, [Dayjs, Dayjs]> = {
    "Zadnjih 5 dana": [dayjs().subtract(5, "days"), dayjs()],
    "Zadnih 10 dana": [dayjs().subtract(10, "days"), dayjs()],
    "Zadnih 30 dana": [dayjs().subtract(30, "days"), dayjs()],
    "Ovaj mjesec": [dayjs().startOf("month"), dayjs().endOf("month")],
}

export interface DashboardSettings {
    range: [Dayjs, Dayjs];
}

const MAX_RANGE = 40;

interface Props {
    loading?: boolean;
    onChange: (value: DashboardSettings) => void;
    disabled?: boolean;
    initialValue: DashboardSettings;
}

export const Settings = ({ onChange, loading, disabled, initialValue }: Props) => {
    const [value, setValue] = useState<DashboardSettings>(null);
    const [screnSize] = useActiveScreenSize();

    const showButtonText = screnSize !== "mobile";

    const change = (values: Partial<DashboardSettings>) => {
        setValue(existing => {
            const newValue = { ...existing, ...values };
            if (newValue.range && newValue.range[0] && newValue.range[1]) {
                if (newValue.range[1].diff(newValue.range[0], "days") > MAX_RANGE) {
                    newValue.range[1] = newValue.range[0].add(MAX_RANGE, "days");
                    message.warn("Maksimalni dozvoljeni raspon je " + MAX_RANGE + " dana.");
                }
                return newValue;
            }
            return initialValue;
        });
    }
    const submit = () => {
        onChange(value);
    }

    useEffect(() => {
        // initial load
        setValue(initialValue)
        onChange(initialValue);
    }, [onChange, setValue, initialValue]);

    if (!value) return null;

    return (<Space direction="horizontal" style={{ float: "right" }}>
        <DatePickerEx.RangePicker
            disabled={disabled}
            ranges={DateRanges}
            format="L"
            value={value.range}
            onChange={v => change({ range: v })}
            style={{ width: 210 }}
        />
        <Button
            type="primary"
            loading={loading}
            icon={<SyncOutlined />}
            onClick={submit}
        //disabled={disabled}
        >
            {showButtonText && "Osvježi"}
        </Button>
    </Space>);

}

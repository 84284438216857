import { useState } from "react";
import { Button, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { kApi } from "Api";
import { getAuthManager, parseJson } from "net-common-ui";
import { UploadProps } from "antd/lib/upload";
import { getShipmentCodAllowUpdate, ShipmentCod } from "Models/Logi/ShipmentCod";
import { ExcelState, makeFromResponse } from "Models/ExcelState";

interface Props {
    onComplete: (result: ExcelState<ShipmentCod[]>) => void;
    onSuccess?: (success: ExcelState) => void;
    onError?: (error: ExcelState) => void;
}

export const UploadButton = ({ onComplete, onSuccess, onError }: Props) => {
    const [uploading, setUploading] = useState(false);

    const handleResult = (response: any) => {
        const ers = makeFromResponse<ShipmentCod[]>(response);
        //console.log(ers, ers.result);

        // ers.result is undefined when import fails
        if (ers.result) {
            ers.result.forEach(item => item.shouldUpdate = getShipmentCodAllowUpdate(item));
        }

        if (ers.isSuccess) {
            // all good
            message.success("Obrada datoteke uspješna.");
            onSuccess && onSuccess(ers);
        }
        else {
            message.error("Greška kod uvoza.");
            onError && onError(ers);
        }
        onComplete(ers);
    };

    const props: UploadProps = {
        name: 'file',
        action: `${kApi.defaults.baseURL}/import/shipment-cod`,
        showUploadList: false,
        headers: {
            "Authorization": "Bearer " + getAuthManager().bearer
        },
        onChange(info) {
            switch (info.file.status) {
                case "uploading":
                    setUploading(true);
                    break;

                case "done":
                case "error":
                    setUploading(false);

                    // for errors (problem model): info.file.response.state
                    // for success: info.file.response
                    if (info.file.response.state) {
                        handleResult(info.file.response.state);
                    }
                    else {
                        // use custom parseJson to handle dates correctly
                        const response = parseJson(info.file.xhr.responseText);
                        handleResult(response);
                    }
                    break;
            }
        }
    };

    return (<Upload {...props}>
        <Button loading={uploading} type="primary" icon={<UploadOutlined />}>Uvoz</Button>
    </Upload>);
}

import { useState } from 'react';
import { Col, Form, Row, Typography } from 'antd';
import { PriceInsuranceRange } from 'Models/Bus/PriceList/PriceInsuranceRange';
import { FormInstance } from 'antd/lib/form';
import { InsuranceEditFormItem } from './InsuranceEditFormItem';
import { InsuranceExample } from "./InsuranceExample";

export interface ModelWrapper {
    ranges: PriceInsuranceRange[];
}

interface Props {
    form: FormInstance;
    value: ModelWrapper;
}

export const InsuranceEditForm = ({ form, value }: Props) => {
    const [ranges, setRanges] = useState<PriceInsuranceRange[]>(value.ranges);
    //console.log(ranges);

    const handleChange = (_, allValues: any) => {
        if (allValues?.ranges) {
            setRanges(allValues.ranges);
        }
    }

    return (
        <Row gutter={[8, 40]} justify="center">
            <Col sm={24} md={16}>
                <Form form={form} layout="vertical" name="form" initialValues={value} onValuesChange={handleChange}>
                    <Typography.Title level={3}>Razredi cijena za osiguranje pošiljke</Typography.Title>
                    <Form.Item name="ranges">
                        <InsuranceEditFormItem />
                    </Form.Item>
                </Form>
            </Col>
            <Col sm={24} md={16}>
                <Typography.Title level={3}>Primjer izračuna</Typography.Title>
                <InsuranceExample ranges={ranges} />
            </Col>
        </Row>
    )
}

import { Switch, Route } from "react-router";

import { Grid } from "./Grid";
import { WeightImport } from "./WeightImport";
import { Profile } from "./Profile";

export const Shipment = ({ match }) => (
    <Switch>
        <Route path={match.url} exact component={Grid} />
        <Route path={match.url + "/import-weight"} exact component={WeightImport} />
        <Route path={match.url + "/:id(\\d+)"} component={Profile} />
    </Switch>);

import React, { useState } from 'react';
import { Button, Col, Empty, message, PageHeader, Row } from 'antd';
import { UploadButton } from "./UploadButton"
import { Bux } from 'Components/Bux';
import { saveShipmentCodMultiple, ShipmentCod } from 'Models/Logi/ShipmentCod';
import { EditForm } from "./EditForm";
import { ExcelStateDisplay } from 'Components/ExcelStateDisplay';
import { ExcelState } from 'Models/ExcelState';

export const CarrierTransferImport = () => {
    const [ers, setErs] = useState<ExcelState<ShipmentCod[]>>(null);
    const [saving, setSaving] = useState(false);

    const handleSave = async (model: ShipmentCod[]) => {
        setSaving(true);
        const result = await saveShipmentCodMultiple(model).execute();
        if (result.success) {
            message.success("Uvoz uspješno završen.");
            setSaving(false);
            setErs(null);
        };
    }
    const handleReset = () => {
        setErs(null);
    }

    const showReset = ers && !ers.isSuccess;

    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title={"Uvoz isplate kurira"} extra={showReset && <Button onClick={handleReset} type="primary" ghost>Povratak</Button>} />
            </Bux.Header>
            <Bux.Content>
                {!ers && <Row justify="center">
                    <Col span={8}>
                        <Empty description="Uvezite isplatnu listu od kurira.">
                            <UploadButton key="upload" onComplete={ers => setErs(ers)} />
                        </Empty>
                    </Col>
                </Row>}
                {ers && <ExcelStateDisplay result={ers} backOnClick={handleReset}>
                    <EditForm value={ers.result} saving={saving} onSave={handleSave} onCancel={handleReset} />
                </ExcelStateDisplay>}
            </Bux.Content>
        </Bux.Page>
    );
};

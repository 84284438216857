import { useState } from 'react';
import { Button, Empty, PageHeader, Space } from 'antd';
import { UploadButton } from "./UploadButton"
import { ExcelState } from "Models/ExcelState";
import { Bux } from 'Components/Bux';
import { ExcelStateDisplay } from "Components/ExcelStateDisplay"
import { kApi } from 'Api';
import { getAppInfo } from 'Models/AppInfo';

export const Management = () => {
    const [ers, setErs] = useState<ExcelState>(null);

    const handleTestIntegration = async (force: boolean) => {
        const result = await kApi.post("util/integration-connectivity", null, { params: { force } });
        if (result.status === 200) alert(result.data);
        else alert("error: " + result.status);
    }

    const handleSyncIntegration = async () => {
        const result = await kApi.post("util/integration-sync");
        if (result.status === 200) alert(result.data);
        else alert("error: " + result.status);
    }

    const handleAppInfo = async () => {
        const result = await getAppInfo().execute();
        alert(JSON.stringify(result.item));
    }

    return (
        <Bux.Page>
            <Bux.Header>
                <PageHeader title="Uvoz šifarnika" />
            </Bux.Header>
            <Bux.SubHeader>
                <Space direction="horizontal">
                    <Button onClick={() => handleTestIntegration(false)}>Test integration</Button>
                    <Button onClick={() => handleTestIntegration(true)}>Test integration (force)</Button>
                    <Button onClick={handleSyncIntegration}>Sync integration</Button>
                    <Button onClick={handleAppInfo}>App info</Button>
                </Space>
            </Bux.SubHeader>
            <Bux.Content>
                {ers
                    ? <ExcelStateDisplay result={ers} backOnClick={() => setErs(null)} />
                    : <Empty description="Uvezite excel datoteku." image={null}>
                        <UploadButton key="upload-codebook" onComplete={ers => setErs(ers)} />
                    </Empty>}
            </Bux.Content>
        </Bux.Page>
    );
};

import React, { useState } from 'react';
import { Customer, saveCustomer } from "Models/Bus/Customer"
import { CodeInput, useDataSourceRef } from "net-common-ui";
import { Form, Input, Button, Row, Col, Typography, Radio, InputNumber, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { InvoiceInterval, invoiceIntervalLabel, LabelFormat, labelFormatLabel } from 'Models/Enum';
import { SettlementComboBox } from 'Components/SettlementComboBox';
import { antOibValidator } from "Components/Validation"
import { dsoSettlement } from 'Models/Codebook/Settlement';

interface Props {
    customer: Customer;
    onUpdate: (updated: boolean, customer?: Customer) => void;
}

export const Edit = React.forwardRef(({ customer, onUpdate }: Props, ref: any) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [settlementDs] = useDataSourceRef(dsoSettlement(), {
        configure: b => b.setQuery(cfg => cfg.sort.set("postOffice.code", "asc").set("name", "asc"))
    });

    const handleCancel = () => {
        onUpdate(false);
    }
    const handleSave = () => {
        form.validateFields().then((model: Customer) => {
            setSaving(true);
            // note izbijamo van jer ne zelimo da se spremi s ovom formom (ima svoj patch, a ovo bi ga randomly resetiralo na prethodni value)
            saveCustomer({ ...customer, ...model, note: null }).execute().then(result => {
                setSaving(false);
                if (result.success) {
                    message.success("Korisnik uspješno spremljen.");
                    onUpdate(true, result.item);
                }
            });
        });
    }

    const verticalRadioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <Form ref={ref} form={form} layout="vertical" name="form" onFinish={handleSave} initialValues={customer}>
            <Row gutter={[8, 40]} justify="center">
                <Col span={8}>
                    <Typography.Title level={3}>Osnovno</Typography.Title>
                    <Form.Item name="code" label="Šifra" rules={[{ required: true }]}>
                        <CodeInput disabled />
                    </Form.Item>
                    <Form.Item name="name" label="Naziv" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="contractNumber" label="Broj ugovora">
                        <Input />
                    </Form.Item>
                    <Form.Item name="oib" label="OIB" rules={[{ required: true }, { validator: antOibValidator }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="iban" label="IBAN" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="codIban" label="IBAN za otkupnine" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="invoiceIntervalId" label="Interval fakturiranja" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio style={verticalRadioStyle} value={InvoiceInterval.Montly}>{invoiceIntervalLabel(InvoiceInterval.Montly)}</Radio>
                            <Radio style={verticalRadioStyle} value={InvoiceInterval.BiMonthly}>{invoiceIntervalLabel(InvoiceInterval.BiMonthly)}</Radio>
                            <Radio style={verticalRadioStyle} value={InvoiceInterval.Weekly}>{invoiceIntervalLabel(InvoiceInterval.Weekly)}</Radio>
                            <Radio style={verticalRadioStyle} value={InvoiceInterval.Daily}>{invoiceIntervalLabel(InvoiceInterval.Daily)}</Radio>
                            <Radio style={verticalRadioStyle} value={InvoiceInterval.Never}>{invoiceIntervalLabel(InvoiceInterval.Never)}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="invoiceDueOnOffset" label="Valuta plaćanja (dana)">
                        <InputNumber placeholder="30" max={500} min={1} step={1} />
                    </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                    <Typography.Title level={3}>Sjedište</Typography.Title>
                    <Form.Item label="Adresa">
                        <Form.Item name="street" noStyle rules={[{ required: true, message: "Ulica je obavezan unos." }]}>
                            <Input placeholder="Ulica" maxLength={200} style={{ display: 'inline-block', width: 'calc(100% - 70px)' }} />
                        </Form.Item>
                        <Form.Item name="streetNo" noStyle rules={[{ required: true, message: "Kućni broj je obavezan unos." }]}>
                            <Input placeholder="K.br." maxLength={20} style={{ display: 'inline-block', width: '60px', marginLeft: '10px' }} />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item name="settlement" label="Mjesto" rules={[{ required: true }]}>
                        <SettlementComboBox dataSource={settlementDs} />
                    </Form.Item>

                    <Typography.Title level={3}>Kontakt</Typography.Title>
                    <Form.Item name="phone" label="Telefon" rules={[{ required: true }]}>
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
                        <Input maxLength={200} />
                    </Form.Item>
                    <Form.Item name="emailInvoice" label="Email za fakture" rules={[{ type: "email" }]}>
                        <Input maxLength={200} />
                    </Form.Item>
                    <Form.Item name="emailCodTransfer" label="Email za transfere otkupnina" rules={[{ type: "email" }]}>
                        <Input maxLength={200} />
                    </Form.Item>

                    <Typography.Title level={3}>Ostalo</Typography.Title>
                    <Form.Item name="labelFormatId" label="Vrsta adresnica" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio style={verticalRadioStyle} value={InvoiceInterval.Montly}>{labelFormatLabel(LabelFormat.A42PerPage)}</Radio>
                            <Radio style={verticalRadioStyle} value={InvoiceInterval.BiMonthly}>{labelFormatLabel(LabelFormat.A44PerPage)}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8} justify="center">
                <Col span={4}><Button disabled={saving} type="primary" onClick={handleCancel} block ghost>Odustani</Button></Col>
                <Col span={4}><Button disabled={saving} htmlType="submit" type="primary" block icon={<SaveOutlined />}>Spremi</Button></Col>
            </Row>
        </Form>
    )
});

import { Pagination, PageHeader, Space, Button } from 'antd';
import { FilterForm, ShipmentFilter, applyShipmentFilter } from "Components/Shipment/FilterForm"
import { useDataSource, useStateManager, applyGridState } from "net-common-ui";
import Bux from "Components/Bux";
import { dsoShipment } from 'Models/Logi/Shipment';
import withFilterDrawer from "Components/withFilterDrawer"
import downloadStream from 'downloadStream';
import { FileExcelOutlined } from '@ant-design/icons';
import { kApi } from 'Api';
import { AdminShipmentTable } from 'Components/Shipment/Table';
import { useHistory } from 'react-router-dom';
import { GridState } from 'net-common-ui/dist/Data/GridState';

const FilterDrawer = withFilterDrawer(FilterForm);

export const Grid = () => {
    const history = useHistory();
    //const [columns, columnSelector] = useGridViewDrawer("shipments:admin", AllColumns, ["code", "customer"], true);

    const [view, ds] = useDataSource(dsoShipment()); // DO NOT EXECUTE (pre-ui logic)
    const [state, sm] = useStateManager<GridState<ShipmentFilter>>({
        sort: { field: "id", dir: "desc" },
        filter: { showCancelled: true }
    });

    sm.registerDataSource(ds, (ds, state) => {
        applyGridState(ds, state, (fb, filter) =>
            applyShipmentFilter(fb, filter)
        );
    });

    const handleFilterChange = (filter: ShipmentFilter) => {
        sm.update(
            (draft) => {
                draft.filter = filter;
                draft.page = 1;
            },
            { executeDataSources: "force" },
        );
    };

    const handleDownloadXls = async () => {
        // filename is igonred if content disposition is successfully sniffed
        const result = await downloadStream(kApi, {
            url: "shipment;xls",
            sniffContentDisposition: true,
            fileName: "shipments.xlsx",
            params: { filter: ds.builder.filter.serialize() }
        });
        result.success && result.download();
    }

    return <Bux.Page>
        <Bux.Header>
            <PageHeader title={"Pošiljke"} />
        </Bux.Header>
        <Bux.SubHeader>
            <Space>
                <FilterDrawer onChange={handleFilterChange} disabled={view.loading} filter={state.filter} />
                {/* {columnSelector} */}
                <Button disabled={!view.total} onClick={handleDownloadXls} icon={<FileExcelOutlined />}>Izvoz</Button>
            </Space>
            <Space style={{ float: "right" }}>
                <Button type="primary" onClick={() => history.push("/admin/shipment/import-weight")}>Uvoz verif. mase</Button>
            </Space>
        </Bux.SubHeader>
        <Bux.Content>
            <AdminShipmentTable dataView={view} />
        </Bux.Content>
        <Bux.Footer>
            <Pagination
                disabled={view.loading}
                onChange={(page) =>
                    sm.update((draft) => {
                        draft.page = page;
                    })
                }
                current={view.page}
                total={view.total}
                pageSize={view.size}
                showSizeChanger={false}
                showTotal={total => "Ukupno: " + total}
            />
        </Bux.Footer>
    </Bux.Page>;
};

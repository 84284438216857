import { Badge, Button, ButtonProps, message, Modal, Popover } from 'antd';
import { cancelShipment, deleteShipment, saveShipmentGratis, Shipment } from 'Models/Logi/Shipment';
import { WarningOutlined, DeleteOutlined, EuroOutlined } from '@ant-design/icons';

interface Props extends Omit<ButtonProps, "onClick" | "type" | "disabled"> {
    shipment: Shipment;
    onExecuted: () => void;
}

export const DeleteButton = ({ shipment, onExecuted, ...rest }: Props) => {
    const allow = shipment && !shipment.shipmentGroup && !shipment.isDeleted;

    const handleDelete = async () => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Obrisati pošiljku? Ova akcija je nepovratna.</p>,
            okType: "primary",
            okText: "Obriši",
            okButtonProps: { danger: true },
            async onOk() {
                const result = await deleteShipment(shipment.id, true).execute();
                if (result.success) {
                    message.success("Pošiljka uspješno obrisana.");
                    onExecuted();
                }
            }
        });
    }

    return <Popover
        title="Brisanje pošiljke"
        content={<div style={{ maxWidth: 400 }}>
            <p>Brisanje će trajno ukloniti ovu pošiljku! Ukoliko ste ispisali adresnice, molimo da iste ne koristite nakon brisanja!</p>
            <p>
                Pošiljku {allow ? "je" : "nije"} moguće obrisati:
                <br />
                {shipment.shipmentGroup
                    ? <Badge status="error" text="Zahtjev je predan" />
                    : <Badge status="success" text="Zahtjev nije predan" />
                }
                {shipment.isDeleted && <><br /><Badge status="error" text="Pošiljka je obrisana" /></>}
            </p>
        </div>}
    >
        <Button key="delete" disabled={!allow} type="ghost" danger onClick={handleDelete} {...rest} icon={<DeleteOutlined />}>Obriši</Button>
    </Popover>;
}

export const StornoButton = ({ shipment, onExecuted, ...rest }: Props) => {
    const allow = shipment && shipment.shipmentGroup && !shipment.isDeleted && !shipment.isCancelled && shipment.status.logicalOrder < 40;

    const handleStorno = async () => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Stornirati pošiljku? Ova akcija je nepovratna.</p>,
            okType: "primary",
            okText: "Storniraj",
            okButtonProps: { danger: true },
            async onOk() {
                const result = await cancelShipment(shipment.id, true).execute();
                if (result.success) {
                    message.success("Pošiljka uspješno stornirana.");
                    onExecuted();
                }
            }
        });
    }

    return <Popover
        title="Storno pošiljke"
        content={<div style={{ maxWidth: 400 }}>
            <p>
                Storniranje će ukloniti pošiljku sa većine mjesta u aplikaciji, ali neće trajno izbrisati podatke.
                Ukoliko se pošiljka stornira, a nakon toga od kurira dođe nova informacija o statusu - oznaka STORNO će biti automatski uklonjena.
            </p>
            <p>
                Pošiljku {allow ? "je" : "nije"} moguće stornirati:
                <br />
                {shipment.isCancelled
                    ? <Badge status="error" text="Pošiljka je već stornirana" />
                    : <Badge status="success" text="Pošiljka trenutno nije stornirana" />
                }
                {shipment.isDeleted && <><br /><Badge status="error" text="Pošiljka je obrisana" /></>}
                <br />
                {shipment.shipmentGroup
                    ? <Badge status="success" text="Zahtjev je predan" />
                    : <Badge status="error" text="Zahtjev nije predan" />
                }
                <br />
                {shipment.status.logicalOrder < 40
                    ? <Badge status="success" text="Kurir nije zaprimio pošiljku" />
                    : <Badge status="error" text="Kurir je zaprimio pošiljku" />
                }
            </p>
        </div>}
    >
        <Button key="delete" disabled={!allow} type="ghost" danger onClick={handleStorno} {...rest} icon={<DeleteOutlined />}>Storniraj</Button>
    </Popover>;
}

export const GratisButton = ({ shipment, onExecuted, ...rest }: Props) => {
    const allow = shipment && shipment.shipmentGroup && !shipment.isDeleted && !shipment.isCancelled
        && !shipment.isGratis && !shipment.shipmentInvoice && !shipment.shipmentReturnInvoice

    const handleGratis = async () => {
        Modal.confirm({
            icon: <WarningOutlined />,
            content: <p>Aktivirati GRATIS za pošiljku? Pošiljka se neće fakturirati!</p>,
            okType: "primary",
            okText: "Aktiviraj",
            async onOk() {
                const result = await saveShipmentGratis(shipment.id, true).execute();
                if (result.success) {
                    message.success("Pošiljka uspješno ažurirana.");
                    onExecuted();
                }
            }
        });
    }

    return <Popover
        title="Gratis opcija"
        content={<div style={{ maxWidth: 400 }}>
            <p>
                Aktivacija GRATIS opcije za pošiljku će onemogućiti kreiranje fakture za ovu pošiljku.
            </p>
            <p>
                Gratis opciju {allow ? "je" : "nije"} moguće aktivirati:
                <br />
                {shipment.isCancelled
                    ? <Badge status="error" text="Pošiljka je stornirana" />
                    : <Badge status="success" text="Pošiljka trenutno nije stornirana" />
                }
                {shipment.isDeleted && <><br /><Badge status="error" text="Pošiljka je obrisana" /></>}
                <br />
                {shipment.shipmentGroup
                    ? <Badge status="success" text="Zahtjev je predan" />
                    : <Badge status="error" text="Zahtjev nije predan" />
                }
                <br />
                {shipment.isGratis
                    ? <Badge status="error" text="Opcija Gratis je već aktivirana" />
                    : <Badge status="success" text="Opcija Gratis nije aktivirana" />
                }
                <br />
                {!shipment.shipmentInvoice && !shipment.shipmentReturnInvoice
                    ? <Badge status="success" text="Pošiljka nema izrađenu faktru" />
                    : <Badge status="error" text="Pošiljka je već fakturirana" />
                }
            </p>
        </div>}
    >
        <Button key="gratis" disabled={!allow} onClick={handleGratis} {...rest} icon={<EuroOutlined />}>Gratis</Button>
    </Popover>;
}

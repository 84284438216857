import React, { CSSProperties } from "react";
import { Layout as AntdLayout } from "antd";
import { classNames, useActiveScreenSize } from "net-common-ui";

type SizeType = "compact" | "expand" | "auto";
type ChildrenFlowType = "vertical" | "horizontal";
type ScrollType = "x" | "y" | "both" | "none";

export interface BoxProps {
    size?: SizeType;
    childrenFlow?: ChildrenFlowType;
    scroll?: ScrollType;
    className?: string;
    children?: React.ReactNode;
    style?: CSSProperties;
    [key: string]: any;
}

const options = {
    size: {
        "compact": "box-flex-compact",
        "expand": "box-flex-expand",
        "auto": "box-flex-auto"
    },
    childrenFlow: {
        "horizontal": "box-flex-row",
        "vertical": "box-flex-col"
    },
    scroll: {
        "y": "box-overflow-y",
        "x": "box-overflow-x",
        "both": "box-overflow-both",
        "none": "box-overflow-hidden"
    }
}

export const Bux: IBux<BoxProps> = ({ size, childrenFlow, scroll, className, children, ...rest }: BoxProps) => {
    const cls = className ? className.split(" ") : [];

    const addClass = (x: string) => cls.push(x);

    cls.push("box");
    if (size) addClass(options.size[size]);
    if (childrenFlow) addClass(options.childrenFlow[childrenFlow]);
    if (scroll) addClass(options.scroll[scroll]);

    const classNames = cls.filter(c => !!c).join(" ");
    return <div {...rest} className={classNames}>{children}</div>;
}




type IBux<T> = React.FunctionComponent<T> & {
    Layout: typeof Layout
    MenuBar: typeof MenuBar;

    Header: typeof Header;
    SubHeader: typeof SubHeader;
    Page: typeof Page;
    Content: typeof Content;
    Sidebar: typeof Sidebar;
    Footer: typeof Footer;
}


const Layout = ({ children }) => <Bux childrenFlow="horizontal" size="expand" className="spec-layout">{children}</Bux>;
const MenuBar = ({ children }) => {
    const [screenSize] = useActiveScreenSize();
    return <AntdLayout.Sider style={{ overflowY: "auto" }} collapsed={screenSize !== "desktop"}>{children}</AntdLayout.Sider>;
}

const Sidebar = ({ children }) => <Bux childrenFlow="vertical" className="spec-sidebar">{children}</Bux>;
const Page = ({ children, className }: BoxProps) => <Bux size="expand" childrenFlow="vertical" className={classNames("spec-page", className)}>{children}</Bux>;
const Content = ({ children }) => <Bux size="expand" scroll="y" className="spec-content">{children}</Bux>;

const Header = ({ children }) => <Bux className="spec-header">{children}</Bux>;
const SubHeader = ({ children }) => <Bux className="spec-subheader">{children}</Bux>;
const Footer = ({ children }) => <Bux className="spec-footer">{children}</Bux>;

Bux.Layout = Layout;
Bux.MenuBar = MenuBar;
Bux.Header = Header;
Bux.SubHeader = SubHeader;
Bux.Page = Page;
Bux.Content = Content;
Bux.Sidebar = Sidebar;
Bux.Footer = Footer;
export default Bux;

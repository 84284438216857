import React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

type InputPercentProps = Omit<InputNumberProps, "min" | "max" | "value"> & {
    value?: number;
};

export const InputPercent = React.forwardRef(({ precision, value, onChange, ...props }: InputPercentProps, ref: any) => {
    const handleChange = (value: number) => {
        onChange(value ? value / 100 : value);
    }
    return <InputNumber
        ref={ref}
        min={0}
        max={100}
        precision={precision === undefined ? 2 : precision}
        placeholder="%"
        decimalSeparator=","
        onChange={handleChange}
        value={value ? value * 100 : value}
        autoComplete="off"
        autoCorrect="off"
        {...props}
    />;
});
